import palette from '../palette'

export default {
  tooltip: {
    backgroundColor: palette.text.primary,
    padding: 6,
    paddingLeft: 8,
    paddingRight: 8,
    fontSize: 12,
    borderRadius: 10,
  },
  arrow: {
    color: palette.text.primary,
  },
}
