import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import {
  DrawerHeader,
  RichTextEditor,
  TicketRevTextField,
  ChannelIcon,
  Alert,
  PaperLoader,
} from 'components'
import { useQuery } from 'react-query'
import { api, queryKeys } from 'services'
import { makeStyles, Theme } from '@material-ui/core'
import useDebounce from 'utils/useDebounce'
import { StepProps, SmsProps } from '../../../helpers'

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    marginTop: theme.spacing(2),
  },
}))

interface Props {
  step: SmsProps
  updateStep: (newStep: StepProps) => void
}

const SmsForm = ({ step, updateStep }: Props) => {
  const classes = useStyles()

  const { data: textTokensData, error: textTokensError } = useQuery(
    queryKeys.textTokens(),
    () => api.general.referenceData.getAllTextTokens(false),
    {
      refetchOnWindowFocus: false,
    }
  )

  const { setFieldValue, values } = useFormik({
    initialValues: {
      senderName: step.senderName,
      messageBody: step.messageBody,
    },
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: () => updateStep(step),
  })

  const [debouncedUpdate] = useDebounce(values, 350)
  useEffect(() => {
    const newStep = { ...step, ...values }
    updateStep(newStep)
    // eslint-disable-next-line
  }, [debouncedUpdate])

  return (
    <>
      <DrawerHeader
        primary="Edit SMS"
        secondary={step.status === 'draft' ? 'Draft' : 'Live'}
        icon={<ChannelIcon channel={step.type} size={70} />}
        withMargin={false}
      />
      <div className={classes.form}>
        <TicketRevTextField
          label="Sender Name"
          placeholder="e.g. Company Name"
          onChange={setFieldValue}
          name="senderName"
          value={values.senderName}
        />
        {textTokensData?.result ? (
          <RichTextEditor
            label="Message body"
            defaultValue={values.messageBody}
            tokens={textTokensData.result}
            scope={['sms']}
            onChange={setFieldValue}
            withSmsCount
            name="messageBody"
          />
        ) : (
          (textTokensError && <Alert severity="error" message="An error occurred" />) || (
            <PaperLoader />
          )
        )}
      </div>
    </>
  )
}
export default SmsForm
