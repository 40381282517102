export default {
  root: {
    backgroundColor: 'transparent',
  },
  container: {
    display: 'flex',
    flexDirection: 'column-reverse',
    margin: 0,
    padding: 0,
  },
  editor: {
    backgroundColor: 'transparent',
    width: '100%',
    color: 'inherit',
    minHeight: 38,
  },
  editorContainer: {
    margin: 0,
    padding: 0,
  },
  toolbar: {
    paddingTop: 8,
    backgroundColor: 'transparent',
  },
  hidePlaceholder: {
    display: 'none',
  },
  placeHolder: {
    display: 'none',
  },
}
