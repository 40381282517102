import appConfig from 'config';
import {
    ApiResponseDtoOfListOfUserGrantedOrganisation
} from 'generated/api/account-service-proxies';
import {
    ApiResponseDtoOfListOfLoginProviderInfo, ApiResponseError
} from 'generated/api/app-service-proxies';
import { useQuery, UseQueryOptions } from 'react-query';
import { api } from 'services';

import queryKeys from './queryKeys';

export const useLoginProviders = (
  inviteToken?: string,
  options?: UseQueryOptions<
    ApiResponseDtoOfListOfLoginProviderInfo,
    ApiResponseError,
    ApiResponseDtoOfListOfLoginProviderInfo,
    ReturnType<typeof queryKeys.loginProviders>
  >
) => {
  return useQuery(
    queryKeys.loginProviders({
      redirectUrl: appConfig.loginProviderRedirectUrl,
      inviteToken,
    }),
    () => api.loginProviders.getLoginProviders(appConfig.loginProviderRedirectUrl, inviteToken),
    {
      ...options,
      refetchOnMount: false,
      staleTime: Infinity,
    }
  )
}

export const useGetOrgsForEmailAddress = (
  emailAddress: string,
  options?: UseQueryOptions<
    ApiResponseDtoOfListOfUserGrantedOrganisation,
    ApiResponseError,
    ApiResponseDtoOfListOfUserGrantedOrganisation,
    ReturnType<typeof queryKeys.orgsForEmailAddress>
  >
) => {
  return useQuery(
    queryKeys.orgsForEmailAddress(emailAddress),
    () => api.account.listTenants(),
    {
      ...options,
    }
  )
}
