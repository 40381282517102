import { useState, useEffect } from 'react'
import classNames from 'classnames'
import api from 'services/serviceResolver'
import { makeStyles, Theme, Box, Tooltip, alpha } from '@material-ui/core'
import { LinkRounded as LinkIcon } from '@material-ui/icons'
import { ContentState } from 'draft-js'

const useStyles = makeStyles((theme: Theme) => ({
  chip: {
    height: 20,
    verticalAlign: 'middle',
    cursor: 'pointer',
  },
  isValid: {
    color: theme.palette.success.main,
  },
  invalid: {
    color: theme.palette.error.main,
  },
  label: {
    paddingLeft: 5,
    paddingRight: 7,
    color: theme.palette.success.main,
    userSelect: 'text',
    whiteSpace: 'pre-wrap',
    overflowWrap: 'break-word',
    overflow: 'visible',
  },
  icon: {
    width: 18,
    height: 18,
    verticalAlign: 'text-bottom',
    color: theme.palette.success.main,
    marginBottom: -1,
  },
  success: {
    color: theme.palette.success.main,
  },
  error: {
    color: theme.palette.error.main,
  },
  linkSpan: {
    cursor: 'pointer',
    fontSize: '12px',
    '&:hover': {
      opacity: 0.8,
      backgroundColor: alpha(theme.palette.common.black, 0.03),
    },
  },
}))

interface Props {
  children: any
  // offsetKey: string
  entityKey: string
  contentState: ContentState
  handleSetAnchorEl: (anchorEl: HTMLElement | null) => void
  handleSetUrl: (
    props: {
      url: string
      start: number
      end: number
      blockKey: string
      id: string
      entityKey: string
    } | null
  ) => void
  start: number
  end: number
  blockKey: string
  decoratedText: string
}

const ResolvedLinkSpan = (props: Props) => {
  const [isValid, setIsValid] = useState(true)
  const classes = useStyles()
  const {
    children,
    entityKey,
    contentState,
    handleSetAnchorEl,
    handleSetUrl,
    start,
    end,
    blockKey,
    decoratedText,
  } = props
  const metaData = contentState.getEntity(entityKey).getData()

  const handleValidateLink = async () => {
    if (metaData && metaData.id) {
      try {
        await api.urls.getSingle(metaData.id)
      } catch (e) {
        setIsValid(false)
      }
    }
  }

  const handleOpenLinkSetup = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    handleSetAnchorEl(event.currentTarget)
    handleSetUrl({
      url: decoratedText,
      start,
      end,
      id: metaData.id,
      blockKey,
      entityKey,
    })
  }

  useEffect(() => {
    handleValidateLink()
  }, [])

  return (
    <Tooltip
      title={
        metaData && isValid
          ? `Click to edit` // ? `Will be shown as ${metaData.shortUrl} in SMS. Click to edit`
          : `Link is broken. Click to edit`
      }
      arrow
    >
      <Box
        component="span"
        className={classNames([classes.linkSpan], {
          [classes.isValid]: isValid,
          [classes.invalid]: !isValid,
        })}
        onClick={handleOpenLinkSetup}
      >
        <LinkIcon
          className={classNames([classes.icon], {
            [classes.success]: isValid,
            [classes.error]: !isValid,
          })}
        />{' '}
        {children}
      </Box>
    </Tooltip>
  )
}

export default ResolvedLinkSpan
