import React, { useState, useEffect } from 'react'
import { makeStyles, Theme, Button, Divider } from '@material-ui/core'
import classNames from 'classnames'
import api from 'services/serviceResolver'
import {
  UserRecommendation,
  UserRecommendationDeterminationRequest,
} from 'generated/api/app-service-proxies'
import UserRecommendationList from './components/UserRecommendationList'

interface Props {
  recommendationTopicKeys: string[]
  recommendationInput: { [key: string]: any }
  recommendationDescription: string
  updateRecommendations?: boolean
  onRecommendationActionComplete?: (recommendationCompletions: any[]) => void
  className?: string
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    boxShadow: 'none',
    overflow: 'hidden',
    backgroundColor: 'transparent',
  },
  content: {
    textAlign: 'center',
  },
  selected: {
    backgroundColor: theme.palette.primary.light,
  },
  title: {
    marginTop: theme.spacing(),
  },
  divider: {
    marginTop: 0,
    marginBottom: theme.spacing(5),
  },
}))

const UserRecommendationToolbox = ({
  recommendationTopicKeys,
  recommendationInput,
  recommendationDescription,
  updateRecommendations,
  onRecommendationActionComplete,
  className,
}: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setIsLoading] = useState(true)
  const [showRecommendationsList, setShowRecommendationsList] = useState(false)
  const [hasAutomatedRecommendations, setHasAutomatedRecommendations] = useState(false)
  const [recommendations, setRecommendations] = useState<UserRecommendation[]>([])

  const classes = useStyles()
  // const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    async function fetchRecommendations() {
      await setIsLoading(true)

      const result = await api.userRecommendation.determineRecommendations({
        topicKeys: recommendationTopicKeys,
        input: recommendationInput,
      } as UserRecommendationDeterminationRequest)
      setRecommendations(result.result)

      await setIsLoading(false)
      if (result.result.find((r: UserRecommendation) => r.isActionAutomated)) {
        setHasAutomatedRecommendations(true)
      }
    }

    fetchRecommendations()
    // eslint-disable-next-line
  }, [updateRecommendations])

  const root = classNames([classes.root], {}, className)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const completeRecommendations = async (rec: UserRecommendation[]): Promise<void> => {
    // TODO: Carry out recommendation via API and send result to onRecommendationActionComple
    onRecommendationActionComplete?.(rec)
  }

  const handleToggleRecommendationsList = () =>
    setShowRecommendationsList(!showRecommendationsList)

  return recommendations ? (
    <div className={root}>
      {recommendations.length > 0 ? <Divider className={classes.divider} /> : <></>}
      {recommendations.length > 0 && !showRecommendationsList ? (
        <Button
          variant="outlined"
          disableElevation
          color="secondary"
          onClick={() => handleToggleRecommendationsList()}
        >
          {`${
            (showRecommendationsList ? 'Hide ' : 'Show ') + recommendations.length
          } ${recommendationDescription} recommendation${
            recommendations.length > 1 ? 's' : ''
          }`}
        </Button>
      ) : (
        <></>
      )}
      {showRecommendationsList && recommendations.length > 0 ? (
        <>
          <UserRecommendationList recommendations={recommendations} />
          {hasAutomatedRecommendations ? (
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleToggleRecommendationsList()}
            >
              Fix for me
            </Button>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  ) : (
    <></>
  )
}

export default UserRecommendationToolbox
