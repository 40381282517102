import React, { useState } from 'react'
import { Menu, Box, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  label: {
    justifyContent: 'space-between',
  },
  paper: {
    minWidth: 270,
    maxWidth: '100%',
    paddingRight: theme.spacing(),
    paddingLeft: theme.spacing(),
  },
}))

interface Props {
  listItem: JSX.Element
  parentMenuOpen: boolean
  children: JSX.Element
}

const NestedMenuItem = ({ listItem, parentMenuOpen, children }: Props) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  return (
    <Box
      onMouseEnter={(event) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget)
      }}
      onMouseLeave={() => {
        setAnchorEl(null)
      }}
    >
      {listItem}
      <Menu
        style={{ pointerEvents: 'none' }}
        classes={{
          paper: classes.paper,
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(parentMenuOpen && anchorEl)}
        onClose={() => {
          setAnchorEl(null)
        }}
      >
        <div style={{ pointerEvents: 'auto', maxHeight: 500, overflow: 'auto' }}>
          {children}
        </div>
      </Menu>
    </Box>
  )
}

export default NestedMenuItem
