import React, { useState, useCallback } from 'react'
import { useQueryClient } from 'react-query'
import { api, queryKeys } from 'services'
import { DialogWrapper, GradientButton, TicketRevTextField } from 'components'
import {
  LinearProgress,
  makeStyles,
  Theme,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import { useSnackbar } from 'notistack'
import { useFormik } from 'formik'
import { OrganisationRegistrationRequest } from 'generated/api/app-service-proxies'
import { useInterval, transformApiErrors, setToken, getToken } from 'utils'
import loginManager from 'services/loginManager'

interface Props {
  open: boolean
  handleClose: () => void
  handleReCaptchaVerify: () => Promise<string | undefined>
}

const useStyles = makeStyles((theme: Theme) => ({
  progressBar: {
    height: 10,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  button: {
    marginBottom: theme.spacing(2.5),
  },
  checkbox: {
    marginLeft: theme.spacing(),
    marginBottom: theme.spacing(3),
  },
}))

const CreateOrgDialog = ({ open, handleClose, handleReCaptchaVerify }: Props) => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()

  const [progress, setProgress] = useState(0)

  const { setFieldValue, handleSubmit, values, isSubmitting, setErrors, errors } =
    useFormik({
      initialValues: {
        organisationDescription: '',
        seedDemoData: false,
      },
      validateOnBlur: false,
      validateOnChange: false,
      onSubmit: async (payload) => {
        const body = OrganisationRegistrationRequest.fromJS({
          ...payload,
        })
        try {
          const response = await api.account.registerNewTenant(body)
          const newToken = await loginManager.getTokenForOrgOrThrow(
            getToken() as string,
            response.result.id,
            (await handleReCaptchaVerify()) as string
          )
          setToken(newToken.accessToken)
          queryClient.invalidateQueries(queryKeys.organisations)
          queryClient.invalidateQueries(queryKeys.me)
        } catch (response) {
          const { message, validation } = response.error || response || {}
          enqueueSnackbar(`${message || 'An error occurred'}`, { variant: 'error' })
          if (validation && validation.properties) {
            setErrors(transformApiErrors(validation.properties))
          }
        }
      },
    })

  useInterval(
    () => {
      if (isSubmitting) {
        setProgress((oldProgress) => {
          if (oldProgress === 100) {
            return 0
          }
          const diff = Math.random() * 10
          return Math.min(oldProgress + diff, 97)
        })
      } else {
        setProgress(0)
      }
    },
    isSubmitting ? 1000 : null
  )

  const removeErrorOnChange = (name: string) => {
    // eslint-disable-next-line
    if (errors.hasOwnProperty(name)) {
      setErrors({ ...errors, [name]: undefined })
    }
  }

  const setFieldValueWrapper = (name: string, value: any) => {
    setFieldValue(name, value)
    removeErrorOnChange(name)
  }

  const handleCloseIntercept = () => {
    if (!isSubmitting) handleClose()
  }

  return (
    <DialogWrapper
      open={open}
      onClose={handleCloseIntercept}
      withForm={false}
      title="Create organisation"
      description="Need another workspace? There's nothing shared between organisations, so creating a new org will be a totally separate environment."
    >
      <form onSubmit={handleSubmit}>
        <TicketRevTextField
          onChange={setFieldValueWrapper}
          autoFocus
          disabled={isSubmitting}
          value={values.organisationDescription}
          label="Organisation name"
          placeholder="Your new org"
          name="organisationDescription"
          required
          helperText={errors.organisationDescription}
          error={Boolean(errors.organisationDescription)}
        />
        <FormGroup row className={classes.checkbox}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                disabled={isSubmitting}
                checked={values.seedDemoData}
                onChange={(
                  _event: React.ChangeEvent<HTMLInputElement>,
                  checked: boolean
                ) => setFieldValueWrapper('seedDemoData', checked)}
              />
            }
            label="Seed the new organisation with demo data. Useful for testing purposes"
          />
        </FormGroup>
        {isSubmitting && (
          <LinearProgress
            variant="determinate"
            value={progress}
            className={classes.progressBar}
          />
        )}
        <GradientButton
          backText="Cancel"
          back={handleClose}
          loading={isSubmitting}
          edgeEnd
        >
          Create
        </GradientButton>
      </form>
    </DialogWrapper>
  )
}

export default CreateOrgDialog
