export default {
  loginProviders: ({
    redirectUrl,
    inviteToken,
  }: {
    redirectUrl: string
    inviteToken?: string
  }) => ['loginProviders', redirectUrl, inviteToken],
  orgsForEmailAddress: (emailAddress: string) => ['orgsForEmailAddress', emailAddress],
}
