import React from 'react'
import classNames from 'classnames'
import { Skeleton } from '@material-ui/lab'
import { Typography, makeStyles, Theme, Grid } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  inline: {
    display: 'inline-flex',
    paddingLeft: theme.spacing(3),
    verticalAlign: 'middle',
  },
  ctaContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}))

interface Props {
  title?: string
  className?: string
  children?: React.ReactNode
  toggleComponent?: React.ReactNode
  loading?: boolean
}

const PageTitle = ({ title, className, children, toggleComponent, loading }: Props) => {
  const classes = useStyles()

  const rootClassName = classNames(
    {
      [classes.container]: true,
    },
    className
  )

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      className={rootClassName}
    >
      <Typography variant="h1" display="inline">
        {loading ? (
          <Skeleton width={300} variant="rect" />
        ) : (
          <>
            <strong>{title}</strong>
            {toggleComponent && <div className={classes.inline}>{toggleComponent}</div>}
          </>
        )}
      </Typography>
      {children && !loading && <div className={classes.ctaContainer}>{children}</div>}
    </Grid>
  )
}

export default PageTitle
