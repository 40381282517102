import {
  EditorState,
  Modifier,
  ContentBlock,
  DraftEntityMutability,
  ContentState,
  convertToRaw,
  RawDraftContentBlock,
  RawDraftEntity,
  SelectionState,
} from 'draft-js'
import Immutable from 'immutable'
import { TextTokensProps } from 'utils/formatTextTokens'

export const styleMap = {
  BOLD: {},
  STRIKETHROUGH: {},
  H1: {},
  CODE: {},
  ITALIC: {},
  UNDERLINE: {},
}

export const blockRenderMap = Immutable.Map({
  unstyled: {
    element: 'div',
  },
})

// URLs starting with http://, https://, or ftp://
const URL_WITH_PROTOCOL_REGEX =
  /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim
// URLs starting with "www." (without // before it, or it'd re-link the ones done above).
const URL_WITHOUT_PROTOCOL = /(^|[^/])(www\.[\S]+(\b|$))/gim
// eslint-disable-next-line
export const TOKEN_REGEX = /\{\{[^\}]+\}\}/g
// eslint-disable-next-line
export const TOKEN_REGEX_WITH_DELIMITER = /(\{\{[^\}]+\}\})/g
// eslint-disable-next-line
const CURLY_BRACE_REGEX = /{[\w]+/g

const invalidTokenReplaceText = '{{INVALID VARIABLE}}'

const calcDiff = (a: number, b: number) => (a > b ? a - b : b - a)

// Go through possible initial text from API and give tokens and shortlinks rich entities.
// Replace handlebars with e.g. First Name and put the rest of token "metadata" as entity data.
export const createRawContentStateFromText = (
  text: string,
  tokens: TextTokensProps[]
) => {
  const getTokenEntities = [...text.matchAll(TOKEN_REGEX)].map((x) => {
    return { ...x, token: x[0] }
  })
  const initialTextSplit = text.split(TOKEN_REGEX_WITH_DELIMITER)

  // replace {{token}} with the token description, but don't .join('') yet so that we can calculate new entity offset (cos length is now different than {{token}})
  const replaceTokensWithDescription = initialTextSplit.map((x) => {
    if (TOKEN_REGEX.test(x)) {
      if (x.startsWith('{{shortUrl::')) {
        // Format: {{shortUrl::${id}::${destinationUrl}::${shortUrl}}}
        const shortUrlParameters = x.split('::')
        return (
          shortUrlParameters[shortUrlParameters.length - 1].replace('}}', '') ||
          invalidTokenReplaceText
        )
      }
      return (
        tokens.find((y) => x.includes(y.tokenKey || ''))?.description ||
        invalidTokenReplaceText
      )
    }
    return x
  })

  // don't create valid entities for TOKEN_REGEX that doesn't exist in valid tokens array. If a token has inline pipe data, add to obj
  const validEnrichedTokens = getTokenEntities.map((x, index) => {
    const isShortUrl = x.token.startsWith('{{shortUrl::')
    const parameters = x.token.split('::')

    // .matchAll keeps string index. Neat. Loop through up until index -> calc length difference between initial token key "{{Audience.Name.First}}" and new token "First name" and add up -> subtract with string index later
    const getStringIndexSubtractionValue = getTokenEntities.reduce(
      (accumulator, currentValue, tokenIndex) => {
        const currentIsShortUrl = currentValue.token.startsWith('{{shortUrl::')
        const currentShortUrlParameters = currentValue.token.split('::')

        const originalTokenLength = currentValue.token.length
        const newTokenLength = currentIsShortUrl
          ? currentShortUrlParameters[currentShortUrlParameters.length - 1].replace(
              '}}',
              ''
            ).length
          : tokens.find((y) => currentValue.token.includes(y.tokenKey))?.description
              .length || invalidTokenReplaceText.length
        const difference =
          tokenIndex < index ? calcDiff(originalTokenLength, newTokenLength) : 0

        return accumulator + difference
      },
      0
    )

    if (isShortUrl) {
      // Format: {{shortUrl::id::destinationUrl::shortUrl}}
      const [shortUrlType, shortUrlId, destinationUrl, shortUrl] = parameters
      const removeHandles = shortUrl.replace('}}', '')
      return {
        description: removeHandles,
        destinationUrl,
        shortUrl: removeHandles,
        id: shortUrlId,
        shortUrlType: `${shortUrlType.replace('{{', '')}`,
        entityOffset: (x.index || 0) - getStringIndexSubtractionValue,
        initialStringIndex: x.index,
      }
    }

    const tokenData = tokens.find((y) => x.token.includes(y.tokenKey))
    return {
      description: tokenData?.description,
      group: tokenData?.group,
      longDescription: tokenData?.longDescription,
      tokenKey: tokenData?.tokenKey,
      value: tokenData?.value,
      initialToken: x.token,
      initialStringIndex: x.index,
      entityOffset: (x.index || 0) - getStringIndexSubtractionValue,
    }
  })

  const entityMap = {}

  const blocks = [
    {
      text: replaceTokensWithDescription.join(''),
      type: 'unstyled',
      key: 'initial-value',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: validEnrichedTokens
        .filter((x) => x.tokenKey || x.shortUrlType)
        .map((x) => {
          const entityKey = x.initialStringIndex || 12345
          Object.assign(entityMap, {
            [entityKey]: {
              type: x.shortUrlType ? 'SHORTURL' : 'TOKEN',
              mutability: 'IMMUTABLE' as DraftEntityMutability,
              data: {
                ...x,
                // data will be accessible inside token components
              },
            },
          })
          return {
            offset: x.entityOffset,
            // original string is replaced by token description, so use description length
            length: (x.description && x.description.length) || 0,
            key: entityKey,
          }
        }),
    },
  ]

  return {
    blocks,
    entityMap,
  }
}

const findWithRegex = (
  regex: RegExp,
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void
) => {
  const text = contentBlock.getText()
  let matchArr
  let start
  // eslint-disable-next-line
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index
    callback(start, start + matchArr[0].length)
  }
}

export const curlyBraceTokenAddStrategy = (
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void
) => {
  findWithRegex(CURLY_BRACE_REGEX, contentBlock, callback)
}

export const findTokenEntities = (
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState
) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity()
    return entityKey !== null && contentState.getEntity(entityKey).getType() === 'TOKEN'
  }, callback)
}

export const findUrlEntities = (
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState
) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity()
    return (
      entityKey !== null && contentState.getEntity(entityKey).getType() === 'SHORTURL'
    )
  }, callback)
}

export const findUrlWithProtolMatchesNotTurnedToEntitiesYet = (
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void
) => {
  findWithRegex(URL_WITH_PROTOCOL_REGEX, contentBlock, callback)
}

export const findUrlWithoutProtolMatchesNotTurnedToEntitiesYet = (
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void
) => {
  findWithRegex(URL_WITHOUT_PROTOCOL, contentBlock, callback)
}

// a searchable token dropdown will appear. Text is used to search, start & end used to insert new token, and coordinates used to position the dropdown
export const getTriggerRange = (trigger: string) => {
  const selection = window.getSelection()
  if (selection?.rangeCount === 0) return null

  const range = selection?.getRangeAt(0)
  const text = range?.startContainer?.textContent?.substring(0, range.startOffset)
  if (text && /s+$/.test(text)) return null

  const index = text?.lastIndexOf(trigger)
  if (index === -1) return null

  // perhaps more conditions here. End should max be 4 chars more than start? No whitespace? whatevs
  if (text && index !== undefined && range) {
    return {
      text: text.substring(index),
      start: index,
      end: range.startOffset,
      range,
    }
  }
  return null
}

export const getInsertRange = (editorState: EditorState) => {
  const currentSelectionState = editorState.getSelection()
  const end = currentSelectionState.getAnchorOffset()
  const anchorKey = currentSelectionState.getAnchorKey()
  const currentContent = editorState.getCurrentContent()
  const currentBlock = currentContent.getBlockForKey(anchorKey)
  const blockText = currentBlock.getText()
  const start = blockText.substring(0, end).lastIndexOf('{')

  return {
    start,
    end,
  }
}

export const insertCharacter = (characterToInsert: string, editorState: EditorState) => {
  const currentContent = editorState.getCurrentContent()
  const currentSelection = editorState.getSelection()

  const newContent = Modifier.replaceText(
    currentContent,
    currentSelection,
    characterToInsert
  )

  const newEditorState = EditorState.push(editorState, newContent, 'insert-characters')

  return newEditorState // EditorState.forceSelection(newEditorState, newContent.getSelectionAfter())
}

export const insertShortUrlAtCursor = (
  editorState: EditorState,
  destinationUrl: string,
  shortUrl: string,
  id: string,
  start: number,
  end: number
) => {
  const currentSelectionState = editorState.getSelection()
  const selection = currentSelectionState.merge({
    anchorOffset: start,
    focusOffset: end,
  })
  const contentState = editorState.getCurrentContent()
  const contentStateWithEntity = contentState.createEntity('SHORTURL', 'IMMUTABLE', {
    destinationUrl,
    shortUrl,
    id,
  })
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
  const newContentState = Modifier.replaceText(
    contentStateWithEntity,
    selection,
    shortUrl, // `{{shortUrl::${id}::${destinationUrl}::${shortUrl}}}`,
    undefined,
    entityKey
  )
  const newEditorState = EditorState.push(editorState, newContentState, 'apply-entity')
  return EditorState.forceSelection(newEditorState, newContentState.getSelectionAfter())
}

export const insertShortUrl = (
  editorState: EditorState,
  destinationUrl: string,
  shortUrl: string,
  id: string,
  start: number,
  end: number,
  blockKey: string
) => {
  const contentState = editorState.getCurrentContent()
  const contentStateWithEntity = contentState.createEntity('SHORTURL', 'IMMUTABLE', {
    destinationUrl,
    shortUrl,
    id,
  })
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
  const selection = new SelectionState({
    anchorKey: blockKey,
    anchorOffset: start,
    focusKey: blockKey,
    focusOffset: end,
  })
  const newContentState = Modifier.replaceText(
    contentStateWithEntity,
    selection,
    shortUrl,
    undefined,
    entityKey
  )
  const newEditorState = EditorState.push(editorState, newContentState, 'apply-entity')
  return EditorState.forceSelection(newEditorState, newContentState.getSelectionAfter())
}

export const insertToken = (editorState: EditorState, selectedToken: TextTokensProps) => {
  const { start, end } = getInsertRange(editorState)

  const currentSelectionState = editorState.getSelection()
  const selection = currentSelectionState.merge({
    anchorOffset: start,
    focusOffset: end,
  })

  const contentState = editorState.getCurrentContent()
  const contentStateWithEntity = contentState.createEntity(
    'TOKEN',
    'IMMUTABLE',
    selectedToken
  )
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey()

  const newContentState = Modifier.replaceText(
    contentStateWithEntity,
    selection,
    selectedToken.description,
    undefined,
    entityKey
  )

  const newEditorState = EditorState.push(editorState, newContentState, 'apply-entity')

  return EditorState.forceSelection(newEditorState, newContentState.getSelectionAfter())
}

// ENTITY REPLACEMENT

type ISection = {
  start: number
  end: number
  entityKey?: number
  type?: string
}

type IEntityMap = {
  [key: string]: RawDraftEntity<{
    [key: string]: any
  }>
}

const getSections = (block: RawDraftContentBlock): ISection[] => {
  const sections: any[] = []
  let lastOffset = 0
  let sectionRanges = block.entityRanges.map((range) => {
    const { offset, length, key } = range
    return {
      offset,
      length,
      key,
      type: 'ENTITY',
    }
  })
  sectionRanges = sectionRanges.sort((s1, s2) => s1.offset - s2.offset)
  sectionRanges.forEach((r) => {
    if (r.offset > lastOffset) {
      sections.push({
        start: lastOffset,
        end: r.offset,
      })
    }

    sections.push({
      start: r.offset,
      end: r.offset + r.length,
      entityKey: r.key,
      type: r.type,
    })
    lastOffset = r.offset + r.length
  })

  if (lastOffset < block.text.length) {
    sections.push({
      start: lastOffset,
      end: block.text.length,
    })
  }

  return sections
}

const isEmptyString = (str: string) => {
  return str === undefined || str === null || str.length === 0 || str.trim().length === 0
}

const isAtomicEntityBlock = (block: RawDraftContentBlock) => {
  return (
    block.entityRanges.length > 0 &&
    (isEmptyString(block.text) || block.type === 'atomic')
  )
}

const getEntity = (entityMap: IEntityMap, entityKey: number, text?: string) => {
  const entity = entityMap[entityKey]

  // transform into {{Audience.Name.First | 'fallback': awofe }}
  if (entity.type === 'TOKEN') {
    const replacementValue = entity.data.value
    return replacementValue || text || ''
  }
  if (entity.type === 'SHORTURL') {
    const replacementValue = `{{shortUrl::${entity.data.id}::${entity.data.destinationUrl}::${entity.data.shortUrl}}}`
    return replacementValue || text || ''
  }

  // continue with future entity types here

  return text || ''
}

const getSectionMarkup = (
  block: RawDraftContentBlock,
  entityMap: IEntityMap,
  section: ISection
) => {
  let sectionText = block.text.substring(section.start, section.end)

  if (section.type === 'ENTITY') {
    if (section.entityKey !== undefined && section.entityKey !== null) {
      sectionText = getEntity(entityMap, section.entityKey, sectionText)
    }
  }

  return sectionText
}

const getInnerBlock = (block: RawDraftContentBlock, entityMap: IEntityMap) => {
  const blockString: string[] = []

  const sections = getSections(block)

  sections.forEach((section) => {
    const sectionText = getSectionMarkup(block, entityMap, section)
    blockString.push(sectionText)
  })

  return blockString.join('')
}

const convertBlock = (block: RawDraftContentBlock, entityMap: IEntityMap) => {
  const blockString: string[] = []

  if (isAtomicEntityBlock(block)) {
    blockString.push(getEntity(entityMap, block.entityRanges[0].key))
  } else {
    blockString.push(getInnerBlock(block, entityMap))
  }
  return blockString.join('')
}

export const convertToString = (editorState: EditorState) => {
  const rawContentState = convertToRaw(editorState.getCurrentContent())
  const { blocks, entityMap } = rawContentState

  const convertedBlocks: string[] = []

  blocks.forEach((block) => {
    const convertedBlock = convertBlock(block, entityMap)

    convertedBlocks.push(convertedBlock)
  })

  return convertedBlocks.join('\n')
}

export const insertCharsAtCursor = (char: string, editorState: EditorState) => {
  const currentContentState = editorState.getCurrentContent()
  const currentSelectionState = editorState.getSelection()

  // find out if there's a whitespace before inserting {
  const anchorKey = currentSelectionState.getAnchorKey()
  const currentContentBlock = currentContentState.getBlockForKey(anchorKey)
  const cursorStartOffset = currentSelectionState.getStartOffset()
  const textOneCharBeforeInsertionPoint = currentContentBlock
    .getText()
    .slice(cursorStartOffset - 1, cursorStartOffset)
  const hasWhiteSpace = textOneCharBeforeInsertionPoint === ' '
  const insertWhiteSpace = !hasWhiteSpace && textOneCharBeforeInsertionPoint.length === 1

  const insertValue = insertWhiteSpace ? ` ${char}` : `${char}`

  const newContentState = currentSelectionState.isCollapsed()
    ? Modifier.insertText(currentContentState, currentSelectionState, insertValue)
    : Modifier.replaceText(currentContentState, currentSelectionState, insertValue)

  const newEditorState = EditorState.push(
    editorState,
    newContentState,
    'insert-characters'
  )
  return newEditorState
}

export const handleUpdateShortUrl = (
  editorState: EditorState,
  originalId: string,
  newId: string,
  shortUrl: string
) => {
  const rawText = convertToString(editorState)
  const split = rawText.split(TOKEN_REGEX_WITH_DELIMITER)

  return split
    .map((x) => {
      if (x.includes(originalId)) {
        const innerSplit = x.split('::')
        const replaceShortUrl = innerSplit.map((y, index) => {
          if (y.includes(originalId)) return newId
          if (index === innerSplit.length - 1) return `${shortUrl}}}`
          return y
        })
        return replaceShortUrl.join('::')
      }
      return x
    })
    .join('')
}
