import { useEffect, RefObject } from 'react'

interface Props {
  root?: RefObject<any>
  target?: RefObject<any>
  onIntersect: () => void
  rootMargin?: IntersectionObserverInit['rootMargin']
  threshold?: IntersectionObserverInit['threshold']
  enabled?: boolean
}

export default ({
  root,
  target,
  onIntersect,
  threshold = 1.0,
  rootMargin = '0px',
  enabled = true,
}: Props) => {
  useEffect(() => {
    if (!enabled) return

    const observer = new IntersectionObserver(
      (entries) => entries.forEach((entry) => entry.isIntersecting && onIntersect()),
      {
        root: root && root.current,
        rootMargin,
        threshold,
      }
    )

    const el = target && target.current

    if (!el) return

    observer.observe(el)

    // eslint-disable-next-line
    return () => {
      observer.unobserve(el)
    }
  }, [target?.current, enabled])
}
