import { useContext } from 'react'
import AppContext from 'context'
import { UseQueryOptions, useQuery } from 'react-query'
import { api } from 'services'
import {
  ApiResponseError,
  ItemListResponseOfPlatformChannelType,
  ItemListResponseOfPlatformChannelWithServiceConnection,
} from 'generated/api/app-service-proxies'
import { PlatformChannelProps } from './types'
import queryKeys from './queryKeys'

const { platformChannels, platformChannelTypes } = queryKeys

export const usePlatformChannels = (
  props?: PlatformChannelProps,
  options?: UseQueryOptions<
    ItemListResponseOfPlatformChannelWithServiceConnection,
    ApiResponseError,
    ItemListResponseOfPlatformChannelWithServiceConnection,
    ReturnType<typeof platformChannels>
  >
) => {
  const { userGrants } = useContext(AppContext)

  return useQuery(
    platformChannels(props),
    () =>
      api.channels.base.getAllPlatformChannels(
        props?.platformChannelTypeId,
        props?.serviceConnectionId,
        props?.serviceVendorId
      ),
    {
      ...options,
      enabled:
        options && 'enabled' in options
          ? options.enabled && userGrants.includes('seeka.Channels')
          : userGrants.includes('seeka.Channels'),
    }
  )
}

export const usePlatformChannelTypes = (
  options?: UseQueryOptions<
    ItemListResponseOfPlatformChannelType,
    ApiResponseError,
    ItemListResponseOfPlatformChannelType,
    typeof platformChannelTypes
  >
) => {
  const { userGrants } = useContext(AppContext)

  return useQuery(platformChannelTypes, api.channels.base.getAllPlatformChannelTypes, {
    ...options,
    enabled:
      options && 'enabled' in options
        ? options.enabled && userGrants.includes('seeka.Channels')
        : userGrants.includes('seeka.Channels'),
    refetchOnMount: false,
    staleTime: 3000000,
  })
}
