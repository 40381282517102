import React, { useState } from 'react'
import { useDrop } from 'react-dnd'
import { FloatingButton, DropdownMenu, TicketRevListItem, ChannelIcon } from 'components'
import classNames from 'classnames'
import { GetAppRounded as DropIcon } from '@material-ui/icons'
import { makeStyles, Theme, Typography } from '@material-ui/core'
import { StepTypeKey, UtilityProps } from '../../../../helpers'
import dummyUtilities from '../../../SidebarUtilities/dummyUtilities'

const useStyles = makeStyles((theme: Theme) => ({
  btnArrowContainer: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    position: 'relative',
  },
  container: {
    height: 52,
    borderRadius: theme.shape.borderRadius,
    border: `2px dashed transparent`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    maxWidth: 200,
    transition: theme.transitions.create(['border-color', 'background-color'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  isOver: {
    backgroundColor: '#e6e6e6',
    border: `2px dashed ${theme.palette.success.main}`,
  },
  isDragging: {
    backgroundColor: theme.palette.background.default,
    border: `2px dashed ${theme.palette.divider}`,
  },
  buttonGlow: {
    backgroundColor: theme.palette.success.main,
    boxShadow: '0 0 5px 3px #fff, 0 0 6px 3px #f0f, 0 0 8px 4px #0ff',
  },
  fab: {
    width: 26,
    height: 26,
    minHeight: 26,
  },
}))

interface Props {
  index: number
  sectionKey: string
  acceptedNextStep: StepTypeKey[]
  addStep: (type: StepTypeKey, insertAfterIndex: number, sectionKey: string) => void
  line?: JSX.Element
}

const AddStepButton = ({ index, acceptedNextStep, addStep, sectionKey, line }: Props) => {
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const [{ canDrop, isOver }, dropRef] = useDrop({
    // use accept to control what can be dropped
    accept: acceptedNextStep,
    // use the name to identify where drop happened in <UtilityItem />
    drop: () => ({ name: { insertAfterIndex: index, sectionKey } }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })

  const containerClassName = classNames([classes.container], {
    [classes.isDragging]: canDrop && !isOver,
    [classes.isOver]: canDrop && isOver,
  })

  const buttonClassName = classNames([classes.fab], {
    [classes.buttonGlow]: canDrop,
  })

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleAddStep = (x: UtilityProps) => {
    setAnchorEl(null)
    addStep(x.type, index, sectionKey)
  }

  const handleMenuClose = () => setAnchorEl(null)

  const flattenedDummyUtilities = Object.values(dummyUtilities).flat()

  return (
    <>
      <div className={classes.btnArrowContainer}>
        <div className={containerClassName} ref={dropRef}>
          {isOver ? (
            <Typography>
              <strong>Drop here</strong>
            </Typography>
          ) : (
            <FloatingButton
              onClick={handleOpenMenu}
              size="small"
              icon={canDrop ? <DropIcon /> : undefined}
              className={buttonClassName}
            />
          )}
        </div>
        {line}
      </div>
      {anchorEl && (
        <DropdownMenu anchorEl={anchorEl} handleClose={handleMenuClose}>
          {flattenedDummyUtilities.map((x) => {
            return (
              <TicketRevListItem
                key={x.label}
                primaryText={x.label}
                disabled={x.disabled}
                icon={<ChannelIcon channel={x.type} size={30} />}
                onClick={() => handleAddStep(x)}
                size="small"
                listItemSize="smallListItem"
              />
            )
          })}
        </DropdownMenu>
      )}
    </>
  )
}
export default AddStepButton
