import { Popover, PopoverProps, Typography } from '@material-ui/core'
import { VerifiedUser } from '@material-ui/icons'
import Alert from '@material-ui/lab/Alert'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

type PrivacyAlertProps = {
  children: React.ReactNode
  title: React.ReactNode
  id: string
  popoverProps?: Omit<PopoverProps, 'children' | 'id' | 'open' | 'onClose'>
}
const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}))

export const PrivacyAlert = ({
  id,
  title,
  children,
  popoverProps,
}: PrivacyAlertProps) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const classes = useStyles()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const alertId = open ? id : undefined

  return (
    <>
      <Alert
        aria-describedby={alertId}
        severity="success"
        icon={<VerifiedUser />}
        onClick={handleClick}
        style={{
          cursor: 'pointer',
          padding: '2px 10px',
          border: `1px solid rgb(206 237 231)`,
        }}
      >
        {title}
      </Alert>
      <Popover
        id={alertId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        {...popoverProps}
      >
        <Typography className={classes.typography} component="div">
          {children}
        </Typography>
      </Popover>
    </>
  )
}
