import React from 'react'
import { makeStyles, Theme, Tooltip } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  helperTextHover: {
    color: theme.palette.primary.main,
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.7,
    },
  },
}))

interface Props {
  text?: string
  tooltip?: string
  float?: 'left' | 'right'
}

const BrandInfoHelperText = ({
  text = "What's a brand?",
  tooltip = 'Use a brand to do stuff, man',
  float = 'right',
}: Props) => {
  const classes = useStyles()

  return (
    <Tooltip title={tooltip} placement="top" arrow>
      <span className={classes.helperTextHover} style={{ float }}>
        {text}
      </span>
    </Tooltip>
  )
}

export default BrandInfoHelperText
