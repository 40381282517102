import React, { cloneElement } from 'react'
import { IconButton, Tooltip, makeStyles, Theme, TooltipProps } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    maxWidth: 24,
    maxHeight: 24,
    fontSize: 24,
    color: theme.palette.common.black,
  },
  iconButton: {
    padding: theme.spacing(),
    backgroundColor: theme.palette.secondary.light,
  },
}))

interface Props {
  tooltip?: string
  tooltipPlacement?: TooltipProps['placement']
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  icon: JSX.Element
  className?: string
}

const FilledIconButton = ({
  tooltip = '',
  tooltipPlacement = 'right',
  onClick,
  icon,
  className,
}: Props) => {
  const classes = useStyles()

  return (
    <Tooltip title={tooltip} placement={tooltipPlacement} arrow>
      <IconButton
        onClick={onClick}
        className={`${classes.iconButton} ${className || ''}`}
        size="small"
      >
        {cloneElement(icon, { className: classes.icon })}
      </IconButton>
    </Tooltip>
  )
}

export default FilledIconButton
