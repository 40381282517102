import React from 'react'
import classNames from 'classnames'
import {
  StepConnector,
  StepButton,
  Step,
  Stepper,
  StepLabel,
  makeStyles,
  Theme,
  Tooltip,
} from '@material-ui/core'
import Check from '@material-ui/icons/Check'

const useStepIconStyles = makeStyles((theme: Theme) => ({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: theme.palette.primary.main,
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: theme.palette.success.main,
    zIndex: 1,
    fontSize: 18,
  },
}))

interface StepIconProps {
  active: boolean
  completed: boolean
}

const StepIcon = ({ active, completed }: StepIconProps) => {
  const classes = useStepIconStyles()

  return (
    <div
      className={classNames(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 0,
    backgroundColor: 'transparent',
  },
  alternativeLabel: {
    top: 9,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
    padding: 0,
  },
  active: {
    '& $line': {
      borderColor: theme.palette.border,
    },
  },
  completed: {
    '& $line': {
      borderColor: theme.palette.border,
    },
  },
  line: {
    border: `2px solid ${theme.palette.border}`,
    borderRadius: theme.shape.borderRadius,
    transition: theme.transitions.create(['border-color', 'color'], {
      duration: theme.transitions.duration.complex,
    }),
  },
  stepLabel: {
    display: 'none',
  },
  tooltip: {
    maxWidth: 133,
    textAlign: 'center',
  },
}))

interface TopbarProps {
  currentStep: number
  totalSteps: string[]
}

const DescriptionStepper = ({ currentStep, totalSteps }: TopbarProps) => {
  const classes = useStyles()

  return (
    <Stepper
      classes={{
        root: classes.root,
      }}
      alternativeLabel
      nonLinear
      activeStep={currentStep + 2}
      connector={
        <StepConnector
          classes={{
            alternativeLabel: classes.alternativeLabel,
            active: classes.active,
            completed: classes.completed,
            line: classes.line,
          }}
        />
      }
    >
      {totalSteps.map((label: string) => (
        <Step key={label}>
          <Tooltip
            title={label}
            arrow
            open
            placement="top"
            classes={{ tooltip: classes.tooltip }}
          >
            <StepButton>
              <StepLabel
                StepIconComponent={StepIcon}
                classes={{ labelContainer: classes.stepLabel }}
              >
                <div>O</div>
              </StepLabel>
            </StepButton>
          </Tooltip>
        </Step>
      ))}
    </Stepper>
  )
}

export default DescriptionStepper
