import React from 'react'
import { ArcherContainer, ArcherElement } from 'react-archer'
import { Grow, Typography, makeStyles, Theme, useTheme } from '@material-ui/core'
import { StepTypeKey, FlowDefinition, allStepTypes } from '../../helpers'
import AddStepButton from '../Step/components/AddStepButton'
import WaitStep from '../Step/Wait'
import Stage from '../Step/Stage'
import LeaveNote from '../Step/LeaveNote'
import ConditionalSplit from '../Step/ConditionalSplit'
import SmsStep from '../Step/OutboundContent/SmsStep'
import YesNoCircle from '../YesNoCircle'
import ConnectingLine from '../Step/components/ConnectingLine'
import ConnectingDot from '../Step/components/ConnectingDot'

const useStyles = makeStyles((theme: Theme) => ({
  splitContainer: {
    width: 'fit-content',
    margin: 'auto',
    position: 'relative',
  },
  yes: {
    display: 'inline-flex',
    flexDirection: 'column',
    minWidth: 276,
    marginRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  no: {
    display: 'inline-flex',
    flexDirection: 'column',
    minWidth: 276,
    marginLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  conditialSplitDot: {},
  yesCircle: {},
  noCircle: {},
}))

interface Props {
  rootSection: FlowDefinition['rootSection']
  sections: FlowDefinition['sections']
  editingStepId?: string
  editStep: (id: string, sectionKey: string) => void
  addStep: (type: StepTypeKey, insertAfterIndex: number, sectionKey: string) => void
  updateStep: (property: string, value: string, id: string, sectionKey: string) => void
  removeStep: (id: string, sectionKey: string) => void
}

const Section = ({
  rootSection,
  sections,
  editingStepId,
  editStep,
  addStep,
  removeStep,
  updateStep,
}: Props) => {
  const classes = useStyles()
  const theme = useTheme()
  const sharedStepProps = {
    editStep,
    removeStep,
    updateStep,
  }

  const renderSteps = (sectionKey: string) => (
    <>
      {sectionKey in sections &&
        sections[sectionKey].map((x, index) => {
          const isLast = index === sections[sectionKey].length - 1
          if (x.type === StepTypeKey.SMS) {
            return (
              <Grow in key={x.id}>
                <div>
                  <SmsStep
                    {...x}
                    {...sharedStepProps}
                    isEditing={editingStepId === x.id}
                    sectionKey={sectionKey}
                  />
                  <AddStepButton
                    index={index}
                    acceptedNextStep={x.acceptedNextStep}
                    addStep={addStep}
                    sectionKey={sectionKey}
                    line={<ConnectingLine hasNextStep={!isLast} />}
                  />
                </div>
              </Grow>
            )
          }
          if (x.type === StepTypeKey.WAIT) {
            return (
              <Grow in key={x.id}>
                <div>
                  <WaitStep
                    {...x}
                    {...sharedStepProps}
                    isEditing={editingStepId === x.id}
                    sectionKey={sectionKey}
                  />
                  <AddStepButton
                    index={index}
                    acceptedNextStep={x.acceptedNextStep}
                    addStep={addStep}
                    sectionKey={sectionKey}
                    line={<ConnectingLine hasNextStep={!isLast} />}
                  />
                </div>
              </Grow>
            )
          }
          if (x.type === StepTypeKey.STAGE) {
            return (
              <Grow in key={x.id}>
                <div>
                  <Stage
                    {...x}
                    {...sharedStepProps}
                    isEditing={editingStepId === x.id}
                    sectionKey={sectionKey}
                  />
                  <AddStepButton
                    index={index}
                    acceptedNextStep={x.acceptedNextStep}
                    addStep={addStep}
                    sectionKey={sectionKey}
                  />
                </div>
              </Grow>
            )
          }
          if (x.type === StepTypeKey.NOTE) {
            return (
              <Grow in key={x.id}>
                <div>
                  <LeaveNote
                    {...x}
                    {...sharedStepProps}
                    isEditing={editingStepId === x.id}
                    sectionKey={sectionKey}
                  />
                  <AddStepButton
                    index={index}
                    acceptedNextStep={x.acceptedNextStep}
                    addStep={addStep}
                    sectionKey={sectionKey}
                  />
                </div>
              </Grow>
            )
          }
          if (x.type === StepTypeKey.CONDITIONALSPLIT) {
            return (
              <>
                <Grow in key={x.id}>
                  <div>
                    <ConditionalSplit
                      {...x}
                      {...sharedStepProps}
                      isEditing={editingStepId === x.id}
                      sectionKey={sectionKey}
                    />
                  </div>
                </Grow>

                <div className={classes.splitContainer}>
                  <ArcherElement
                    id={x.id}
                    relations={[
                      {
                        targetId: x.passFlowId,
                        targetAnchor: 'top',
                        sourceAnchor: 'bottom',
                        style: {
                          endShape: {
                            circle: {
                              radius: 0,
                              strokeWidth: 0,
                              fillColor: 'transparent',
                              strokeColor: 'transparent',
                            },
                          },
                        },
                      },
                      {
                        targetId: x.failFlowId,
                        targetAnchor: 'top',
                        sourceAnchor: 'bottom',
                        style: {
                          endShape: {
                            circle: {
                              radius: 0,
                              strokeWidth: 0,
                              fillColor: 'transparent',
                              strokeColor: 'transparent',
                            },
                          },
                        },
                      },
                    ]}
                  >
                    <div>
                      <ConnectingDot
                        placement="top"
                        className={classes.conditialSplitDot}
                      />
                    </div>
                  </ArcherElement>
                  <div className={classes.yes}>
                    <YesNoCircle
                      variant="yes"
                      className={classes.yesCircle}
                      lineConnectorId={x.passFlowId}
                    />
                    <AddStepButton
                      index={-1}
                      acceptedNextStep={x.acceptedNextStep}
                      addStep={addStep}
                      sectionKey={x.passFlowId}
                      line={
                        <ConnectingLine
                          hasNextStep={
                            sections[x.passFlowId] && sections[x.passFlowId].length > 0
                          }
                          withTopDot={false}
                        />
                      }
                    />
                    {renderSteps(x.passFlowId)}
                  </div>
                  <div className={classes.no}>
                    <YesNoCircle
                      variant="no"
                      className={classes.noCircle}
                      lineConnectorId={x.failFlowId}
                    />
                    <AddStepButton
                      index={-1}
                      acceptedNextStep={x.acceptedNextStep}
                      addStep={addStep}
                      sectionKey={x.failFlowId}
                      line={
                        <ConnectingLine
                          hasNextStep={
                            sections[x.failFlowId] && sections[x.failFlowId].length > 0
                          }
                          withTopDot={false}
                        />
                      }
                    />
                    {renderSteps(x.failFlowId)}
                  </div>
                </div>
              </>
            )
          }
          return (
            <Grow in key={x.id}>
              <div>
                <Typography key={x.id} color="error" align="center" variant="h3">
                  <strong>Type not supported</strong>
                </Typography>
                <AddStepButton
                  index={index}
                  acceptedNextStep={x.acceptedNextStep}
                  addStep={addStep}
                  sectionKey={sectionKey}
                />
              </div>
            </Grow>
          )
        })}
    </>
  )

  const rootSectionHasSteps = rootSection in sections && sections[rootSection].length > 0

  return (
    <div>
      <h1 style={{ textAlign: 'center' }}>Starting step</h1>
      <ArcherContainer strokeColor={theme.palette.text.disabled}>
        {rootSectionHasSteps ? (
          <>
            <AddStepButton
              index={0}
              acceptedNextStep={allStepTypes}
              addStep={addStep}
              sectionKey={rootSection}
            />
            {renderSteps(rootSection)}
          </>
        ) : (
          <AddStepButton
            index={-1}
            acceptedNextStep={allStepTypes}
            addStep={addStep}
            sectionKey={rootSection}
          />
        )}
      </ArcherContainer>
    </div>
  )
}
export default Section
