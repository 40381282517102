import React from 'react'
import { makeStyles, Drawer, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    overflowY: 'auto',
    height: '100%',
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    justifyContent: 'space-between',
  },
  drawerPaper: {
    border: 'none',
    borderRight: `1px solid ${theme.palette.border}`,
    backgroundColor: theme.palette.common.white,
    transition: theme.transitions.create(['width'], {
      duration: theme.transitions.duration.shorter,
    }),
  },
}))

interface Props {
  open: boolean
  onClose?: (
    event: Record<string, unknown>,
    reason: 'backdropClick' | 'escapeKeyDown'
  ) => void
  isMobile?: boolean
  children: JSX.Element | (JSX.Element | false | undefined | '')[]
  dockOrientation?: 'left' | 'top' | 'right' | 'bottom'
  width?: number
  paperStyle?: React.CSSProperties
}

const SidebarMenu = ({
  open,
  onClose,
  isMobile = false,
  children,
  dockOrientation = 'left',
  width = 300,
  paperStyle,
}: Props) => {
  const classes = useStyles()

  return (
    <Drawer
      anchor={dockOrientation}
      classes={{ paper: classes.drawerPaper }}
      onClose={onClose}
      open={open}
      variant={isMobile ? 'temporary' : 'permanent'}
      PaperProps={{
        style: {
          width: `${width}px`,
          ...paperStyle,
        },
      }}
    >
      <nav className={classes.root}>{children}</nav>
    </Drawer>
  )
}

export default SidebarMenu
