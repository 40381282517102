import { useContext } from 'react'
import AppContext from 'context'
import { useQuery, UseQueryOptions, useInfiniteQuery } from 'react-query'
import {
  UserAlertItemSeverity,
  ApiResponseError,
  ApiResponseDtoOfConvergeInsightsAggregation,
  ApiResponseDtoOfConvergeInstance,
  ApiResponseDtoOfPagedResultDtoOfUserAlertItem,
  AnalyticsLibrary,
  ApiResponseDtoOfConvergePlatformInsightsAggregation,
  ApiResponseDtoOfItemListResponseOfConvergeInstanceDomain,
  ApiResponseDtoOfListResultDtoOfConvergeDataDestinationStatus,
  ApiResponseDtoOfListResultDtoOfConvergeSdkClientStatus,
  ApiResponseDtoOfItemListResponseOfConvergeInstanceSummaryWithApiDetails,
  ApiResponseDtoOfConvergeInstanceDomainRulesInfo,
} from 'generated/api/app-service-proxies'
import { api } from 'services'
import { Moment } from 'moment'
import queryKeys from './queryKeys'

export const useConvergePerformanceInsights = (
  startDate: Moment,
  endDate: Moment,
  domainId?: string,
  options?: UseQueryOptions<
    ApiResponseDtoOfConvergeInsightsAggregation,
    ApiResponseError,
    ApiResponseDtoOfConvergeInsightsAggregation,
    ReturnType<typeof queryKeys.insights>
  >
) => {
  const { selectedBrand } = useContext(AppContext)
  return useQuery(
    queryKeys.insights(startDate, endDate, selectedBrand.id, domainId),
    () => {
      return api.converge.performanceInsights(
        startDate,
        endDate,
        [selectedBrand.id],
        domainId
      )
    },
    {
      ...options,
    }
  )
}

export const useConvergePlatformPerformanceInsights = (
  startDate: Moment,
  endDate: Moment,
  platform: AnalyticsLibrary,
  domainId?: string,
  options?: UseQueryOptions<
    ApiResponseDtoOfConvergePlatformInsightsAggregation,
    ApiResponseError,
    ApiResponseDtoOfConvergePlatformInsightsAggregation,
    ReturnType<typeof queryKeys.platformInsights>
  >
) => {
  const { selectedBrand } = useContext(AppContext)
  return useQuery(
    queryKeys.platformInsights(startDate, endDate, selectedBrand.id, platform, domainId),
    async () => {
      const response = await api.converge.platformPerformanceInsights(
        platform,
        startDate,
        endDate,
        [selectedBrand.id],
        domainId
      )

      return response.result
        ? response
        : ApiResponseDtoOfConvergePlatformInsightsAggregation.fromJS({
          result: {
            platform,
            signals: {
              signalIngressTotal: 0,
              signalEgressTotal: 0,
              signalReinstatedTotal: 0,
              breakdown: [],
            },
          },
        })
    },
    {
      ...options,
    }
  )
}

export const useDefaultConvergeInstance = (
  options?: UseQueryOptions<
    ApiResponseDtoOfConvergeInstance,
    ApiResponseError,
    ApiResponseDtoOfConvergeInstance,
    ReturnType<typeof queryKeys.defaultConvergeInstace>
  >
) => {
  const { selectedBrand } = useContext(AppContext)
  return useQuery(
    queryKeys.defaultConvergeInstace(selectedBrand.id),
    () => api.converge.getDefaultSingleInstanceForBrand(selectedBrand.id),
    {
      ...options,
    }
  )
}

export const useGetAllConvergeInstances = (
  options?: UseQueryOptions<
    ApiResponseDtoOfItemListResponseOfConvergeInstanceSummaryWithApiDetails,
    ApiResponseError,
    ApiResponseDtoOfItemListResponseOfConvergeInstanceSummaryWithApiDetails,
    ReturnType<typeof queryKeys.getAllConvergeInstances>
  >
) => {
  const { selectedBrand } = useContext(AppContext)
  return useQuery(
    queryKeys.getAllConvergeInstances(selectedBrand.id),
    () => api.converge.getAllConvergeInstances(selectedBrand.id),
    {
      ...options,
    }
  )
}

export const useConvergeActivity = (
  startDate: Moment,
  endDate: Moment,
  severities: UserAlertItemSeverity[],
  rowsPerPage = 15
) => {
  const { selectedBrand, userGrants } = useContext(AppContext)
  return useInfiniteQuery<
    ApiResponseDtoOfPagedResultDtoOfUserAlertItem,
    ApiResponseError,
    ApiResponseDtoOfPagedResultDtoOfUserAlertItem,
    ReturnType<typeof queryKeys.activity>
  >(
    queryKeys.activity(startDate, endDate, selectedBrand.id, severities, rowsPerPage),
    ({ pageParam = 0 }) =>
      api.userAlerts.getAll(
        startDate,
        endDate,
        [selectedBrand.id],
        severities,
        ['converge'],
        rowsPerPage,
        pageParam * rowsPerPage
      ),
    {
      getNextPageParam: (lastPage, allPages) => {
        const flattenedPagesLength = allPages.map((x) => x.result.items).flat().length
        const currentPage = flattenedPagesLength / rowsPerPage - 1
        const hasNextPage = currentPage < lastPage.result.totalCount / rowsPerPage - 1
        return hasNextPage ? currentPage + 1 : undefined
      },
      enabled: userGrants.includes('seeka.Converge'),
    }
  )
}

export const useGetSingleConvergeInstance = (
  convergeInstanceId: string,
  options?: UseQueryOptions<
    ApiResponseDtoOfConvergeInstance,
    ApiResponseError,
    ApiResponseDtoOfConvergeInstance,
    ReturnType<typeof queryKeys.singleConvergeInstance>
  >
) => {
  return useQuery(
    queryKeys.singleConvergeInstance(convergeInstanceId),
    () => {
      return api.converge.getSingleInstance(convergeInstanceId)
    },
    {
      ...options,
    }
  )
}

export const useGetAllConvergeDomains = (
  convergeInstanceId: string,
  options?: UseQueryOptions<
    ApiResponseDtoOfItemListResponseOfConvergeInstanceDomain,
    ApiResponseError,
    ApiResponseDtoOfItemListResponseOfConvergeInstanceDomain,
    ReturnType<typeof queryKeys.allConvergeDomains>
  >
) => {
  return useQuery(
    queryKeys.allConvergeDomains(convergeInstanceId),
    () => {
      return api.converge.getAllConvergeDomains(convergeInstanceId)
    },
    {
      ...options,
    }
  )
}

export const useGetAllConvergeDomainRules = (
  convergeInstanceId: string,
  options?: UseQueryOptions<
  ApiResponseDtoOfConvergeInstanceDomainRulesInfo,
    ApiResponseError,
    ApiResponseDtoOfConvergeInstanceDomainRulesInfo,
    ReturnType<typeof queryKeys.allConvergeDomainRules>
  >
) => {
  return useQuery(
    queryKeys.allConvergeDomainRules(convergeInstanceId),
    () => {
      return api.converge.getConvergeDomainRules(convergeInstanceId)
    },
    {
      ...options,
    }
  )
}

export const useGetListOfConnectedDataConnections = (
  convergeInstanceId: string,
  options?: UseQueryOptions<
    ApiResponseDtoOfListResultDtoOfConvergeDataDestinationStatus,
    ApiResponseError,
    ApiResponseDtoOfListResultDtoOfConvergeDataDestinationStatus,
    ReturnType<typeof queryKeys.listOfDataConnections>
  >
) => {
  return useQuery(
    queryKeys.listOfDataConnections(convergeInstanceId),
    () => {
      return api.converge.dataDestinationsInsights()
    },
    {
      ...options,
    }
  )
}

export const useGetAllDomainsThatHaveData = (
  options?: UseQueryOptions<
    ApiResponseDtoOfListResultDtoOfConvergeSdkClientStatus,
    ApiResponseError,
    ApiResponseDtoOfListResultDtoOfConvergeSdkClientStatus,
    ReturnType<typeof queryKeys.sdkClientInsights>
  >
) => {
  const { selectedBrand } = useContext(AppContext)
  return useQuery(
    queryKeys.sdkClientInsights(selectedBrand.id),
    async () => {
      const [instanceData, domainsData] = await Promise.all([
        api.converge.getAllConvergeInstances(selectedBrand.id),
        api.converge.sdkClientInsights(),
      ])

      const instanceIds: string[] =
        instanceData.result.result.map((item) => item.id) || []

      domainsData.result.items = domainsData.result.items.filter((item) =>
        instanceIds.includes(item.convergeInstanceId)
      )

      return domainsData
    },
    {
      cacheTime: 0,
      ...options,
    }
  )
}
