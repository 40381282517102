import React from 'react'
import classNames from 'classnames'
import {
  Typography,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  makeStyles,
  CircularProgress,
  Tooltip,
  Badge,
} from '@material-ui/core'
import { ChevronRight as ChevronRightIcon } from '@material-ui/icons'
import logo from 'assets/img/white_hand.png'
import { Skeleton } from '@material-ui/lab'
import { grey } from '@material-ui/core/colors'

const useStyles = makeStyles((theme) => ({
  smallListItem: {
    minHeight: '40px',
    paddingTop: 0,
    paddingBottom: 0,
    '& div': {
      fontSize: '14px',
    },
    '& span': {
      fontSize: '14px',
    },
  },
  mediumListItem: {
    minHeight: '61px',
    paddingTop: theme.spacing(0.6),
    paddingBottom: theme.spacing(0.6),
    paddingLeft: theme.spacing(2),
  },
  largeListItem: {
    minHeight: '70px',
    paddingTop: theme.spacing(0.6),
    paddingBottom: theme.spacing(0.6),
  },
  withBorderRadius: {
    '&:hover': {
      borderRadius: theme.shape.borderRadius,
    },
  },
  noBorderRadius: {
    '&:hover': {
      borderRadius: 0,
    },
  },
  cta: {
    color: theme.palette.primary.main,
  },
  chevron: {
    color: theme.palette.text.secondary,
    maxWidth: 22,
    maxHeight: 22,
    width: 22,
    height: 22,
  },
  avatar: {
    color: theme.palette.common.white,
    backgroundColor: grey[400],
  },
  logo: {
    maxHeight: 24,
    maxWidth: 24,
  },
  small: {
    width: 36,
    height: 36,
  },
  medium: {
    width: 40,
    height: 40,
  },
  large: {
    width: 46,
    height: 46,
  },
  listItemAvatar: {
    minWidth: 52,
  },
  selectedPrimary: {
    color: theme.palette.primary.main,
  },
  selectedAvatar: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light,
  },
  hoverStyle: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    textDecoration: 'none',
  },
}))

interface Props {
  loading?: boolean
  disabled?: boolean
  tooltip?: string
  icon?: JSX.Element
  primaryText?: string | JSX.Element
  secondaryText?: string | JSX.Element
  goToText?: string | JSX.Element
  secondaryAction?: JSX.Element
  blurPrimaryText?: boolean
  blurSecondaryText?: boolean
  onClick?: () => void
  hasBorderRadius?: boolean
  noIcon?: boolean
  skeleton?: boolean
  avatarBackgroundColor?: string
  withChevron?: boolean
  size?: 'small' | 'medium' | 'large'
  listItemSize?: 'smallListItem' | 'mediumListItem' | 'largeListItem'
  alignItems?: 'flex-start' | 'center'
  selected?: boolean
  marginBottom?: number
  hasHoverStyle?: boolean
  noWrap?: boolean
}

interface ListItemProps extends Partial<Props> {
  children: React.ReactNode
}

const ListItemComponent = ({
  loading,
  disabled,
  children,
  onClick,
  hasBorderRadius,
  listItemSize = 'mediumListItem',
  selected,
  marginBottom,
  alignItems,
  hasHoverStyle,
}: ListItemProps) => {
  const classes = useStyles()

  const root = classNames([classes[listItemSize]], {
    [classes.withBorderRadius]: hasBorderRadius,
    [classes.noBorderRadius]: !hasBorderRadius,
    [classes.hoverStyle]: hasHoverStyle,
  })

  if (onClick) {
    return (
      <ListItem
        selected={selected}
        ContainerComponent="div"
        disabled={Boolean(disabled || loading)}
        className={root}
        onClick={onClick}
        button
        style={{ marginBottom }}
        alignItems={alignItems}
      >
        {children}
      </ListItem>
    )
  }
  return (
    <ListItem
      selected={selected}
      ContainerComponent="div"
      disabled={Boolean(disabled || loading)}
      className={classes[listItemSize]}
      alignItems={alignItems}
    >
      {children}
    </ListItem>
  )
}

const TicketRevListItem = ({
  loading = false,
  skeleton = false,
  disabled = false,
  blurPrimaryText = false,
  blurSecondaryText = false,
  hasBorderRadius = true,
  tooltip = '',
  icon,
  primaryText,
  secondaryText,
  goToText,
  secondaryAction,
  onClick,
  noIcon = false,
  avatarBackgroundColor,
  withChevron,
  size = 'medium',
  listItemSize = 'mediumListItem',
  selected,
  marginBottom,
  alignItems = 'center',
  hasHoverStyle,
  noWrap,
}: Props) => {
  const classes = useStyles()

  const avatarRoot = classNames({
    [classes[size]]: true,
    [classes.avatar]: !selected,
    [classes.selectedAvatar]: selected,
  })

  const baseInfoProps = {
    primaryTypographyProps: { noWrap },
    secondaryTypographyProps: { noWrap },
  }

  return skeleton ? (
    <ListItem className={classes[listItemSize]}>
      <ListItemAvatar>
        <Skeleton variant="circle" className={classes[size]} />
      </ListItemAvatar>
      <ListItemText
        primary={<Skeleton height={14} width="60%" style={{ marginBottom: 6 }} />}
        secondary={<Skeleton height={8} width="40%" />}
      />
    </ListItem>
  ) : (
    <ListItemComponent
      onClick={onClick}
      loading={loading}
      disabled={disabled}
      hasBorderRadius={hasBorderRadius}
      listItemSize={listItemSize}
      selected={selected}
      marginBottom={marginBottom}
      alignItems={alignItems}
      hasHoverStyle={hasHoverStyle}
    >
      {!noIcon && (
        <Tooltip placement="right" title={tooltip || ''} arrow>
          <ListItemAvatar className={classes.listItemAvatar}>
            <Avatar
              className={avatarRoot}
              style={
                (avatarBackgroundColor && { backgroundColor: avatarBackgroundColor }) ||
                {}
              }
            >
              <Badge
                color="primary"
                variant="dot"
                invisible={Boolean(!tooltip) || loading}
              >
                {loading ? (
                  <CircularProgress size={18} />
                ) : (
                  icon || (
                    <img
                      src={logo}
                      alt={typeof primaryText === 'string' ? primaryText : ''}
                      className={classes.logo}
                    />
                  )
                )}
              </Badge>
            </Avatar>
          </ListItemAvatar>
        </Tooltip>
      )}
      <ListItemText
        {...baseInfoProps}
        primary={primaryText}
        secondary={loading ? 'Loading...' : secondaryText || undefined}
        classes={{
          primary: selected ? classes.selectedPrimary : '',
        }}
        secondaryTypographyProps={{
          ...baseInfoProps.secondaryTypographyProps,
          style: { filter: blurSecondaryText ? 'blur(4px)' : undefined },
        }}
        primaryTypographyProps={{
          ...baseInfoProps.primaryTypographyProps,
          style: { filter: blurPrimaryText ? 'blur(4px)' : undefined },
        }}
      />
      {goToText && (
        <Typography variant="body2" color="textSecondary" display="inline">
          {goToText}
        </Typography>
      )}
      {withChevron && <ChevronRightIcon className={classes.chevron} />}
      {secondaryAction && (
        <ListItemSecondaryAction>{secondaryAction}</ListItemSecondaryAction>
      )}
    </ListItemComponent>
  )
}

export default TicketRevListItem
