import { makeStyles, Theme, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    marginBottom: theme.spacing(5),
  },
  subtitle: {
    fontWeight: 400,
    marginTop: theme.spacing(),
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
}))

interface Props {
  title: string
  subtitle?: string
  noWrap?: boolean
  center?: boolean
}

const Title = ({ title, subtitle, noWrap, center }: Props) => {
  const classes = useStyles()

  return (
    <div className={classes.title} style={{ textAlign: center ? 'center' : 'left' }}>
      <Typography variant="h3" className={noWrap ? classes.noWrap : ''}>
        {title}
      </Typography>
      {subtitle && (
        <Typography variant="h5" color="secondary" className={classes.subtitle}>
          {subtitle}
        </Typography>
      )}
    </div>
  )
}

export default Title
