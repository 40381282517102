import { useMe } from 'hooks'
import { useEffect, useState } from 'react'

export type InternalStaffControls = {
  isInternalStaff: boolean
  controls: InternalStaffControlSettings
  reset: () => void
  set: (key: keyof InternalStaffControlSettings, enabled: boolean) => void
  blurredStyling: any
}

export type InternalStaffControlSettings = {
  isDemoModeEnabled: boolean
}

const useSeekaInternalStaffControls = (): InternalStaffControls => {
  const [stateLastUpdate, setStateLastUpdate] = useState<number>(new Date().getTime());

  const { data: meData } = useMe();

  const reset = () => {
    sessionStorage.removeItem('seeka.staff.setting.isDemoModeEnabled')

    setStateLastUpdate(new Date().getTime())
  }

  const set = (key: keyof InternalStaffControlSettings, enabled: boolean) => {
    if (enabled) {
      sessionStorage.setItem(`seeka.staff.setting.${key}`, '1')
    }
    else {
      sessionStorage.removeItem(`seeka.staff.setting.${key}`)
    }
    setStateLastUpdate(new Date().getTime())
  }

  const hasPermission = () => {
    return meData?.result.config.auth.grantedPolicies[`_Core.Internal.StaffMember`] === true
  }

  const blurredCss = {
    filter: 'blur(4px)'
  }

  const [installContext, setInstallContext] = useState<InternalStaffControls>({ isInternalStaff: hasPermission(), reset, set, controls: { isDemoModeEnabled: false }, blurredStyling: {} });

  useEffect(() => {
    const hasPerm = hasPermission();
    const isDemoModeEnabled = hasPerm && sessionStorage.getItem(`seeka.staff.setting.isDemoModeEnabled`) === '1'
    setInstallContext(
      {
        isInternalStaff: hasPerm,
        reset,
        set,
        blurredStyling: isDemoModeEnabled ? blurredCss : {},
        controls: {
          isDemoModeEnabled
        }
      }
    )
  }, [stateLastUpdate, meData])

  return installContext
}

export default useSeekaInternalStaffControls
