import googlelogo from 'assets/img/google_logo.png'
import classNames from 'classnames'
import { Facebook } from 'mdi-material-ui'

import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'

// import microsoftlogo from 'assets/img/microsoft_logo.svg'

const useStyles = makeStyles((theme: Theme) => ({
  providerButton: {
    borderRadius: 2,
    height: 46,
  },
  facebook: {
    backgroundColor: theme.palette.brand.facebook.backgroundColor,
    '&:hover': {
      opacity: 0.9,
      backgroundColor: theme.palette.brand.facebook.backgroundColor,
    },
  },
  google: {
    backgroundColor: theme.palette.brand.google.backgroundColor,
    '&:hover': {
      opacity: 0.9,
      backgroundColor: theme.palette.brand.google.backgroundColor,
    },
  },
  providerButtonText: {
    color: theme.palette.common.white,
    textAlign: 'center',
  },
  listItemIcon: {
    minWidth: '28px',
  },
  listItemText: {
    color: theme.palette.common.white,
  },
  facebookLogo: {
    color: theme.palette.common.white,
    width: 28,
    height: 28,
  },
  googleLogo: {
    width: 42,
    height: 42,
    borderRadius: 2,
    backgroundColor: theme.palette.common.white,
    marginLeft: -6,
    marginTop: -1,
    marginBottom: -1,
    '& img': {
      width: 22,
      height: 22,
    },
  },
}))

interface Props {
  provider: 'microsoft' | 'facebook' | 'google'
  variant: 'login' | 'create-account'
  link: string
  disabled?: boolean
  onClick?: () => void
}

const LoginProvider = ({ provider, link, disabled, variant, onClick }: Props) => {
  const classes = useStyles()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'), {
    noSsr: true,
  })

  const continueWithProvider = `${
    isMobile
      ? variant === 'login'
        ? 'With'
        : 'With'
      : variant === 'login'
      ? 'Sign in with'
      : 'Sign up with'
  } ${provider.charAt(0).toUpperCase() + provider.slice(1)}`

  const listItemClassName = classNames([classes.providerButton], {
    [classes.facebook]: provider === 'facebook',
    [classes.google]: provider === 'google',
  })

  return (
    <ListItem
      button
      onClick={onClick}
      className={listItemClassName}
      component="a"
      href={link}
      disabled={disabled}
    >
      {provider === 'facebook' && (
        <ListItemIcon className={classes.listItemIcon}>
          <Facebook className={classes.facebookLogo} />
        </ListItemIcon>
      )}
      {provider === 'google' && (
        <ListItemAvatar className={classes.listItemIcon}>
          <Avatar
            src={googlelogo}
            alt={continueWithProvider}
            className={classes.googleLogo}
            variant="rounded"
          />
        </ListItemAvatar>
      )}
      <ListItemText
        primary={continueWithProvider}
        className={classes.listItemText}
        color="inherit"
        classes={{
          primary: classes.providerButtonText,
        }}
        primaryTypographyProps={{
          noWrap: true,
        }}
      />
    </ListItem>
  )
}

export default LoginProvider
