import classNames from 'classnames'

import { Grid, makeStyles, Theme, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginBottom: theme.spacing(3),
  },
}))

interface Props {
  title: string
}

const SettingsTitle = ({ title }: Props) => {
  const classes = useStyles()

  const rootClassName = classNames({
    [classes.container]: true,
  })

  if (!title) return <></>

  return (
    <Grid container className={rootClassName}>
      <Typography variant="h1" display="inline">
        {title}
      </Typography>
    </Grid>
  )
}

export default SettingsTitle
