import {
  FilterConditionSourceGroup,
  ConditionComparer,
  FilterCondition,
  QueryCriteria,
} from 'generated/api/app-service-proxies'
import { v4 as uuidv4 } from 'uuid'
import { Criteria } from './index'

export const formatSegmentConditionFields = (
  conditionFields: FilterConditionSourceGroup[]
) => {
  return conditionFields
    .map((x) => {
      return x.fields.map((y) => {
        return {
          ...y,
          sourceTypeDescription: x.sourceTypeDescription,
          sourceTypeKey: x.sourceTypeKey,
        }
      })
    })
    .flat()
}

export const createCriteria = (
  conditionFields: FilterConditionSourceGroup[],
  fieldKey: string,
  operator: string,
  value: any,
  locked?: boolean
): Criteria => {
  const formattedConditionFields = formatSegmentConditionFields(conditionFields)
  const findField = formattedConditionFields.find((x) => x.fieldKey === fieldKey)
  const findOperator = findField?.options.find((x) => x.operator.operator === operator)
  return {
    field: findField || null,
    operator: findOperator || null,
    value: value || undefined,
    id: uuidv4(),
    locked: Boolean(locked),
  }
}

export const createEmptyCriteria = (): Criteria => {
  return {
    field: null,
    operator: null,
    value: null,
    id: uuidv4(),
    locked: false,
  }
}

export const getFullyConfiguredCriteria = (criteria: Criteria[]) => {
  return criteria.filter((x) => {
    const isValid = Boolean(
      x.operator?.valueType === 'noValue' || (x.operator?.valueType && x.value)
    )
    return x.field && x.operator && isValid
  })
}

export const formatCriteria = (
  criteria: Criteria[],
  comparer: ConditionComparer = ConditionComparer.And,
  type: 'groups' | 'conditionGroups' = 'groups'
): QueryCriteria => {
  const conditions = [
    ...criteria.map((x) => {
      return {
        comparer,
        conditions: [
          {
            sourceFieldKey: x.field?.fieldKey,
            sourceTypeKey: x.field?.sourceTypeKey,
            valueType: x.operator?.valueType,
            operator: {
              operator: x.operator?.operator.operator,
              description: x.operator?.operator.description,
            },
            value: x.value || undefined,
          },
        ] as FilterCondition[],
      }
    }),
  ]
  return type === 'groups'
    ? ({
        rootComparer: comparer,
        conditionGroups: conditions,
      } as QueryCriteria)
    : ({
        rootComparer: comparer,
        conditionGroups: conditions,
      } as QueryCriteria)
}
