import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import {
  DrawerHeader,
  TicketRevTextField,
  TicketRevSelect,
  ChannelIcon,
  TicketRevTimePicker,
  TicketRevDatePicker,
} from 'components'
import { makeStyles, Theme, Grid, Typography } from '@material-ui/core'
import useDebounce from 'utils/useDebounce'
import moment from 'moment-timezone'
import { StepProps, WaitProps } from '../../../helpers'

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    marginTop: theme.spacing(2),
  },
}))

interface DelayDurationMeasureOptionsProps {
  label: string
  value: WaitProps['delayDurationMeasure']
}

const delayDurationMeasureOptions: DelayDurationMeasureOptionsProps[] = [
  {
    label: 'Days',
    value: 'days',
  },
  {
    label: 'Hours',
    value: 'hours',
  },
  {
    label: 'Minutes',
    value: 'minutes',
  },
  {
    label: 'Seconds',
    value: 'seconds',
  },
]

interface DelayTypeOptionsProps {
  label: string
  description: string
  value: WaitProps['delayType']
}

const delayTypeOptions: DelayTypeOptionsProps[] = [
  {
    label: 'Duration',
    description: 'Wait for a duration of time and then continue',
    value: 'duration',
  },
  {
    label: 'Date',
    description: 'Wait until a specific date and then continue',
    value: 'date',
  },
]

interface Props {
  step: WaitProps
  updateStep: (newStep: StepProps) => void
}

const WaitForm = ({ step, updateStep }: Props) => {
  const classes = useStyles()

  const { setFieldValue, values } = useFormik({
    initialValues: {
      delayType: step.delayType,
      delayDurationMeasure: step.delayDurationMeasure,
      delayDate: step.delayDate || moment(),
      delayDuration: step.delayDuration,
    },
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: () => updateStep(step),
  })

  const [debouncedUpdate] = useDebounce(values, 350)
  useEffect(() => {
    const newStep = { ...step, ...values }
    updateStep(newStep)
    // eslint-disable-next-line
  }, [debouncedUpdate])

  const handleSetSelect = (
    name: string,
    value: DelayTypeOptionsProps | DelayDurationMeasureOptionsProps
  ) => {
    setFieldValue(name, value.value)
  }

  return (
    <>
      <DrawerHeader
        primary={step.label}
        secondary="Delay the flow and then continue"
        icon={<ChannelIcon channel={step.type} size={70} />}
        withMargin={false}
      />
      <div className={classes.form}>
        <TicketRevSelect
          label="Delay type"
          value={delayTypeOptions.find((x) => x.value === values.delayType)}
          options={delayTypeOptions}
          getOptionLabel={(option) => option.label}
          getOptionSelected={(option, value) => option.value === value.value}
          onChange={handleSetSelect}
          name="delayType"
          renderOption={(option: DelayTypeOptionsProps) => {
            return (
              <>
                <Typography noWrap>{option.label}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {option.description}
                </Typography>
              </>
            )
          }}
        />
        {values.delayType === 'duration' && (
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <TicketRevTextField
                label="Delay duration"
                placeholder="e.g. 7"
                onChange={setFieldValue}
                name="delayDuration"
                type="number"
                value={values.delayDuration}
              />
            </Grid>
            <Grid item xs={5}>
              <TicketRevSelect
                label="Delay unit"
                value={delayDurationMeasureOptions.find(
                  (x) => x.value === values.delayDurationMeasure
                )}
                options={delayDurationMeasureOptions}
                getOptionLabel={(option) => option.label}
                getOptionSelected={(option, value) => option.value === value.value}
                onChange={handleSetSelect}
                name="delayDurationMeasure"
              />
            </Grid>
          </Grid>
        )}
        {values.delayType === 'date' && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TicketRevDatePicker
                  name="delayDate"
                  value={values.delayDate}
                  onChange={setFieldValue}
                  label="Delay date"
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TicketRevTimePicker
                  label="Delay time"
                  required
                  name="delayDate"
                  onChange={setFieldValue}
                  value={values.delayDate}
                />
              </Grid>
            </Grid>
            {/* <Grid item xs={12}>
              <TicketRevSelect
                label="Timezone"
                disableClearable={false}
                required
                options={timezones}
                getOptionLabel={(option: TimeZoneInfo) => option.description}
                getOptionSelected={(option: TimeZoneInfo, value: TimeZoneInfo) =>
                  option.friendlyId === value.friendlyId
                }
                onChange={(name: string, value: TimeZoneInfo) =>
                  setValue(name, value.friendlyId)
                }
                value={timezones?.find((x) => x.friendlyId === timezone) || null}
                name="timezone"
                disabled={loading}
              />
              </Grid> */}
          </>
        )}
      </div>
    </>
  )
}
export default WaitForm
