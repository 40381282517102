import config from 'config'
import { ConfirmProvider } from 'material-ui-confirm'
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter'
import bash from 'react-syntax-highlighter/dist/esm/languages/hljs/bash'
import { IntercomProvider } from 'react-use-intercom'
import Router from 'routes/Router'

import { Theme, useMediaQuery } from '@material-ui/core'
import { SeekaProvider } from '@seeka-labs/converge-react'

import { OfflineSnackbar, ThemedSnackbarProvider } from './components'

SyntaxHighlighter.registerLanguage('bash', bash)

const seekaOrgId = '6b38b47b35f158df06823a0c0c4c6003'
const seekaPublicKey =
  'sdkr_Q2ZESjhPWkNZdzdQRGdSQXU4VWZjV0c3TmdIcFlxTklka2ZJNzIyMjBrZF9iQkZNZUxGOU8xNUNhZUhOT1lacHBLM24zN042X2hPRVctSktkWTVWODRfWUhpUHgtNkJaZ3ZwMDkwaDRkYXRQS3BkaU1aQlE2eS1heVFkWDRfTGpsRk1TZHJiT0dxYlVQdEN6eFlyTld2aXdhZFNyTGZwSkFSaFJCR2tUOWtYZTlyTVM'
const seekaInstanceId = '0c1426cac8cbdea4dd023a0c0c4c7d52'
const seekaTagHosts = [
  {
    hostname: '9cab.seeka.app',
  },
]
// export const getSeekaConfig = () => {
//   const seekaConfig = new ConvergeSdkConfiguration(
//     '',
//     '6b38b47b35f158df06823a0c0c4c6003',
//     {
//       analytics: {
//         autoCollection: {
//           activityNames: [],
//         },
//       },
//       debug: {
//         isEnabled: true,
//       },
//       defaults: { currencyCode: 'AUD' },
//       processing: {
//         consent: {
//           disableConsentManagement: false,
//           regionalConsent: [
//             {
//               configuration: {
//                 adStorage: RegionalConsentGrantOption.Granted,
//                 adUserData: RegionalConsentGrantOption.Granted,
//                 adPersonalisation: RegionalConsentGrantOption.Granted,
//                 functionalityStorage: RegionalConsentGrantOption.Granted,
//                 personalisationStorage: RegionalConsentGrantOption.Granted,
//                 securityStorage: RegionalConsentGrantOption.Granted,
//                 analyticsStorage: RegionalConsentGrantOption.Granted,
//               },
//             },
//             {
//               countryCode: 'AU',
//               configuration: {
//                 adStorage: RegionalConsentGrantOption.Granted,
//                 adUserData: RegionalConsentGrantOption.Granted,
//                 adPersonalisation: RegionalConsentGrantOption.Granted,
//                 functionalityStorage: RegionalConsentGrantOption.Granted,
//                 personalisationStorage: RegionalConsentGrantOption.Granted,
//                 securityStorage: RegionalConsentGrantOption.Granted,
//                 analyticsStorage: RegionalConsentGrantOption.Granted,
//               },
//             },
//             {
//               countryCode: 'US',
//               configuration: {
//                 adStorage: RegionalConsentGrantOption.Granted,
//                 adUserData: RegionalConsentGrantOption.Granted,
//                 adPersonalisation: RegionalConsentGrantOption.Granted,
//                 functionalityStorage: RegionalConsentGrantOption.Granted,
//                 personalisationStorage: RegionalConsentGrantOption.Granted,
//                 securityStorage: RegionalConsentGrantOption.Granted,
//                 analyticsStorage: RegionalConsentGrantOption.Granted,
//               },
//             },
//             {
//               countryCode: 'US-CA',
//               configuration: {
//                 adStorage: RegionalConsentGrantOption.Denied,
//                 adUserData: RegionalConsentGrantOption.Denied,
//                 adPersonalisation: RegionalConsentGrantOption.Denied,
//                 functionalityStorage: RegionalConsentGrantOption.Denied,
//                 personalisationStorage: RegionalConsentGrantOption.Denied,
//                 securityStorage: RegionalConsentGrantOption.Denied,
//                 analyticsStorage: RegionalConsentGrantOption.Denied,
//               },
//             },
//           ],
//         },
//       },
//     }
//   )
//   seekaConfig.scopeDescription = 'Seeka HQ'
//   seekaConfig.scopeKey = '96b9589f92309f668e3f3a0c0c4c650a'
//   seekaConfig.plugins = [
//     new FacebookPixelConvergeSdkPlugin({
//       pixelId: '762654351363552',
//     }),
//     new GoogleAnalytics4ConvergeSdkPlugin({
//       measurementId: 'G-55S9NW6Q6M',
//     }),
//     new SeekaConvergeSdkPlugin({ scopeKey: seekaConfig.scopeKey, debug: false }),
//     new HotjarConvergeSdkPlugin({
//       siteId: 3880227,
//       enableDebugEventNames: false,
//     }),
//   ]
//   if (config.seekaEventTrackingDisabled) {
//     seekaConfig.isDisabled = true
//   }

//   return seekaConfig
// }

const App = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'), {
    noSsr: true,
  })

  return (
    <ConfirmProvider>
      <ThemedSnackbarProvider>
        {/* <SeekaProvider config={getSeekaConfig()}> */}
        <SeekaProvider
          org={seekaOrgId}
          publicKey={seekaPublicKey}
          id={seekaInstanceId}
          hosts={seekaTagHosts}
        >
          <IntercomProvider
            appId={config.intercomAppId}
            autoBoot
            autoBootProps={{
              hideDefaultLauncher: true,
            }}
          >
            <OfflineSnackbar message="It appears you're offline. Establish an internet connection and come back!" />
            <Router />
          </IntercomProvider>
        </SeekaProvider>
      </ThemedSnackbarProvider>
    </ConfirmProvider>
  )
}

export default App
