import React from 'react'
import classNames from 'classnames'
import { makeStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  line: {
    height: 6,
    borderRadius: theme.shape.borderRadius,
    flexGrow: 1,
    margin: 1,
  },
  inactive: {
    backgroundColor: theme.palette.background.default,
  },
  active: {
    backgroundColor: theme.palette.success.main,
  },
}))

interface Props {
  active: boolean
  paddingLeft: boolean
}

const PasswordCheckLine = ({ active, paddingLeft }: Props) => {
  const classes = useStyles()

  const rootClassName = classNames({
    [classes.line]: true,
    [classes.active]: active,
    [classes.inactive]: !active,
  })

  return <div className={rootClassName} style={{ paddingLeft: paddingLeft ? 4 : 0 }} />
}

export default PasswordCheckLine
