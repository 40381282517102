import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

export type ShopifyAppInstallContext = {
  shopifyContextId?: string
  shopifyDomain?: string
  shopifyStoreName?: string
  hasContext: boolean
  reset: () => void
}

const useShopifyAppInstallContext = (): ShopifyAppInstallContext => {
  const reset = () => {
    sessionStorage.removeItem('seeka.shopify.install.contextId')
    sessionStorage.removeItem('seeka.shopify.install.domain')
    sessionStorage.removeItem('seeka.shopify.install.storeName')

    // setInstallContext({
    //   hasContext: false,
    //   reset
    // })
  }

  const [installContext, setInstallContext] = useState<ShopifyAppInstallContext>({ hasContext: false, reset });
  const location = useLocation()

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const shopifyContextId = queryParams.get('shopifyContextId') || sessionStorage.getItem('seeka.shopify.install.contextId')
    if (shopifyContextId) {
      sessionStorage.setItem('seeka.shopify.install.contextId', shopifyContextId)
    }
    const shopifyDomain = queryParams.get('shopifyDomain') || sessionStorage.getItem('seeka.shopify.install.domain')
    if (shopifyDomain) {
      sessionStorage.setItem('seeka.shopify.install.domain', shopifyDomain)
    }
    const shopifyStoreName = queryParams.get('shopifyStoreName') || sessionStorage.getItem('seeka.shopify.install.storeName')
    if (shopifyStoreName) {
      sessionStorage.setItem('seeka.shopify.install.storeName', shopifyStoreName)
    }

    if (shopifyContextId && shopifyDomain && shopifyStoreName) {
      setInstallContext({
        hasContext: true,
        shopifyContextId,
        shopifyDomain,
        shopifyStoreName,
        reset
      })
    }
  }, [location.search])

  return installContext
}

export default useShopifyAppInstallContext
