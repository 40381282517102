import { useMe } from 'hooks';

import { Typography } from '@material-ui/core';

import { ErrorSplashContainer } from './ErrorSplashContainer';

export const NoBrandAuthError = () => {
    const me = useMe();

    return <ErrorSplashContainer title='This is awkward...' content={<>
        <Typography variant="h4" component="h4" style={{ fontWeight: 'inherit', marginBottom: '20px' }}>Your user does not have access to any brands within the <strong>{me.data?.result.organisation.description}</strong> organisation.</Typography>
        <Typography variant="h4" component="h4" style={{ fontWeight: 'inherit' }}>Select another organisation or create an organisation of your own from the top right corner toolbar.</Typography>
    </>} />
}