import React, { memo } from 'react'
import { NavLink } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'
// import { AlienOutline as NewIcon } from 'mdi-material-ui'
import authenticatedRoutes from 'routes/authenticatedRoutes'
import { hasOneOfRequiredGrants } from 'utils'

import {
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Theme,
  Tooltip,
} from '@material-ui/core'
import { ContactSupport as HelpIcon } from '@material-ui/icons'

import linkStyle from './linkStyle'

// import SetupTicketRev from '../SetupTicketRev'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingBottom: theme.spacing(4),
  },
}))

interface Props {
  userGrants: string[]
  slimMode: boolean
}

const BottomSidebarContainer = ({ userGrants, slimMode }: Props) => {
  const classes = useStyles()
  const linkClasses = linkStyle()

  const findIntegrations = authenticatedRoutes.find((x) => x.key === 'ViewIntegrations')
  const findSettings = authenticatedRoutes.find((x) => x.key === 'ViewSettings')

  const intercom = useIntercom()

  const openIntercom = () => {
    intercom.show()
  }

  return (
    <div className={classes.container}>
      {/* <SetupTicketRev /> */}
      {findIntegrations &&
        hasOneOfRequiredGrants(userGrants, findIntegrations.requiresOneOf) && (
          <Tooltip title={slimMode ? findIntegrations.title : ''} placement="right" arrow>
            <ListItem
              activeClassName={linkClasses.active}
              className={linkClasses.listItem}
              component={NavLink}
              to={`/${findIntegrations.rootPath}`}
            >
              <ListItemIcon className={linkClasses.listItemIcon}>
                <findIntegrations.icon className={linkClasses.icon} />
              </ListItemIcon>
              {!slimMode && (
                <ListItemText
                  primary={findIntegrations.title}
                  classes={{ primary: linkClasses.listItemText }}
                />
              )}
            </ListItem>
          </Tooltip>
        )}
      {findSettings && hasOneOfRequiredGrants(userGrants, findSettings.requiresOneOf) && (
        <Tooltip title={slimMode ? findSettings.title : ''} placement="right" arrow>
          <ListItem
            activeClassName={linkClasses.active}
            className={linkClasses.listItem}
            component={NavLink}
            to={`/${findSettings.rootPath}`}
          >
            <ListItemIcon className={linkClasses.listItemIcon}>
              <findSettings.icon className={linkClasses.icon} />
            </ListItemIcon>
            {!slimMode && (
              <ListItemText
                primary={findSettings.title}
                classes={{ primary: linkClasses.listItemText }}
              />
            )}
          </ListItem>
        </Tooltip>
      )}
      <Tooltip title={slimMode ? 'Support' : ''} placement="right" arrow>
        <ListItem
          className={linkClasses.listItem}
          // component={NavLink}
          onClick={() => {
            openIntercom()
          }}
        >
          <ListItemIcon className={linkClasses.listItemIcon}>
            <HelpIcon />
          </ListItemIcon>
          {!slimMode && (
            <ListItemText
              primary="Support"
              classes={{ primary: linkClasses.listItemText }}
            />
          )}
        </ListItem>
      </Tooltip>
      {/* <ListItem
        activeClassName={linkClasses.active}
        className={linkClasses.listItem}
        component={NavLink}
        to={`/${orgId}/settingsawefawef`}
      >
        <ListItemIcon className={linkClasses.listItemIcon}>
          <HelpIcon className={linkClasses.icon} />
        </ListItemIcon>
        <ListItemText primary="Help" classes={{ primary: linkClasses.listItemText }} />
      </ListItem>
      <ListItem
        activeClassName={linkClasses.active}
        className={linkClasses.listItem}
        component={NavLink}
        to={`/${orgId}/settingsawefawef`}
      >
        <ListItemIcon className={linkClasses.listItemIcon}>
          <NewIcon className={linkClasses.icon} />
        </ListItemIcon>
        <ListItemText
          primary="What's new"
          classes={{ primary: linkClasses.listItemText }}
        />
      </ListItem> */}
    </div>
  )
}

export default memo(BottomSidebarContainer)
