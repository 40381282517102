import React, { useRef, useEffect } from 'react'
import {
  makeStyles,
  Paper,
  MenuList,
  MenuItem,
  Popper,
  Grow,
  PopperProps,
  ListSubheader,
} from '@material-ui/core'
import { InlineTokenDropdownProps } from '../..'

const useStyles = makeStyles((theme) => ({
  menu: {
    overflow: 'auto',
    maxHeight: 250,
    boxShadow:
      '0px 5px 4px -3px rgb(0 0 0 / 8%), 0px 8px 10px 1px rgb(0 0 0 / 0%), 0px 3px 14px 2px rgb(0 0 0 / 9%)',
  },
  popper: {
    zIndex: 2500,
  },
  listSubHeader: {
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.common.white,
    lineHeight: '38px',
  },
}))

interface Props extends InlineTokenDropdownProps {
  isHovering: boolean
  setIsHovering: React.Dispatch<React.SetStateAction<boolean>>
  handleInsertToken: (tokenValue: string) => void
}

const InlineTokenDropdown = ({
  range,
  selectedIndex,
  groupedSearchedTokens,
  indexedTokens,
  isHovering,
  setIsHovering,
  handleInsertToken,
}: Props) => {
  const classes = useStyles()
  const menuRef = useRef<HTMLElement>(null)

  const getBoundingClientRect = range.getBoundingClientRect()

  const anchorEl: PopperProps['anchorEl'] = {
    clientWidth: getBoundingClientRect.width,
    clientHeight: getBoundingClientRect.height,
    getBoundingClientRect: () => {
      return {
        bottom: getBoundingClientRect.bottom,
        height: getBoundingClientRect.height,
        left: getBoundingClientRect.left,
        right: getBoundingClientRect.right,
        top: getBoundingClientRect.top,
        width: getBoundingClientRect.width,
        x: getBoundingClientRect.x,
        y: getBoundingClientRect.y,
        toJSON: () => 'yay',
      }
    },
  }

  const handleScrollToIndex = () => {
    // TODO - when decreasing index....
    // const currentScrolled = menuRef.current?.scrollTop
    // const amountToScroll = MENU_ITEM_HEIGHT * (selectedIndex - 5)
    // eslint-disable-next-line
    // menuRef.current?.scrollTo(0, amountToScroll)
    // if selected item SPACE to
  }

  useEffect(() => {
    if (!isHovering) handleScrollToIndex()
    // eslint-disable-next-line
  }, [selectedIndex])

  return (
    <Popper
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      role={undefined}
      transition
      disablePortal
      className={classes.popper}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
          }}
        >
          <Paper className={classes.menu} ref={menuRef}>
            <MenuList variant="selectedMenu">
              {Object.keys(groupedSearchedTokens)
                .sort()
                .map((group) => {
                  return (
                    <div key={group}>
                      <ListSubheader
                        component="div"
                        className={classes.listSubHeader}
                        disableSticky
                      >
                        {group}
                      </ListSubheader>
                      {groupedSearchedTokens[group].map((token) => {
                        const isSelected =
                          indexedTokens[selectedIndex].value === token.value
                        return (
                          <MenuItem
                            key={token.value}
                            selected={isSelected}
                            onClick={() => handleInsertToken(token.value)}
                          >
                            {token.description}
                          </MenuItem>
                        )
                      })}
                    </div>
                  )
                })}
            </MenuList>
          </Paper>
        </Grow>
      )}
    </Popper>
  )
}

export default InlineTokenDropdown
