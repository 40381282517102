import { useState, memo } from 'react'
import { Button } from '@material-ui/core'
import SaveTemplateDialog from '../SaveTemplateDialog'

interface Props {
  currentText: string
  organisationBrandId?: string
}

const SaveTemplateButton = ({ organisationBrandId, currentText }: Props) => {
  const [templateDialogOpen, setTemplateDialogOpen] = useState(false)

  const handleOpenSaveTemplateDialog = () => setTemplateDialogOpen(true)

  const handleCloseSaveTemplateDialog = () => {
    setTemplateDialogOpen(false)
  }

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleOpenSaveTemplateDialog}
        disabled={currentText.length === 0}
      >
        Save as template
      </Button>
      {templateDialogOpen && organisationBrandId && (
        <SaveTemplateDialog
          open={templateDialogOpen}
          handleClose={handleCloseSaveTemplateDialog}
          organisationBrandId={organisationBrandId}
          body={currentText}
        />
      )}
    </>
  )
}

export default memo(SaveTemplateButton)
