import React, { useState } from 'react'
import classNames from 'classnames'
import {
  Card,
  CardHeader,
  CardContent,
  CardActionArea,
  makeStyles,
} from '@material-ui/core'
import { ChannelIcon } from 'components'
import { ConditionalSplitProps, SharedStepProps } from '../../../helpers'
import useSharedStyles from '../sharedStyles'
import CardHeaderIconButton from '../components/CardHeaderIconButton'
import Menu from '../components/Menu'
import InputActionBox from '../components/InputActionBox'

const useStyles = makeStyles(() => ({
  step: {
    minWidth: 280,
    width: 'fit-content',
  },
}))

const ConditionalSplit = ({
  label,
  category,
  isEditing,
  editStep,
  removeStep,
  type,
  id,
  sectionKey,
}: ConditionalSplitProps & SharedStepProps) => {
  const classes = useStyles()
  const sharedClasses = useSharedStyles()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const cardClassName = classNames([classes.step, sharedClasses.sharedStepStyles], {
    [sharedClasses.menuOpen]: Boolean(anchorEl),
    [sharedClasses.isEditing]: isEditing,
  })

  const handleRemoveStep = () => removeStep(id, sectionKey)

  const handleEditStep = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    // click on canvas closes editing drawer. Stop that when wanting to edit
    event.stopPropagation()
    editStep(id, sectionKey)
  }

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => setAnchorEl(null)

  return (
    <>
      <Card raised={false} className={cardClassName}>
        <CardActionArea onClick={handleEditStep} className={sharedClasses.cardActionArea}>
          <CardHeader
            avatar={<ChannelIcon size={38} channel={type} />}
            action={<CardHeaderIconButton handleClick={handleOpenMenu} />}
            title={category}
            subheader={label}
            classes={{
              root: sharedClasses.cardHeader,
              title: sharedClasses.cardTitle,
              subheader: sharedClasses.cardSubHeader,
            }}
          />
          <CardContent>
            <InputActionBox text="Add condition" />
          </CardContent>
        </CardActionArea>
      </Card>
      {anchorEl && (
        <Menu
          anchorEl={anchorEl}
          handleClose={handleMenuClose}
          removeStep={handleRemoveStep}
        />
      )}
    </>
  )
}
export default ConditionalSplit
