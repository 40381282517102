import { AppBar } from 'components';
import { useFormik } from 'formik';
import {
    OrganisationAndUserRegistrationRequest, PersonIdentityValidationContextBase
} from 'generated/api/account-service-proxies';
import { useMe } from 'hooks';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { api } from 'services';
import loginManager from 'services/loginManager';
import { setToken, useDocumentTitle } from 'utils';
import { v4 as uuidv4 } from 'uuid';

import {
    Button, CircularProgress, IconButton, makeStyles, Theme, Typography
} from '@material-ui/core';
import { CloseRounded as CloseIcon } from '@material-ui/icons';
import { useSeekaConverge } from '@seeka-labs/converge-react';

import { Container, Title } from '../../components';
import { VerificationFormProps } from '../index';
import VerificationCodeInput from '../VerificationCodeInput';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    height: '100%',
    maxWidth: 900,
    margin: 'auto',
    width: '100%',
    flexDirection: 'column',
    backgroundColor: theme.palette.common.white,
    alignItems: 'center',
  },
  verificationCodeContainer: {
    maxWidth: 500,
    paddingTop: theme.spacing(12),
  },
  orgFormContainer: {
    paddingTop: theme.spacing(7.5),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    width: '100%',
    maxWidth: 500,
  },
  submitButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
  },
}))

interface Props extends NonNullable<VerificationFormProps> {
  history: RouteComponentProps['history']
  getRecaptchaToken: () => Promise<string | undefined>
}

const CreateOrgFlow = ({
  history,
  firstName,
  lastName,
  email,
  password,
  emailVerificationProcessId,
  getRecaptchaToken,
}: Props) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  useDocumentTitle('Verify email')
  const [resolved, setResolved] = useState(false)

  const { data: meData } = useMe({
    enabled: resolved,
  })

  useEffect(() => {
    if (meData && resolved) {
      enqueueSnackbar('Welcome to Seeka!', {
        variant: 'success',
      })
      history.push('/dashboard/signals')
    }
  }, [meData])

  const converge= useSeekaConverge();

  const { setFieldValue, handleSubmit, values, setSubmitting, isSubmitting } = useFormik({
    initialValues: {
      emailVerificationCode: '',
      emailVerificationProcessId,
    },
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (payload) => {
      setSubmitting(true)
      try {
        let recaptchaToken = await getRecaptchaToken()
        if (!recaptchaToken) return
        const body = {
          userFirstName: firstName,
          userLastName: lastName,
          userEmailAddress: email,
          userPassword: password,
          organisationDescription: uuidv4(),
          emailVerificationCode: payload.emailVerificationCode,
          emailVerificationProcessId: payload.emailVerificationProcessId,
        } as OrganisationAndUserRegistrationRequest

        const response = await api.account.registerNewTenantAndUser(body, recaptchaToken)

        // Refresh recaptcha token
        recaptchaToken = await getRecaptchaToken()
        // log in after creating org
        const getTokenCall = await loginManager.loginOrThrow(
          email,
          password,
          response.result.id,
          recaptchaToken as string
        )
        setToken(getTokenCall.accessToken)
        setResolved(true)

        await converge.identity.mergeProfile({
          firstName: [firstName],
          lastName: [lastName],
          email: [email]
        })
        await converge.track.userLoginSignup({
          emailAddress: email
        })
        // At this stage Router.ts will detect user logged in and will auto redirect to create Org/Brand flow
      } catch (err) {
        const { message } = err.error || err || {}
        enqueueSnackbar(message || 'An error occurred', {
          variant: 'error',
        })
        setSubmitting(false)
      }
    },
  })

  const handleVerifyCode = async () => {
    setSubmitting(true)
    try {
      const body = {
        verificationCode: values.emailVerificationCode,
        validationId: values.emailVerificationProcessId,
      } as PersonIdentityValidationContextBase
      await api.account.validateEmailVerification(body, email)
      handleSubmit()
    } catch (err) {
      const { message } = err.error || err || {}
      enqueueSnackbar(message || 'The code could not be validated', {
        variant: 'error',
      })
      setFieldValue('emailVerificationCode', '')
      setSubmitting(false)
    }
  }

  const handleResend = async () => {
    setSubmitting(true)
    try {
      const recaptchaToken = await getRecaptchaToken()
      if (!recaptchaToken) return
      const response = await api.account.triggerEmailVerification(email, recaptchaToken)
      setFieldValue('emailVerificationProcessId', response.result)
      enqueueSnackbar('Verification code resent to your email!', {
        variant: 'success',
      })
      setSubmitting(false)
    } catch (err) {
      const { message } = err.error || err || {}
      enqueueSnackbar(message || 'An error occurred', {
        variant: 'error',
      })
      setSubmitting(false)
    }
  }

  const handleClose = () => {
    history.push('/sign-up')
  }

  useEffect(() => {
    if (values.emailVerificationCode.length === 4) handleVerifyCode()
  }, [values])

  return (
    <Container>
      <AppBar
        showLogo={false}
        prependToLogo={
          <IconButton color="primary" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        }
      >
        <Typography variant="h5">Sign up</Typography>
        <div style={{ width: 42 }} />
      </AppBar>
      <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
        <div className={classes.container}>
          <div className={classes.verificationCodeContainer}>
            <Title title="Enter verification code" center />
            <Typography color="textSecondary" align="center" style={{ marginTop: -16 }}>
              We&apos;ve sent you a 4-digit verification code to your email.
            </Typography>
            <Typography
              color="textSecondary"
              align="center"
              style={{ marginTop: 16, marginBottom: 24 }}
            >
              Check your inbox and return to this page with the code to confirm yourself.
            </Typography>
            <div style={{ position: 'relative' }}>
              <VerificationCodeInput
                emailVerificationCode={values.emailVerificationCode}
                setValue={(value) => setFieldValue('emailVerificationCode', value)}
                disabled={isSubmitting}
              />
              {isSubmitting && (
                <CircularProgress size={28} style={{ position: 'absolute', top: 30 }} />
              )}
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                variant="text"
                color="primary"
                onClick={handleResend}
                disabled={isSubmitting}
              >
                Resend code
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default CreateOrgFlow
