import palette from '../palette'

export default {
  root: {
    fontWeight: 700,
    fontSize: '14px',
  },
  textColorPrimary: {
    color: palette.text.secondary,
  },
}
