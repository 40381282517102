import React from 'react'
import { InputAdornment } from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import { EventOutlined as CalendarIcon } from '@material-ui/icons'
import useInputStyles from 'theme/hooks/useInputStyles'

interface Props {
  withMargin?: boolean
  name?: string
  label: string
  helperText?: any
  required?: boolean
  error?: boolean
  disabled?: boolean
  minDate?: any
  onChange: any
  value?: any
  autoFocus?: boolean
  placeholder?: string
  invisibleInput?: boolean
  small?: boolean
  disablePast?: boolean
}

const TicketRevDatePicker = ({
  withMargin = true,
  onChange,
  value,
  label,
  helperText,
  disabled,
  minDate,
  error,
  name,
  required,
  autoFocus,
  placeholder,
  invisibleInput,
  small,
  disablePast = true,
}: Props) => {
  const inputStyles = useInputStyles()

  return (
    <div className={withMargin ? inputStyles.withMargin : ''}>
      <DatePicker
        value={value === undefined ? null : value}
        onChange={(date: any) => onChange(name, date)}
        fullWidth
        label={label}
        disabled={disabled}
        required={required}
        placeholder={placeholder}
        minDate={minDate}
        helperText={helperText}
        disablePast={disablePast}
        autoFocus={autoFocus}
        name={name}
        error={error}
        format="MMM D YYYY"
        InputProps={{
          classes: {
            root: invisibleInput
              ? inputStyles.invisibleRoot
              : (small && inputStyles.smallRoot) || inputStyles.root,
            focused: inputStyles.focused,
            error: inputStyles.error,
            disabled: inputStyles.disabled,
            input: invisibleInput
              ? inputStyles.invisibleInputElement
              : (small && inputStyles.smallInputTextFieldElement) || '',
          },
          disableUnderline: true,
          readOnly: true,
          endAdornment: (
            <InputAdornment
              position="end"
              className={small ? inputStyles.datePickerIconSmall : ''}
            >
              <CalendarIcon />
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          shrink: invisibleInput || small ? true : undefined,
          classes: {
            root: invisibleInput || small ? inputStyles.invisibleLabel : '',
          },
        }}
        inputVariant="filled"
      />
    </div>
  )
}

export default TicketRevDatePicker
