import {
    ApiResponseDtoOfConvergeInstance, ApiResponseError, ConvergeInstanceMode,
    ConvergeInstanceSettingsUpdateRequest, ConvergeVendorDestinationPublishStrategy
} from 'generated/api/app-service-proxies';
import { useSnackbar } from 'notistack';
import { useMutation, useQueryClient } from 'react-query';
import { api } from 'services';

import queryKeys from './queryKeys';

export const useConvergeMode = () => {
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  return useMutation<
    ApiResponseDtoOfConvergeInstance,
    ApiResponseError,
    { convergeInstanceId: string; request: ConvergeInstanceSettingsUpdateRequest }
  >(
    (payload) =>
      api.converge.manageInstanceSettings(payload.convergeInstanceId, payload.request),
    {
      onError: (err) => {
        enqueueSnackbar(err?.error?.message || 'An error occurred', { variant: 'error' })
      },
      onSuccess: (_data, variables) => {
        enqueueSnackbar(
          variables.request.mode === ConvergeInstanceMode.Full
            ? 'Delivery mode enabled'
            : 'Delivery mode disabled',
          { variant: 'success' }
        )
      },
      onSettled: () => {
        queryClient.invalidateQueries(queryKeys.converge)
      },
    }
  )
}

export const useSetConvergePlatformStrategy = () => {
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  return useMutation<
    void,
    ApiResponseError,
    { convergeInstanceId: string; request: ConvergeVendorDestinationPublishStrategy }
  >(
    async (payload) =>api.converge.setPlatformStrategy(payload.convergeInstanceId, payload.request),
    {
      onError: (err) => {
        enqueueSnackbar(err?.error?.message || 'An error occurred', { variant: 'error' })
      },
      onSettled: () => {
        queryClient.invalidateQueries(queryKeys.converge)
      },
    }
  )
}
