import React from 'react'
import classNames from 'classnames'
import {
  makeStyles,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Theme,
  Tooltip,
  CircularProgress,
} from '@material-ui/core'
import {
  KeyboardArrowDown as ChevronDownIcon,
  KeyboardArrowUp as ChevronUpIcon,
} from '@material-ui/icons'

const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    cursor: 'pointer',
    paddingLeft: 10,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(2),
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      '& $primaryText': {
        color: theme.palette.primary.main,
      },
      '& $secondaryText': {
        color: theme.palette.primary.main,
      },
    },
  },
  inMenuListItem: {
    paddingLeft: theme.spacing(),
    paddingBottom: theme.spacing(0.5),
    paddingTop: theme.spacing(0.5),
    minWidth: 218,
  },
  primaryText: {
    lineHeight: '22px',
    fontSize: 16,
    fontWeight: 700,
    color: theme.palette.text.primary,
    transition: theme.transitions.create(['color'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
  },
  secondaryText: {
    transition: theme.transitions.create(['color'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
    lineHeight: '16px',
    textTransform: 'capitalize',
  },
  squareAvatar: {
    width: 20,
    height: 20,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.text.primary,
    textTransform: 'uppercase',
  },
  roundAvatar: {
    width: 25,
    height: 25,
    backgroundColor: theme.palette.text.primary,
    textTransform: 'uppercase',
  },
  listItemAvatar: {
    minWidth: 37,
  },
  chevronDown: {
    fontSize: 26,
    maxWidth: 26,
    maxHeight: 26,
  },
  slim: {
    padding: `${theme.spacing()}px !important`,
  },
  isOpen: {
    backgroundColor: theme.palette.primary.light,
  },
}))

interface Props {
  primaryText: string
  subText?: string
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  inMenu?: boolean
  blurText?: boolean
  avatarOnly?: boolean
  disabled?: boolean
  loading?: boolean
  variant?: 'square' | 'round'
  isOpen?: boolean
}

const MenuAvatarListItem = ({
  primaryText,
  subText,
  onClick,
  inMenu = false,
  blurText = false,
  avatarOnly = false,
  disabled,
  loading,
  variant = 'square',
  isOpen,
}: Props) => {
  const classes = useStyles()

  const listItemClassName = classNames({
    [classes.listItem]: !inMenu,
    [classes.inMenuListItem]: inMenu,
    [classes.slim]: avatarOnly,
    [classes.isOpen]: isOpen,
  })

  const orgNameClassName = classNames({
    [classes.primaryText]: true,
  })

  const avatarChar = primaryText?.charAt(0)

  return (
    <Tooltip title={avatarOnly ? primaryText : ''} placement="right" arrow>
      <ListItem
        className={listItemClassName}
        button
        onClick={onClick}
        disabled={disabled}
      >
        <ListItemAvatar className={classes.listItemAvatar}>
          <Avatar
            className={variant === 'square' ? classes.squareAvatar : classes.roundAvatar}
          >
            {avatarChar}
          </Avatar>
        </ListItemAvatar>
        {loading && <CircularProgress size={26} />}
        {!avatarOnly && !loading && (
          <>
            <ListItemText
              primary={primaryText}
              secondary={subText}
              primaryTypographyProps={{
                noWrap: true,
              }}
              secondaryTypographyProps={{
                noWrap: true,
              }}
              classes={{
                primary: orgNameClassName,
                secondary: classes.secondaryText,
              }}
              style={{ filter: blurText ? 'blur(4px)' : undefined }}
            />
            {!inMenu && (
              <>
                {isOpen ? (
                  <ChevronUpIcon className={classes.chevronDown} />
                ) : (
                  <ChevronDownIcon className={classes.chevronDown} />
                )}
              </>
            )}
          </>
        )}
      </ListItem>
    </Tooltip>
  )
}

export default MenuAvatarListItem
