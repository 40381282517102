import ClientOAuth2, { Token } from 'client-oauth2'
import appConfig from 'config'
import { UserGrantedOrganisation } from 'generated/api/account-service-proxies'
import { Moment } from 'moment'
import api from 'services/serviceResolver'

const oAuthOptions = {
  clientId: appConfig.identityClientName,
  accessTokenUri: `${appConfig.identityBaseUrl}/connect/token`,
  redirectUri: `${appConfig.appSelfBaseUrl}/auth/login/callback`,
  scopes: appConfig.identityScopes.split(' '),
};

const loginClient = new ClientOAuth2(oAuthOptions);

const orgTokenClient = new ClientOAuth2(oAuthOptions, async (method: string, url: string, body: string, headers: { [key: string]: string | string[] }): Promise<{ status: number, body: string }> => {
  const newHeaders = new Headers();

  Object.keys(headers).forEach((key) => {
    const element = headers[key];
    if (Array.isArray(element)) {
      element.forEach((item) => {
        newHeaders.append(key, item);
      })
    } else {
      newHeaders.append(key, element as string);
    }
  })

  const result = await fetch(url, {
    method,
    body,
    headers: newHeaders,
  });

  return { status: result.status, body: await result.text() };
})

interface LoginState {
  token: Token | undefined
  expiresAt: Moment | undefined
}

export const state: LoginState = {
  token: undefined,
  expiresAt: undefined,
}

const loginManager = {
  async loginOrThrow(
    usernameOrEmail: string,
    password: string,
    organisationId: string,
    recaptchaToken: string
  ): Promise<Token> {
    return loginClient.owner.getToken(usernameOrEmail, password, {
      headers: {
        'X-OrgId': organisationId,
        'X-Recaptcha-Token': recaptchaToken,
      },
    })
  },

  async getTokenForOrgOrThrow(
    currentToken: string,
    organisationId: string,
    recaptchaToken: string
  ): Promise<Token> {
    return orgTokenClient.jwt.getToken(currentToken, {
      body: {
        tenantId: organisationId,
        grant_type: 'org_token'
      },
      headers: {
        'X-Recaptcha-Token': recaptchaToken
      }
    })
  },

  async getOrgForLogin(emailAddress: string): Promise<string | undefined> {
    return (await api.account.getPrincipalTenant(emailAddress)).result
  },
}

export default loginManager
