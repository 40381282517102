import React from 'react'
import { makeStyles, Theme, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  value: {
    padding: theme.spacing(),
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    whiteSpace: 'pre-line',
    marginTop: theme.spacing(),
  },
}))

interface Props {
  value: string
}

const ValueBox = ({ value }: Props) => {
  const classes = useStyles()

  return (
    <Typography variant="body2" className={classes.value} component="p">
      {value}
    </Typography>
  )
}
export default ValueBox
