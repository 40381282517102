import React from 'react'
import {
  makeStyles,
  Theme,
  ListItem,
  ListItemIcon,
  ListItemText,
  List,
  Fade,
} from '@material-ui/core'
import classNames from 'classnames'
import { Skeleton } from '@material-ui/lab'

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    padding: '0px',
  },
  listMargin: {
    marginLeft: theme.spacing(3),
  },
  primary: {
    fontSize: 24,
    lineHeight: '42px',
  },
  secondary: {
    fontSize: 16,
    lineHeight: '20px',
  },
  listItemText: {
    marginTop: 0,
    marginLeft: theme.spacing(2),
  },
  icon: {
    maxWidth: 60,
    maxHeight: 60,
    width: 60,
    height: 60,
  },
  largeIcon: {
    maxWidth: 90,
    maxHeight: 90,
    width: 90,
    height: 90,
  },
}))

interface Props {
  primary?: string
  secondary?: string | JSX.Element
  icon: JSX.Element
  loading?: boolean
  size?: 'medium' | 'large'
  withMargin?: boolean
}

const DrawerHeader = ({
  primary,
  secondary,
  icon,
  loading,
  size = 'medium',
  withMargin = true,
}: Props) => {
  const classes = useStyles()

  const iconWithProps = React.cloneElement(icon, {
    className: `${size === 'medium' ? classes.icon : classes.largeIcon} ${
      icon.props.className
    }`,
  })

  const listClassName = classNames([classes.list], {
    [classes.listMargin]: withMargin,
  })

  return (
    <List className={listClassName}>
      <ListItem disableGutters>
        <ListItemIcon>
          {loading ? (
            <Skeleton
              variant="circle"
              className={size === 'medium' ? classes.icon : classes.largeIcon}
            />
          ) : (
            <Fade in={!loading}>{iconWithProps}</Fade>
          )}
        </ListItemIcon>
        <ListItemText
          className={classes.listItemText}
          primary={
            loading ? <Skeleton variant="text" width="60%" height={56} /> : primary
          }
          secondary={loading ? <Skeleton variant="text" width={112} /> : secondary}
          classes={{ primary: classes.primary, secondary: classes.secondary }}
        />
      </ListItem>
    </List>
  )
}

export default DrawerHeader
