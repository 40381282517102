import { createContext } from 'react'
import { UserRole } from 'types'

const locationHostname = window.location.hostname

export const LAST_VISITED_BRAND = `__!<lvb>${locationHostname}||seeka`

export const setLastVisitedBrand = (brandId: string) =>
  window.localStorage.setItem(LAST_VISITED_BRAND, brandId)

export const getLastVisitedBrand = (): string | null => {
  const brandId = window.localStorage.getItem(LAST_VISITED_BRAND)
  return brandId || null
}

export const removeLastVisitedBrand = () =>
  window.localStorage.removeItem(LAST_VISITED_BRAND)

export default createContext({
  selectedBrand: {
    id: '',
    name: '',
  },
  changeBrand: null as null | ((brandId: string, brandName: string) => void),
  userGrants: [] as string[],
  userRole: '' as UserRole,
})
