import { useEffect, useRef } from 'react'

const useDocumentTitle = (title: string, restoreOnUnmount?: boolean) => {
  const prevTitleRef = useRef(document.title)
  document.title = `${title} - Seeka`

  // eslint-disable-next-line
  useEffect(() => {
    if (restoreOnUnmount) {
      return () => {
        document.title = prevTitleRef.current
      }
    }
  }, [])
}

export default useDocumentTitle
