import MuiAvatar from './MuiAvatar';
import MuiButton from './MuiButton';
import MuiButtonGroup from './MuiButtonGroup';
import MuiCardHeader from './MuiCardHeader';
import MuiChip from './MuiChip';
import MuiFilledInput from './MuiFilledInput';
import MuiFormControlLabel from './MuiFormControlLabel';
import MuiFormHelperText from './MuiFormHelperText';
import MuiIconButton from './MuiIconButton';
import MuiInputBase from './MuiInputBase';
import MuiInputLabel from './MuiInputLabel';
import MuiLinearProgress from './MuiLinearProgress';
import MuiListItem from './MuiListItem';
import MuiListItemIcon from './MuiListItemIcon';
import MuiListItemText from './MuiListItemText';
import MuiMenuItem from './MuiMenuItem';
import MuiOutlinedInput from './MuiOutlinedInput';
import MuiPaper from './MuiPaper';
import MuiRadio from './MuiRadio';
import MUIRichTextEditor from './MUIRichTextEditor';
import MuiSkeleton from './MuiSkeleton';
import MuiSvgIcon from './MuiSvgIcon';
import MuiTab from './MuiTab';
import MuiTableCell from './MuiTableCell';
import MuiTablePagination from './MuiTablePagination';
import MuiTableRow from './MuiTableRow';
import MuiTooltip from './MuiTooltip';

const overrides = {
  MuiButton,
  MuiButtonGroup,
  MuiAvatar,
  MuiChip,
  MuiListItemText,
  MuiFilledInput,
  MuiTablePagination,
  MuiIconButton,
  MuiLinearProgress,
  MuiListItemIcon,
  MuiCardHeader,
  MuiOutlinedInput,
  MuiSvgIcon,
  MuiTab,
  MuiTableCell,
  MuiTableRow,
  MuiMenuItem,
  MuiInputLabel,
  MuiTooltip,
  MuiListItem,
  MuiRadio,
  MuiFormControlLabel,
  MuiFormHelperText,
  MuiSkeleton,
  MuiInputBase,
  MUIRichTextEditor,
  MuiPaper,
}

export default overrides
