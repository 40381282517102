import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from '@material-ui/lab'
import { v4 as uuidv4 } from 'uuid'
import classNames from 'classnames'
import { UserAlertItem, UserAlertItemSeverity } from 'generated/api/app-service-proxies'
import { Alert } from 'components'
import { makeStyles, Theme, Avatar, Typography, Button } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import {
  WarningRounded as WarningIcon,
  FlagRounded as FlagIcon,
  CheckRounded as CheckIcon,
  SyncProblemRounded as SyncProblemIcon,
} from '@material-ui/icons'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 0,
  },
  missingOppositeContent: {
    '&::before': {
      flex: 0,
      padding: 0,
    },
  },
  timestamp: {
    color: grey[600],
    paddingBottom: theme.spacing(1.5),
  },
  timelineDot: {
    padding: 0,
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  connectionError: {
    backgroundColor: theme.palette.error.main,
  },
  generalError: {
    backgroundColor: theme.palette.error.main,
  },
  generalSuccess: {
    backgroundColor: theme.palette.success.main,
  },
  generalWarning: {
    backgroundColor: theme.palette.warning.main,
  },
  milestone: {
    backgroundColor: theme.palette.info.main,
  },
  timelineConnector: {
    backgroundColor: grey[300],
  },
  message: {
    marginBottom: theme.spacing(1.5),
  },
  icon: {
    width: 20,
    height: 20,
  },
  recommendationsButton: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(),
  },
}))

interface Props {
  activity?: UserAlertItem[]
  loading?: boolean
}

const ActivityTimeline = ({ activity, loading }: Props) => {
  const classes = useStyles()

  if (!loading && activity && activity.length === 0) {
    return <Alert message="You don't have any activity for this period" />
  }

  return (
    <Timeline className={classes.root}>
      {activity?.map((x) => {
        const avatarClassNames = classNames([classes.avatar], {
          [classes[UserAlertItemSeverity.ConnectionError]]:
            x.severity === UserAlertItemSeverity.ConnectionError,
          [classes[UserAlertItemSeverity.GeneralError]]:
            x.severity === UserAlertItemSeverity.GeneralError,
          [classes[UserAlertItemSeverity.GeneralSuccess]]:
            x.severity === UserAlertItemSeverity.GeneralSuccess,
          [classes[UserAlertItemSeverity.GeneralWarning]]:
            x.severity === UserAlertItemSeverity.GeneralWarning,
          [classes[UserAlertItemSeverity.Milestone]]:
            x.severity === UserAlertItemSeverity.Milestone,
        })

        return (
          <TimelineItem
            key={uuidv4()}
            classes={{ missingOppositeContent: classes.missingOppositeContent }}
          >
            <TimelineSeparator>
              <TimelineDot className={classes.timelineDot}>
                <Avatar variant="rounded" className={avatarClassNames}>
                  {x.severity === UserAlertItemSeverity.ConnectionError && (
                    <SyncProblemIcon className={classes.icon} />
                  )}
                  {x.severity === UserAlertItemSeverity.GeneralError && (
                    <WarningIcon className={classes.icon} />
                  )}
                  {x.severity === UserAlertItemSeverity.GeneralSuccess && (
                    <CheckIcon className={classes.icon} />
                  )}
                  {x.severity === UserAlertItemSeverity.GeneralWarning && (
                    <WarningIcon className={classes.icon} />
                  )}
                  {x.severity === UserAlertItemSeverity.Milestone && (
                    <FlagIcon className={classes.icon} />
                  )}
                </Avatar>
              </TimelineDot>
              <TimelineConnector className={classes.timelineConnector} />
            </TimelineSeparator>
            <TimelineContent>
              <Typography variant="body2">
                <strong>{x.description}</strong>
              </Typography>
              <Typography variant="body2" className={classes.timestamp}>
                {x.creationTime.format('MMM D HH:mm A')}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                className={classes.message}
              >
                {x.message}
              </Typography>
              {x.recommendations.length > 0 &&
                x.recommendations
                  .filter((y) => !y.ignoredAt)
                  .map((y) => {
                    return (
                      <Button
                        key={uuidv4()}
                        size="small"
                        className={classes.recommendationsButton}
                        variant="contained"
                        disableElevation
                        color="secondary"
                      >
                        {y.description}
                      </Button>
                    )
                  })}
            </TimelineContent>
          </TimelineItem>
        )
      })}
    </Timeline>
  )
}

export default ActivityTimeline
