import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import { makeStyles, Box, BoxProps, Typography, Theme } from '@material-ui/core'
import { IColorInfo } from 'generated/api/app-service-proxies'
import { ChromePicker, ColorResult, RGBColor } from 'react-color'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 'auto',
    borderRadius: '50%',
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.1)',
    },
  },
  label: {
    paddingTop: theme.spacing(0.4),
    fontSize: 11,
  },
  colorPickerOverlay: {
    position: 'absolute',
    zIndex: 2,
  },
  innerOverlay: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
}))

interface Props extends BoxProps {
  className?: string
  currentColor: IColorInfo
  size: number
  label?: string
  onChangeComplete: (color: IColorInfo, name: string) => void
  name: string
}

const ColorPickerDot = ({
  className,
  currentColor,
  size,
  label,
  onChangeComplete,
  name,
  ...rest
}: Props) => {
  const classes = useStyles()
  const [show, setShow] = useState(false)
  const [innerColor, setInnerColor] = useState<RGBColor | undefined>(undefined)

  useEffect(() => {
    setInnerColor({
      r: currentColor.red,
      g: currentColor.green,
      b: currentColor.blue,
      a: currentColor.alpha,
    })
  }, [currentColor])

  const rootClassName = classNames(
    {
      [classes.root]: true,
    },
    className
  )

  const toggleColorPicker = () => {
    setShow(!show)
  }

  const handleOnChange = (color: ColorResult) => {
    setInnerColor(color.rgb)
  }

  const handleChangeComplete = (color: ColorResult) => {
    const { r: red, g: green, b: blue, a } = color.rgb
    onChangeComplete({ red, green, blue, alpha: a || 1 }, name)
  }

  return (
    <>
      <Box {...rest} display="inline-block" justifyContent="center" alignItems="center">
        <Box
          onClick={toggleColorPicker}
          className={rootClassName}
          style={{
            backgroundColor: `rgba(${currentColor.red}, ${currentColor.green}, ${currentColor.blue}, ${currentColor.alpha})`,
            height: size,
            width: size,
          }}
        />
        {label && (
          <Typography
            variant="body2"
            align="center"
            className={classes.label}
            color="secondary"
          >
            {label}
          </Typography>
        )}
      </Box>
      {show ? (
        <div>
          <div className={classes.colorPickerOverlay}>
            <Box className={classes.innerOverlay} onClick={toggleColorPicker} />
            <ChromePicker
              color={innerColor}
              onChange={handleOnChange}
              onChangeComplete={handleChangeComplete}
            />
          </div>
        </div>
      ) : null}
    </>
  )
}

export default ColorPickerDot
