import { RouteComponentProps } from 'react-router-dom'
import { Alert, Paper, PaperContent } from 'components'
import AcceptingInvitation from './AcceptingInvitation'
import CreateOrgFlow from './CreateOrgFlow'

export interface VerificationFormProps {
  firstName: string
  lastName: string
  password: string
  email: string
  /** if inviteToken, only verification code form is shown - no create org wizard */
  inviteToken?: string
  emailVerificationProcessId: string
}

const VerifyFlow = ({
  history,
  location,
  getRecaptchaToken,
}: RouteComponentProps<
  /** To access this route, do a redirect with the following state params */
  /** If user accidentally refreshes etc, props are saved in history */
  any,
  any,
  VerificationFormProps
> & { getRecaptchaToken: () => Promise<string | undefined> }) => {
  const {
    firstName,
    lastName,
    password,
    email,
    inviteToken,
    emailVerificationProcessId,
  } = (location && 'state' in location && location.state) || {}

  // if invite token, show
  if (
    firstName &&
    lastName &&
    password &&
    email &&
    emailVerificationProcessId &&
    inviteToken
  ) {
    return (
      <AcceptingInvitation
        firstName={firstName}
        lastName={lastName}
        email={email}
        password={password}
        emailVerificationProcessId={emailVerificationProcessId}
        history={history}
        inviteToken={inviteToken}
        getRecaptchaToken={getRecaptchaToken}
      />
    )
  }

  if (
    firstName &&
    lastName &&
    password &&
    email &&
    emailVerificationProcessId &&
    !inviteToken
  ) {
    return (
      <CreateOrgFlow
        firstName={firstName}
        lastName={lastName}
        email={email}
        password={password}
        emailVerificationProcessId={emailVerificationProcessId}
        history={history}
        getRecaptchaToken={getRecaptchaToken}
      />
    )
  }

  return (
    <div style={{ maxWidth: 600, margin: 'auto', marginTop: 60 }}>
      <Paper>
        <PaperContent style={{ padding: 8 }}>
          <Alert message="There's something wrong with the verification link. Click the link in your email inbox and try again. If the problem persists, try to sign up again." />
        </PaperContent>
      </Paper>
    </div>
  )
}

export default VerifyFlow
