import { TicketRevTextField, Alert, AppBar } from 'components'
import { useState } from 'react'
import { useMutation } from 'react-query'
import { api } from 'services'
import { RouteComponentProps } from 'react-router-dom'
import { Typography, Theme, makeStyles } from '@material-ui/core'
import { useFormik } from 'formik'
import { useDocumentTitle } from 'utils'
import { Title, SubmitButton, Container, ContentCard } from '../components'

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    cursor: 'pointer',
    marginTop: 6,
    marginLeft: 2,
    marginBottom: theme.spacing(1.5),
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))

const initialValues = {
  email: '',
}

const RequestResetPassword = ({
  history,
  getRecaptchaToken,
}: RouteComponentProps & {
  getRecaptchaToken: () => Promise<string | undefined>
}) => {
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  useDocumentTitle('Account recovery')
  const classes = useStyles()

  const {
    mutateAsync: sendPasswordResetEmailMutation,
    error: sendPasswordResetEmailError,
    data: sendPasswordResetEmailResponse,
  } = useMutation(
    ({ email, recaptchaToken }: { email: string; recaptchaToken: string }) =>
      api.account.sendPasswordResetEmail(recaptchaToken, email)
  )

  const { setFieldValue, handleSubmit, values, isSubmitting } = useFormik({
    initialValues,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (payload) => {
      // returns 204 empty response, so using state
      try {
        const recaptchaToken = await getRecaptchaToken()
        if (!recaptchaToken) return
        await sendPasswordResetEmailMutation({ email: payload.email, recaptchaToken })
        setSuccess(true)
        setError(false)
      } catch (err) {
        setSuccess(false)
        setError(true)
      }
    },
  })

  const handleGoToLogin = () => history.push('/sign-in')

  const showForm =
    (!sendPasswordResetEmailError && !sendPasswordResetEmailResponse) ||
    sendPasswordResetEmailError

  return (
    <Container>
      <AppBar>
        <span>
          <Typography
            display="inline"
            align="right"
            color="primary"
            onClick={handleGoToLogin}
            className={classes.link}
          >
            Sign in
          </Typography>
        </span>
      </AppBar>
      <ContentCard containerHeight={595}>
        <Title title="Account recovery" />

        <Typography variant="body1" style={{ marginTop: -12, marginBottom: 36 }}>
          Enter your account email below and we&apos;ll send you a recovery link with
          further instructions.
        </Typography>

        {success && (
          <Alert
            severity="success"
            message="E-mail sent! Check your inbox and follow the instructions to recover your account."
            withMargin
          />
        )}
        {(sendPasswordResetEmailError || error) && (
          <Alert
            severity="error"
            message="We couldn't send a recovery email to the email you typed in. Please try again."
            withMargin
          />
        )}

        {showForm && (
          <form onSubmit={handleSubmit}>
            <TicketRevTextField
              placeholder="E-mail"
              value={values.email}
              name="email"
              required
              hideAsterix
              type="email"
              label="Account email"
              autoFocus
              disabled={isSubmitting}
              onChange={setFieldValue}
            />
            <div style={{ marginTop: 40 }} />
            <SubmitButton anchor="Send recovery link" loading={isSubmitting} />
          </form>
        )}
      </ContentCard>
    </Container>
  )
}

export default RequestResetPassword
