import React from 'react'
import { makeStyles, Theme, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    color: theme.palette.common.white,
  },
  card: {
    backgroundColor: '#C6B7EB', // theme.palette.primary.light,
    borderRadius: theme.shape.borderRadius,
    width: 180,
    minWidth: 180,
    marginRight: theme.spacing(2),
    height: 230,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    boxShadow: '0 16px 64px -16px rgba(46,55,77,.1)',
  },
  innerContainer: {
    padding: theme.spacing(2),
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  elevatedBox: {
    backgroundColor: '#a68fdf',
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(),
    boxShadow: '0 16px 64px -16px rgba(46,55,77,.42)',
    marginTop: theme.spacing(2),
  },
}))

interface Props {
  title: string
  mainStat: number | string | JSX.Element
  subStatOne?: string | JSX.Element
  subStatTwo?: string | JSX.Element
  diffComponent?: JSX.Element
  children?: JSX.Element
  elevatedBoxOne?: string | JSX.Element
  elevatedBoxTwo?: string | JSX.Element
}

const StatCard = ({
  title,
  mainStat,
  diffComponent,
  subStatOne,
  subStatTwo,
  elevatedBoxOne,
  elevatedBoxTwo,
  children,
}: Props) => {
  const classes = useStyles()

  return (
    <div className={classes.card}>
      <div className={classes.innerContainer}>
        <Typography variant="body1" className={classes.title} gutterBottom noWrap>
          <strong>{title}</strong>
        </Typography>
        <Typography variant="h1" color="primary" className={classes.flex}>
          <strong>{mainStat}</strong>
          {diffComponent}
        </Typography>
        {subStatOne && (
          <Typography variant="body2" color="primary">
            {subStatOne}
          </Typography>
        )}
        {subStatTwo && (
          <Typography variant="body2" color="primary">
            {subStatTwo}
          </Typography>
        )}
        {elevatedBoxOne && <div className={classes.elevatedBox}>{elevatedBoxOne}</div>}
        {elevatedBoxTwo && <div className={classes.elevatedBox}>{elevatedBoxTwo}</div>}
      </div>
      <div>{children}</div>
    </div>
  )
}

export default StatCard
