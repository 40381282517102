import React from 'react'
import { makeStyles, Theme, useTheme } from '@material-ui/core'
import ConnectingDot from '../ConnectingDot'

const useStyles = makeStyles((theme: Theme) => ({
  connectingLine: {
    width: 2,
    backgroundColor: theme.palette.text.secondary,
    position: 'absolute',
    left: '50%',
    height: '100%',
    top: 0,
    marginLeft: -1,
    zIndex: -1,
    borderRadius: theme.shape.borderRadius,
  },
}))

interface Props {
  color?: string
  withTopDot?: boolean
  hasNextStep: boolean
}

const ConnectingLine = ({ color, hasNextStep, withTopDot = true }: Props) => {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <>
      {withTopDot && <ConnectingDot placement="top" color={color} />}
      <span
        className={classes.connectingLine}
        style={{
          height: hasNextStep ? '100%' : '50%',
          backgroundColor: color || theme.palette.text.disabled,
        }}
      />
      {hasNextStep && <ConnectingDot placement="bottom" color={color} />}
    </>
  )
}
export default ConnectingLine
