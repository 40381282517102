import { AppBar } from 'components';
import { useFormik } from 'formik';
import { AcceptUserInviteAndCreateNewUserRequest } from 'generated/api/account-service-proxies';
import { useMe } from 'hooks';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { api } from 'services';
import loginManager from 'services/loginManager';
import { setToken, useDocumentTitle } from 'utils';

import { Button, CircularProgress, Typography } from '@material-ui/core';
import { useSeekaConverge } from '@seeka-labs/converge-react';

import { Container, ContentCard, Title } from '../../components';
import { VerificationFormProps } from '../index';
import VerificationCodeInput from '../VerificationCodeInput';

interface Props extends NonNullable<VerificationFormProps> {
  history: RouteComponentProps['history']
  getRecaptchaToken: () => Promise<string | undefined>
}

const AcceptingInvitation = ({
  history,
  firstName,
  lastName,
  email,
  password,
  emailVerificationProcessId,
  inviteToken,
  getRecaptchaToken,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar()
  useDocumentTitle('Verify email')

  const [resolved, setResolved] = useState(false)

  const { data: meData } = useMe({
    enabled: resolved,
  })

  const converge = useSeekaConverge();

  useEffect(() => {
    if (meData && resolved) history.push('/dashboard/signals')
  }, [meData])

  const { setFieldValue, handleSubmit, values, setSubmitting, isSubmitting } = useFormik({
    initialValues: {
      emailVerificationCode: '',
      emailVerificationProcessId,
    },
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (payload) => {
      setSubmitting(true)
      try {
        const recaptchaToken = await getRecaptchaToken()
        if (!recaptchaToken) return
        const body = AcceptUserInviteAndCreateNewUserRequest.fromJS({
          name: firstName,
          surname: lastName,
          userName: email,
          email,
          phoneNumber: '',
          newPassword: password,
          emailVerificationCode: payload.emailVerificationCode,
          emailVerificationProcessId: payload.emailVerificationProcessId,
          inviteToken,
        })
        const response = await api.account.acceptInviteForNewUser(
          body,
          recaptchaToken,
          inviteToken
        )
        // log into org after creating account
        
        const getTokenCall = await loginManager.loginOrThrow(
          email,
          password,
          response.result.id,
          (await getRecaptchaToken()) as string
        )
        setToken(getTokenCall.accessToken)
        setResolved(true)

        await converge.identity.mergeProfile({
          firstName: [firstName],
          lastName: [lastName],
          email: [email]
        })
        await converge.track.custom('WebAppInviteAccepted');
      } catch (err) {
        const { /* validation, */ message } = err.error || err || {}
        if (message) {
          enqueueSnackbar(message, {
            variant: 'error',
          })
        }
        setSubmitting(false)
      }
    },
  })

  useEffect(() => {
    if (values.emailVerificationCode.length === 4) handleSubmit()
  }, [values])

  const handleResend = async () => {
    setSubmitting(true)
    try {
      const recaptchaToken = await getRecaptchaToken()
      if (!recaptchaToken) return
      const response = await api.account.triggerEmailVerification(email, recaptchaToken)
      setFieldValue('emailVerificationProcessId', response.result)
      enqueueSnackbar('Verification code resent to your email!', {
        variant: 'success',
      })
      setSubmitting(false)
    } catch (err) {
      const { message } = err.error || err || {}
      if (message) {
        enqueueSnackbar(message, {
          variant: 'error',
        })
      }
      setSubmitting(false)
    }
  }

  return (
    <Container>
      <AppBar />
      <ContentCard containerHeight={450}>
        <Title title="Enter verification code" center />
        <Typography color="textSecondary" align="center" style={{ marginTop: -16 }}>
          We&apos;ve sent you a 4-digit verification code to your email.
        </Typography>
        <Typography
          color="textSecondary"
          align="center"
          style={{ marginTop: 16, marginBottom: 24 }}
        >
          Check your inbox and return to this page with the code to confirm yourself.
        </Typography>
        <div style={{ position: 'relative' }}>
          <VerificationCodeInput
            emailVerificationCode={values.emailVerificationCode}
            setValue={(value) => setFieldValue('emailVerificationCode', value)}
            disabled={isSubmitting}
          />
          {isSubmitting && (
            <CircularProgress size={28} style={{ position: 'absolute', top: 30 }} />
          )}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="text"
            color="primary"
            onClick={handleResend}
            disabled={isSubmitting}
          >
            Resend code
          </Button>
        </div>
      </ContentCard>
    </Container>
  )
}

export default AcceptingInvitation
