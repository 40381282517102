import { useEffect } from 'react'
import { useSnackbar } from 'notistack'

const OfflineSnackbar = ({ message = '' }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const offlineSnackKey = 'OFFLINE_NOTIFIER'
  const openOfflineSnack = () => {
    enqueueSnackbar(message, {
      key: offlineSnackKey,
      variant: 'warning',
      persist: true,
    })
  }
  const closeOfflineSnack = () => closeSnackbar()
  useEffect(() => {
    window.addEventListener('offline', openOfflineSnack)
    window.addEventListener('online', closeOfflineSnack)
    return () => {
      window.removeEventListener('offline', openOfflineSnack)
      window.removeEventListener('online', closeOfflineSnack)
    }
  }, [])

  return null
}

export default OfflineSnackbar
