import { makeStyles, Theme } from '@material-ui/core'

export default makeStyles((theme: Theme) => ({
  // editor
  container: {
    padding: '27px 12px 10px',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    overflow: 'visible',
    fontSize: 14,
    maxHeight: '340px',
    overflowY: 'auto',
  },
  shrinkLabel: {
    position: 'absolute',
    left: 0,
    transform: 'translate(12px, 10px) scale(0.75)',
    cursor: 'text',
  },
  unshrinkLabel: {
    position: 'absolute',
    transform: 'translate(12px, 20px) scale(1)',
    left: 0,
    cursor: 'text',
  },
  disabled: {
    cursor: 'default',
  },
  textCursor: {
    cursor: 'text',
  },
  // toolbar
  toolbarContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(),
  },
  leftToolbar: {
    display: 'flex',
  },
  rightToolbar: {
    display: 'flex',
  },
  chip: {
    minWidth: 44,
    marginLeft: theme.spacing(0.5),
  },
  smsCountChip: {
    float: 'right',
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: theme.spacing(0.5),
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.7,
    },
  },
  // mention plugin
  mention: {
    cursor: 'pointer',
    display: 'inline-block',
    paddingLeft: 3,
    paddingRight: 3,
    borderRadius: 3,
    textDecoration: 'none',
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    fontFamily:
      'SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
  },
  mentionSuggestions: {
    marginTop: '0.4em',
    position: 'absolute',
    minWidth: 220,
    maxWidth: 440,
    maxHeight: 440,
    overflow: 'auto',
    background: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    boxShadow:
      '0px 5px 4px -3px rgb(0 0 0 / 8%), 0px 8px 10px 1px rgb(0 0 0 / 0%), 0px 3px 14px 2px rgb(0 0 0 / 9%)',
    cursor: 'pointer',
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    transform: 'scale(0)',
  },
  // emoji plugin
  emoji: {
    backgroundPosition: 'center',
    /* make sure the background the image is only shown once */
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    /* move it a bit further down to align it nicer with the text */
    verticalAlign: 'middle',
    /*
   We need to limit the emoji width because it can be multiple characters
   long if it is a 32bit unicode. Since the proper width depends on the font and
   it's relationship between 0 and other characters it's not ideal. 1.95ch is not
   the best value, but hopefully a good enough approximation for most fonts.
   */
    display: 'inline-block',
    overflow: 'hidden',
    maxWidth: '1.95ch',
    /*
    Needed for iOS rendering to avoid some icons using a lot of height without
    actually needing it.
    */
    maxHeight: '1em',
    lineHeight: 'inherit',
    margin: '-.2ex 0em .2ex',
    /*
    In the past we used opacity: 0 to hide the original Emoji icon no matter what
    system it is. Recently we switched to color: transparent since it started to
    work in recent iOS version.
    */
    color: 'transparent',
    /*
    Some SVG files (say 2764 for :heart:) don't have default width/height, thus
    may not be rendered properly on some platforms/browsers (e.g., Windows 10 +
    Chrome 61).
    */
    minWidth: '1em',
  },
  emojiSuggestions: {
    border: '1px solid #eee',
    marginTop: '1.75em',
    position: 'absolute',
    minWidth: '220px',
    maxWidth: '440px',
    background: '#fff',
    borderRadius: '2px',
    boxShadow: '0px 4px 30px 0px rgba(220,220,220,1)',
    cursor: 'pointer',
    paddingTop: '8px',
    paddingBottom: '8px',
    zIndex: 2,
    display: 'flex',
    '-webkit-box-orient': 'vertical',
    '-webkit-box-direction': 'normal',
    flexDirection: 'column',
    boxSizing: 'border-box',
    '-webkit-transform': 'scale(0)',
    transform: 'scale(0)',
  },
  emojiSuggestionsEntry: {
    padding: '5px 10px 1px 10px',
    transition: 'background-color 0.4s cubic-bezier(.27,1.27,.48,.56)',
    '&:active': {
      backgroundColor: '#cce7ff',
    },
  },
  emojiSuggestionsEntryFocused: {
    backgroundColor: '#e6f3ff',
  },
  emojiSuggestionsEntryText: {
    display: 'inline-block',
    marginLeft: '8px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '368px',
    fontSize: '0.9em',
  },
  emojiSuggestionsEntryIcon: {
    width: '1em',
    height: '1em',
    marginLeft: '0.25em',
    marginRight: '0.25em',
    display: 'inline-block',
  },
  emojiSelect: {},
  emojiSelectButton: {
    display: 'flex',
    color: '#666',
    overflow: 'visible',
    padding: '3px',
    fontSize: '1.125rem',
    textAlign: 'center',
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    borderRadius: '50%',
    outline: 0,
    border: 'none',
    backgroundColor: 'transparent',
    margin: 0,
    font: 'status-bar',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.03)',
    },
  },
  emojiSelectButtonPressed: {
    color: theme.palette.primary.main,
    display: 'flex',
    overflow: 'visible',
    padding: '3px',
    fontSize: '1.125rem',
    textAlign: 'center',
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    borderRadius: '50%',
    outline: 0,
    border: 'none',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    margin: 0,
    font: 'status-bar',
  },
  emojiSelectPopover: {
    marginTop: theme.spacing(),
    padding: theme.spacing(),
    position: 'absolute',
    zIndex: 1000,
    boxSizing: 'content-box',
    background: theme.palette.common.white,
    border: 'none',
    borderRadius: theme.shape.borderRadius,
    boxShadow:
      '0px 5px 4px -3px rgb(0 0 0 / 8%), 0px 8px 10px 1px rgb(0 0 0 / 0%), 0px 3px 14px 2px rgb(0 0 0 / 9%)',
    '& $emojiSelectPopoverNav': {
      margin: '0',
      padding: '0 .5em',
      display: 'flex',
      width: '100%',
      listStyle: 'none',
      '& $emojiSelectPopoverNavItem': {
        width: '2.5em',
        height: '2.5em',
        '& $emojiSelectPopoverNavEntry': {
          padding: '0',
          width: '100%',
          height: '100%',
          fontSize: '1.2em',
          color: theme.palette.text.secondary,
          background: 'none',
          border: 'none',
          outline: 'none',
          transition: theme.transitions.create('color', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          cursor: 'pointer',
          '&:hover': {
            color: theme.palette.primary.main,
          },
        },
        '& $emojiSelectPopoverNavEntryActive': {
          padding: '0',
          width: '100%',
          height: '100%',
          fontSize: '1.2em',
          color: theme.palette.primary.main,
          background: 'none',
          border: 'none',
          outline: 'none',
        },
      },
    },
  },
  emojiSelectPopoverClosed: {
    display: 'none',
  },
  emojiSelectPopoverTitle: {
    margin: '0 0 .3em',
    paddingLeft: '1em',
    height: '2.5em',
    lineHeight: '2.5em',
    fontWeight: 700,
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.text.secondary,
  },
  emojiSelectPopoverGroups: {
    margin: '0 0 .3em',
    position: 'relative',
    zIndex: 0,
    width: '21em',
    height: '20em',
    '& $emojiSelectPopoverScrollbar': {
      position: 'absolute',
      right: '0',
      top: '.3em',
      bottom: '.3em',
      width: '.25em',
      backgroundColor: '#e0e0e0',
      borderRadius: '.125em',
      opacity: '.1',
      transition: 'opacity .4s',
      '& $emojiSelectPopoverScrollbarThumb': {
        backgroundColor: '#000',
        borderRadius: '.125em',
        cursor: 'pointer',
      },
    },
  },
  emojiSelectPopoverGroup: {
    padding: '0 .5em',
  },
  emojiSelectPopoverGroupTitle: {
    margin: '1em 0',
    paddingLeft: '.5em',
    fontWeight: 700,
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.text.secondary,
    display: 'none',
  },
  emojiSelectPopoverGroupList: {
    margin: 0,
    padding: 0,
    display: 'flex',
    listStyle: 'none',
    flexWrap: 'wrap',
  },
  emojiSelectPopoverGroupItem: {
    width: '2.5em',
    height: '2.5em',
    // For some reason, emojiSelectPopoverEntry isn't applied
    '& button': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0',
      width: '100%',
      height: '100%',
      backgroundColor: theme.palette.common.white,
      border: 'none',
      outline: 'none',
      transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      borderRadius: '50%',
      cursor: 'pointer',
      fontSize: '18px',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.03)',
      },
    },
  },
  emojiSelectPopoverToneSelect: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 2,
  },
  emojiSelectPopoverToneSelectList: {
    margin: '.3em',
    padding: '.3em',
    position: 'absolute',
    display: 'flex',
    listStyle: 'none',
    border: '1px solid #e0e0e0',
    borderRadius: '.5em',
    background: theme.palette.common.white,
    boxShadow: '0 0 0.3em rgba(0, 0, 0, 0.1',
  },
  emojiSelectPopoverToneSelectItem: {
    width: '2.5em',
    height: '2.5em',
  },
  emojiSelectPopoverEntry: {
    display: 'none !important',
  },
  emojiSelectPopoverEntryFocused: {
    padding: '0',
    width: '100%',
    height: '100%',
    background: 'none',
    border: 'none',
    outline: 'none',
    transition: 'background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56)',
    backgroundColor: '#efefef',
  },
  emojiSelectPopoverEntryIcon: {
    width: '1.5em',
    height: '1.5em',
    verticalAlign: 'middle',
  },
  emojiSelectPopoverNav: {
    margin: '0',
    padding: '0 .5em',
    display: 'flex',
    width: '20em',
    listStyle: 'none',
  },
  emojiSelectPopoverNavItem: {
    width: '2.5em',
    height: '2.5em',
  },
  emojiSelectPopoverNavEntry: {
    padding: '0',
    width: '100%',
    height: '100%',
    fontSize: '1.2em',
    color: '#bdbdbd',
    background: 'none',
    border: 'none',
    outline: 'none',
  },
  emojiSelectPopoverNavEntryActive: {
    padding: '0',
    width: '100%',
    height: '100%',
    fontSize: '1.2em',
    background: 'none',
    border: 'none',
    outline: 'none',
    color: '#42a5f5',
  },
  emojiSelectPopoverScrollbar: {
    position: 'absolute',
    right: '0',
    top: '.3em',
    bottom: '.3em',
    width: '.25em',
    backgroundColor: '#e0e0e0',
    borderRadius: '.125em',
    opacity: '.1',
    transition: 'opacity .4s',
  },
  emojiSelectPopoverScrollbarThumb: {
    backgroundColor: '#000',
    borderRadius: '.125em',
    cursor: 'pointer',
  },
}))
