import { lazy } from 'react';
import { RouteProps } from 'utils';
import LogStreamTable from 'views/Settings/Diagnostics/LogStream';

import SvgIcon from '@material-ui/core/SvgIcon';
import {
    Camera as HealthIcon, CategoryRounded as ChannelsIcon, FlareRounded as ConvergeIcon,
    PermMediaRounded as ContentLibraryIcon, SendRounded as BroadcastsIcon,
    SettingsInputAntenna as SignalsIcon, SettingsRounded as SettingsIcon,
    TrackChanges as AcquisitionIcon
} from '@material-ui/icons';

// const Dashboard = lazy(() => import('views/Dashboard'))
// const Audience = lazy(() => import('views/Audience'))
const CreateBroadcast = lazy(() => import('views/Broadcasts/CreateBroadcast'))
const Channels = lazy(() => import('views/Channels'))
const ContentLibrary = lazy(() => import('views/ContentLibrary'))
const Broadcasts = lazy(() => import('views/Broadcasts'))
const Settings = lazy(() => import('views/Settings'))
const Plans = lazy(() => import('views/Plans'))
// const Integrations = lazy(() => import('views/Integrations'))
const SignalsDashboard = lazy(() => import('views/Converge/Signals'))
const AcquisitionDashboard = lazy(() => import('views/Converge/Acquisition'))
const HealthDashboard = lazy(() => import('views/Converge/Health'))
const Setup = lazy(() => import('views/Setup'))
const CreateOrganisation = lazy(() => import('views/Settings/CreateOrganisation'))
const Subscribe = lazy(() => import('views/Subscribe'))

export interface AuthenticatedRouteProps {
  key: string
  title: string
  path: string
  rootPath: string
  matchPath: string
  requiresOneOf: string[]
  component: (props: RouteProps) => JSX.Element
  hasLayout: boolean
  disableForMultiBrandOrg?: boolean
  slimSideBar: boolean
  showInSidebar: boolean
  icon: typeof SvgIcon
  containerWidth?: number
}

export default [
  /* {
    key: 'ViewDashboard',
    title: 'Dashboard',
    path: `dashboard`,
    rootPath: 'dashboard',
    matchPath: `/dashboard`,
    requiresOneOf: ['seeka.Dashboard'],
    component: Dashboard,
    hasLayout: true,
    showInSidebar: true,
    slimSideBar: false,
    icon: DashboardIcon,
  }, */
  /** put create broadcast here since it's not actually a subpage to /broadcasts */
  {
    key: 'ViewBroadcastCreateEdit',
    title: 'Create broadcast',
    path: `broadcasts/create`,
    rootPath: 'broadcasts/create',
    matchPath: `/broadcasts/create`,
    requiresOneOf: ['seeka.Audience.Broadcasting', 'seeka.Channels'],
    component: CreateBroadcast,
    hasLayout: false,
    showInSidebar: false,
    slimSideBar: false,
    icon: BroadcastsIcon,
  },
  {
    key: 'ViewBroadcasts',
    title: 'Broadcasts',
    path: `broadcasts/:broadcastId?/:details?`,
    rootPath: 'broadcasts',
    matchPath: `/broadcasts/:subPage?/:details?`,
    requiresOneOf: ['seeka.Audience.Broadcasting'],
    component: Broadcasts,
    hasLayout: true,
    showInSidebar: true,
    slimSideBar: false,
    icon: BroadcastsIcon,
  },
  /* {
    key: 'ViewAudience',
    title: 'Audience',
    path: `audience/:details?/:detail?/:action?`,
    rootPath: 'audience',
    matchPath: `/audience/:details?/:detail?/:action?`,
    requiresOneOf: ['seeka.Audience'],
    component: Audience,
    hasLayout: true,
    showInSidebar: true,
    slimSideBar: false,
    icon: AudienceIcon,
  }, */
  {
    key: 'ViewChannels',
    title: 'Channels',
    path: `channels/:channel?/:action?`,
    rootPath: 'channels',
    matchPath: `/channels/:channel?/:action?`,
    requiresOneOf: ['seeka.Channels'],
    component: Channels,
    hasLayout: true,
    showInSidebar: true,
    slimSideBar: false,
    icon: ChannelsIcon,
  },
  {
    key: 'ViewContentLibrary',
    title: 'Content',
    path: `content/:content?/:action?`,
    rootPath: 'content',
    matchPath: `/content/:content?/:action?`,
    requiresOneOf: ['seeka.ContentLibrary'],
    component: ContentLibrary,
    hasLayout: true,
    showInSidebar: true,
    slimSideBar: false,
    icon: ContentLibraryIcon,
  },
  // {
  //   key: 'ViewIntegrations',
  //   title: 'Integrations',
  //   path: `integrations/:integration?/:action?`,
  //   rootPath: 'integrations',
  //   matchPath: `/integrations/:integration?/:action?`,
  //   requiresOneOf: ['module.Org.ServiceConnections.Manage'],
  //   component: Integrations,
  //   hasLayout: true,
  //   showInSidebar: false,
  //   slimSideBar: false,
  //   icon: IntegrationsIcon,
  // },
  {
    key: 'ViewDiagnosticsStream',
    title: 'Diagnostics',
    path: `settings/developer/diagnostics/stream`,
    rootPath: 'settings',
    matchPath: `/settings/developer/diagnostics/stream`,
    requiresOneOf: ['module.Converge.Read'],
    component: LogStreamTable,
    hasLayout: false,
    showInSidebar: false,
    slimSideBar: false,
    icon: ConvergeIcon,
  },
  {
    key: 'ViewSettings',
    title: 'Settings',
    path: `settings/:category?/:setting?/:action?/:anotherAction?/:andAnotherAction?`,
    rootPath: 'settings',
    matchPath: `/settings/:category?/:setting?/:action?/:anotherAction?/:andAnotherAction?`,
    // requiresOneOf: ['settingManagement.Enable'],
    component: Settings,
    hasLayout: true,
    showInSidebar: false,
    slimSideBar: false,
    icon: SettingsIcon,
  },
  {
    key: 'ViewPlans',
    title: 'Plans',
    path: `plans`,
    rootPath: 'plans',
    matchPath: `/plans`,
    component: Plans,
    hasLayout: false,
    showInSidebar: false,
    slimSideBar: true,
  },
  {
    key: 'ViewCreateOrganisation',
    title: 'Create organisation',
    path: `create-organisation`,
    rootPath: 'create-organisation',
    matchPath: `/create-organisation`,
    // requiresOneOf: ['settingManagement.Enable'],
    component: CreateOrganisation,
    hasLayout: false,
    showInSidebar: false,
    slimSideBar: false,
    icon: SettingsIcon,
  },
  {
    key: 'SetupConverge',
    title: 'Set-up',
    path: `setup/:category?/:item?`,
    rootPath: 'setup',
    matchPath: `/setup/:category?/:item?`,
    requiresOneOf: ['module.Converge.Manage'],
    component: Setup,
    hasLayout: false,
    showInSidebar: false,
    slimSideBar: false,
    icon: ConvergeIcon,
  },
  {
    key: 'SignalsDashboard',
    title: 'Signals',
    path: `dashboard/signals`,
    rootPath: 'dashboard/signals',
    matchPath: `/dashboard/signals/:subpage?`,
    requiresOneOf: ['module.Converge.Read'],
    component: SignalsDashboard,
    hasLayout: true,
    showInSidebar: true,
    slimSideBar: false,
    icon: SignalsIcon,
    containerWidth: 2400,
  },
  {
    key: 'AcquisitionDashboard',
    title: 'Acquisition',
    path: `dashboard/acquisition`,
    rootPath: 'dashboard/acquisition',
    matchPath: `/dashboard/acquisition`,
    requiresOneOf: ['module.Converge.Read'],
    component: AcquisitionDashboard,
    hasLayout: true,
    disableForMultiBrandOrg: true,
    showInSidebar: true,
    slimSideBar: false,
    icon: AcquisitionIcon,
    containerWidth: 2400,
  },
  {
    key: 'HealthDashboard',
    title: 'Health',
    path: `dashboard/health`,
    rootPath: 'dashboard/health',
    matchPath: `/dashboard/health`,
    // requiresOneOf: ['module.Converge.Read'],
    requiresOneOf: [`_Core.Internal.StaffMember`],
    component: HealthDashboard,
    hasLayout: true,
    showInSidebar: true,
    slimSideBar: false,
    icon: HealthIcon,
    containerWidth: 2400,
  },
  {
    key: 'Subscribe',
    title: 'Subscribe',
    path: `subscribe`,
    matchPath: `/subscribe/:subpage?`,
    component: Subscribe,
    hasLayout: false,
    showInSidebar: false,
    slimSideBar: false,
  },
] as AuthenticatedRouteProps[]
