import React, { useEffect } from 'react'
import { makeStyles, Theme, Tooltip, Box, alpha } from '@material-ui/core'
import { LinkPlus as LinkIcon } from 'mdi-material-ui'

const useStyles = makeStyles((theme: Theme) => ({
  chip: {
    height: 20,
    verticalAlign: 'middle',
    cursor: 'pointer',
    borderColor: theme.palette.error.light,
  },
  label: {
    paddingLeft: 5,
    paddingRight: 7,
    color: theme.palette.error.main,
  },
  icon: {
    color: theme.palette.warning.dark,
    width: 18,
    height: 18,
    verticalAlign: 'text-bottom',
  },
  menu: {
    overflow: 'auto',
    maxHeight: 250,
    boxShadow:
      '0px 5px 4px -3px rgb(0 0 0 / 8%), 0px 8px 10px 1px rgb(0 0 0 / 0%), 0px 3px 14px 2px rgb(0 0 0 / 9%)',
    width: '100%',
    maxWidth: 370,
    padding: theme.spacing(2),
  },
  popper: {
    zIndex: 2500,
  },
  linkSpan: {
    cursor: 'pointer',
    fontSize: '12px',
    color: theme.palette.warning.dark,
    '&:hover': {
      opacity: 0.8,
      backgroundColor: alpha(theme.palette.common.black, 0.03),
    },
  },
}))

interface Props {
  children: any
  start: number
  end: number
  /** show link setup or just plain text */
  withIntelligentUrls: boolean
  decoratedText: string
  /** having trouble with editor focus, so moved popper out of this component */
  handleSetAnchorEl: (anchorEl: HTMLElement | null) => void
  handleSetUrl: (
    props: { url: string; start: number; end: number; blockKey: string } | null
  ) => void
  blockKey: string
  // offsetKey: string
}

const NonResolvedLinkSpan = ({
  children,
  start,
  end,
  withIntelligentUrls,
  decoratedText,
  handleSetAnchorEl,
  handleSetUrl,
  blockKey,
}: // offsetKey,
Props) => {
  const classes = useStyles()

  const handleOpenLinkSetup = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    handleSetAnchorEl(event.currentTarget)
    handleSetUrl({
      url: decoratedText,
      start,
      end,
      blockKey,
    })
  }

  useEffect(() => {
    return () => {
      handleSetAnchorEl(null)
      handleSetUrl(null)
    }
  }, [])

  useEffect(() => {
    handleSetUrl({
      url: decoratedText,
      start,
      end,
      blockKey,
    })
  }, [decoratedText])

  return withIntelligentUrls ? (
    <Tooltip title="Set up trackable shortlink" arrow>
      <Box component="span" className={classes.linkSpan} onClick={handleOpenLinkSetup}>
        <LinkIcon className={classes.icon} /> {children}
      </Box>
    </Tooltip>
  ) : (
    children
  )
}

export default NonResolvedLinkSpan
