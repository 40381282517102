import React from 'react'
import classNames from 'classnames'
import { FieldProps } from 'views/Channels/DigitalWallet/CreateEditDialog/helpers'
import { Paper, makeStyles, Theme } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import Back from '../Back'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: theme.shape.borderRadius,
    width: 320,
    height: 468,
    margin: 'auto',
  },
  innerContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.easeInOut,
      duration: 800,
    }),
    transformStyle: 'preserve-3d',
  },
  front: {
    transition: theme.transitions.create('background-color', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
    position: 'absolute',
    width: '100%',
    height: '100%',
    '-webkit-backface-visibility': 'hidden',
    backfaceVisibility: 'hidden',
    boxShadow: '0px 0px 14px 0px rgba(46,55,77,0.2)',
    overflow: 'auto',
  },
  back: {
    backgroundColor: theme.palette.secondary.light,
    position: 'absolute',
    width: '100%',
    height: '100%',
    '-webkit-backface-visibility': 'hidden',
    backfaceVisibility: 'hidden',
    transform: 'rotateY(180deg)',
    boxShadow: '0px 0px 14px 0px rgba(46,55,77,0.2)',
    overflow: 'auto',
    paddingBottom: theme.spacing(3),
  },
  flip: {
    transform: 'rotateY(180deg)',
  },
}))

interface Props {
  children?: JSX.Element | JSX.Element[]
  className?: string
  backgroundColor?: string
  loading?: boolean
  showBack?: boolean
  backFields?: FieldProps[]
}

const CardContainer = ({
  children,
  className,
  backgroundColor,
  loading,
  showBack,
  backFields,
}: Props) => {
  const classes = useStyles()

  const containerClassName = classNames(
    {
      [classes.container]: true,
    },
    className
  )

  const innerContainerClassName = classNames([classes.innerContainer], {
    [classes.flip]: showBack,
  })

  return (
    <div className={containerClassName}>
      <div className={innerContainerClassName}>
        <Paper
          className={classes.front}
          elevation={0}
          component="div"
          square={false}
          style={{ backgroundColor }}
        >
          {loading ? <Skeleton variant="rect" width="100%" height="100%" /> : children}
        </Paper>
        <Paper className={classes.back} elevation={0} component="div" square={false}>
          {loading ? (
            <Skeleton variant="rect" width="100%" height="100%" />
          ) : (
            <Back fields={backFields} />
          )}
        </Paper>
      </div>
    </div>
  )
}

export default CardContainer
