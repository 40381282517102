import { defaultTableState } from './queries'

const audience = ['audience'] as const
const audienceInfinite = (filters: typeof defaultTableState) =>
  [...audience, 'infinite', filters] as const
const singleAudienceMembers = [...audience, 'single'] as const
const singleAudienceMember = (id: string) => [...singleAudienceMembers, id] as const
const segmentConditionFields = ['segmentConditionFields'] as const

export default {
  audience,
  audienceInfinite,
  singleAudienceMembers,
  singleAudienceMember,
  segmentConditionFields,
}
