import classNames from 'classnames'
import { LoyaltyRounded as BrandIcon } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'
import { makeStyles, Theme, Chip, Tooltip, Avatar } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  chip: {
    backgroundColor: theme.palette.primary.light,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(0.5),
  },
  chipLabel: {
    paddingLeft: 8,
    paddingRight: 8,
    fontWeight: 600,
    fontSize: 'inherit',
  },
  withLink: {
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      opacity: 0.7,
    },
  },
  icon: {
    height: '100%',
    fontSize: 'inherit',
    color: theme.palette.text.primary,
    marginRight: -theme.spacing(0.5),
  },
}))

interface Props {
  label: string
  tooltip?: string
  withLink?: boolean
  className?: string
  fontSize?: number
}

const BrandChip = ({ label, tooltip, withLink, className, fontSize = 11 }: Props) => {
  const classes = useStyles()
  const history = useHistory()

  const handleGoToBrands = () => {
    history.push(`/settings/brand/brand-settings`)
  }

  const chipClassName = classNames(
    [classes.chip],
    {
      [classes.withLink]: withLink,
    },
    className
  )

  return (
    <Tooltip title={tooltip || ''} arrow>
      <Chip
        onClick={withLink ? handleGoToBrands : undefined}
        component="span"
        avatar={
          <Avatar style={{ backgroundColor: 'transparent' }}>
            <BrandIcon className={classes.icon} style={{ width: fontSize * 1.35 }} />
          </Avatar>
        }
        label={<>{label}</>}
        size="small"
        className={chipClassName}
        style={{ fontSize }}
        classes={{ label: classes.chipLabel }}
      />
    </Tooltip>
  )
}

export default BrandChip
