const locationHostname = window.location.hostname

export const TOKEN_NAME = `__!<bearer>${locationHostname}||seeka`

export const setToken = (token: string) => window.localStorage.setItem(TOKEN_NAME, token)

export const getToken = (): string | null => {
  const token = window.localStorage.getItem(TOKEN_NAME)
  return token || null
}

export const removeToken = () => window.localStorage.removeItem(TOKEN_NAME)
