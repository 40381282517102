const black = '#202121'
const white = '#FFFFFF'

export default {
  common: {
    black,
    white,
    neutral: '#E4E7EB',
    muted: '#9586A5',
  },
  primary: {
    contrastText: white,
    main: '#722ED1',
    light: '#f2eafc', // '#e9e0ff',
    dark: '#4f2f7f',
  },
  secondary: {
    contrastText: white,
    main: '#F50057',
    light: '#d9d9d9',
    dark: '#54456b',
  },
  success: {
    contrastText: white,
    main: '#38C2A1',
    light: '#E1F7DE',
    dark: '#009D62',
  },
  info: {
    contrastText: white,
    main: '#66ADD3',
    light: '#E0EFF6',
    dark: '#2E6B8C',
  },
  warning: {
    contrastText: white,
    main: '#FF9800',
    light: '#ffedb5',
    dark: '#E9B100',
  },
  error: {
    contrastText: white,
    main: '#F44336',
    light: '#F8DFDF',
    dark: '#A83B3B',
  },
  text: {
    primary: '#050505',
    secondary: '#666',
    disabled: '#A6B1BB',
  },
  gradient: {
    red: 'linear-gradient(to bottom right,#ff1053,#ff4f0f)',
    blue: 'linear-gradient(to bottom right,#0de2b8,#3987cc)',
    purple: 'linear-gradient(to bottom right,#e15ac3,#6949cd)',
  },
  background: {
    default: '#f9f8f8',
    dark: '#f3f3f3',
    paper: white,
  },
  brand: {
    facebook: {
      backgroundColor: '#1877F2',
      color: white,
    },
    google: {
      backgroundColor: '#4285F4',
      color: white,
    },
    messenger: {
      backgroundColor: '#0066ff',
      color: white,
    },
    email: {
      backgroundColor: '#00ccff',
      color: white,
    },
    sms: {
      backgroundColor: '#63F47B',
      color: white,
    },
    wallet: {
      backgroundColor: '#1c181b',
      color: white,
    },
    ticketrev: {
      backgroundColor: '#704acd',
      color: white,
    },
    manychat: {
      backgroundColor: '#f6f7f9',
      color: '#0084ff',
    },
    zenGlobal: {
      backgroundColor: '#cccc66',
      color: white,
    },
    omnichannel: {
      backgroundColor: '#5d2cbb',
      color: white,
    },
    oneTimeCampaign: {
      backgroundColor: '#4c4c4c',
      color: white,
    },
    automatedCampaign: {
      backgroundColor: '#4c4c4c',
      color: white,
    },
    enrichmentCampaign: {
      backgroundColor: '#4c4c4c',
      color: white,
    },
    acquisitionCampaign: {
      backgroundColor: '#4c4c4c',
      color: white,
    },
    webhook: {
      backgroundColor: '#333333',
      color: '#cc3366',
    },
    wait: {
      backgroundColor: '#DD5E5E',
      color: white,
    },
    goal: {
      backgroundColor: '#FFD039',
      color: white,
    },
    stage: {
      backgroundColor: '#722ED1',
      color: white,
    },
    note: {
      backgroundColor: '#666',
      color: white,
    },
    conditionalSplit: {
      backgroundColor: '#6aa6ff',
      color: white,
    },
    outboundDirection: {
      backgroundColor: '#a4e6a0',
      color: '#333333',
    },
    inboundDirection: {
      backgroundColor: '#969dec',
      color: '#333333',
    },
    omniDirection: {
      backgroundColor: '#d4a0f5',
      color: '#333333',
    },
  },
  converge: {
    shopify: {
      backgroundColor: '#95BF471A',
      color: white,
    },
    woocommerce: {
      backgroundColor: '#eee0ff',
      color: white,
    },
    zapier: {
      backgroundColor: '#ffd6c3',
      color: white,
    },
    manychat: {
      backgroundColor: 'black',
      color: white,
    },    
    gatsby: {
      backgroundColor: 'black',
      color: white,
    },
    magento: {
      backgroundColor: 'black',
      color: white,
    },
    bigcommerce: {
      backgroundColor: '#d3d3d3',
      color: white,
    },
    goHiLevel: {
      backgroundColor: '#e4fdf4',
      color: '#02D185',
    },
    seekaApp: {
      backgroundColor: '#e4fdf4',
      color: '#2c2c2c',
    },
    wordpress: {
      backgroundColor: '#d5e3e7',
      color: '#333333',
    },
    oztix: {
      backgroundColor: '#ddecf8',
      color: '#333333',
    },
    megatix: {
      backgroundColor: '#ddecf8',
      color: '#333333',
    },
    scriptTag: {
      backgroundColor: '#f7eeff',
      color: '#8400ff',
    },
    iframe: {
      backgroundColor: '#e9e9e9',
      color: '#9e9e9e',
    },
  },
  pipelineIntegration: {
    klaviyo: {
      backgroundColor: '#F1F4F5',
      color: white,
    },
    loke: {
      backgroundColor: '#F1F4F5',
      color: white,
    }
  },
  convergeDestination: {
    facebook: {
      backgroundColor: '#1877F21A',
      color: white,
    },
    googleAds: {
      backgroundColor: '#FBBD041A',
      color: white,
    },
    googleAnalytics: {
      backgroundColor: '#E374001A',
      color: white,
    },
    snapchat: {
      backgroundColor: '#E1E4341A',
      color: white,
    },
    pinterest: {
      backgroundColor: '#D7143A0F',
      color: white,
    },
    tiktok: {
      backgroundColor: '#BF474E1A',
      color: white,
    },
  },
  border: '#e6e6e6',
  divider: '#e6e6e6',
}
