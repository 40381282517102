import { defaultTableState as listBroadcastsTableState } from 'views/Broadcasts/ListBroadcasts'
import { defaultTableState as outboundHistoryTableState } from 'views/Broadcasts/OutboundHistory'
import { defaultTableState as contentLibraryTableState } from 'views/ContentLibrary'
import { defaultTableState as verifiedDomainsTableState } from 'views/Settings/VerifiedDomains'

// https://react-query.tanstack.com/guides/query-keys
export default {
  me: ['me'],
  platformChannels: ['platformChannels'],
  brands: ['brands'],
  shopifyStores: (brandId: string) => ['shopifyStores', brandId],
  dataDestinations: ['dataDestinations'],
  platformChannelTypes: ['platformChannelTypes'],
  segmentConditionFields: ['segmentConditionFields'],
  textTokens: (walletPassInstallEmailTemplate?: boolean) => [
    'textTokens',
    walletPassInstallEmailTemplate,
  ],
  timezones: ['timezones'],
  broadcasts: (props: typeof listBroadcastsTableState) => [
    'broadcasts',
    Object.values(props),
  ],
  broadcast: (id: string) => ['broadcast', id],
  allBroadcasts: ['broadcasts'],
  outboundHistory: (props: typeof outboundHistoryTableState) => [
    'outboundHistory',
    Object.values(props),
  ],
  allAudienceMembers: ['audienceMembers'],
  contentLibrary: (props: typeof contentLibraryTableState) => [
    'contentLibrary',
    Object.values(props),
  ],
  allContentLibrary: ['contentLibrary'],
  contentLibraryPurposes: ['contentLibraryPurposes'],
  audienceMember: (id: string) => ['audienceMember', id],
  emailTemplates: (templatePurposeKey?: string) => ['emailTemplates', templatePurposeKey],
  walletInstances: ['walletInstances'],
  webhookEndpoints: ['webhookEndpoints'],
  webhookTypes: ['webhookTypes'],
  tags: ['tags'],
  segments: ['segments'],
  countries: ['countries'],
  passwordOptions: ['passwordOptions'],
  loginProviders: (redirectUrl?: string, inviteUrl?: string) => [
    'loginProviders',
    redirectUrl,
    inviteUrl,
  ],
  organisations: ['organisations'],
  teamMembers: ['teamMembers'],
  teamInvites: ['teamInvites'],
  userRoles: ['userRoles'],
  verifiedDomains: (props: typeof verifiedDomainsTableState) => [
    'verifiedDomains',
    Object.values(props),
  ],
  allVerifiedDomains: ['verifiedDomains'],
  colorThemes: ['colorThemes'],
  intelligentUrlDomains: ['intelligentUrlDomains'],
  smsTemplates: ['smsTemplates'],
}
