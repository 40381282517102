import { Alert, AppBar, TicketRevTextField } from 'components'
import { useFormik } from 'formik'
import { CompletePasswordResetRequest } from 'generated/api/account-service-proxies'
import { useMe } from 'hooks'
import { useSnackbar } from 'notistack'
import PasswordValidator from 'password-validator'
import { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { RouteComponentProps } from 'react-router-dom'
import { api, queryKeys } from 'services'
import loginManager from 'services/loginManager'
import { setToken, useDocumentTitle } from 'utils'

import { Fade, IconButton, makeStyles, Theme, Typography } from '@material-ui/core'
import { VisibilityOffOutlined, VisibilityOutlined } from '@material-ui/icons'

import {
  Container,
  ContentCard,
  PasswordCheckContainer,
  PasswordCheckLine,
  SubmitButton,
  Title,
} from '../components'

type FormProps = {
  passwordOne: string
  passwordTwo: string
}

interface MessageProps {
  message: string
  type: 'error' | 'success'
}

const initialValues: FormProps = {
  passwordOne: '',
  passwordTwo: '',
}

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    cursor: 'pointer',
    marginTop: 6,
    marginLeft: 2,
    marginBottom: theme.spacing(1.5),
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))

interface Props extends RouteComponentProps {
  getRecaptchaToken: () => Promise<string | undefined>
}

const SetNewPassword = ({ location, history, getRecaptchaToken }: Props) => {
  useDocumentTitle('Set new password')
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()

  const { data: passwordOptionsData } = useQuery(queryKeys.passwordOptions, () =>
    api.account.getPasswordOptions()
  )

  const { mutateAsync: passwordResetMutation } = useMutation(
    ({
      body,
      recaptchaToken,
    }: {
      body: CompletePasswordResetRequest
      recaptchaToken: string
    }) => api.account.completePasswordReset(body, recaptchaToken)
  )

  const [showPassword, setShowPassword] = useState(false)
  const [initialValidation, setInitialValidation] = useState<string[]>([])
  const [validationRules, setValidationRules] = useState(new PasswordValidator())
  const [message, setMessage] = useState<MessageProps | null>(null)
  const [resolved, setResolved] = useState(false)

  const { data: meData } = useMe({
    enabled: resolved,
  })

  useEffect(() => {
    if (meData && resolved) history.push('/dashboard/signals')
  }, [meData])

  const queryString = location.search
  const urlParams = new URLSearchParams(queryString)
  const passwordResetToken = urlParams.get('t')
  const emailParam = urlParams.get('e')

  useEffect(() => {
    if (passwordOptionsData?.result && initialValidation.length === 0) {
      const { result: rules } = passwordOptionsData
      const validator = validationRules

      if (rules.requireDigit) validator.has().digits()
      if (rules.requireNonAlphanumeric) validator.has().symbols()
      if (rules.requireLowercase) validator.has().lowercase()
      if (rules.requireUppercase) validator.has().uppercase()
      if (rules.requiredLength) validator.min(rules.requiredLength)

      const getInitialValidationLength = validator.validate('', { list: true })

      setInitialValidation(
        (typeof getInitialValidationLength !== 'boolean' &&
          getInitialValidationLength) || ['']
      )
      setValidationRules(validator)
    }
  }, [passwordOptionsData])

  const sendToLogin = () => {
    enqueueSnackbar('Success! Please log in with your new password', {
      variant: 'success',
    })
    history.push('/sign-in')
  }

  const { setFieldValue, handleSubmit, values, isSubmitting, errors, setErrors } =
    useFormik({
      initialValues,
      validateOnBlur: false,
      validateOnChange: false,
      onSubmit: async (payload) => {
        // eslint-disable-next-line
        if (passwordIsValid && passwordsMatch) {
          try {
            const recaptchaToken = await getRecaptchaToken()
            if (!recaptchaToken) return
            const body = {
              token: passwordResetToken,
              newPassword: payload.passwordOne,
            } as CompletePasswordResetRequest
            await passwordResetMutation({ body, recaptchaToken })
            if (emailParam) {
              const loginOrgId = await loginManager.getOrgForLogin(atob(emailParam))
              if (!loginOrgId) {
                sendToLogin()
              } else {
                try {
                  const getToken = await loginManager.loginOrThrow(
                    atob(emailParam),
                    payload.passwordOne,
                    loginOrgId,
                    (await getRecaptchaToken()) as string
                  )
                  setToken(getToken.accessToken)
                  setResolved(true)
                } catch (err) {
                  sendToLogin()
                }
              }
            } else {
              sendToLogin()
            }
          } catch (err) {
            setMessage({
              message:
                err?.error.message ||
                "An error occurred. The password couldn't be reset. Please try again.",
              type: 'error',
            })
          }
        } else {
          setErrors({
            // eslint-disable-next-line
            passwordOne: passwordIsValid ? '' : 'aewf',
            // eslint-disable-next-line
            passwordTwo: passwordsMatch ? '' : 'aewf',
          })
        }
      },
    })

  const handleGoToLogin = () => history.push('/sign-in')

  const passwordValidationString = (rule: string | number) => {
    if (rule === 'min') return 'Minimum 6 characters'
    if (rule === 'uppercase') return 'Minimum 1 uppercase letter'
    if (rule === 'lowercase') return 'Minimum 1 lowercase letter'
    if (rule === 'digits') return 'Minimum 1 digit'
    if (rule === 'symbols') return 'Minimum 1 symbol'
    return ''
  }

  const passwordValidationArray = validationRules.validate(values.passwordOne, {
    list: true,
  }) as string[]

  const passwordIsValid =
    typeof passwordValidationArray !== 'boolean' && passwordValidationArray.length === 0
  const passwordsMatch = values.passwordOne === values.passwordTwo
  const showNoMatchMessage =
    passwordIsValid &&
    !passwordsMatch &&
    values.passwordOne.length <= values.passwordTwo.length

  const showValidationHelp =
    typeof passwordValidationArray !== 'boolean' &&
    passwordValidationArray.length > 0 &&
    values.passwordOne.length > 0

  useEffect(() => {
    if (errors.passwordOne || errors.passwordTwo)
      setErrors({ passwordOne: '', passwordTwo: '' })
  }, [values])

  const handleTogglePasswordVisibility = () => setShowPassword(!showPassword)

  return (
    <Container>
      <AppBar>
        <span>
          <Typography
            display="inline"
            align="right"
            color="primary"
            onClick={handleGoToLogin}
            className={classes.link}
          >
            Sign in
          </Typography>
        </span>
      </AppBar>
      <ContentCard containerHeight={595}>
        <Title title="Set new password" />

        {message && (
          <Alert severity={message.type} message={message.message} withMargin />
        )}

        {passwordResetToken ? (
          <form onSubmit={handleSubmit}>
            <TicketRevTextField
              type={showPassword ? 'text' : 'password'}
              required
              value={values.passwordOne}
              label="New password"
              name="passwordOne"
              placeholder="Password"
              disabled={isSubmitting}
              onChange={setFieldValue}
              error={Boolean(errors.passwordOne)}
              endAdornment={
                <IconButton
                  edge="end"
                  size="small"
                  onClick={handleTogglePasswordVisibility}
                >
                  {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                </IconButton>
              }
            />
            <PasswordCheckContainer
              message={
                <Fade in={showValidationHelp} mountOnEnter unmountOnExit>
                  <Typography variant="body2" color="error" align="center">
                    {passwordValidationString(
                      (typeof passwordValidationArray !== 'boolean' &&
                        passwordValidationArray[0]) ||
                      ''
                    )}
                  </Typography>
                </Fade>
              }
            >
              {typeof passwordValidationArray !== 'boolean' &&
                initialValidation
                  .sort((a, b) => {
                    return (
                      passwordValidationArray.indexOf(a) -
                      passwordValidationArray.indexOf(b)
                    )
                  })
                  .map((x) => (
                    <PasswordCheckLine
                      key={x}
                      active={
                        typeof passwordValidationArray !== 'boolean' &&
                        !passwordValidationArray.includes(x)
                      }
                      paddingLeft
                    />
                  ))}
            </PasswordCheckContainer>

            <TicketRevTextField
              type="password"
              required
              value={values.passwordTwo}
              label="Repeat new password"
              name="passwordTwo"
              placeholder="Repeat password"
              disabled={isSubmitting}
              onChange={setFieldValue}
              error={Boolean(errors.passwordTwo)}
              helperText={showNoMatchMessage ? `The passwords doesn't match` : ''}
            />

            <SubmitButton anchor="Set new password" loading={isSubmitting} />
          </form>
        ) : (
          <Alert
            severity="error"
            withMargin
            message="We couldn't find a valid link to set a new password. Check your email inbox and click the link."
          />
        )}
      </ContentCard>
    </Container>
  )
}

export default SetNewPassword
