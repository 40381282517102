import { makeStyles, Theme } from '@material-ui/core'

export default makeStyles((theme: Theme) => ({
  outboundStep: {
    width: 280,
  },
  sharedStepStyles: {
    margin: 'auto',
    boxShadow: '0 16px 16px -16px rgba(46,55,77,.1)',
    border: `2px solid ${theme.palette.common.white}`,
    position: 'relative',
    overflow: 'visible',
  },
  isEditing: {
    border: `2px solid ${theme.palette.success.main}`,
  },
  menuOpen: {
    // border: `2px solid ${theme.palette.border}`,
  },
  cardTitle: {
    color: theme.palette.text.secondary,
  },
  cardSubHeader: {
    fontSize: '15px',
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  cardHeader: {
    paddingBottom: 0,
  },
  cardActionArea: {
    borderRadius: theme.shape.borderRadius,
  },
  endIcon: {
    marginLeft: 0,
  },
  cardActions: {
    paddingTop: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  colorSecondary: {
    color: theme.palette.text.secondary,
  },
  colorSuccess: {
    color: theme.palette.success.main,
  },
}))
