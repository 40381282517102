import React from 'react'
import classNames from 'classnames'
import { makeStyles, Theme, Typography } from '@material-ui/core'
import iPhonePreviewImage from 'assets/img/iphone-preview.png'
import { TOKEN_REGEX_WITH_DELIMITER } from '../RichTextEditor/utils'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: 'auto',
  },
  title: {
    fontSize: theme.typography.caption.fontSize,
  },
  content: {
    lineHeight: '18px',
    fontSize: theme.typography.caption.fontSize,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(2.5),
    padding: theme.spacing(4.4),
    maxHeight: 373,
    overflow: 'auto',
  },
  iphone: {
    backgroundImage: `url(${iPhonePreviewImage})`,
    backgroundSize: 'contain',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    height: '512px',
    width: '257px',
    paddingTop: theme.spacing(6),
  },
  iphoneContent: {},
  sms: {},
  messageBubble: {
    wordWrap: 'break-word',
    lineHeight: '18px',
    position: 'relative',
    padding: '6px 10px',
    borderRadius: '20px',
    whiteSpace: 'pre-line',
  },
  receiveMessageBubble: {
    backgroundColor: '#E9E9EB',
    color: theme.palette.common.black,
    alignSelf: 'flex-start',
    maxWidth: '100%',
  },
  sendMessageBubble: {
    backgroundColor: '#0B93F6',
    color: 'white',
    alignSelf: 'flex-end',
  },
  messageBubbleTeardrop: {
    position: 'absolute',
    bottom: 0,
    height: '25px',
  },
  receiveMessageBubbleTeardropBefore: {
    left: '28px',
    width: '6px',
    backgroundColor: '#E9E9EB',
    borderBottomRightRadius: '16px 14px',
  },
  receiveMessageBubbleTeardropAfter: {
    left: '-26px',
    width: '26px',
    backgroundColor: 'white',
    borderBottomRightRadius: '10px',
  },
}))

interface Props {
  deviceType: 'iPhone'
  contentType: 'receiveSms'
  data: { [key: string]: string }
  style?: Partial<React.CSSProperties>
}

export const linkify = (inputText: string) => {
  let replacedText

  // URLs starting with http://, https://, or ftp://
  const replacePattern1 =
    /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim
  replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>')

  // URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  const replacePattern2 = /(^|[^/])(www\.[\S]+(\b|$))/gim
  replacedText = replacedText.replace(
    replacePattern2,
    '$1<a href="http://$2" target="_blank">$2</a>'
  )

  // Change email addresses to mailto:: links.
  const replacePattern3 = /(([a-zA-Z0-9\-_.])+@[a-zA-Z_]+?(\.[a-zA-Z]{2,6})+)/gim
  replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>')

  return replacedText
}

const DevicePreview = ({ deviceType, contentType, data, style }: Props) => {
  const classes = useStyles()

  const root = classNames([classes.container], {
    [classes.iphone]: deviceType === 'iPhone',
    [classes.sms]: contentType === 'receiveSms',
  })

  const content = classNames([classes.content], {
    [classes.iphoneContent]: deviceType === 'iPhone',
  })
  const messageBubble = classNames([classes.messageBubble], {
    [classes.receiveMessageBubble]: contentType === 'receiveSms',
  })
  const messageBubbleAfter = classNames({
    [classes.receiveMessageBubbleTeardropAfter]: contentType === 'receiveSms',
  })

  const titleContent = (): JSX.Element => {
    switch (contentType) {
      case 'receiveSms':
        if (!data || !data.senderIdentifier) return <></>
        return <>{data.senderIdentifier}</>
      default:
        return <></>
    }
  }

  const replaceShortUrlTokens = data?.messageBody
    ?.split(TOKEN_REGEX_WITH_DELIMITER)
    .map((x) => {
      if (x.startsWith('{{shortUrl::')) {
        const shortUrlParameters = x.split('::')
        return shortUrlParameters[shortUrlParameters.length - 1].replace('}}', '')
      }
      return x
    })
    .join('')

  return (
    <div className={root} style={{ ...style }}>
      <Typography
        variant="body2"
        align="center"
        className={classes.title}
        component="span"
        display="block"
      >
        {titleContent()}
      </Typography>
      <Typography variant="body2" className={content} component="span" display="block">
        {data && data.messageBody && (
          <>
            <div
              className={messageBubble}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: linkify(replaceShortUrlTokens),
              }}
            />
            <span className={messageBubbleAfter}> </span>
          </>
        )}
      </Typography>
    </div>
  )
}

export default DevicePreview
