import React, { useState } from 'react'
import { TicketRevTextField, DialogWrapper } from 'components'
import { IconButton, makeStyles, Theme } from '@material-ui/core'
import { DateRangePicker as RangePicker, DateRange } from 'materialui-daterange-picker'
import moment from 'moment'
import { DateRangeProps } from 'utils'
import {
  DateRangeRounded as DateRangeIcon,
  ClearRounded as ClearIcon,
} from '@material-ui/icons'

interface Props {
  onChange: (name: string, dateRange: DateRangeProps | null) => void
  name: string
  minDate?: moment.Moment
  maxDate?: moment.Moment
  value?: DateRangeProps
  className?: string
  placeholder?: string
}

const useStyles = makeStyles((theme: Theme) => ({
  dialogPaper: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    width: 'auto',
    borderRadius: theme.shape.borderRadius,
  },
  dialogContent: {
    padding: 0,
  },
  wrapper: {},
}))

export const DateRangePicker = ({
  onChange,
  name,
  className,
  maxDate,
  minDate,
  value,
  placeholder = '',
}: Props) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const handleOnChange = (dateRange: DateRange) => {
    const startDate = moment(dateRange.startDate)
    const endDate = moment(dateRange.endDate)
    setOpen(false)
    onChange(name, { startDate, endDate })
  }

  const handleClear = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    onChange(name, null)
  }

  const toggleOpen = () => setOpen(!open)

  const hasValue = value?.startDate && value?.endDate

  return (
    <>
      <TicketRevTextField
        value={
          hasValue
            ? `${moment(value?.startDate).format('MMM D')} - ${moment(
                value?.endDate
              ).format('MMM D')}`
            : undefined
        }
        label="Date range"
        placeholder={placeholder}
        withMargin={false}
        asButton
        onClick={toggleOpen}
        startAdornment={<DateRangeIcon />}
        endAdornment={
          hasValue ? (
            <IconButton size="small" edge="end" onClick={handleClear}>
              <ClearIcon />
            </IconButton>
          ) : undefined
        }
      />
      <DialogWrapper
        open={open}
        maxWidth="md"
        onClose={() => setOpen(false)}
        withForm={false}
        paperClassName={classes.dialogPaper}
        contentClassName={classes.dialogContent}
        contentPadding={false}
      >
        <div>
          <RangePicker
            open
            toggle={toggleOpen}
            onChange={handleOnChange}
            wrapperClassName={`${className} ${classes.wrapper}`}
            minDate={minDate ? moment(minDate).toDate() : undefined}
            maxDate={maxDate ? moment(maxDate).toDate() : undefined}
            initialDateRange={{
              startDate: value?.startDate ? moment(value.startDate).toDate() : undefined,
              endDate: value?.endDate ? moment(value.endDate).toDate() : undefined,
            }}
          />
        </div>
      </DialogWrapper>
    </>
  )
}
