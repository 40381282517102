import { TokenListing } from 'generated/api/app-service-proxies'

export interface TextTokensProps {
  description: string
  name: string
  value: string
  longDescription: string
  group: string
  tokenKey: string
}

export default (textTokens: TokenListing, scope: string[]): TextTokensProps[] => {
  return textTokens.tokens
    .filter((n) => {
      return (
        scope.length === 0 ||
        Boolean(n.scopes.find((reqScope) => scope.includes(reqScope)))
      )
    })
    .map((y) => {
      const val = `${textTokens.startToken}${y.tokenKey}${textTokens.endToken}`
      return {
        name: y.description,
        description: y.description,
        value: val,
        longDescription: y.longDescription,
        group: y.group,
        tokenKey: y.tokenKey,
      }
    })
}
