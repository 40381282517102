import palette from '../palette'

export default {
  root: {
    borderBottom: `0px solid ${palette.background.default}`,
    padding: '16px 40px 14px 16px',
    fontSize: '14px',
    '&:first-child': {
      borderTopLeftRadius: 10,
      borderBottomLeftRadius: 10,
    },
    '&:last-child': {
      borderTopRightRadius: 10,
      borderBottomRightRadius: 10,
    },
  },
  head: {
    fontSize: '14px',
    fontWeight: 700,
  },
}
