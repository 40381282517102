import classNames from 'classnames';
import React from 'react';
import useInputStyles from 'theme/hooks/useInputStyles';
import { v4 as uuidv4 } from 'uuid';

import { CircularProgress, InputAdornment, makeStyles, TextField, Theme } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles((theme: Theme) => ({
  option: {
    display: 'block',
    paddingTop: theme.spacing(1.2),
    paddingBottom: theme.spacing(1.2),
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    borderRadius: theme.shape.borderRadius,
  },
  loading: {
    position: 'absolute',
    right: 38,
    top: '30%',
  },
  groupLabel: {
    fontWeight: 700,
    fontSize: theme.typography.body2.fontSize,
    textTransform: 'uppercase',
    paddingTop: -8,
    paddingBottom: -8,
  },
  paper: {
    boxShadow:
      '0px 5px 4px -3px rgb(0 0 0 / 8%), 0px 8px 10px 1px rgb(0 0 0 / 0%), 0px 3px 14px 2px rgb(0 0 0 / 9%)',
  },
}))

interface Props {
  options: any
  onChange?: any
  name?: string
  disableClearable?: boolean
  value?: any
  withMargin?: boolean
  required?: boolean
  disabled?: boolean
  loading?: boolean
  error?: boolean
  label?: string
  helperText?: any
  loadingText?: string
  noOptionsText?: string
  placeholder?: string
  filterOptions?: any
  getOptionLabel?: any
  freeSolo?: boolean
  autoFocus?: boolean
  limitTags?: number
  containerClassName?: string
  multiple?: boolean
  popperClassName?: string
  renderOption?: any
  getOptionSelected?: (option: any, value: any) => boolean
  includeInputInList?: boolean
  invisibleInput?: boolean
  startAdornment?: JSX.Element
  small?: boolean
  groupBy?: any
  innerLoading?: boolean
  clearOnBlur?: boolean
  disableCloseOnSelect?: boolean
  onInputChange?: (event: any, newInputValue: string) => void
  onClick?: () => void
  openOnFocus?: boolean
  getOptionDisabled?: (option: any) => boolean
  renderTags?: any
}

const TicketRevSelect = ({
  options,
  value,
  name,
  onChange,
  withMargin = true,
  required,
  error,
  disableClearable = true,
  label,
  loading,
  disabled,
  helperText,
  loadingText = 'Fetching your data...',
  noOptionsText = 'No options',
  multiple,
  getOptionDisabled,
  placeholder = '',
  filterOptions,
  getOptionLabel,
  limitTags,
  getOptionSelected,
  freeSolo,
  invisibleInput,
  startAdornment,
  containerClassName,
  small,
  autoFocus,
  groupBy,
  disableCloseOnSelect,
  innerLoading,
  clearOnBlur,
  includeInputInList,
  onInputChange,
  popperClassName,
  onClick,
  renderTags,
  openOnFocus = true,
  ...props
}: Props) => {
  const classes = useStyles()
  const inputStyles = useInputStyles()

  const handleOnChange = (
    event: React.ChangeEvent<Record<string, unknown>>,
    val: any
  ) => {
    onChange(name, val, event)
  }

  const rootClassName = classNames({
    [inputStyles.smallRoot]: small,
    [inputStyles.root]: !small,
    [inputStyles.startAdornmentInput]: startAdornment,
  })

  const container = classNames(
    [inputStyles.containerRoot],
    {
      [inputStyles.withMargin]: withMargin,
    },
    containerClassName
  )

  return (
    <div className={withMargin ? inputStyles.withMargin : ''}>
      <Autocomplete
        {...props}
        size={small ? 'small' : 'medium'}
        value={value}
        options={options}
        includeInputInList={includeInputInList}
        multiple={multiple}
        loading={innerLoading || loading}
        filterOptions={filterOptions}
        ChipProps={{
          size: 'small',
        }}
        getLimitTagsText={(more: number) =>
          limitTags === 0 ? `${more} selected` : `+${more}`
        }
        onClick={onClick}
        getOptionDisabled={getOptionDisabled}
        openOnFocus={openOnFocus}
        limitTags={limitTags}
        clearOnBlur={clearOnBlur}
        groupBy={groupBy}
        disabled={disabled || loading}
        onInputChange={onInputChange}
        getOptionSelected={getOptionSelected}
        freeSolo={freeSolo}
        disableCloseOnSelect={disableCloseOnSelect}
        renderTags={renderTags}
        disableClearable={disableClearable}
        onChange={handleOnChange}
        classes={{
          option: classes.option,
          groupLabel: classes.groupLabel,
          popper: popperClassName,
          paper: classes.paper,
        }}
        loadingText={loadingText}
        closeText="Close"
        getOptionLabel={getOptionLabel}
        noOptionsText={noOptionsText}
        renderInput={(params) => (
          <div className={container}>
            {/* can't use startAdornment on TextField since a lot of stuff is happening with params.InputProps.startAdornment. Ugly hack for now */}
            {startAdornment && (
              <InputAdornment position="start" className={inputStyles.startAdornment}>
                {startAdornment}
              </InputAdornment>
            )}
            <TextField
              {...params}
              fullWidth
              error={error}
              autoFocus={autoFocus}
              name={name}
              required={required}
              id={uuidv4()}
              placeholder={placeholder}
              label={label}
              helperText={helperText}
              variant="filled"
              InputLabelProps={{
                shrink: invisibleInput || small ? true : undefined,
                classes: {
                  root: startAdornment
                    ? inputStyles.startAdornmentLabel
                    : (Boolean(invisibleInput || small) && inputStyles.invisibleLabel) ||
                      '',
                },
              }}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
                autoComplete: 'new-password', // disable autocomplete and autofill
                classes: {
                  root: rootClassName,
                  focused: inputStyles.focused,
                  error: inputStyles.error,
                  disabled: inputStyles.disabled,
                  input: small ? inputStyles.smallInputElement : '',
                },
              }}
            />
            {(loading || innerLoading) && (
              <CircularProgress size={20} className={classes.loading} />
            )}
          </div>
        )}
      />
    </div>
  )
}

export default TicketRevSelect
