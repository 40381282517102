import React from 'react'
import classNames from 'classnames'
import { makeStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    flexGrow: 1,
  },
  hasHeader: {
    paddingTop: 0,
  },
  noPadding: {
    padding: 0,
  },
}))

interface Props {
  className?: string
  children: any
  noPadding?: boolean
  hasHeader?: boolean
  style?: React.CSSProperties
}

const PaperContent = (props: Props) => {
  const classes = useStyles()
  const { className, children, hasHeader, noPadding, ...rest } = props

  const rootClassName = classNames(
    {
      [classes.root]: true,
      [classes.noPadding]: noPadding,
      [classes.hasHeader]: hasHeader,
    },
    className
  )

  return (
    <div {...rest} className={rootClassName}>
      {children}
    </div>
  )
}

export default PaperContent
