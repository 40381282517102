import { useEffect, useState } from 'react'
import {
  makeStyles,
  Typography,
  Theme,
  ListItem,
  ListItemText,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core'
import {
  IAudienceMemberListRequest,
  SmsPricingCalculation,
  SmsSendCalculationRequest,
} from 'generated/api/app-service-proxies'
import { ExpandMoreRounded as ExpandMoreIcon } from '@material-ui/icons'
import api from 'services/serviceResolver'
import { PaperLoader } from 'components'
import useDebounce from 'utils/useDebounce'

const useStyles = makeStyles((theme: Theme) => ({
  container: {},
  alert: {
    cursor: 'pointer',
  },
  totalLine: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
  },
  table: {
    width: '100%',
    marginTop: theme.spacing(0.5),
  },
  tableRow: {
    backgroundColor: 'inherit',
    height: 'inherit',
    borderBottomWidth: '2px',
  },
  tableCell: {
    padding: '5px 24px 5px 16px',
  },
  valueTableCell: {
    width: '120px',
    padding: '0 24px 0 16px',
  },
  alignRight: {
    textAlign: 'right',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  accordianRoot: {
    backgroundColor: 'transparent',
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  price: {
    marginTop: theme.spacing(2),
    fontSize: theme.typography.pxToRem(48),
    color: theme.palette.text.primary,
  },
  accordionSummary: {
    minHeight: 68,
  },
  accordion: {
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(2),
    border: 'none',
    '&::before': {
      height: 0,
    },
  },
  accordionDetails: {
    flexDirection: 'column',
  },
  primary: {
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.text.primary,
    display: 'block',
  },
  secondary: {
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.secondary,
    display: 'block',
  },
}))

interface Props {
  audience: IAudienceMemberListRequest
  smsBodyTemplate: string
  /** if result is saved outside component, populate */
  initialPricingResult?: SmsPricingCalculation
  /** to save outside component */
  callback?: (pricingResult: SmsPricingCalculation) => void
  /** if you just wanna show presaved result and not fetch on mount */
  fetchOnChange?: boolean
}

const SmsPricingSummary = ({
  audience,
  smsBodyTemplate,
  initialPricingResult,
  callback,
  fetchOnChange,
}: Props) => {
  const classes = useStyles()
  const [pricingResult, setPricingResult] = useState<SmsPricingCalculation | null>(
    initialPricingResult || null
  )
  const [isLoading, setIsLoading] = useState(false)
  const [debouncedUpdate] = useDebounce(smsBodyTemplate, 1500)

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true)
      try {
        const result = await api.sms.send.calculatePricing({
          audience,
          smsBodyTemplate,
        } as SmsSendCalculationRequest)
        setPricingResult(result.result)
      } catch (e) {
        setPricingResult(null)
      } finally {
        setIsLoading(false)
      }
    }
    if (
      smsBodyTemplate &&
      Boolean(fetchOnChange === undefined || fetchOnChange === true)
    ) {
      fetchData()
    } else if (pricingResult && !initialPricingResult) {
      setPricingResult(null)
    }
  }, [debouncedUpdate])

  useEffect(() => {
    if (callback && pricingResult) callback(pricingResult)
  }, [pricingResult])

  const pricingResultComponent = (
    <div>
      <ListItem>
        <ListItemText
          disableTypography
          primary={<Typography variant="body1">Send total:</Typography>}
          secondary={
            isLoading && !pricingResult ? (
              <PaperLoader compact size={24} />
            ) : (
              <Typography className={classes.price}>
                {pricingResult
                  ? `${pricingResult.totalCost.amount.toFixed(2)}
                  ${pricingResult.totalCost.currencyCode}`
                  : 'N/A'}
              </Typography>
            )
          }
        />
      </ListItem>
      <Accordion className={classes.accordion} disabled={!pricingResult}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className={classes.accordionSummary}
        >
          <Typography>Show breakdown</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <ListItem>
            <ListItemText
              disableTypography
              primary={<Typography variant="body1">Total recipients</Typography>}
              secondary={
                pricingResult && !isLoading ? (
                  <Typography variant="body2" color="textSecondary">
                    {pricingResult.totalRecipients}
                  </Typography>
                ) : (
                  <PaperLoader compact size={14} />
                )
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              disableTypography
              primary={<Typography variant="body1">Total parts</Typography>}
              secondary={
                pricingResult && !isLoading ? (
                  <Typography variant="body2" color="textSecondary">
                    {pricingResult.totalSmsUnits}
                  </Typography>
                ) : (
                  <PaperLoader compact size={14} />
                )
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              disableTypography
              primary={<Typography variant="body1">Average parts / sms</Typography>}
              secondary={
                pricingResult && !isLoading ? (
                  <Typography variant="body2" color="textSecondary">
                    {pricingResult.averageUnitsPerMessage}
                  </Typography>
                ) : (
                  <PaperLoader compact size={14} />
                )
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              disableTypography
              primary={<Typography variant="body1">Average cost / sms</Typography>}
              secondary={
                pricingResult && !isLoading ? (
                  <Typography variant="body2" color="textSecondary">
                    {pricingResult.averageCostPerMessage.amount.toFixed(2)}{' '}
                    {pricingResult.totalCost.currencyCode}
                  </Typography>
                ) : (
                  <PaperLoader compact size={14} />
                )
              }
            />
          </ListItem>
        </AccordionDetails>
      </Accordion>
    </div>
  )

  return pricingResultComponent
}

export default SmsPricingSummary
