import { PlatformChannelProps } from './types'

const platformChannels = (props?: PlatformChannelProps) =>
  ['platformChannels', props] as const
const platformChannelTypes = ['platformChannelTypes'] as const

export default {
  platformChannels,
  platformChannelTypes,
}
