import { getCookieItem } from './cookie'

export function getPartnerCode(locationSearch: string): string | undefined {
  if (locationSearch && locationSearch.search(/partner/gi)) {
    const params = new URLSearchParams(location.search)
    const partnerCode = params.get('partner')
    if (partnerCode) {
      return partnerCode
    }
  }

  const cookie = getCookieItem('seeka_partner_key')

  return cookie || undefined
}
