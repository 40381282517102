import { makeStyles, Theme, useTheme } from '@material-ui/core'
import ReactCodeInput from 'react-code-input'

const CODE_LENGTH = 4

const useStyles = makeStyles((theme: Theme) => ({
  buttonWrapper: {
    width: '100%',
  },
  loginButton: {
    height: theme.spacing(5),
    width: '100%',
  },
  numberInput: {
    marginBottom: theme.spacing(3),
  },
}))

interface Props {
  emailVerificationCode: string
  setValue: (val: string) => void
  disabled: boolean
}

const VerificationCodeInput = ({ emailVerificationCode, setValue, disabled }: Props) => {
  const theme = useTheme()
  const classes = useStyles()

  return (
    <ReactCodeInput
      type="tel"
      fields={CODE_LENGTH}
      name="emailVerificationCode"
      inputMode="numeric"
      value={emailVerificationCode}
      onChange={(value: string) => setValue(value)}
      disabled={disabled}
      autoFocus={false}
      className={classes.numberInput}
      inputStyle={{
        fontFamily: 'monospace',
        borderRadius: theme.shape.borderRadius,
        border: '1px solid',
        borderColor: theme.palette.divider,
        padding: 0,
        width: `${100 / CODE_LENGTH - 5}%`,
        height: '65px',
        fontSize: '32px',
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.common.white,
        textAlign: 'center',
        outline: 0,
        margin: `2.5%`,
      }}
    />
  )
}

export default VerificationCodeInput
