export default {
  shopify: 'shopify',
  woocommerce: 'woocommerce',
  bigcommerce: 'bigcommerce',
  wordpress: 'wordpress',
  scriptTag: 'scriptTag',
  iframe: 'iframe',
  zapier: 'zapier',
  manychat: 'manychat',
  magento: 'magento',
  gatsby: 'gatsby',
  oztix: 'oztix',
  megatix: 'megatix',
  goHiLevel: 'goHiLevel',
  seekaApp: 'seekaApp',
}
