import React from 'react'
import { Box, makeStyles, Theme, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    border: `2px dashed ${theme.palette.border}`,
    padding: theme.spacing(1.5),
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.text.secondary,
    fontWeight: 500,
  },
}))

interface Props {
  text: string
}

const InputActionBox = ({ text }: Props) => {
  const classes = useStyles()

  return (
    <Box className={classes.box}>
      <Typography color="inherit">{text}</Typography>
    </Box>
  )
}
export default InputActionBox
