import {
  Toolbar,
  IconButton,
  makeStyles,
  Theme,
  Grid,
  Typography,
} from '@material-ui/core'
import { CloseRounded as ExitIcon } from '@material-ui/icons'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    height: '71px',
    zIndex: theme.zIndex.appBar,
    position: 'fixed',
    boxShadow: 'inset 0px -1px 0px #E6E6E6',
    top: 0,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: '100%',
  },
  toolbar: {
    minHeight: 'auto',
    width: '100%',
    alignItems: 'center',
  },
  gridContainer: {
    width: '100%',
    alignItems: 'center',
  },
  rightBox: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  middleBox: {
    display: 'flex',
    justifyContent: 'center',
  },
  leftBox: {
    display: 'flex',
  },
  logo: {
    width: 116,
    marginLeft: -theme.spacing(0.5),
    display: 'block',
  },
  sidebarButton: {
    marginLeft: -theme.spacing(),
    marginRight: theme.spacing(2),
  },
  campaignName: {
    alignItems: 'center',
    display: 'flex',
    marginLeft: theme.spacing(3),
    marginTop: -2,
    borderBottom: `2px dashed ${theme.palette.border}`,
  },
}))

type ITopbarProps = {
  handleExit: () => void
  title: string
  rightItems?: React.ReactNode
}

const Topbar = ({ handleExit, title, rightItems }: ITopbarProps) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <Grid container className={classes.gridContainer}>
          <Grid item xs={4} className={classes.leftBox}>
            <IconButton onClick={handleExit} className={classes.sidebarButton}>
              <ExitIcon color="primary" />
            </IconButton>
          </Grid>
          <Grid item xs={4} className={classes.middleBox}>
            <Typography variant="h4">{title}</Typography>
          </Grid>
          <Grid item xs={4} className={classes.rightBox}>
            {rightItems}
          </Grid>
        </Grid>
      </Toolbar>
    </div>
  )
}

export default Topbar
