import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import { InputBase, makeStyles, Theme, InputAdornment, alpha } from '@material-ui/core'
import { Search as SearchIcon } from '@material-ui/icons'
import useDebounce from 'utils/useDebounce'

const useStyles = makeStyles((theme: Theme) => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.default,
    marginRight: 'auto',
    width: '100%',
  },
  white: {
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.divider}`,
  },
  // focused: {
  //   backgroundColor: theme.palette.common.white,
  //   boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
  //   borderColor: theme.palette.primary.main,
  // },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
    height: '100%',
  },
  inputInput: {
    fontSize: theme.typography.body1.fontSize,
    padding: '10px 8px 10px 48px',
    width: '100%',
    height: '1.375em',
    '&$focused': {
      backgroundColor: theme.palette.common.white,
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
  },
}))

interface Props {
  placeholder: string
  autoFocus?: boolean
  handleChange: (searchTerm: string) => void
  debounce?: number
  white?: boolean
  endAdornment?: JSX.Element
  style?: Partial<React.CSSProperties>
  value?: string
  withDebounce?: boolean
}

const SearchBar = ({
  placeholder,
  autoFocus = false,
  handleChange,
  debounce = 0,
  white = false,
  endAdornment,
  style,
  value,
  withDebounce = true,
}: Props) => {
  const classes = useStyles()
  const [searchTerm, setSearchTerm] = useState(value || '')
  const [debouncedSearchTerm] = useDebounce(searchTerm, debounce)

  const root = classNames([classes.search], {
    [classes.white]: white,
  })

  useEffect(() => {
    if (withDebounce && value !== undefined && searchTerm !== value) setSearchTerm(value)
  }, [value])

  useEffect(() => {
    if (withDebounce && value !== searchTerm) handleChange(searchTerm)
  }, [debouncedSearchTerm])

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  return (
    <div className={root} style={{ ...style }}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        fullWidth
        value={withDebounce ? searchTerm : value}
        autoFocus={autoFocus}
        placeholder={placeholder}
        onChange={handleSearch}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
          // focused: classes.focused,
        }}
        inputProps={{ 'aria-label': placeholder }}
        endAdornment={
          endAdornment && (
            <InputAdornment position="end" style={{ marginRight: '8px' }}>
              {endAdornment}
            </InputAdornment>
          )
        }
      />
    </div>
  )
}

export default SearchBar
