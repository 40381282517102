import { useState, useEffect, useRef } from 'react'
import {
  LoyaltyRounded as BrandIcon,
  AddRounded as AddIcon,
  KeyboardBackspaceRounded as BackIcon,
} from '@material-ui/icons'
import { useInfiniteBrands } from 'hooks'
import { matchSorter } from 'match-sorter'
import { DropdownDivider, TicketRevListItem, SearchBar, Alert } from 'components'
import { useIntersectionObserver } from 'utils'
import {
  makeStyles,
  Theme,
  Typography,
  IconButton,
  Slide,
  Radio,
} from '@material-ui/core'
import { getBrandSelectDimensions } from '../../helpers'

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    marginLeft: theme.spacing(1.3),
  },
  backContainer: {
    display: 'flex',
    color: theme.palette.text.primary,
    marginLeft: 6,
    alignItems: 'center',
    marginBottom: theme.spacing(0.5),
  },
  searchContainer: {
    marginBottom: theme.spacing(),
  },
  brandsContainer: {
    maxHeight: 398,
    overflow: 'auto',
  },
}))

interface Props {
  toggleView: (view: 'switch-brand' | 'switch-organisation' | 'show-menu') => void
  goToPage: (page: string) => void
  brandId: string
  handleSelectBrand: ((brandId: string, brandName: string) => void) | null
  handleSetDimensions: (newDimensions: {
    paperWidth: number
    paperHeight: number
  }) => void
}

const ViewSwitchBrand = ({
  toggleView,
  goToPage,
  brandId,
  handleSetDimensions,
  handleSelectBrand,
}: Props) => {
  const classes = useStyles()
  const [searchString, setSearchString] = useState('')

  const { data, error, isFetchingNextPage, isFetching, fetchNextPage, hasNextPage } =
    useInfiniteBrands({
      searchString,
      pageSize: 50,
    })
  const flattenedBrands = data?.pages.map((x) => x.result).flat() || []

  const loadMoreContainerRef = useRef<HTMLDivElement>(null)

  useIntersectionObserver({
    target: loadMoreContainerRef,
    onIntersect: fetchNextPage,
    enabled: Boolean(hasNextPage),
  })

  const searchedBrands = matchSorter(flattenedBrands, searchString, {
    keys: ['description'],
  })

  useEffect(() => {
    handleSetDimensions(
      getBrandSelectDimensions(flattenedBrands.length > 0 ? flattenedBrands.length : 3)
    )
  }, [])

  useEffect(() => {
    if (data?.pages && data.pages.length > 0) {
      handleSetDimensions(
        getBrandSelectDimensions(
          data.pages[0].result.length > 8 ? 8 : data.pages[0].result.length
        )
      )
    }
  }, [data])

  const handleSearchChange = (newValue: string) => setSearchString(newValue)

  return (
    <Slide in direction="left" timeout={{ appear: 350, enter: 200, exit: 0 }}>
      <div>
        <div className={classes.backContainer}>
          <IconButton color="inherit" onClick={() => toggleView('show-menu')}>
            <BackIcon color="inherit" />
          </IconButton>
          <Typography variant="h4" className={classes.title}>
            <strong>Select brand</strong>
          </Typography>
        </div>
        <div className={classes.searchContainer}>
          <SearchBar
            placeholder="Search"
            handleChange={handleSearchChange}
            autoFocus
            value={searchString}
          />
        </div>
        <div className={classes.brandsContainer}>
          {isFetching && flattenedBrands.length === 0 && (
            <>
              <TicketRevListItem size="small" listItemSize="smallListItem" skeleton />
              <TicketRevListItem size="small" listItemSize="smallListItem" skeleton />
              <TicketRevListItem size="small" listItemSize="smallListItem" skeleton />
            </>
          )}
          {error && (
            <Alert
              severity="error"
              message={error?.error?.message || 'An error occurred'}
            />
          )}
          {searchedBrands.map((x) => {
            return (
              <TicketRevListItem
                key={x.id}
                primaryText={x.description}
                icon={<BrandIcon />}
                onClick={
                  handleSelectBrand
                    ? () => handleSelectBrand(x.id, x.description)
                    : undefined
                }
                size="small"
                listItemSize="smallListItem"
                noWrap
                disabled={brandId === x.id}
                secondaryAction={<Radio checked={brandId === x.id} color="primary" />}
              />
            )
          })}
          {isFetchingNextPage && (
            <TicketRevListItem size="small" listItemSize="smallListItem" skeleton />
          )}
          <div ref={loadMoreContainerRef} style={{ width: '100%', height: 1 }} />
        </div>
        <DropdownDivider />
        <TicketRevListItem
          primaryText="Create brand"
          icon={<AddIcon />}
          onClick={() => goToPage('settings/brand/brand-settings')}
          size="small"
          listItemSize="smallListItem"
        />
      </div>
    </Slide>
  )
}

export default ViewSwitchBrand
