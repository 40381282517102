import { StepTypeKey } from '../../helpers'

export default {
  outboundContent: [
    {
      label: 'Send SMS',
      description: 'Send an SMS',
      category: 'Outbound content',
      type: StepTypeKey.SMS,
      disabled: false,
    },
    {
      label: 'Send Email',
      description: 'Send an email',
      category: 'Outbound content',
      type: StepTypeKey.EMAIL,
      disabled: false,
    },
    {
      label: 'Send Wallet Push',
      description: 'Send a digital wallet push notification',
      category: 'Outbound content',
      type: StepTypeKey.DIGITALWALLET,
      disabled: false,
    },
    {
      label: 'Send Message',
      description: 'Send a Messenger message',
      category: 'Outbound content',
      type: StepTypeKey.MESSENGER,
      disabled: true,
    },
  ],
  actions: [
    {
      label: 'Update audience member',
      description: 'Update profile, tags, etc',
      category: 'Action',
      type: StepTypeKey.UPDATEUSER,
      disabled: false,
    },
    {
      label: 'Integrations',
      category: 'Action',
      description: 'Do somemething in an integration',
      type: StepTypeKey.INTEGRATION,
      disabled: false,
    },
    {
      label: 'Trigger webhook',
      category: 'Action',
      description: 'Launch a webhook to an external service',
      type: StepTypeKey.WEBHOOK,
      disabled: false,
    },
  ],
  logic: [
    {
      label: 'Wait',
      description: 'Wait for a period of time or to a specific date',
      category: 'Logic',
      type: StepTypeKey.WAIT,
      disabled: false,
    },
    {
      label: 'Conditional split',
      category: 'Logic',
      description: 'Continue the flow in different paths based on a condition',
      type: StepTypeKey.CONDITIONALSPLIT,
      disabled: false,
    },
  ],
  internalHelpers: [
    {
      label: 'Insert stage',
      description:
        'Wrap all consecutive flow steps in a stage for a better overview. Only for internal use',
      type: StepTypeKey.STAGE,
      category: 'Internal helpers',
      disabled: false,
    },
    {
      label: 'Add goal',
      description:
        'Create some conditions for your goal to be fulfilled and we will keep track of it',
      type: StepTypeKey.GOAL,
      category: 'Internal helpers',
      disabled: false,
    },
    {
      label: 'Leave a note',
      description: 'Leave an internal note',
      type: StepTypeKey.NOTE,
      category: 'Internal helpers',
      disabled: false,
    },
  ],
}
