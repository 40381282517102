import { makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) => ({
  // gradient: {}, coming soon
  primary: {
    background: theme.palette.primary.light,
    color: theme.palette.primary.main,
    transition: theme.transitions.create(['opacity'], {
      duration: theme.transitions.duration.shorter,
    }),
    border: 'none',
    '&:hover': {
      background: theme.palette.primary.light,
      opacity: '0.7',
    },
    '&:disabled': {
      border: 'none',
      cursor: 'default',
      pointerEvents: 'none',
    },
  },
  warning: {
    background: theme.palette.warning.light,
    color: theme.palette.warning.main,
    transition: theme.transitions.create(['opacity'], {
      duration: theme.transitions.duration.shorter,
    }),
    border: 'none',
    '&:hover': {
      background: theme.palette.warning.light,
      opacity: '0.7',
    },
    '&:disabled': {
      border: 'none',
      cursor: 'default',
      pointerEvents: 'none',
    },
  },
  error: {
    background: theme.palette.error.light,
    color: theme.palette.error.main,
    transition: theme.transitions.create(['opacity'], {
      duration: theme.transitions.duration.shorter,
    }),
    border: 'none',
    '&:hover': {
      background: theme.palette.error.light,
      opacity: '0.7',
    },
    '&:disabled': {
      border: 'none',
      cursor: 'default',
      pointerEvents: 'none',
    },
  },
  success: {
    background: theme.palette.success.light,
    color: theme.palette.success.main,
    transition: theme.transitions.create(['opacity'], {
      duration: theme.transitions.duration.shorter,
    }),
    border: 'none',
    '&:hover': {
      background: theme.palette.success.light,
      opacity: '0.7',
    },
    '&:disabled': {
      border: 'none',
      cursor: 'default',
      pointerEvents: 'none',
    },
  },
  neutral: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.default,
    transition: theme.transitions.create(['opacity', 'background-color'], {
      duration: theme.transitions.duration.shorter,
    }),
    border: 'none',
    '&:hover': {
      background: theme.palette.primary.light,
      opacity: '0.7',
    },
    '&:disabled': {
      border: 'none',
      cursor: 'default',
      pointerEvents: 'none',
    },
  },
}))
