import {
  DigitalWalletStylingType,
  DigitalWalletFieldPlacement,
  DigitalWalletField,
  DigitalWalletInstance,
} from 'generated/api/app-service-proxies'
import { Theme } from '@material-ui/core'
import { v4 as uuidv4 } from 'uuid'

export interface SelectProps {
  label: string
  value: DigitalWalletStylingType
}

export const typeOptions = Object.entries(DigitalWalletStylingType)
  .map(([key, value]) => {
    return {
      label: key,
      value,
    }
  })
  .filter((x) => x.value !== 'undefined') as SelectProps[]

export interface DialogProps {
  open: boolean
  purpose: string
}

export const initialDialog: DialogProps = {
  open: false,
  purpose: '',
}

export const fieldMap = (stylingType: DigitalWalletStylingType) => {
  switch (stylingType) {
    case DigitalWalletStylingType.EventTicket:
      return [
        DigitalWalletFieldPlacement.Header,
        DigitalWalletFieldPlacement.Secondary,
        // DigitalWalletFieldPlacement.Auxiliary,
        DigitalWalletFieldPlacement.Back,
        DigitalWalletFieldPlacement.Back,
      ]
    case DigitalWalletStylingType.StoreCard:
      return [
        DigitalWalletFieldPlacement.Header,
        DigitalWalletFieldPlacement.Secondary,
        // DigitalWalletFieldPlacement.Auxiliary,
        DigitalWalletFieldPlacement.Back,
        DigitalWalletFieldPlacement.Back,
      ]
    default:
      return []
  }
}

export interface FieldProps {
  active: boolean
  placement: string
  description: string
  value: string
  id: string
}

export const formatFields = (fields?: DigitalWalletField[]): FieldProps[] => {
  return (
    fields?.map((x) => {
      return {
        active: true,
        placement: x.placement,
        description: x.description,
        value: x.value,
        id: uuidv4(),
      }
    }) || []
  )
}

export const createFields = (stylingType: DigitalWalletStylingType): FieldProps[] => {
  return fieldMap(stylingType).map((x) => {
    return {
      active: true,
      placement: x,
      description: '',
      value: '',
      id: uuidv4(),
    }
  })
}

export const createField = (
  placement: string,
  description = '',
  value = '',
  active = true
): FieldProps => {
  return {
    active,
    placement,
    description,
    value,
    id: uuidv4(),
  }
}

export const getValidFields = (fields: FieldProps[]) => {
  return fields.filter((x) => x.description.length > 2 && x.value.length > 2 && x.active)
}

export const getInitialValues = (
  theme: Theme,
  walletInstance?: DigitalWalletInstance
) => {
  return {
    description: walletInstance?.description ?? '',
    organisationName: walletInstance?.applePassKitMetadata.organisationName ?? '',
    stylingType: walletInstance
      ? typeOptions.find(
          (x) => x.value === walletInstance?.applePassKitMetadata.styling.stylingType
        ) || null
      : null,
    passBackgroundColor:
      walletInstance?.applePassKitMetadata.styling.passBackgroundColor ||
      theme.palette.brand.wallet.backgroundColor,
    passForegroundColor:
      walletInstance?.applePassKitMetadata.styling.passForegroundColor ||
      theme.palette.warning.main,
    passLabelColor:
      walletInstance?.applePassKitMetadata.styling.passLabelColor ||
      theme.palette.common.white,
    logoText: createField(
      'logoText',
      walletInstance?.applePassKitMetadata.styling.logoText || '',
      '',
      Boolean(walletInstance?.applePassKitMetadata.styling.logoText)
    ),
    icon1xImageUrl: walletInstance?.applePassKitMetadata.styling.icon1xImageUrl || '',
    icon2xImageUrl: walletInstance?.applePassKitMetadata.styling.icon2xImageUrl || '',
    icon3xImageUrl: walletInstance?.applePassKitMetadata.styling.icon3xImageUrl || '',
    logo1xImageUrl: walletInstance?.applePassKitMetadata.styling.logo1xImageUrl || '',
    logo2xImageUrl: walletInstance?.applePassKitMetadata.styling.logo2xImageUrl || '',
    logo3xImageUrl: walletInstance?.applePassKitMetadata.styling.logo3xImageUrl || '',
    strip1xImageUrl: walletInstance?.applePassKitMetadata.styling.strip1xImageUrl || '',
    strip2xImageUrl: walletInstance?.applePassKitMetadata.styling.strip2xImageUrl || '',
    strip3xImageUrl: walletInstance?.applePassKitMetadata.styling.strip3xImageUrl || '',
    email: walletInstance?.applePassKitMetadata.email ?? {
      walletPassInstallEmailTemplateId: '',
    },
    sms: walletInstance?.applePassKitMetadata.sms ?? {
      senderName: '',
    },
    fields: walletInstance?.applePassKitMetadata.styling.stylingType
      ? createFields(walletInstance?.applePassKitMetadata.styling.stylingType).map(
          (x) => {
            const existsAlready =
              walletInstance?.applePassKitMetadata.styling.fields.find(
                (y) => y.placement === x.placement
              )
            return {
              active: Boolean(
                existsAlready || x.placement === DigitalWalletFieldPlacement.Back
              ),
              placement: x.placement,
              description: existsAlready?.description ?? x.description,
              value: existsAlready?.value ?? x.value,
              id: x.id,
            }
          }
        )
      : [],
  }
}
