import { InputAdornment } from '@material-ui/core'
import { DateTimePicker } from '@material-ui/pickers'
import { EventRounded as CalendarIcon } from '@material-ui/icons'
import useInputStyles from 'theme/hooks/useInputStyles'

interface Props {
  withMargin?: boolean
  name?: string
  label: string
  helperText?: string
  required?: boolean
  disabled?: boolean
  loading?: boolean
  onChange: any
  value?: any
  error?: boolean
}

const TicketRevDateTimePicker = ({
  withMargin = true,
  loading,
  label,
  helperText,
  onChange,
  value,
  disabled,
  name,
  required,
  error,
}: Props) => {
  const classes = useInputStyles()

  return (
    <div className={withMargin ? classes.withMargin : ''}>
      <DateTimePicker
        autoOk
        value={value}
        onChange={(date: any) => onChange(name, date)}
        fullWidth
        label={label}
        error={error}
        disabled={disabled || loading}
        required={required}
        helperText={helperText}
        name={name}
        InputProps={{
          classes: {
            root: classes.root,
            focused: classes.focused,
            disabled: classes.disabled,
          },
          disableUnderline: true,
          endAdornment: (
            <InputAdornment position="end">
              <CalendarIcon color="secondary" />
            </InputAdornment>
          ),
        }}
        inputVariant="filled"
      />
    </div>
  )
}

export default TicketRevDateTimePicker
