import { useState } from 'react'
import { DialogWrapper, GradientButton, TicketRevTextField } from 'components'
import { makeStyles, Theme, Tooltip, IconButton, Box } from '@material-ui/core'
import { LinkRounded as LinkIcon } from '@material-ui/icons'
import { useSnackbar } from 'notistack'
import api from 'services/serviceResolver'
import { useFormik } from 'formik'
import { SmsTemplateCreateOrUpdateRequest } from 'generated/api/app-service-proxies'
import { transformApiErrors } from 'utils'

interface Props {
  organisationBrandId: string
  readOnly: boolean
  handleBlur: () => void
}

const useStyles = makeStyles((theme: Theme) => ({
  textField: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  button: {
    paddingBottom: theme.spacing(2),
  },
}))

const TrackingLinkDialog = ({ organisationBrandId, readOnly, handleBlur }: Props) => {
  const [open, setOpen] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()

  const { setFieldValue, handleSubmit, values, isSubmitting, setErrors, errors } =
    useFormik({
      initialValues: {
        description: '',
      },
      validateOnBlur: false,
      validateOnChange: false,
      onSubmit: async (payload) => {
        const templateBody = SmsTemplateCreateOrUpdateRequest.fromJS({
          description: payload.description,
          organisationBrandId,
        })
        try {
          await api.sms.template.create(templateBody)
          enqueueSnackbar('Template saved!', { variant: 'success' })
        } catch (response) {
          const { message, validation } = response.error || response || {}
          enqueueSnackbar(`${message || 'An error occurred'}`, { variant: 'error' })
          if (validation && validation.properties) {
            setErrors(transformApiErrors(validation.properties))
          }
        }
      },
    })

  const removeErrorOnChange = (name: string) => {
    // eslint-disable-next-line
    if (errors.hasOwnProperty(name)) {
      setErrors({ ...errors, [name]: undefined })
    }
  }

  const setFieldValueWrapper = (name: string, value: any) => {
    setFieldValue(name, value)
    removeErrorOnChange(name)
  }

  const handleToggleOpen = () => {
    handleBlur()
    setOpen(!open)
  }
  const handleContainerClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation()
  }

  const handleLaunchLinkSnackbar = () => {
    enqueueSnackbar(
      'Type in your link and click it to turn it into a trackable shortlink',
      {
        variant: 'info',
      }
    )
  }

  return (
    <>
      <Tooltip
        title={readOnly ? '' : 'Insert trackable shortlink'}
        placement="bottom"
        arrow
      >
        <IconButton size="small" disabled={readOnly} onClick={handleLaunchLinkSnackbar}>
          <LinkIcon />
        </IconButton>
      </Tooltip>
      {open && (
        <Box onClick={handleContainerClick}>
          <DialogWrapper
            open={open}
            onClose={handleToggleOpen}
            withForm={false}
            title="Insert trackable shortlink"
            description="Type in the URL and use a shortlink domain. This enables us to track traffic and show you valuable insights."
          >
            <form onSubmit={handleSubmit}>
              <div className={classes.textField}>
                <TicketRevTextField
                  onChange={setFieldValueWrapper}
                  disabled={isSubmitting}
                  value={values.description}
                  label="New template name"
                  placeholder=""
                  name="description"
                  required
                  helperText={errors.description}
                  error={Boolean(errors.description)}
                />
              </div>
              <div className={classes.button}>
                <GradientButton
                  backText="Cancel"
                  back={handleToggleOpen}
                  loading={isSubmitting}
                >
                  Insert
                </GradientButton>
              </div>
            </form>
          </DialogWrapper>
        </Box>
      )}
    </>
  )
}

export default TrackingLinkDialog
