import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import { PaperLoader } from 'components'
import api from 'services/serviceResolver'
import { sampleHtml, sampleCss } from './sampleEmail'

const CDN_PATH = 'https://plugins.stripo.email/static/latest/stripo.js'

// TODO declare global Stripo
const anyWindow: any = window

const initializeStripoPlugin = () => {
  anyWindow.Stripo.init({
    settingsId: 'stripoSettingsContainer',
    previewId: 'stripoPreviewContainer',
    // codeEditorButtonId: 'codeEditor',
    // undoButtonId: 'undoButton',
    // redoButtonId: 'redoButton',
    // locale: 'en',
    html: sampleHtml,
    css: sampleCss,
    // userFullName: 'Test name here',
    /* notifications: {
      // replace with snackbars { info: message => console.log(message), error: message => console.error(message), success: message => console.log(message), warn: message => console.log(message), loader: message => console.log(message), hide: message => console.log(message) }
      info: notifications.info.bind(notifications),
      error: notifications.error.bind(notifications),
      warn: notifications.warn.bind(notifications),
      loader: notifications.loader.bind(notifications),
      hide: notifications.hide.bind(notifications),
      success: notifications.success.bind(notifications)
    }, */
    // consider using "draft" functionality and control save // https://stripo.email/plugin-api/
    apiRequestData: {
      emailId: 45,
    },
    getAuthToken: async (callback: (token: string) => void) => {
      const response = await api.emailTemplate.getEditorToken()
      callback(response.result)
    },
  })
}

const loadStripoScript = (callback: () => void) => {
  const script = document.createElement('script')
  script.id = 'stripoScript'
  script.type = 'text/javascript'
  script.src = CDN_PATH
  // eslint-disable-next-line
  script.onload = () => callback(),
    document.body.appendChild(script)
}

const StripoEmailEditor = (/* { someProp }: Props */) => {
  const [loading, setLoading] = useState(true)
  const stopStripo = () => anyWindow.StripoApi?.stop()

  useEffect(() => {
    if (anyWindow.Stripo) {
      // prolly with settings params from parent
      initializeStripoPlugin()
      setLoading(false)
    } else {
      loadStripoScript(() => {
        // prolly with settings params from parent
        initializeStripoPlugin()

        setLoading(false)
      })
    }
    return () => stopStripo()
  }, [])

  // consider https://github.com/kevde/stripo.email/blob/master/src/index.js

  return (
    <>
      {/* no rerenders, so doesnt work. Consider initializing in try catch */}
      {loading && <PaperLoader />}
      <Grid container>
        <Grid item xs={12} sm={4} id="stripoSettingsContainer" />
        <Grid item xs={12} sm={8} id="stripoPreviewContainer" />
      </Grid>
    </>
  )
}

export default StripoEmailEditor
