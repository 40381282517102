import React from 'react'
import classNames from 'classnames'
import { makeStyles, Theme, Menu, MenuProps, PopoverOrigin } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  menuPaper: {
    minWidth: 200,
    maxWidth: '90%',
    paddingRight: theme.spacing(),
    paddingLeft: theme.spacing(),
    boxShadow:
      '0px 5px 4px -3px rgb(0 0 0 / 8%), 0px 8px 10px 1px rgb(0 0 0 / 0%), 0px 3px 14px 2px rgb(0 0 0 / 9%)',
  },
}))

interface Props {
  anchorEl: null | HTMLElement
  handleClose: () => void
  children: React.ReactNode
  autoFocus?: boolean
  showBelowAnchor?: boolean
  variant?: 'selectedMenu' | 'menu'
  disableAutoFocusItem?: boolean
  anchorPosition?: MenuProps['anchorPosition']
  paperClassName?: string
  anchorOrigin?: PopoverOrigin
  transformOrigin?: PopoverOrigin
}

const DropdownMenu = ({
  anchorEl,
  handleClose,
  children,
  autoFocus = true,
  showBelowAnchor,
  variant = 'selectedMenu',
  disableAutoFocusItem,
  anchorPosition,
  paperClassName,
  anchorOrigin,
  transformOrigin,
}: Props) => {
  const classes = useStyles()

  const paper = classNames([classes.menuPaper], paperClassName)

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl || anchorPosition)}
      onClose={handleClose}
      autoFocus={autoFocus}
      disableAutoFocusItem={disableAutoFocusItem}
      transitionDuration={200}
      variant={variant}
      // keepMounted
      // disablePortal
      // disableScrollLock
      anchorReference={anchorPosition ? 'anchorPosition' : 'anchorEl' || null}
      anchorPosition={anchorPosition}
      classes={{
        paper,
      }}
      getContentAnchorEl={showBelowAnchor ? null : undefined}
      anchorOrigin={
        showBelowAnchor
          ? { vertical: 'bottom', horizontal: 'center' }
          : anchorOrigin || undefined
      }
      transformOrigin={
        showBelowAnchor
          ? { vertical: 'top', horizontal: 'center' }
          : transformOrigin || undefined
      }
    >
      <span>{children}</span>
    </Menu>
  )
}

export default DropdownMenu
