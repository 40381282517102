import React from 'react'
import { makeStyles, Theme, Tooltip, Box, Typography } from '@material-ui/core'
import { AddPhotoAlternateRounded as AddPhotoIcon } from '@material-ui/icons'

const useStyles = makeStyles((theme: Theme) => ({
  addPhotoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    width: '100%',
    marginBottom: theme.spacing(2),
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.7,
    },
  },
  imageWithEditing: {
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.7,
    },
  },
  text: {
    marginLeft: theme.spacing(),
  },
}))

interface Props {
  stripUrl?: string
  logoText?: string
  addImage?: (type: string) => void
  type: string
  previewStripHeight?: number
}

const StripImage = ({
  stripUrl,
  logoText,
  addImage,
  type,
  previewStripHeight = 110,
}: Props) => {
  const classes = useStyles()

  const handleAddImage = () => {
    if (addImage) addImage(`digitalWallet.${type}.strip`)
  }

  return stripUrl ? (
    <Tooltip title={addImage ? 'Change strip image' : ''} placement="left" arrow>
      <Box
        onClick={handleAddImage}
        className={addImage ? classes.imageWithEditing : ''}
        marginBottom={2}
      >
        <img src={stripUrl} alt={logoText} style={{ width: '100%' }} />
      </Box>
    </Tooltip>
  ) : (
    (Boolean(addImage) && (
      <Tooltip title="Add strip image" placement="left" arrow>
        <Box
          className={classes.addPhotoContainer}
          onClick={handleAddImage}
          style={{ height: previewStripHeight }}
        >
          <AddPhotoIcon />
          <Typography variant="body2" className={classes.text}>
            <strong>Add strip image</strong>
          </Typography>
        </Box>
      </Tooltip>
    )) ||
      null
  )
}

export default StripImage
