export interface OrganisationsFilterProps {
  pageSize: number
  searchString?: string
}

const organisations = ['organisations'] as const

export default {
  organisations,
  organisationsPage: (filters: OrganisationsFilterProps) =>
    [...organisations, filters] as const,
}
