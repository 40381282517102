import React from 'react'

interface Props {
  height: number
}

const Filler = ({ height }: Props) => {
  return <div style={{ height, width: '100%' }} />
}

export default Filler
