const convertMonthlySessionsToHumanReadable = (
  monthlySessions: number | undefined
): string => {
  if (monthlySessions && monthlySessions !== 1) {
    return `Includes ${monthlySessions / 1000}k sessions / month`
  }
  return `Unlimited sessions`
}

export default convertMonthlySessionsToHumanReadable
