export default {
  root: {
    borderRadius: 10,
  },
  button: {
    '&:hover': {
      // backgroundColor: 'rgba(193, 160, 226, 0.21)',
    },
  },
  gutters: {
    paddingLeft: 8,
    paddingRight: 8,
  },
}
