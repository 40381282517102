import { AppBillingPlan } from 'generated/api/app-service-proxies';
import { useEffect } from 'react';
import { api } from 'services';

interface CustomPlan {
  customPricingToken: string
}

const isCustomPlan = (plan: unknown): plan is CustomPlan => {
  return (plan as CustomPlan).customPricingToken !== undefined
}

const setSubscription = async (
  selectedBrandId: string,
  plan: CustomPlan | AppBillingPlan
) => {
  const {
    result: { items: paymentMethods },
  } = await api.billing.getPaymentMethods(selectedBrandId)

  if (paymentMethods.length === 0) {
    throw new Error('No payment methods on organization')
  }

  const paymentId = paymentMethods[0].billingPlatformPaymentMethodIdentifier as string

  sessionStorage.removeItem('seeka_selected_plan')

  if (isCustomPlan(plan)) {
    await api.billing.setSubscriptionForBrandForCustomPricing(
      selectedBrandId,
      plan.customPricingToken,
      paymentId
    )
  } else {
    await api.billing.setSubscriptionForBrand(
      selectedBrandId,
      plan.pricing[0].billingPlanPricingIdentifier,
      paymentId
    )
  }
}

export default setSubscription
