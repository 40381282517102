import React, { useState, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { setDocumentTitle } from 'utils'
import { useFormik } from 'formik'
import { Alert, Paper, PaperContent, TicketRevTextField } from 'components'
import api from 'services/serviceResolver'
import {
  makeStyles,
  Theme,
  Grid,
  Typography,
  Button,
  useMediaQuery,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
} from '@material-ui/core'
import {
  ChannelUnsubscribeReason,
  UnsubscribeAudienceMemberByTokenRequest,
} from 'generated/api/app-service-proxies'
import { Skeleton } from '@material-ui/lab'

interface ValuesProps {
  reason: string
  comment: string
}

const initialValues: ValuesProps = {
  reason: '',
  comment: '',
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxWidth: 500,
    margin: 'auto',
    marginTop: theme.spacing(5),
  },
  unsubHeader: {
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(1.5),
  },
  comment: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  skeleton: {
    marginBottom: theme.spacing(2),
  },
}))

const Unsubscribe = ({ location }: RouteComponentProps) => {
  const classes = useStyles()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'), {
    noSsr: true,
  })

  const queryString = location.search
  const urlParams = new URLSearchParams(queryString)
  const tokenParam = urlParams.get('t')

  const [error, setError] = useState('')
  const [hasUnsubscribed, setHasUnsubscribed] = useState(false)
  const [unsubscribeReasons, setUnsubscribeReasons] = useState<
    ChannelUnsubscribeReason[] | null
  >(null)

  useEffect(() => {
    setDocumentTitle('Unsubscribe')
  }, [])

  useEffect(() => {
    if (tokenParam) {
      Promise.all([
        api.channels.subscription.getAllUnsubscribeReasons(),
        api.channels.subscription.checkIfUnsubscribeTokenIsConsumed(tokenParam),
      ])
        .then(([unsubReasons]) => {
          setUnsubscribeReasons(unsubReasons.result)
        })
        .catch((err) => {
          const errorObject = { ...err }
          if (errorObject.status === 422) {
            setError(
              'You have visited this page too many times. Wait a bit and click the unsubscribe link in the message we sent you.'
            )
          } else {
            setError(err.message)
          }
        })
    }
    // eslint-disable-next-line
  }, [])

  const { handleSubmit, values, setFieldValue, isSubmitting } = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues,
    onSubmit: async (payload) => {
      try {
        const body = UnsubscribeAudienceMemberByTokenRequest.fromJS({
          token: tokenParam,
          channelUnsubscribeReasonId: payload.reason,
          unsubscribeComments: payload.comment,
        })
        await api.channels.subscription.unsubscribeAudienceMemberByToken(body)
        setHasUnsubscribed(true)
      } catch (response) {
        const { message } = response.error
        setError(message)
      }
    },
  })

  let content = (
    <>
      <Skeleton variant="rect" width="100%" height={40} className={classes.skeleton} />
      <Skeleton variant="text" width="75%" className={classes.skeleton} />
      <Skeleton variant="text" width="50%" className={classes.skeleton} />
      <Skeleton variant="text" width="50%" className={classes.skeleton} />
      <Skeleton variant="text" width="50%" className={classes.skeleton} />
      <Skeleton variant="rect" width="100%" height={30} className={classes.skeleton} />
    </>
  )

  if (!tokenParam) {
    content = (
      <>
        <Alert
          message="Something is wrong with the link. Try to click the link directly in the email or SMS you received."
          severity="info"
          inputMargin
        />
        {/* <Button
          variant="contained"
          color="primary"
          disableElevation
          fullWidth
          // eslint-disable-next-line
          onClick={() => window.open('https://www.youtube.com/watch?v=dQw4w9WgXcQ', '_blank')}
        >
          Contact us
        </Button> */}
      </>
    )
  }

  if (hasUnsubscribed) {
    content = (
      <Alert
        message="You have successfully unsubscribed!"
        severity="success"
        inputMargin
      />
    )
  }

  if (error) {
    content = <Alert message={error} severity="error" />
  }

  if (unsubscribeReasons) {
    content = (
      <>
        <Typography variant="h5" className={classes.unsubHeader}>
          <strong>Please let us know why you want to unsubscribe</strong>
        </Typography>
        <form onSubmit={handleSubmit}>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="unsubscribeReason"
              name="reason"
              value={values.reason}
              onChange={(_event: React.ChangeEvent<HTMLInputElement>, value: string) =>
                setFieldValue('reason', value)
              }
            >
              {unsubscribeReasons.map((x) => {
                return (
                  <FormControlLabel
                    key={x.id}
                    value={x.id}
                    control={<Radio color="primary" />}
                    label={x.description}
                  />
                )
              })}
            </RadioGroup>
          </FormControl>
          <TicketRevTextField
            label="Leave a comment"
            value={values.comment}
            onChange={setFieldValue}
            name="comment"
            multiline
            containerClassName={classes.comment}
          />
          <Button
            variant="contained"
            color="primary"
            disableElevation
            fullWidth
            type="submit"
          >
            {isSubmitting ? <CircularProgress size={20} /> : 'Unsubscribe'}
          </Button>
        </form>
      </>
    )
  }

  return (
    <Grid container spacing={3} direction="column" className={classes.container}>
      <Paper color={isMobile ? 'transparent' : undefined}>
        <PaperContent noPadding={isMobile}>
          <Grid item xs={12}>
            <Paper>
              <PaperContent>
                <Typography variant="h1" gutterBottom>
                  Unsubscribe
                </Typography>
                {content}
              </PaperContent>
            </Paper>
          </Grid>
        </PaperContent>
      </Paper>
    </Grid>
  )
}

export default Unsubscribe
