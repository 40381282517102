import { Moment } from 'moment'
import {
  UserAlertItemSeverity,
  AnalyticsLibrary,
} from 'generated/api/app-service-proxies'

const converge = ['converge'] as const
const convergeInsights = ['convergeInsights'] as const
const insights = (
  startDate: Moment,
  endDate: Moment,
  brandId: string,
  domainId?: string
) =>
  [
    ...converge,
    ...convergeInsights,
    'insights',
    startDate,
    endDate,
    brandId,
    domainId,
  ] as const
const platformInsights = (
  startDate: Moment,
  endDate: Moment,
  brandId: string,
  platform: AnalyticsLibrary,
  domainId?: string
) =>
  [
    ...converge,
    ...convergeInsights,
    startDate,
    endDate,
    brandId,
    platform,
    domainId,
  ] as const
const defaultConvergeInstace = (brandId: string) =>
  [...converge, 'defaultConvergeInstace', brandId] as const
const getAllConvergeInstances = (brandId: string) =>
  [...converge, 'getAllConvergeInstances', brandId] as const
const sdkClientInsights = (brandId: string) =>
  [...converge, 'sdkClientInsights', brandId] as const
const singleConvergeInstance = (instanceId: string) =>
  [...converge, 'singleConvergeInstance', instanceId] as const
const allConvergeDomains = (instanceId: string) =>
  [...converge, 'allConvergeDomains', instanceId] as const
const allConvergeDomainRules = (instanceId: string) =>
  [...converge, 'allConvergeDomainRules', instanceId] as const
const listOfDataConnections = (instanceId: string) =>
  [...converge, 'listOfDataConnections', instanceId] as const
const activity = (
  startDate: Moment,
  endDate: Moment,
  brandId: string,
  severities: UserAlertItemSeverity[],
  rowsPerPage: number
) => [...converge, startDate, endDate, brandId, severities, rowsPerPage]

export default {
  converge,
  sdkClientInsights,
  insights,
  platformInsights,
  singleConvergeInstance,
  getAllConvergeInstances,
  convergeInsights,
  allConvergeDomains,
  allConvergeDomainRules,
  defaultConvergeInstace,
  activity,
  listOfDataConnections,
}
