import React, { useState } from 'react'
import { makeStyles, Button, Menu, Theme } from '@material-ui/core'
import { ArrowDropDown as ArrowDownIcon } from '@material-ui/icons'

const useStyles = makeStyles((theme: Theme) => ({
  toggle: {
    fontWeight: 400,
    fontSize: 12,
    marginRight: -4,
  },
  button: {
    fontSize: 12,
    marginLeft: theme.spacing(0.5),
  },
  icon: {
    marginLeft: -theme.spacing(0.5),
  },
}))

interface Props {
  toggleTitle: string
  children: JSX.Element | JSX.Element[]
}

const SectionToolbar = ({ toggleTitle, children }: Props) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <div className={classes.toggle}>
        <Button
          onClick={handleClick}
          className={classes.button}
          variant="text"
          size="small"
          endIcon={<ArrowDownIcon className={classes.icon} />}
        >
          {toggleTitle}
        </Button>
      </div>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        {children}
      </Menu>
    </>
  )
}

export default SectionToolbar
