import React from 'react'

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  makeStyles,
  Theme,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles((theme: Theme) => ({
  noContentPadding: {
    padding: '0px !important',
  },
  title: {
    fontWeight: 700,
    fontSize: 22,
    marginTop: theme.spacing(),
    paddingBottom: theme.spacing(),
  },
  contentPadding: {
    paddingTop: '8px !important',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))

interface Props {
  open: boolean
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg'
  children?: React.ReactNode
  title?: string | JSX.Element
  buttons?: JSX.Element
  // closeButtonText?: string
  withDivider?: boolean
  description?: React.ReactNode
  onClose: (value: string) => void
  contentPadding?: boolean
  onFormSubmit?: (e?: React.FormEvent<HTMLFormElement> | undefined) => void
  withForm?: boolean
  className?: string
  paperClassName?: string
  contentClassName?: string
  actionsClassName?: string
  rootTitleClassName?: string
  preventBackdropClickClose?: boolean
  disableEscapeKeyDown?: boolean
  contentIdentifier?: string
}

const DialogWrapper = ({
  open,
  maxWidth = 'sm',
  onClose,
  title,
  description,
  buttons,
  withDivider = false,
  contentPadding = true,
  children,
  onFormSubmit,
  withForm = true,
  className,
  paperClassName,
  contentClassName,
  actionsClassName,
  rootTitleClassName,
  preventBackdropClickClose,
  disableEscapeKeyDown,
  contentIdentifier,
}: Props) => {
  const classes = useStyles()

  const onCloseWrapper = (ev: any, reason: string) => {
    if (preventBackdropClickClose && reason === 'backdropClick') return

    onClose(ev)
  }

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth={maxWidth}
      onClose={onCloseWrapper}
      className={className}
      classes={{ paper: paperClassName }}
      disableEscapeKeyDown={disableEscapeKeyDown}
    >
      {title && (
        <DialogTitle
          disableTypography
          className={classes.title}
          classes={{
            root: rootTitleClassName,
          }}
        >
          {title}
          {preventBackdropClickClose && (
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={() => onCloseWrapper(null, '')}
            >
              <CloseIcon />
            </IconButton>
          )}
        </DialogTitle>
      )}
      {!title && preventBackdropClickClose && (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={() => onCloseWrapper(null, '')}
        >
          <CloseIcon />
        </IconButton>
      )}
      {withForm ? (
        <form onSubmit={onFormSubmit}>
          <DialogContent
            dividers={withDivider}
            className={`${
              contentPadding ? classes.contentPadding : classes.noContentPadding
            } ${contentClassName || ''}`}
          >
            {description && (
              <DialogContentText component={'div'}>{description}</DialogContentText>
            )}
            {children}
          </DialogContent>
          {buttons && (
            <DialogActions className={`${actionsClassName || ''}`}>
              {buttons}
            </DialogActions>
          )}
        </form>
      ) : (
        <>
          <DialogContent
            dividers={withDivider}
            className={`${
              contentPadding ? classes.contentPadding : classes.noContentPadding
            } ${contentClassName || ''}`}
          >
            <span id={contentIdentifier || ''}>
              {description && (
                <DialogContentText component={'div'}>{description}</DialogContentText>
              )}
              {children}
            </span>
          </DialogContent>
          {buttons && (
            <DialogActions className={`${actionsClassName || ''}`}>
              {buttons}
            </DialogActions>
          )}
        </>
      )}
    </Dialog>
  )
}

export default DialogWrapper
