import { GradientButton } from 'components'

interface Props {
  anchor: string
  loading: boolean
  onClick?: () => void
  disabled?: boolean
}

const SubmitButton = ({ anchor, onClick, loading, disabled }: Props) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
      <GradientButton
        type="submit"
        onClick={onClick || undefined}
        disabled={disabled}
        loading={loading}
      >
        {anchor}
      </GradientButton>
    </div>
  )
}

export default SubmitButton
