import config from 'config'
import ReactDOM from 'react-dom'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter } from 'react-router-dom'
import { queryClient } from 'services'
import mixpanel from 'services/mixpanel/actions'

import MomentUtils from '@date-io/moment'
import { CssBaseline, MuiThemeProvider } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'

import App from './App'
import theme from './theme'

mixpanel.connect()

const TicketRevApp = () => {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <GoogleReCaptchaProvider reCaptchaKey={config.recaptchaV3SiteKey}>
              <App />
            </GoogleReCaptchaProvider>
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </QueryClientProvider>
    </BrowserRouter>
  )
}

ReactDOM.render(<TicketRevApp />, document.getElementById('root'))
