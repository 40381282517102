import React, { useState } from 'react'
import { makeStyles, Button, Menu, Theme } from '@material-ui/core'
import { ArrowDropDown as ArrowDownIcon } from '@material-ui/icons'

const useStyles = makeStyles((theme: Theme) => ({
  toggle: {
    fontWeight: 400,
    fontSize: 13,
    paddingBottom: 2,
  },
  button: {
    fontSize: 13,
    fontWeight: 600,
    marginLeft: theme.spacing(0.5),
  },
  icon: {
    marginLeft: -theme.spacing(0.5),
  },
  loading: {
    marginRight: theme.spacing(0.5),
  },
}))

interface Props {
  toggleTitle: string
  label?: string
  menuContent: JSX.Element | JSX.Element[]
  loading?: boolean
}

const TitleToggle = ({
  toggleTitle,
  label = 'Show',
  menuContent,
  loading = false,
}: Props) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <div className={classes.toggle}>
        {label}:{' '}
        <Button
          onClick={handleClick}
          className={classes.button}
          variant="text"
          disabled={loading}
          size="small"
          endIcon={<ArrowDownIcon className={classes.icon} />} // loading ? (<CircularProgress size={18} />) : (
        >
          {toggleTitle}
        </Button>
      </div>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        {menuContent}
      </Menu>
    </>
  )
}

export default TitleToggle
