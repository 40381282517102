import mixpanel, { Dict } from 'mixpanel-browser'
import appConfig from 'config'

const isMixpanelEnabled = appConfig.mixpanelProjectToken && true

export default {
  connect: () => {
    if (isMixpanelEnabled) mixpanel.init(appConfig.mixpanelProjectToken as string)
  },
  identify: (id: string) => {
    if (isMixpanelEnabled) mixpanel.identify(id)
  },
  alias: (id: string) => {
    if (isMixpanelEnabled) mixpanel.alias(id)
  },
  track: (name: string, props: Dict) => {
    if (isMixpanelEnabled) mixpanel.track(name, props)
  },
  people: {
    set: (props: Dict) => {
      if (isMixpanelEnabled) mixpanel.people.set(props)
    },
  },
  // and so on for other methods
}

// future notes https://developer.mixpanel.com/docs/javascript
/*

mixpanel.people.set({ "Plan": "Premium" });

// identify must be called along with every instance of people.set
mixpanel.identify("13793");
// If no number is passed, the default is to increment by 1
mixpanel.people.increment("games played");

// You can also pass a number to increment
// Here we add 500 to the user's point count
mixpanel.people.increment("points earned", 500);

// Pass an object to increment multiple properties
mixpanel.people.increment({
    "dollars spent": 17,
    // Subtract by passing a negative value
    "credits remaining": -34
});
https://developer.mixpanel.com/docs/mixpanel-bigquery-export-design
*/
