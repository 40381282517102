import React, { useState } from 'react'
import { Grid, makeStyles, Theme } from '@material-ui/core'
import UserRecommendationToolbox from 'components/UserRecommendation'
import { StepProps, StepTypeKey } from '../../helpers'
import SmsForm from './SmsForm'
import WaitForm from './WaitForm'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    padding: theme.spacing(2),
    height: '100%',
  },
  section: {
    marginBottom: theme.spacing(3),
  },
  recommendations: {
    marginTop: theme.spacing(5),
  },
}))

interface Props {
  step: StepProps
  sectionKey: NonNullable<string>
  updateStep: (newStep: StepProps, sectionKey: string) => void
  closeEditingDrawer?: () => void
}

const SidebarUpdateStep = ({
  step,
  updateStep,
  sectionKey,
  closeEditingDrawer,
}: Props) => {
  const classes = useStyles()
  const [updateRecommendations, setUpdateRecommendations] = useState(true)

  const handleUpdate = (newStep: StepProps) => {
    updateStep(newStep, sectionKey)
    setUpdateRecommendations(!updateRecommendations)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleCloseEditingDrawer = () => {
    closeEditingDrawer?.()
  }

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} className={classes.section}>
        {step.type === StepTypeKey.SMS && (
          <SmsForm step={step} updateStep={handleUpdate} />
        )}
        {step.type === StepTypeKey.WAIT && (
          <WaitForm step={step} updateStep={handleUpdate} />
        )}
        <UserRecommendationToolbox
          recommendationTopicKeys={[`workflow.editor.step.${step.type}.editor`]}
          recommendationInput={step}
          recommendationDescription="step"
          updateRecommendations={updateRecommendations}
          className={classes.recommendations}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onRecommendationActionComplete={() => {}}
        />
      </Grid>
    </Grid>
  )
}
export default SidebarUpdateStep
