import SeekaLogo from 'assets/img/seeka-logo.svg'
import classNames from 'classnames'
import { Toolbar, makeStyles, Theme, Grid } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    height: 72,
    position: 'fixed',
    width: '100%',
    top: 0,
    zIndex: theme.zIndex.appBar,
    borderBottom: `1px solid ${theme.palette.border}`,
  },
  toolbar: {
    minHeight: 'auto',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  logo: {
    width: 116,
  },
  logoHover: {
    cursor: 'pointer',
  },
  grid: {
    display: 'flex',
    alignItems: 'center',
  },
}))

interface Props {
  children?: React.ReactNode
  prependToLogo?: JSX.Element
  appendToLogo?: JSX.Element
  backLink?: () => void
  showLogo?: boolean
}

const AppBar = ({
  children,
  showLogo = true,
  appendToLogo,
  prependToLogo,
  backLink,
}: Props) => {
  const classes = useStyles()

  const logoClasses = classNames([classes.logo], {
    [classes.logoHover]: Boolean(backLink),
  })

  return (
    <div className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <Grid item className={classes.grid}>
          {prependToLogo}
          {showLogo && (
            /* eslint-disable-next-line */
            <img
              src={SeekaLogo}
              alt="Seeka logo"
              className={logoClasses}
              onClick={backLink}
            />
          )}
          {appendToLogo}
        </Grid>
        {children}
      </Toolbar>
    </div>
  )
}

export default AppBar
