import React from 'react'
import { DigitalWalletFieldPlacement } from 'generated/api/app-service-proxies'
import { FieldProps } from 'views/Channels/DigitalWallet/CreateEditDialog/helpers'
import CardContainer from './components/CardContainer'
import Header from './components/Header'
import StripImage from './components/StripImage'
import SecondaryFields from './components/SecondaryFields'

interface Props {
  logoUrl?: string
  /** in "editing mode", set logoText to undefined to hide */
  logoText?: string
  showBack?: boolean
  type?: string
  passBackgroundColor?: string
  passLabelColor?: string
  passForegroundColor?: string
  stripUrl?: string
  loading?: boolean
  wrapperClassName?: string
  addImage?: (type: string) => void
  fields: FieldProps[]
}

const DigitalWalletCard = ({
  logoUrl,
  logoText,
  passBackgroundColor,
  stripUrl,
  passLabelColor,
  passForegroundColor,
  type,
  showBack,
  loading,
  wrapperClassName,
  addImage,
  fields,
}: Props) => {
  if (loading) return <CardContainer loading className={wrapperClassName} />

  const findHeaderField = fields.find(
    (x) => x.placement === DigitalWalletFieldPlacement.Header
  )
  const findSecondaryField = fields.find(
    (x) => x.placement === DigitalWalletFieldPlacement.Secondary
  )
  const findBackFields = fields.filter(
    (x) => x.placement === DigitalWalletFieldPlacement.Back
  )
  /* const findAuxiliaryField = fields.find(
    (x) => x.placement === DigitalWalletFieldPlacement.Auxiliary
  ) */

  if (type === 'storeCard') {
    return (
      <CardContainer
        backgroundColor={passBackgroundColor}
        className={wrapperClassName}
        showBack={showBack}
        backFields={findBackFields}
      >
        <Header
          logoUrl={logoUrl}
          logoText={logoText}
          value={findHeaderField?.value}
          valueDescription={findHeaderField?.description}
          fieldActive={Boolean(findHeaderField?.active)}
          color={passLabelColor}
          valueColor={passForegroundColor}
          addImage={addImage}
        />
        <StripImage
          stripUrl={stripUrl}
          logoText={logoText}
          addImage={addImage}
          type={type}
          previewStripHeight={127}
        />
        <SecondaryFields
          secondaryValue={findSecondaryField?.value}
          secondaryDescription={findSecondaryField?.description}
          color={passLabelColor}
          valueColor={passForegroundColor}
          fieldActive={Boolean(findSecondaryField?.active)}
        />
      </CardContainer>
    )
  }

  if (type === 'eventTicket') {
    return (
      <CardContainer
        backgroundColor={passBackgroundColor}
        className={wrapperClassName}
        showBack={showBack}
        backFields={findBackFields}
      >
        <Header
          logoUrl={logoUrl}
          logoText={logoText}
          value={findHeaderField?.value}
          valueDescription={findHeaderField?.description}
          fieldActive={Boolean(findHeaderField?.active)}
          color={passLabelColor}
          valueColor={passForegroundColor}
          addImage={addImage}
        />
        <StripImage
          stripUrl={stripUrl}
          logoText={logoText}
          addImage={addImage}
          type={type}
          previewStripHeight={88}
        />
        <SecondaryFields
          secondaryValue={findSecondaryField?.value}
          secondaryDescription={findSecondaryField?.description}
          color={passLabelColor}
          valueColor={passForegroundColor}
          fieldActive={Boolean(findSecondaryField?.active)}
        />
      </CardContainer>
    )
  }

  return <p>Digital wallet card type not supported</p>
}

export default DigitalWalletCard
