import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  root: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    display: 'flex',
  },
}))

interface Props {
  className?: string
  children: React.ReactNode
}

const PaperToolbar = ({ className, children, ...rest }: Props) => {
  const classes = useStyles()

  const rootClassName = classNames(
    {
      [classes.root]: true,
    },
    className
  )

  return (
    <div {...rest} className={rootClassName}>
      {children}
    </div>
  )
}

export default PaperToolbar
