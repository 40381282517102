import React, { useState, useEffect } from 'react'
import { Typography } from '@material-ui/core'
import { ColorPicker, Color } from 'material-ui-color'
import useDebounce from 'utils/useDebounce'

interface Props {
  value: string
  onChange?: (name: string, value: string) => void
  withDebounce?: boolean
  debounceInterval?: number
  name: string
  disabled?: boolean
  label?: string
}

const ColorPickerWrapper = ({
  value,
  onChange,
  withDebounce,
  debounceInterval = 50,
  name,
  disabled,
  label,
}: Props) => {
  const [innerValue, setInnerValue] = useState(value)
  const [debouncedUpdate] = useDebounce(innerValue, debounceInterval)

  const handleDebouncedOnChange = (color: Color) => {
    setInnerValue(`#${color.hex}`)
  }
  const handleOnChange = (color: Color) => {
    if (onChange) onChange(name, `#${color.hex}`)
  }

  useEffect(() => {
    if (onChange && withDebounce && value !== innerValue) onChange(name, innerValue)
    // eslint-disable-next-line
  }, [debouncedUpdate])

  return (
    <div style={{ marginBottom: 16 }}>
      {label && <Typography>{label}</Typography>}
      <ColorPicker
        value={withDebounce ? innerValue : value}
        palette={undefined}
        onChange={withDebounce ? handleDebouncedOnChange : handleOnChange}
        disableTextfield={disabled}
        hideTextfield
      />
    </div>
  )
}

export default ColorPickerWrapper
