import React from 'react'
import { makeStyles, Box, BoxProps, Avatar } from '@material-ui/core'
import { Robot } from 'mdi-material-ui'

interface Props extends BoxProps {
  children: string | JSX.Element
}

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
  },
  avatar: {
    width: 30,
    height: 30,
    border: 'none',
    marginRight: 4,
    marginTop: -4,
    color: '#704acd',
    backgroundColor: 'transparent',
  },
  chatBubble: {
    backgroundColor: '#e5edf8', // create a chatbot theme
    borderRadius: 18,
    borderTopLeftRadius: 2,
    fontSize: 11,
    padding: 8,
    color: '#262529',
    wordBreak: 'break-word',
    marginBottom: 8,
  },
}))

const ChatBubble = ({ children, ...rest }: Props) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <Avatar className={classes.avatar}>
        <Robot />
      </Avatar>
      <Box {...rest} className={classes.chatBubble}>
        {children}
      </Box>
    </div>
  )
}

export default ChatBubble
