import React, { useState, useEffect, useMemo } from 'react'
import classNames from 'classnames'
import {
  TextField,
  CircularProgress,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import useInputStyles from 'theme/hooks/useInputStyles'
import api from 'services/serviceResolver'
import { throttle } from 'lodash'
import {
  QueryPlacesByTextRequest,
  GeographicPlace,
} from 'generated/api/app-service-proxies'

const useStyles = makeStyles((theme: Theme) => ({
  option: {
    width: '100%',
    display: 'block',
    paddingTop: theme.spacing(1.2),
    paddingBottom: theme.spacing(1.2),
    '& > span:first-child': {
      marginRight: 10,
    },
    '& > span:last-child': {
      float: 'right',
      marginLeft: 10,
      marginRight: -2,
    },
  },
  container: {
    position: 'relative',
  },
  loading: {
    position: 'absolute',
    right: 38,
    top: 19,
  },
}))

interface Props {
  onChange: any
  name: string
  value: any
  withMargin?: boolean
  required?: boolean
  disabled?: boolean
  error?: boolean
  label: string
  helperText?: string
}

const TicketRevSelect = ({
  value,
  name,
  onChange,
  withMargin = true,
  required = false,
  error = false,
  label,
  disabled = false,
  helperText,
}: Props) => {
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState<GeographicPlace[]>([])
  const [loading, setLoading] = useState(false)

  const classes = useStyles()
  const inputStyles = useInputStyles()

  const root = classNames({
    [inputStyles.root]: true,
  })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
  }

  const handleOnChange = (
    _event: React.ChangeEvent<Record<string, unknown>>,
    val: any
  ) => {
    onChange(name, val)
  }

  const fetch = useMemo(
    () =>
      throttle(async (input: string, callback: any) => {
        setLoading(true)
        const body = QueryPlacesByTextRequest.fromJS({
          queryText: input,
        })
        const response = await api.general.geo.queryPlaces(body)
        callback(response.result)
        setLoading(false)
      }, 200),
    // eslint-disable-next-line
    []
  )

  useEffect(() => {
    let active = true

    if (inputValue === '') {
      setOptions([])
      return undefined
    }

    fetch(inputValue, (results?: GeographicPlace[]) => {
      if (active) {
        setOptions(results || [])
      }
    })

    return () => {
      active = false
    }
  }, [inputValue, fetch])

  return (
    <div className={withMargin ? inputStyles.withMargin : ''}>
      <Autocomplete
        value={value}
        options={options}
        loading={loading}
        disabled={disabled}
        includeInputInList
        filterOptions={(x) => x}
        // freeSolo
        openOnFocus={false}
        onChange={handleOnChange}
        classes={{
          option: classes.option,
        }}
        closeText="Close"
        getOptionLabel={(option: any) =>
          typeof option === 'string' ? option : option.description
        }
        noOptionsText={loading ? 'Looking for venues...' : ''}
        renderOption={(option: any) => {
          const splitString = option.description?.split(',')
          return (
            <>
              {splitString[0]}
              <Typography variant="body2" color="textSecondary">
                {splitString.map((x: string, index: number) => index > 0 && `${x}, `)}
              </Typography>
            </>
          )
        }}
        renderInput={(params) => (
          <div className={classes.container}>
            <TextField
              {...params}
              fullWidth
              onChange={handleChange}
              error={error}
              name={name}
              required={required}
              label={label}
              helperText={helperText}
              variant="filled"
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
              // eslint-disable-next-line
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
                autoComplete: 'new-password', // disable autocomplete and autofill
                classes: {
                  root,
                  focused: inputStyles.focused,
                  error: inputStyles.error,
                  disabled: inputStyles.disabled,
                },
              }}
            />
            {loading && <CircularProgress size={20} className={classes.loading} />}
          </div>
        )}
      />
    </div>
  )
}

export default TicketRevSelect
