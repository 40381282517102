import React, { useRef, useState } from 'react'
import classNames from 'classnames'
import {
  InputAdornment,
  TextField,
  FilledInputProps,
  makeStyles,
  Typography,
  Theme,
} from '@material-ui/core'
import { useSnackbar } from 'notistack'
import useInputStyles from 'theme/hooks/useInputStyles'
import { AttachFile as FileIcon } from '@material-ui/icons'
import Cropper from 'react-easy-crop'
import cropImage from './cropImage'

const useStyles = makeStyles((theme: Theme) => ({
  cropContainer: {
    position: 'relative',
    minHeight: 200,
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
  },
  linkContainer: {
    marginTop: theme.spacing(0.6),
    textAlign: 'right',
  },
  link: {
    cursor: 'pointer',
    paddingRight: theme.spacing(1.5),
    display: 'inline-block',
    '&:hover': {
      opacity: 0.85,
    },
  },
}))

interface Props {
  withMargin?: boolean
  className?: string
  label?: string
  name?: string
  required?: boolean
  helperText?: string
  aspectRatio: number
  setImage?: (value: string, name: string) => void
  defaultValue?: string
  disabled?: boolean
  accept?: string
  error?: boolean
  canRemove?: boolean
  reqWidth?: number
  reqHeight?: number
  cropShape?: 'rect' | 'round'
}

const readFile = (file: any): any => {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => resolve(reader.result), false)
    reader.readAsDataURL(file)
  })
}

const TicketRevFileInput = ({
  label,
  withMargin = true,
  className,
  setImage,
  required,
  aspectRatio,
  helperText,
  name = 'image',
  accept = 'image/x-png,image/jpeg',
  disabled,
  defaultValue,
  error,
  canRemove = false,
  reqWidth,
  reqHeight,
  cropShape = 'rect',
}: Props) => {
  const inputStyle = useInputStyles()
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()
  const fileInput = useRef<HTMLInputElement>(null)
  const [isCropping, setIsCropping] = useState(false)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [uploadedImage, setUploadedImage] = useState(defaultValue || '')

  const root = classNames({
    [inputStyle.root]: true,
    className,
  })

  const triggerFileInput = () => {
    return fileInput?.current?.click()
  }

  const handleCropImage = async (croppedAreaPixels: any) => {
    try {
      setIsCropping(true)
      const croppedImage = await cropImage(uploadedImage, croppedAreaPixels)
      setIsCropping(false)
      if (setImage && uploadedImage !== defaultValue) setImage(name, croppedImage)
    } catch (e) {
      setIsCropping(false)
    }
  }

  const handleRemoveImage = () => {
    setUploadedImage('')
    if (setImage) setImage(name, '')
  }

  const onCropComplete = (_croppedArea: any, croppedAreaPixels: any) => {
    if (!isCropping) {
      handleCropImage(croppedAreaPixels)
    }
  }

  /* const handleRevertToDefault = () => {
    if (defaultValue) setUploadedImage(defaultValue)
  } */

  const handleUploadImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0]
      const imageDataUrl = await readFile(file)
      if (reqWidth && reqHeight) {
        // eslint-disable-next-line
        const img = new Image()
        img.src = imageDataUrl
        img.onload = () => {
          if (img.width < reqWidth || img.height < reqHeight) {
            enqueueSnackbar(`Image must be at least ${reqWidth}x${reqHeight}`, {
              variant: 'warning',
            })
          } else {
            setUploadedImage(imageDataUrl)
          }
        }
      } else {
        setUploadedImage(imageDataUrl)
      }
    }
  }

  return (
    <div className={withMargin ? inputStyle.withMargin : ''}>
      {!uploadedImage || disabled ? (
        <TextField
          onClick={triggerFileInput}
          fullWidth
          label={label}
          required={required}
          error={error}
          helperText={helperText}
          disabled={disabled}
          InputProps={
            {
              readOnly: true,
              inputComponent: 'aside',
              endAdornment: (
                <InputAdornment position="end">
                  <FileIcon color="secondary" />
                </InputAdornment>
              ),
              classes: {
                root,
                focused: inputStyle.focused,
                error: inputStyle.error,
                disabled: inputStyle.disabled,
              },
              disableUnderline: true,
            } as Partial<FilledInputProps>
          }
          variant="filled"
        />
      ) : (
        <>
          <Typography gutterBottom>{label}</Typography>
          <div className={classes.cropContainer}>
            <Cropper
              image={uploadedImage}
              crop={crop}
              zoom={1}
              aspect={aspectRatio}
              onCropChange={setCrop}
              cropShape={cropShape}
              onCropComplete={onCropComplete}
            />
          </div>
          <div className={classes.linkContainer}>
            <Typography
              onClick={triggerFileInput}
              gutterBottom
              variant="body2"
              color="primary"
              className={classes.link}
            >
              Upload new image
            </Typography>
            {/* defaultValue && defaultValue !== uploadedImage && (
              <Typography
                onClick={handleRevertToDefault}
                gutterBottom
                variant="body2"
                color="primary"
                className={classes.link}
              >
                Revert to default
              </Typography>
            ) */}
            {canRemove && (
              <Typography
                onClick={handleRemoveImage}
                gutterBottom
                variant="body2"
                color="error"
                className={classes.link}
              >
                Remove image
              </Typography>
            )}
          </div>
          {error && (
            <Typography gutterBottom variant="body2" color="error">
              {helperText}
            </Typography>
          )}
        </>
      )}
      <input
        type="file"
        accept={accept}
        ref={fileInput}
        hidden
        onChange={handleUploadImage}
      />
    </div>
  )
}

export default TicketRevFileInput
