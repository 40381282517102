import React from 'react'
import { Grid, Tooltip } from '@material-ui/core'
import Fab from '@material-ui/core/Fab'
import { AddRounded as AddIcon } from '@material-ui/icons'

interface Props {
  icon?: JSX.Element
  size?: 'small' | 'medium'
  tooltip?: string
  justify?: 'flex-start' | 'center' | 'flex-end'
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  marginTop?: number
  className?: string
}

const FloatingButton = ({
  size = 'medium',
  icon = <AddIcon />,
  tooltip = '',
  justify = 'center',
  onClick,
  marginTop,
  className,
}: Props) => {
  return (
    <Grid container justifyContent={justify} style={{ marginTop }}>
      <Tooltip title={tooltip} aria-label={tooltip} arrow>
        <Fab
          size={size}
          color="primary"
          aria-label={tooltip}
          onClick={onClick}
          className={className}
        >
          {icon}
        </Fab>
      </Tooltip>
    </Grid>
  )
}

export default FloatingButton
