const billing = ['billing'] as const


export default {
  billingPlans: [...billing, 'plans'],
  paymentMethods: (organisationBrandId: string) => [
    ...billing,
    'payment-methods',
    organisationBrandId,
  ],
  recommendedPlan: (monthlySessions: string, offering: string, frequency: string) => [
    ...billing,
    'recommended-plan',
    monthlySessions,
    offering,
    frequency,
  ],
  billingStatus: (organisationBrandId: string) => [
    ...billing,
    'billing-status',
    organisationBrandId,
  ],
  canExtendTrial: (organisationBrandId: string) => [
    ...billing,
    'can-extend-trial',
    organisationBrandId,
  ],
  listInvoices: (
    organisationBrandId: string,
    pageSize: number,
    pageToken: string | undefined
  ) => [
    ...billing,
    'list-invoices',
    organisationBrandId,
    pageToken || '',
    pageSize.toString(),
  ],
}