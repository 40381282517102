import moment from 'moment-timezone'

// eslint-disable-next-line no-shadow
export enum StepTypeKey {
  SMS = 'sms',
  EMAIL = 'email',
  DIGITALWALLET = 'digitalWallet',
  MANYCHAT = 'manychat',
  MESSENGER = 'messenger',
  CONDITIONALSPLIT = 'conditionalSplit',
  WAIT = 'wait',
  TRIGGER = 'trigger',
  STAGE = 'stage',
  GOAL = 'goal',
  UPDATEUSER = 'updateUser',
  WEBHOOK = 'webhook',
  NOTE = 'note',
  INTEGRATION = 'integration',
}

export interface NoteProps extends StepBaseProps {
  type: StepTypeKey.NOTE
  fontSize: 'small' | 'large'
  content: string
}

export interface SmsProps extends StepBaseProps {
  type: StepTypeKey.SMS
  senderName: string
  messageBody: string
  status: 'draft' | 'live'
}

export interface DigitalWalletProps extends StepBaseProps {
  type: StepTypeKey.DIGITALWALLET
  pushNotificationText: string
  digitalWalletInstanceId: string
  isSilentPassUpdate: boolean
}

export interface WaitProps extends StepBaseProps {
  type: StepTypeKey.WAIT
  delayType: 'duration' | 'date' | 'dynamicDate'
  delayDurationMeasure: 'days' | 'hours' | 'minutes' | 'seconds'
  delayDuration?: number
  delayDate?: moment.Moment
}

export interface ManychatProps extends StepBaseProps {
  type: StepTypeKey.MANYCHAT
}

export interface StageProps extends StepBaseProps {
  type: StepTypeKey.STAGE
  title: string
  caption?: string
}

export interface GoalProps extends StepBaseProps {
  type: StepTypeKey.GOAL
}

export interface UpdateUserProps extends StepBaseProps {
  type: StepTypeKey.UPDATEUSER
}

export interface IntegrationProps extends StepBaseProps {
  type: StepTypeKey.INTEGRATION
}

export interface WebhookProps extends StepBaseProps {
  type: StepTypeKey.WEBHOOK
}

export interface EmailProps extends StepBaseProps {
  type: StepTypeKey.EMAIL
}

export interface ConditionalSplitProps extends StepBaseProps {
  type: StepTypeKey.CONDITIONALSPLIT
  passFlowId: string
  failFlowId: string
}

export interface MessengerProps extends StepBaseProps {
  type: StepTypeKey.MESSENGER
}

export type StepProps =
  | SmsProps
  | NoteProps
  | WebhookProps
  | IntegrationProps
  | UpdateUserProps
  | GoalProps
  | StageProps
  | ManychatProps
  | WaitProps
  | DigitalWalletProps
  | EmailProps
  | ConditionalSplitProps
  | MessengerProps

export type StepBaseProps = {
  label: string
  description: string
  category: string
  id: string
  acceptedNextStep: StepTypeKey[]
}

export type SharedStepProps = {
  isEditing: boolean
  sectionKey: string
  editStep: (id: string, sectionKey: string) => void
  removeStep: (id: string, sectionKey: string) => void
  updateStep: (property: string, value: string, id: string, sectionKey: string) => void
  addStep?: (type: StepTypeKey, insertAfterIndex: number, sectionKey: string) => void
}

export type UtilityProps = {
  label: string
  description: string
  category: string
  type: StepTypeKey
  disabled: boolean
}

export const allStepTypes = [
  StepTypeKey.DIGITALWALLET,
  StepTypeKey.SMS,
  StepTypeKey.EMAIL,
  StepTypeKey.WAIT,
  StepTypeKey.STAGE,
  StepTypeKey.GOAL,
  StepTypeKey.NOTE,
  StepTypeKey.CONDITIONALSPLIT,
]

export interface FlowDefinition {
  /** the starting section of the flow */
  rootSection: string
  /** sections */
  sections: { [key: string]: StepProps[] }
}

export const defaultStepCollection: StepProps[] = [
  {
    label: 'Send SMS',
    description: 'Send an SMS',
    category: 'Outbound content',
    type: StepTypeKey.SMS,
    id: '',
    senderName: '',
    messageBody: '',
    status: 'draft',
    acceptedNextStep: allStepTypes,
  },
  {
    label: 'Send Email',
    description: 'Send an email',
    category: 'Outbound content',
    type: StepTypeKey.EMAIL,
    id: '',
    acceptedNextStep: allStepTypes,
  },
  {
    label: 'Send Wallet Push',
    description: 'Send a digital wallet push notification',
    category: 'Outbound content',
    type: StepTypeKey.DIGITALWALLET,
    id: '',
    pushNotificationText: '',
    digitalWalletInstanceId: '',
    isSilentPassUpdate: false,
    acceptedNextStep: allStepTypes,
  },
  {
    label: 'Send Message',
    description: 'Send a Messenger message',
    category: 'Outbound content',
    type: StepTypeKey.MESSENGER,
    id: '',
    acceptedNextStep: allStepTypes,
  },
  {
    label: 'Update audience member',
    description: 'Update profile, tags, etc',
    category: 'Action',
    type: StepTypeKey.UPDATEUSER,
    acceptedNextStep: allStepTypes,
    id: '',
  },
  {
    label: 'Integrations',
    category: 'Action',
    description: 'Do somemething in an integration',
    type: StepTypeKey.INTEGRATION,
    acceptedNextStep: allStepTypes,
    id: '',
  },
  {
    label: 'Trigger webhook',
    category: 'Action',
    description: 'Launch a webhook to an external service',
    type: StepTypeKey.WEBHOOK,
    id: '',
    acceptedNextStep: allStepTypes,
  },
  {
    label: 'Wait',
    description: 'Wait for a period of time or to a specific date',
    category: 'Logic',
    type: StepTypeKey.WAIT,
    delayType: 'duration',
    delayDurationMeasure: 'days',
    delayDuration: 1,
    delayDate: undefined,
    id: '',
    acceptedNextStep: allStepTypes,
  },
  {
    label: 'Conditional split',
    category: 'Logic',
    description: 'Continue the flow in different paths based on a condition',
    type: StepTypeKey.CONDITIONALSPLIT,
    id: '',
    acceptedNextStep: allStepTypes,
    passFlowId: '',
    failFlowId: '',
  },
  {
    label: 'Insert stage',
    description:
      'Wrap all consecutive flow steps in a stage for a better overview. Only for internal use',
    type: StepTypeKey.STAGE,
    category: 'Internal helpers',
    title: '',
    caption: '',
    acceptedNextStep: allStepTypes,
    id: '',
  },
  {
    label: 'Add goal',
    description:
      'Create some conditions for your goal to be fulfilled and we will keep track of it',
    type: StepTypeKey.GOAL,
    category: 'Internal helpers',
    acceptedNextStep: allStepTypes,
    id: '',
  },
  {
    label: 'Leave a note',
    description: 'Leave an internal note',
    type: StepTypeKey.NOTE,
    category: 'Internal helpers',
    fontSize: 'small',
    content: '',
    acceptedNextStep: allStepTypes,
    id: '',
  },
]
