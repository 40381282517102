import appConfig from 'config';
import {
    ExternalLoginProviderServiceProxy, IdentityServiceProxy
} from 'generated/api/account-service-proxies';
import {
    AudienceMemberServiceProxy, AudienceSegmentServiceProxy, AudienceTagServiceProxy,
    BillingServiceProxy, BroadcastServiceProxy, ChannelServiceProxy,
    ChannelSubscriptionServiceProxy, ColorThemeServiceProxy, ContentLibraryServiceProxy,
    ConvergePipelineIntegrationServiceProxy, ConvergeServiceProxy,
    DigitalWalletInstanceServiceProxy, DigitalWalletPassServiceProxy, EmailTemplateServiceProxy,
    FacebookPixelServiceProxy, GeographyServiceProxy, GoogleAnalyticsStreamServiceProxy,
    InboundWebhookServiceProxy, IntelligenceDashboardServiceProxy, IntelligentUrlServiceProxy,
    InternalStaffServiceProxy, LokeIntegrationsServiceProxy, ManyChatServiceProxy,
    OrganisationBrandServiceProxy, OrganisationDomainServiceProxy, OrganisationServiceProxy,
    OutboundWebhookEndpointServiceProxy, OztixServiceProxy, PersonDataPrivacyServiceProxy, PinterestPixelServiceProxy,
    PipelineRuleContainerServiceProxy,
    ReferenceDataServiceProxy, SeekaDeveloperAppServiceProxy, ServiceConnectionServiceProxy,
    ShopifyServiceProxy, SmsSendServiceProxy, SmsTemplateServiceProxy, SnapchatPixelServiceProxy,
    StaticContentCdnServiceProxy, TikTokPixelServiceProxy, UserAlertsServiceProxy,
    UserProfileServiceProxy, UserRecommendationServiceProxy, WorkflowStepServiceProxy,
    WorkflowTemplateServiceProxy
} from 'generated/api/app-service-proxies';
import { getToken } from 'utils';

// Augment requests before they are sent to window.fetch HTTP client
const fetch: { fetch(url: RequestInfo, init?: RequestInit): Promise<Response> } = {
  async fetch(url: RequestInfo, init?: RequestInit | undefined): Promise<Response> {
    const requestInit = init ?? {}
    const newHeaders = new Headers(requestInit.headers)

    const authToken = getToken()
    if (authToken) {
      newHeaders.set('Authorization', `Bearer ${authToken}`)
    }
    newHeaders.set('Accept', `application/json`)
    requestInit.headers = newHeaders
    return window.fetch(url, init)
  },
}

const api = {
  userProfile: new UserProfileServiceProxy(appConfig.apiBaseUrl, fetch),
  billing: new BillingServiceProxy(appConfig.apiBaseUrl, fetch),
  org: {
    base: new OrganisationServiceProxy(appConfig.apiBaseUrl, fetch),
    domain: new OrganisationDomainServiceProxy(appConfig.apiBaseUrl, fetch),
    brand: new OrganisationBrandServiceProxy(appConfig.apiBaseUrl, fetch),
  },
  integrations: {
    shopify: new ShopifyServiceProxy(appConfig.apiBaseUrl, fetch),
    oztix: new OztixServiceProxy(appConfig.apiBaseUrl, fetch),
    facebook: new FacebookPixelServiceProxy(appConfig.apiBaseUrl, fetch),
    snapchat: new SnapchatPixelServiceProxy(appConfig.apiBaseUrl, fetch),
    pinterest: new PinterestPixelServiceProxy(appConfig.apiBaseUrl, fetch),
    tiktok: new TikTokPixelServiceProxy(appConfig.apiBaseUrl, fetch),
    googleAnalytics: new GoogleAnalyticsStreamServiceProxy(appConfig.apiBaseUrl, fetch),
    pipeline: new ConvergePipelineIntegrationServiceProxy(appConfig.apiBaseUrl, fetch),
    loke: new LokeIntegrationsServiceProxy(appConfig.apiBaseUrl, fetch),
    developerApps: new SeekaDeveloperAppServiceProxy(appConfig.apiBaseUrl, fetch),
  },
  internalStaff: new InternalStaffServiceProxy(appConfig.apiBaseUrl, fetch),
  broadcast: {
    base: new BroadcastServiceProxy(appConfig.apiBaseUrl, fetch),
  },
  urls: new IntelligentUrlServiceProxy(appConfig.apiBaseUrl, fetch),
  sms: {
    send: new SmsSendServiceProxy(appConfig.apiBaseUrl, fetch),
    template: new SmsTemplateServiceProxy(appConfig.apiBaseUrl, fetch),
  },
  account: new IdentityServiceProxy(appConfig.identityBaseUrl, fetch),
  loginProviders: new ExternalLoginProviderServiceProxy(appConfig.identityBaseUrl, fetch),
  general: {
    referenceData: new ReferenceDataServiceProxy(appConfig.apiBaseUrl, fetch),
    geo: new GeographyServiceProxy(appConfig.apiBaseUrl, fetch),
  },
  userRecommendation: new UserRecommendationServiceProxy(appConfig.apiBaseUrl, fetch),
  userAlerts: new UserAlertsServiceProxy(appConfig.apiBaseUrl, fetch),
  contentLibrary: new ContentLibraryServiceProxy(appConfig.apiBaseUrl, fetch),
  colorTheme: new ColorThemeServiceProxy(appConfig.apiBaseUrl, fetch),
  audience: {
    members: new AudienceMemberServiceProxy(appConfig.apiBaseUrl, fetch),
    segment: new AudienceSegmentServiceProxy(appConfig.apiBaseUrl, fetch),
    tags: new AudienceTagServiceProxy(appConfig.apiBaseUrl, fetch),
  },
  emailTemplate: new EmailTemplateServiceProxy(appConfig.apiBaseUrl, fetch),
  webhook: {
    inbound: new InboundWebhookServiceProxy(appConfig.apiBaseUrl, fetch),
    outbound: new OutboundWebhookEndpointServiceProxy(appConfig.apiBaseUrl, fetch),
  },
  channels: {
    base: new ChannelServiceProxy(appConfig.apiBaseUrl, fetch),
    serviceConnection: new ServiceConnectionServiceProxy(appConfig.apiBaseUrl, fetch),
    manychat: new ManyChatServiceProxy(appConfig.apiBaseUrl, fetch),
    subscription: new ChannelSubscriptionServiceProxy(appConfig.apiBaseUrl, fetch),
  },
  wallet: {
    instance: new DigitalWalletInstanceServiceProxy(appConfig.apiBaseUrl, fetch),
    pass: new DigitalWalletPassServiceProxy(appConfig.apiBaseUrl, fetch),
  },
  workflow: {
    template: new WorkflowTemplateServiceProxy(appConfig.apiBaseUrl, fetch),
    step: new WorkflowStepServiceProxy(appConfig.apiBaseUrl, fetch),
  },  
  privacy: {
    person: new PersonDataPrivacyServiceProxy(appConfig.apiBaseUrl, fetch),
  },
  pipeline: {
    rules: {
      containers: new PipelineRuleContainerServiceProxy(appConfig.apiBaseUrl, fetch)
    }
  },
  converge: new ConvergeServiceProxy(appConfig.apiBaseUrl, fetch),
  intelligence: new IntelligenceDashboardServiceProxy(appConfig.apiBaseUrl, fetch),
  cdn: new StaticContentCdnServiceProxy(appConfig.apiBaseUrl, fetch),
}

// This class provides a way to resolve a instance of a service to mimic the dependency injection pattern.
// All services proxies will be resolved as singleton
// Instead of exporting service classes as static / constant, we inject an instance of the class to allow mocking for unit testing.

export default api
