import { useState, useEffect, memo } from 'react'
import { TicketRevSelect } from 'components'
import { useQuery } from 'react-query'
import { api, queryKeys } from 'services'
import { Grid, Typography } from '@material-ui/core'

interface Props {
  handleSetTemplate: (template: string) => void
}

const TemplateSelect = ({ handleSetTemplate }: Props) => {
  const { data: smsTemplatesData } = useQuery(
    queryKeys.platformChannels,
    () => api.sms.template.getAll(undefined, undefined) // organisationBrandId
  )

  const [selectedSmsTemplate, setSelectedSmsTemplate] = useState('')

  useEffect(() => {
    const findSelectedTemplate = smsTemplatesData?.result.find(
      (x) => x.id === selectedSmsTemplate
    )
    if (findSelectedTemplate) handleSetTemplate(findSelectedTemplate.body)
  }, [selectedSmsTemplate])

  return (
    <Grid item xs={12} sm={6}>
      <TicketRevSelect
        label="Choose a template"
        options={smsTemplatesData?.result || []}
        getOptionSelected={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.description}
        value={smsTemplatesData?.result.find((x) => x.id === selectedSmsTemplate) || null}
        onChange={(_event, val) => setSelectedSmsTemplate(val.id)}
        renderOption={(option) => {
          return <Typography noWrap>{option.description}</Typography>
        }}
      />
    </Grid>
  )
}

export default memo(TemplateSelect)
