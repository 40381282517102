import React from 'react'
import classNames from 'classnames'
import {
  makeStyles,
  Theme,
  Card,
  CardContent,
  CardActionArea,
  Typography,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    boxShadow: 'none',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.default,
  },
  active: {
    backgroundColor: theme.palette.primary.light,
  },
  cardContent: {
    padding: theme.spacing(1.5),
  },
  icon: {
    maxWidth: 26,
    maxHeight: 26,
    fontSize: 26,
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(0.6),
  },
}))

interface Props {
  icon?: JSX.Element
  title?: string
  subtitle?: string
  active?: boolean
  onClick?: () => void
  loading?: boolean
  disabled?: boolean
}

const QuickNavCard = ({
  icon,
  title,
  subtitle,
  active,
  onClick,
  loading = false,
  disabled,
}: Props) => {
  const classes = useStyles()

  const root = classNames([classes.root], {
    [classes.active]: active,
  })

  const iconWithProps = icon
    ? React.cloneElement(icon, {
        className: `${classes.icon} ${icon.props.className}`,
      })
    : null

  return loading ? (
    <Skeleton variant="rect" width="100%" height={102} />
  ) : (
    <Card className={root}>
      <CardActionArea onClick={onClick} disabled={disabled}>
        <CardContent className={classes.cardContent}>
          {iconWithProps}
          <Typography
            variant="h5"
            gutterBottom
            noWrap
            color={active ? 'primary' : 'inherit'}
          >
            <strong>{title}</strong>
          </Typography>
          <Typography color="textSecondary" variant="body2" noWrap>
            {subtitle}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default QuickNavCard
