import React from 'react'
import { makeStyles, Theme } from '@material-ui/core'
import { FiberManualRecordRounded as DotIcon } from '@material-ui/icons'
import { DropdownMenu, TicketRevListItem } from 'components'

const useStyles = makeStyles((theme: Theme) => ({
  success: {
    color: theme.palette.success.main,
  },
}))

interface Props {
  anchorEl: HTMLElement | null
  handleClose: () => void
  currentStatus: 'draft' | 'live'
  changeStatus: (newStatus: 'draft' | 'live') => void
}

const StatusMenu = ({ anchorEl, handleClose, currentStatus, changeStatus }: Props) => {
  const classes = useStyles()

  const handleSetDraft = () => changeStatus('draft')
  const handleSetLive = () => changeStatus('live')

  return (
    <DropdownMenu anchorEl={anchorEl} handleClose={handleClose}>
      <TicketRevListItem
        primaryText="Draft"
        icon={<DotIcon color="secondary" />}
        onClick={handleSetDraft}
        size="small"
        listItemSize="smallListItem"
        disabled={currentStatus === 'draft'}
      />
      <TicketRevListItem
        primaryText="Live"
        icon={<DotIcon className={classes.success} />}
        onClick={handleSetLive}
        size="small"
        listItemSize="smallListItem"
        disabled={currentStatus === 'live'}
      />
    </DropdownMenu>
  )
}
export default StatusMenu
