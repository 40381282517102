import { memo } from 'react'

interface Props {
  name: string
  disabled?: boolean
  currentText: string
}

const HiddenRequiredInput = ({ name, disabled, currentText }: Props) => {
  return (
    <input
      // hidden input to trigger form
      required
      disabled={disabled}
      value={currentText}
      onChange={() => undefined}
      style={{
        opacity: 0,
        position: 'absolute',
        left: 0,
        width: '100%',
        pointerEvents: 'none',
      }}
      name={name}
    />
  )
}

export default memo(HiddenRequiredInput)
