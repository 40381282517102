import React, { useState, useCallback } from 'react'
import { TicketRevTextField, Filler, TicketRevSelect } from 'components'
import api from 'services/serviceResolver'
import { makeStyles, Theme, Grid, Button } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import { useClipboard } from 'use-clipboard-copy'
import { CountryInfo } from 'generated/api/app-service-proxies'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxWidth: 400,
    margin: 'auto',
    marginTop: theme.spacing(5),
  },
  lineHeight: {
    lineHeight: '28px',
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    height: 40,
  },
  walletPass: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  buttonContainer: {
    textAlign: 'center',
  },
  inputContainer: {
    marginBottom: theme.spacing(2),
  },
}))

interface Props {
  audienceMemberId: string
  walletInstanceFriendlyId: string
  installUrl?: string
  countries?: CountryInfo[]
}

const DesktopInputs = ({
  audienceMemberId,
  walletInstanceFriendlyId,
  installUrl,
  countries,
}: Props) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const clipboard = useClipboard({
    onSuccess() {
      enqueueSnackbar(`Copied to clipboard`)
    },
    onError() {
      enqueueSnackbar(`Couldn't copy to clipboard`, {
        variant: 'error',
      })
    },
  })

  const handleCopy = useCallback(() => {
    clipboard.copy(installUrl)
  }, [clipboard, installUrl])

  const [loading, setLoading] = useState<'email' | 'mobilePhone' | null>(null)
  const [countryCode, setCountryCode] = useState('')
  const [mobilePhone, setMobilePhone] = useState('')
  const [email, setEmail] = useState('')

  const handleSendEmail = async () => {
    setLoading('email')
    try {
      await api.wallet.pass.createOrGetPassForAudienceMember(
        walletInstanceFriendlyId,
        audienceMemberId,
        false,
        true,
        email,
        undefined,
        undefined
      )
      enqueueSnackbar('E-mail sent with install link!', { variant: 'info' })
      setEmail('')
      setLoading(null)
    } catch (response) {
      enqueueSnackbar(response.error.message, {
        variant: 'error',
      })
      setLoading(null)
    }
  }

  const handleSendSms = async () => {
    setLoading('mobilePhone')
    try {
      await api.wallet.pass.createOrGetPassForAudienceMember(
        walletInstanceFriendlyId,
        audienceMemberId,
        true,
        false,
        undefined,
        mobilePhone,
        countryCode
      )
      enqueueSnackbar('SMS sent with install link!', { variant: 'info' })
      setCountryCode('')
      setMobilePhone('')
      setLoading(null)
    } catch (response) {
      enqueueSnackbar(response.error.message, {
        variant: 'error',
      })
      setLoading(null)
    }
  }

  return (
    <>
      <Filler height={16} />
      <Grid
        container
        direction="row"
        alignItems="center"
        className={classes.inputContainer}
        spacing={1}
      >
        <Grid item xs={10}>
          <TicketRevTextField
            label="Install link"
            value={installUrl}
            readOnly
            withMargin={false}
          />
        </Grid>
        <Grid item xs={2} className={classes.buttonContainer}>
          <Button variant="text" color="primary" onClick={handleCopy}>
            COPY
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        alignItems="center"
        className={classes.inputContainer}
        spacing={1}
      >
        <Grid item xs={4}>
          <TicketRevSelect
            label="Country"
            placeholder="+"
            value={countries?.find((x) => x.friendlyId === countryCode) || null}
            options={countries?.filter((x) => x.callingCode) || []}
            getOptionLabel={(option: CountryInfo) => `+${option.callingCode}`}
            getOptionSelected={(option: CountryInfo, value: CountryInfo) =>
              option.friendlyId === value.friendlyId
            }
            onChange={(_name: string, val: CountryInfo) =>
              setCountryCode(val?.friendlyId || '')
            }
            name="countryCode"
            disabled={loading === 'mobilePhone'}
            withMargin={false}
          />
        </Grid>
        <Grid item xs={6}>
          <TicketRevTextField
            label="Mobile phone"
            placeholder="8665835838"
            withMargin={false}
            type="number"
            loading={loading === 'mobilePhone'}
            onChange={(_name: string, value: string) => setMobilePhone(value)}
            value={mobilePhone}
          />
        </Grid>
        <Grid item xs={2} className={classes.buttonContainer}>
          <Button
            variant="text"
            color="primary"
            onClick={handleSendSms}
            disabled={mobilePhone.length < 4 || !countryCode || loading === 'mobilePhone'}
          >
            SEND
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        alignItems="center"
        className={classes.inputContainer}
        spacing={1}
      >
        <Grid item xs={10}>
          <TicketRevTextField
            label="E-mail"
            placeholder="your@email.com"
            withMargin={false}
            loading={loading === 'email'}
            onChange={(_name: string, value: string) => setEmail(value)}
            value={email}
          />
        </Grid>
        <Grid item xs={2} className={classes.buttonContainer}>
          <Button
            variant="text"
            color="primary"
            onClick={handleSendEmail}
            disabled={email.length < 4 || loading === 'email'}
          >
            SEND
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default DesktopInputs
