import React, { useState } from 'react'
import classNames from 'classnames'
import { Card, CardHeader, makeStyles, Theme, CardActionArea } from '@material-ui/core'
import { ChannelIcon } from 'components'
import moment from 'moment'
import { SharedStepProps, WaitProps } from '../../../helpers'
import useSharedStyles from '../sharedStyles'
import CardHeaderIconButton from '../components/CardHeaderIconButton'
import Menu from '../components/Menu'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: 'fit-content',
    maxWidth: 330,
    margin: 'auto',
    textAlign: 'center',
  },
  cardContainer: {
    backgroundColor: theme.palette.primary.dark,
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '&:hover': {
      opacity: 0.8,
    },
  },
  cardHeader: {
    paddingBottom: theme.spacing(),
    paddingTop: theme.spacing(),
  },
  delayDateSubHeader: {
    fontSize: '14px',
    fontWeight: 400,
    color: '#bcbcbc',
    marginLeft: -6,
    textAlign: 'left',
    marginRight: 4,
  },
  delayDurationSubHeader: {
    fontSize: '14px',
    fontWeight: 400,
    color: theme.palette.common.white,
    marginLeft: -6,
    textAlign: 'center',
    marginRight: 4,
  },
  cardTitle: {
    textAlign: 'left',
    fontWeight: 700,
    fontSize: '14px',
    color: theme.palette.common.white,
    marginLeft: -6,
    marginRight: 4,
  },
  action: {
    marginTop: 0,
    alignSelf: 'center',
  },
}))

const Wait = ({
  type,
  isEditing,
  editStep,
  removeStep,
  id,
  delayType,
  delayDurationMeasure,
  delayDuration,
  delayDate,
  sectionKey,
}: WaitProps & SharedStepProps) => {
  const sharedClasses = useSharedStyles()
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const cardClassName = classNames(
    [sharedClasses.sharedStepStyles, classes.cardContainer],
    {
      // [classes.menuOpen]: Boolean(anchorEl),
      [sharedClasses.isEditing]: isEditing,
    }
  )

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => setAnchorEl(null)

  const handleEditStep = () => {
    editStep(id, sectionKey)
  }

  const handleRemoveStep = () => removeStep(id, sectionKey)

  return (
    <div className={classes.container}>
      <Card raised={false} className={cardClassName}>
        <CardActionArea onClick={handleEditStep} className={sharedClasses.cardActionArea}>
          <CardHeader
            avatar={<ChannelIcon size={64} channel={type} withAvatar={false} />}
            action={<CardHeaderIconButton handleClick={handleOpenMenu} />}
            subheader={
              delayType === 'duration'
                ? `Wait ${delayDuration} ${
                    delayDuration === 1
                      ? delayDurationMeasure.slice(0, -1)
                      : delayDurationMeasure
                  } and then continue`
                : moment(delayDate).format('MMM D YYYY HH:mm ZZ')
            }
            title={delayType === 'duration' ? '' : 'Wait until'}
            classes={{
              root: classes.cardHeader,
              title: classes.cardTitle,
              subheader:
                delayType === 'duration'
                  ? classes.delayDurationSubHeader
                  : classes.delayDateSubHeader,
              action: classes.action,
            }}
          />
        </CardActionArea>
      </Card>
      {anchorEl && (
        <Menu
          anchorEl={anchorEl}
          handleClose={handleMenuClose}
          removeStep={handleRemoveStep}
        />
      )}
    </div>
  )
}
export default Wait
