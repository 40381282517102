import classNames from 'classnames'
import { Typography, makeStyles, Theme, Tooltip } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  diff: {
    paddingLeft: theme.spacing(),
    fontWeight: 600,
  },
  withTooltip: {
    cursor: 'pointer',
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
    '&:hover': {
      opacity: 0.5,
    },
  },
  positive: {
    color: '#4ea77c',
  },
  negative: {
    color: theme.palette.error.main,
  },
  text: {
    color: theme.palette.primary.main,
  },
}))

interface Props {
  difference?: number
  text?: string
  tooltip?: string
}

const Diff = ({ difference, text, tooltip = '' }: Props) => {
  const classes = useStyles()

  const diffClassName = classNames([classes.diff], {
    [classes.positive]: difference && difference >= 0,
    [classes.negative]: difference && difference < 0,
    [classes.text]: text,
    [classes.withTooltip]: tooltip,
  })

  return (
    <Tooltip title={tooltip} arrow>
      <Typography variant="body1" display="inline" className={diffClassName} noWrap>
        {difference
          ? (difference >= 0 && `+${difference.toFixed(1)}%`) ||
            `${difference.toFixed(1)}%`
          : null}
        {text}
      </Typography>
    </Tooltip>
  )
}

export default Diff
