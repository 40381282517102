import React, { useEffect, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { api, queryKeys } from 'services'
import { Grid, Typography, makeStyles, Theme, Collapse } from '@material-ui/core'
import {
  ColorPickerDot,
  DialogWrapper,
  GradientButton,
  TicketRevTextField,
} from 'components'
import { useSnackbar } from 'notistack'
import {
  IColorInfo,
  IColorThemeSummary,
  ColorThemeCreateRequest,
  ColorThemeUpdateRequest,
} from 'generated/api/app-service-proxies'

interface Props {
  tempColors: IColorThemeSummary
  setTempColors: (theme: IColorThemeSummary) => void
  currentColorTheme: IColorThemeSummary
  setCurrentColorTheme: (name: string, theme: IColorThemeSummary) => void
  justifyDots?: 'flex-start' | 'center' | 'flex-end'
  name: string
}

const useStyles = makeStyles((theme: Theme) => ({
  linkContainer: {
    marginTop: -theme.spacing(1.5),
    marginBottom: theme.spacing(2.5),
    paddingLeft: theme.spacing(1.5),
  },
  link: {
    paddingRight: theme.spacing(2),
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
      opacity: 0.95,
    },
  },
}))

const ColorThemeEditor = ({
  currentColorTheme,
  setCurrentColorTheme,
  tempColors,
  setTempColors,
  justifyDots = 'center',
  name,
}: Props) => {
  const classes = useStyles()
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()
  const [newThemeName, setNewThemeName] = useState('')
  const [newThemeModalOpen, setNewThemeModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [hasChanged, setHasChanged] = useState(false)

  const { data: colorThemesData } = useQuery(queryKeys.colorThemes, () =>
    api.colorTheme.getAll()
  )

  const onColorChange = (color: IColorInfo, colorName: string) => {
    setHasChanged(true)
    setTempColors({ ...tempColors, [colorName]: color })
  }

  const handleRevertChanges = () => {
    setTempColors(currentColorTheme)
    setHasChanged(false)
  }

  const handleOverwriteCurrentTheme = async () => {
    const themeFriendlyId = tempColors.friendlyId
    const body = ColorThemeUpdateRequest.fromJS(tempColors)
    if (!loading) {
      setLoading(true)
      try {
        await api.colorTheme.update(themeFriendlyId, body)
        setLoading(false)
        queryClient.invalidateQueries(queryKeys.colorThemes)
        enqueueSnackbar('Theme updated', { variant: 'info' })
        setHasChanged(false)
      } catch (response) {
        enqueueSnackbar(`Theme couldn't be updated: ${response.error.message}`, {
          variant: 'error',
        })
        setLoading(false)
      }
    }
  }

  const openCreateNewThemeModal = () => {
    setNewThemeModalOpen(true)
  }

  const closeCreateNewThemeModal = () => {
    setNewThemeModalOpen(false)
    setNewThemeName('')
  }

  const handleSaveAsNewTheme = async () => {
    const body = ColorThemeCreateRequest.fromJS({
      ...tempColors,
      description: newThemeName,
    })
    if (!loading) {
      setLoading(true)
      try {
        const response = await api.colorTheme.create(body)
        setCurrentColorTheme(name, response.result)
        setLoading(false)
        setHasChanged(false)
        setNewThemeModalOpen(false)
        setNewThemeName('')
        queryClient.invalidateQueries(queryKeys.colorThemes)
        enqueueSnackbar('Theme created!', { variant: 'info' })
      } catch (response) {
        enqueueSnackbar(`Theme couldn't be created: ${response.error.message}`, {
          variant: 'error',
        })
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    setTempColors(currentColorTheme)
  }, [currentColorTheme, setTempColors])

  return (
    <>
      {colorThemesData?.result && colorThemesData.result.length > 0 && (
        <>
          {/* <TicketRevSelect
            disabled={hasChanged}
            options={colorThemes.data.result}
            label="E-mail color theme"
            loading={loading}
            getOptionLabel={(option: IColorThemeSummary) => option.description}
            onChange={handleSetCurrentColorTheme}
            value={currentColorTheme}
            renderOption={(option: IColorThemeSummary) => {
              return (
                <Grid container alignItems="center" justifyContent="space-between">
                  <Grid item>{option.description}</Grid>
                  <Grid item>
                    <StatusCircle
                      size="md"
                      customColor={`rgba(${option.primaryColor.red}, ${option.primaryColor.green}, ${option.primaryColor.blue}, ${option.primaryColor.alpha})`}
                    />
                    <StatusCircle
                      size="md"
                      customColor={`rgba(${option.secondaryColor.red}, ${option.secondaryColor.green}, ${option.secondaryColor.blue}, ${option.secondaryColor.alpha})`}
                    />
                    <StatusCircle
                      size="md"
                      customColor={`rgba(${option.textColor.red}, ${option.textColor.green}, ${option.textColor.blue}, ${option.textColor.alpha})`}
                    />
                    <StatusCircle
                      size="md"
                      customColor={`rgba(${option.linkColor.red}, ${option.linkColor.green}, ${option.linkColor.blue}, ${option.linkColor.alpha})`}
                    />
                    <StatusCircle
                      size="md"
                      customColor={`rgba(${option.backgroundColor.red}, ${option.backgroundColor.green}, ${option.backgroundColor.blue}, ${option.backgroundColor.alpha})`}
                    />
                  </Grid>
                </Grid>
              )
            }}
          /> */}
          <Collapse in={hasChanged} className={classes.linkContainer}>
            <Grid container>
              <Grid item>
                <Typography
                  color="primary"
                  display="inline"
                  variant="body2"
                  className={classes.link}
                  onClick={handleOverwriteCurrentTheme}
                >
                  Overwrite selected theme
                </Typography>
                <Typography
                  color="primary"
                  display="inline"
                  variant="body2"
                  className={classes.link}
                  onClick={openCreateNewThemeModal}
                >
                  Save as new theme
                </Typography>
                <Typography
                  color="primary"
                  display="inline"
                  variant="body2"
                  className={classes.link}
                  onClick={handleRevertChanges}
                >
                  Revert changes
                </Typography>
              </Grid>
            </Grid>
          </Collapse>
        </>
      )}
      <Grid container justifyContent={justifyDots}>
        <ColorPickerDot
          currentColor={tempColors.primaryColor}
          name="primaryColor"
          size={34}
          paddingLeft={1.5}
          paddingRight={1.5}
          onChangeComplete={onColorChange}
          label="Primary"
        />
        <ColorPickerDot
          currentColor={tempColors.secondaryColor}
          name="secondaryColor"
          size={34}
          paddingLeft={1.5}
          paddingRight={1.5}
          onChangeComplete={onColorChange}
          label="Secondary"
        />
        <ColorPickerDot
          currentColor={tempColors.textColor}
          name="textColor"
          size={34}
          paddingLeft={1.5}
          paddingRight={1.5}
          onChangeComplete={onColorChange}
          label="Text"
        />
        <ColorPickerDot
          currentColor={tempColors.linkColor}
          name="linkColor"
          size={34}
          paddingLeft={1.5}
          paddingRight={1.5}
          onChangeComplete={onColorChange}
          label="Link"
        />
        <ColorPickerDot
          currentColor={tempColors.backgroundColor}
          name="backgroundColor"
          size={34}
          paddingLeft={1.5}
          paddingRight={1.5}
          onChangeComplete={onColorChange}
          label="Background"
        />
      </Grid>
      <DialogWrapper
        open={newThemeModalOpen}
        onClose={closeCreateNewThemeModal}
        title="Theme name"
        description="Give your new color theme a name"
        buttons={
          <GradientButton
            backText="Cancel"
            back={closeCreateNewThemeModal}
            loading={loading}
            onClick={handleSaveAsNewTheme}
            disabled={newThemeName.length < 3}
          >
            Create
          </GradientButton>
        }
      >
        <TicketRevTextField
          label="Theme name"
          autoFocus
          placeholder="My Company Theme"
          value={newThemeName}
          name="themename"
          required
          onChange={(_name: string, value: string) => setNewThemeName(value)}
        />
      </DialogWrapper>
    </>
  )
}

export default ColorThemeEditor
