import { Alert, TicketRevTextField } from 'components';
import { FormikErrors } from 'formik';
import { LoginProviderInfo } from 'generated/api/app-service-proxies';

import { Box, Grid, makeStyles, Theme, Typography } from '@material-ui/core';

import { FooterMessageRecaptchaEtc, LoginProvider, OrSeparator, SubmitButton } from '../components';
import { TermsAndPrivacyAgreement } from '../components/Terms';

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    cursor: 'pointer',
    marginTop: 6,
    marginLeft: 2,
    marginBottom: theme.spacing(1.5),
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))

export interface FormProps {
  email: string
  password: string
}

interface Props {
  loginProviders: LoginProviderInfo[]
  values: FormProps
  errors: FormikErrors<FormProps>
  setFieldValue: (name: string, value: any) => void
  isLoading: boolean
  handleSubmit: () => void
  handleGoToResetPassword: () => void
  withEmail?: boolean
}

const LoginForm = ({
  loginProviders,
  isLoading,
  handleSubmit,
  setFieldValue,
  values,
  errors,
  handleGoToResetPassword,
  withEmail = true,
}: Props) => {
  const classes = useStyles()

  const facebookLoginProvider = loginProviders?.find((x) => x.name === 'Facebook')
  const googleLoginProvider = loginProviders?.find((x) => x.name === 'Google')

  return (
    <>
      <Grid container spacing={2} style={{ paddingBottom: 32 }}>
        <Grid item xs={6}>
          <LoginProvider
            provider="google"
            link={googleLoginProvider?.url || ''}
            disabled={!googleLoginProvider || isLoading}
            variant="login"
          />
        </Grid>
        <Grid item xs={6}>
          <LoginProvider
            provider="facebook"
            link={facebookLoginProvider?.url || ''}
            disabled={!facebookLoginProvider || isLoading}
            variant="login"
          />
        </Grid>
      </Grid>
      <OrSeparator />
      {errors.email && <Alert withMargin message={errors.email} />}
      {errors.password && <Alert withMargin message={errors.password} />}
      <form onSubmit={handleSubmit} name="login">
        {withEmail && (
          <TicketRevTextField
            placeholder="E-mail"
            required
            type="email"
            label="E-mail"
            autocomplete="email"
            id="email"
            hideAsterix
            name="email"
            value={values.email}
            autoFocus
            disabled={isLoading}
            onChange={setFieldValue}
            error={Boolean(errors.email)}
          />
        )}
        <TicketRevTextField
          type="password"
          required
          label="Password"
          id="password"
          hideAsterix
          autocomplete="current-password"
          name="password"
          value={values.password}
          placeholder="Password"
          disabled={isLoading}
          onChange={setFieldValue}
          error={Boolean(errors.password)}
          helperText={
            <Typography
              onClick={handleGoToResetPassword}
              color="primary"
              variant="body2"
              component="span"
              display="block"
              className={classes.link}
            >
              Forgot password?
            </Typography>
          }
        />
        <Box pb={2}>
          <TermsAndPrivacyAgreement description='logging in' />
        </Box>
        <SubmitButton anchor="Sign in" loading={isLoading} />
        <FooterMessageRecaptchaEtc />
      </form>
    </>
  )
}

export default LoginForm
