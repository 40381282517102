import React from 'react'
import { Typography, makeStyles, Theme, Button } from '@material-ui/core'
import { Paper, PaperContent } from 'components'
import dude from 'assets/img/designer.svg'

const useStyles = makeStyles((theme: Theme) => ({
  white: {
    color: theme.palette.common.white,
  },
  align: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  invertedButton: {
    color: theme.palette.common.white,
    borderColor: theme.palette.common.white,
    marginTop: theme.spacing(2),
    '&:hover': {
      borderColor: '#bfafaf',
    },
  },
  image: {
    maxWidth: 219,
    width: '34%',
  },
}))

interface Props {
  description: string
  title: string
  buttonLabel?: string
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  disabled?: boolean
}

const ExplainerBox = ({ title, description, buttonLabel, onClick, disabled }: Props) => {
  const classes = useStyles()
  return (
    <Paper color="primary">
      <PaperContent className={classes.align}>
        <div>
          <Typography variant="h4" className={classes.white} gutterBottom>
            {title}
          </Typography>
          <Typography variant="body1" className={classes.white}>
            {description}
          </Typography>
          {Boolean(onClick && buttonLabel) && (
            <Button
              variant="outlined"
              className={classes.invertedButton}
              onClick={onClick}
              disabled={disabled}
            >
              {buttonLabel}
            </Button>
          )}
        </div>
        <img src={dude} alt={title} className={classes.image} />
      </PaperContent>
    </Paper>
  )
}

export default ExplainerBox
