/* eslint-disable camelcase */
export interface AppConfig {
  appSelfBaseUrl: string
  apiBaseUrl: string
  identityBaseUrl: string
  identityClientName: string
  identityScopes: string
  loginProviderRedirectUrl: string
  mixpanelProjectToken: string | undefined
  tinyMceApiKey: string
  intercomAppId: string
  Identity_IntegrationTestUser_Email: string
  Identity_IntegrationTestUser_Password: string
  sentryDsn: string
  envRelease: string
  envName: string
  recaptchaV3SiteKey: string
  stripePublishableKey: string
  seekaEventTrackingDisabled?: boolean
}

export default (window as any).appSettings as AppConfig
