import React, { useState, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { setDocumentTitle } from 'utils'
import { Alert, Paper, PaperContent, DigitalWalletCard } from 'components'
import api from 'services/serviceResolver'
import {
  makeStyles,
  Theme,
  Grid,
  Typography,
  Button,
  Divider,
  useMediaQuery,
} from '@material-ui/core'
import {
  DetectedDeviceInfo,
  DigitalWalletInstancePublicDetails,
  WalletPassGenerationResult,
  CountryInfo,
} from 'generated/api/app-service-proxies'
import { Skeleton } from '@material-ui/lab'
import { formatFields } from 'views/Channels/DigitalWallet/CreateEditDialog/helpers'
import DesktopInputs from './DesktopInputs'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxWidth: 400,
    margin: 'auto',
    marginTop: theme.spacing(5),
  },
  lineHeight: {
    lineHeight: '28px',
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    height: 40,
  },
  walletPass: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  buttonContainer: {
    textAlign: 'center',
  },
  inputContainer: {
    marginBottom: theme.spacing(2),
  },
}))

interface MatchProps {
  audienceMemberId: string
  walletInstanceFriendlyId: string
}

const WalletPass = ({ match }: RouteComponentProps<MatchProps>) => {
  const classes = useStyles()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'), {
    noSsr: true,
  })

  const [countries, setCountries] = useState<CountryInfo[] | null>(null)
  const [error, setError] = useState('')
  const [device, setDevice] = useState<DetectedDeviceInfo | null>(null)
  const [walletDesign, setWalletDesign] =
    useState<DigitalWalletInstancePublicDetails | null>(null)
  const [walletPass, setWalletPass] = useState<WalletPassGenerationResult | null>(null)

  const { audienceMemberId, walletInstanceFriendlyId } = match.params

  useEffect(() => {
    setDocumentTitle('Install Wallet Pass')
    Promise.all([
      api.general.referenceData.deviceDetect(),
      api.wallet.instance.getWalletDesign(walletInstanceFriendlyId),
      api.wallet.pass.createOrGetPassForAudienceMember(
        walletInstanceFriendlyId,
        audienceMemberId
      ),
      api.general.referenceData.getAllCountries(),
    ])
      .then(([deviceDetect, design, pass, countr]) => {
        setDevice(deviceDetect.result)
        setWalletDesign(design.result)
        setWalletPass(pass.result)
        setCountries(countr.result)
      })
      .catch((err) => {
        setError(err.error.message)
      })
    // eslint-disable-next-line
  }, [])

  const resolved = Boolean(device && walletDesign && walletPass && countries)

  const isSupported = device?.isWalletPassSupported

  const openInstallLink = () => {
    if (walletPass?.installUrl) {
      window.location.href = walletPass.installUrl
    }
  }

  let content = (
    <>
      <Typography variant="h1" gutterBottom>
        {resolved ? (
          <strong>Install Wallet Pass</strong>
        ) : (
          <Skeleton variant="text" width="90%" />
        )}
      </Typography>
      {resolved ? (
        (isSupported && 'Click the button to install.') || (
          <>
            <Typography variant="h5" className={classes.lineHeight}>
              Your wallet pass can only be installed on your mobile device.
            </Typography>
            <Typography variant="h5" className={classes.lineHeight}>
              Enter your mobile number or email below to have your pass sent directly to
              your device, or simply open the install email on your phone and try again.
            </Typography>
          </>
        )
      ) : (
        <>
          <Skeleton variant="text" width="70%" />
          <Skeleton variant="text" width="35%" />
        </>
      )}
      <Divider />
      {resolved ? (
        (isSupported && (
          <Button
            color="primary"
            variant="contained"
            disableElevation
            fullWidth
            onClick={openInstallLink}
            className={classes.button}
          >
            Click to install
          </Button>
        )) || (
          <DesktopInputs
            audienceMemberId={audienceMemberId}
            walletInstanceFriendlyId={walletInstanceFriendlyId}
            installUrl={walletPass?.installUrl}
            countries={countries || undefined}
          />
        )
      ) : (
        <Skeleton className={classes.button} />
      )}
      <Divider />
      <DigitalWalletCard
        logoUrl={walletDesign?.styling.logo1xImageUrl}
        logoText={walletDesign?.styling.logoText}
        type={walletDesign?.styling.stylingType}
        passBackgroundColor={walletDesign?.styling.passBackgroundColor}
        passLabelColor={walletDesign?.styling.passLabelColor}
        passForegroundColor={walletDesign?.styling.passForegroundColor}
        stripUrl={walletDesign?.styling.strip1xImageUrl}
        loading={!resolved}
        wrapperClassName={classes.walletPass}
        fields={formatFields(walletDesign?.styling.fields)}
      />
    </>
  )

  if (error) {
    content = (
      <Paper>
        <PaperContent>
          <Alert message={error} severity="error" />
        </PaperContent>
      </Paper>
    )
  }

  return (
    <Grid container spacing={3} direction="column" className={classes.container}>
      <Paper color={isMobile ? 'transparent' : undefined}>
        <PaperContent noPadding={isMobile}>
          <Grid item xs={12}>
            {content}
          </Grid>
        </PaperContent>
      </Paper>
    </Grid>
  )
}

export default WalletPass
