export default {
  root: {
    minWidth: 72,
    'text-transform': 'none',
    fontWeight: 500,
    borderRadius: '1.4375rem'
  },
  label: {
    'text-transform': 'initial',
  },
  sizeSmall: {
    // textTransform: 'none',
  },
  /* contained: {
    backgroundColor: palette.common.white,
    '&:hover': {
      backgroundColor: palette.common.neutral,
    },
  }, */
}
