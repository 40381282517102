import React, { useState, memo } from 'react'
import 'emoji-mart/css/emoji-mart.css'
import { Picker as EmojiPicker, BaseEmoji } from 'emoji-mart'
import { DropdownMenu } from 'components'
import { IconButton, Tooltip, useTheme } from '@material-ui/core'
import { InsertEmoticonRounded as EmojiIcon } from '@material-ui/icons'

interface Props {
  readOnly?: boolean
  handleFocus: () => void
  handleInsertEmoji: (emoji: BaseEmoji) => void
}

const EmojiSelect = ({ handleFocus, handleInsertEmoji, readOnly }: Props) => {
  const theme = useTheme()
  const [emojiSelectAnchor, setEmojiSelectAnchor] = useState<HTMLElement | null>(null)

  const handleOpenEmojiSelect = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation()
    setEmojiSelectAnchor(event.currentTarget)
  }

  const handleCloseEmojiSelect = () => {
    setEmojiSelectAnchor(null)
    setTimeout(() => handleFocus(), 50)
  }

  return (
    <>
      <Tooltip title={readOnly ? '' : 'Insert emoji'} placement="bottom" arrow>
        <IconButton size="small" disabled={readOnly} onClick={handleOpenEmojiSelect}>
          <EmojiIcon />
        </IconButton>
      </Tooltip>
      {emojiSelectAnchor && (
        <DropdownMenu
          anchorEl={emojiSelectAnchor}
          handleClose={handleCloseEmojiSelect}
          showBelowAnchor
          disableAutoFocusItem
          variant="menu"
          autoFocus={false}
        >
          <EmojiPicker
            autoFocus
            native
            title=""
            emoji=""
            showPreview={false}
            color={theme.palette.primary.main}
            useButton={false}
            emojiSize={20}
            onSelect={handleInsertEmoji}
            showSkinTones={false}
          />
        </DropdownMenu>
      )}
    </>
  )
}

export default memo(EmojiSelect)
