import React from 'react'
import { DialogWrapper, GradientButton, TicketRevTextField } from 'components'
import { makeStyles, Theme } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import { queryKeys, api } from 'services'
import { useQueryClient } from 'react-query'
import { useFormik } from 'formik'
import { SmsTemplateCreateOrUpdateRequest } from 'generated/api/app-service-proxies'
import { transformApiErrors } from 'utils'

interface Props {
  open: boolean
  handleClose: () => void
  organisationBrandId: string
  body: string
}

const useStyles = makeStyles((theme: Theme) => ({
  textField: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  button: {
    paddingBottom: theme.spacing(2),
  },
}))

const SaveTemplateDialog = ({ open, handleClose, organisationBrandId, body }: Props) => {
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const classes = useStyles()

  const { setFieldValue, handleSubmit, values, isSubmitting, setErrors, errors } =
    useFormik({
      initialValues: {
        description: '',
      },
      validateOnBlur: false,
      validateOnChange: false,
      onSubmit: async (payload) => {
        const templateBody = SmsTemplateCreateOrUpdateRequest.fromJS({
          body,
          description: payload.description,
          organisationBrandId,
        })
        try {
          await api.sms.template.create(templateBody)
          enqueueSnackbar('Template saved!', { variant: 'success' })
          queryClient.invalidateQueries(queryKeys.smsTemplates)
          handleClose()
        } catch (response) {
          const { message, validation } = response.error || response || {}
          enqueueSnackbar(`${message || 'An error occurred'}`, { variant: 'error' })
          if (validation && validation.properties) {
            setErrors(transformApiErrors(validation.properties))
          }
        }
      },
    })

  const removeErrorOnChange = (name: string) => {
    // eslint-disable-next-line
    if (errors.hasOwnProperty(name)) {
      setErrors({ ...errors, [name]: undefined })
    }
  }

  const setFieldValueWrapper = (name: string, value: any) => {
    setFieldValue(name, value)
    removeErrorOnChange(name)
  }

  const handleCloseIntercept = () => {
    if (!isSubmitting) handleClose()
  }

  return (
    <DialogWrapper
      open={open}
      onClose={handleCloseIntercept}
      withForm={false}
      title="Save template"
      description="You need to name your new template so you can use it for future campaigns"
    >
      <form onSubmit={handleSubmit}>
        <div className={classes.textField}>
          <TicketRevTextField
            onChange={setFieldValueWrapper}
            autoFocus
            disabled={isSubmitting}
            value={values.description}
            label="New template name"
            placeholder=""
            name="description"
            required
            helperText={errors.description}
            error={Boolean(errors.description)}
          />
        </div>
        <div className={classes.button}>
          <GradientButton
            backText="Cancel"
            back={() => handleClose()}
            loading={isSubmitting}
          >
            Save
          </GradientButton>
        </div>
      </form>
    </DialogWrapper>
  )
}

export default SaveTemplateDialog
