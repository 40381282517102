import React from 'react'
import {
  EmailRounded as EmailIcon,
  ChatRounded as SmsIcon,
  PublicRounded as ZenGlobalIcon,
  ControlCamera as OmnichannelIcon,
  FaceRounded as UpdateUserIcon,
  ScheduleRounded as WaitIcon,
  CallSplitRounded as ConditionalSplitIcon,
  EmojiEventsRounded as GoalIcon,
  LabelImportantRounded as StageIcon,
  NoteAddRounded as NoteIcon,
  ExtensionRounded as IntegrationIcon,
  Send as OneTimeCampaignTypeIcon,
  NaturePeople as EnrichmentCampaignTypeIcon,
  PersonAdd as AcquisitionCampaignTypeIcon,
  FlashAuto as AutomatedCampaignTypeIcon,
} from '@material-ui/icons'
import classNames from 'classnames'
import {
  FacebookMessenger as MessengerIcon,
  Webhook as WebhookIcon,
} from 'mdi-material-ui'
import {
  makeStyles,
  Theme,
  Avatar,
  Badge,
  Tooltip,
  TooltipProps,
  CircularProgress,
  useTheme,
} from '@material-ui/core'
import WalletIcon from 'assets/img/wallet-small.png'
import ManychatIcon from 'assets/img/manychat_octopus.svg'
import { Skeleton } from '@material-ui/lab'

interface Props {
  channel?:
    | '12464361-506a-4c8a-8619-16cb53f20bb9' // bot testing
    | '04f1ca69-2bc3-41ff-abf5-6d5bea6d555b' // digital wallet
    | 'd341a326-333d-4675-8771-6f6b69803d55' // sms
    | '447bebe7-0635-4fea-9197-997bb890437b' // web bot
    | '07f7b317-d86f-4d8b-b481-a958eb074583' // chatboss
    | '991e1bd6-093b-4569-b36a-b3f5c49ade05' // landbot
    | 'fc29ce88-21e5-4a47-b697-ca9b960cf3a0' // facebook messenger bot
    | '86365d40-fb70-42ba-914a-e21a33dc402b' // zen global loyalty
    | 'abe42bdd-a8d7-42c1-8bc3-e53d8fcfefd9' // email
    | 'b587d8ec-d105-4eb8-aef6-e6df3c37c678' // manychat page
    | 'omnichannel' // omnichannel
    | 'conditionalSplit'
    | 'wait'
    | 'trigger'
    | 'action'
    | 'stage'
    | 'goal'
    | 'updateUser'
    | 'webhook'
    | 'integration'
    | string
  className?: string
  size: number
  withAvatar?: boolean
  skeleton?: boolean
  badge?: React.ReactNode
  tooltip?: string
  // eslint-disable-next-line react/no-unused-prop-types
  color?: string
  loading?: boolean
  tooltipPlacement?: TooltipProps['placement']
  iconClassName?: string
}

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    margin: 'auto',
  },
  withTooltip: {
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.7,
    },
  },
  email: {
    color: `${theme.palette.brand.email.color} !important`,
    backgroundColor: `${theme.palette.brand.email.backgroundColor} !important`,
  },
  sms: {
    color: `${theme.palette.brand.sms.color} !important`,
    backgroundColor: `${theme.palette.brand.sms.backgroundColor} !important`,
  },
  digitalWallet: {
    color: `${theme.palette.brand.wallet.color} !important`,
    backgroundColor: `${theme.palette.brand.wallet.backgroundColor} !important`,
  },
  messenger: {
    color: `${theme.palette.brand.messenger.color} !important`,
    backgroundColor: `${theme.palette.brand.messenger.backgroundColor} !important`,
  },
  manychat: {
    color: `${theme.palette.brand.manychat.color} !important`,
    backgroundColor: `${theme.palette.brand.manychat.backgroundColor} !important`,
  },
  gatsby: {
    color: `${theme.palette.brand.gatsby.color} !important`,
    backgroundColor: `${theme.palette.brand.gatsby.backgroundColor} !important`,
  },
  magento: {
    color: `${theme.palette.brand.magento.color} !important`,
    backgroundColor: `${theme.palette.brand.magento.backgroundColor} !important`,
  },
  zenGlobal: {
    color: `${theme.palette.brand.zenGlobal.color} !important`,
    backgroundColor: `${theme.palette.brand.zenGlobal.backgroundColor} !important`,
  },
  omnichannel: {
    color: `${theme.palette.brand.omnichannel.color} !important`,
    backgroundColor: `${theme.palette.brand.omnichannel.backgroundColor} !important`,
  },
  webhook: {
    color: `${theme.palette.brand.webhook.color} !important`,
    backgroundColor: `${theme.palette.brand.webhook.backgroundColor} !important`,
  },
  wait: {
    color: `${theme.palette.brand.wait.color} !important`,
    backgroundColor: `${theme.palette.brand.wait.backgroundColor} !important`,
  },
  goal: {
    color: `${theme.palette.brand.goal.color} !important`,
    backgroundColor: `${theme.palette.brand.goal.backgroundColor} !important`,
  },
  stage: {
    color: `${theme.palette.brand.stage.color} !important`,
    backgroundColor: `${theme.palette.brand.stage.backgroundColor} !important`,
  },
  note: {
    color: `${theme.palette.brand.note.color} !important`,
    backgroundColor: `${theme.palette.brand.note.backgroundColor} !important`,
  },
  conditionalSplit: {
    color: `${theme.palette.brand.conditionalSplit.color} !important`,
    backgroundColor: `${theme.palette.brand.conditionalSplit.backgroundColor} !important`,
  },
  oneTimeCampaign: {
    color: `${theme.palette.brand.oneTimeCampaign.color} !important`,
    backgroundColor: `${theme.palette.brand.oneTimeCampaign.backgroundColor} !important`,
  },
  automatedCampaign: {
    color: `${theme.palette.brand.automatedCampaign.color} !important`,
    backgroundColor: `${theme.palette.brand.automatedCampaign.backgroundColor} !important`,
  },
  enrichmentCampaign: {
    color: `${theme.palette.brand.enrichmentCampaign.color} !important`,
    backgroundColor: `${theme.palette.brand.enrichmentCampaign.backgroundColor} !important`,
  },
  acquisitionCampaign: {
    color: `${theme.palette.brand.acquisitionCampaign.color} !important`,
    backgroundColor: `${theme.palette.brand.acquisitionCampaign.backgroundColor} !important`,
  },
}))

const GetChannelIcon = ({ channel, size, iconClassName, color }: Partial<Props>) => {
  const iconSize = (size as number) / 1.8
  const iconProps = {
    style: {
      width: iconSize,
      height: iconSize,
      maxWidth: iconSize,
      maxHeight: iconSize,
      color: color || undefined,
      transform: channel === 'conditionalSplit' ? 'rotate(180deg)' : '',
    },
    className: iconClassName,
  }
  if (channel === 'abe42bdd-a8d7-42c1-8bc3-e53d8fcfefd9' || channel === 'email')
    return <EmailIcon {...iconProps} />
  if (channel === 'd341a326-333d-4675-8771-6f6b69803d55' || channel === 'sms')
    return <SmsIcon {...iconProps} />
  if (channel === '04f1ca69-2bc3-41ff-abf5-6d5bea6d555b' || channel === 'digitalWallet') {
    return (
      <img
        src={WalletIcon}
        style={{ width: (size as number) / 2 }}
        alt="Digital Wallet"
      />
    )
  }
  if (channel === '86365d40-fb70-42ba-914a-e21a33dc402b' || channel === 'zenGlobal')
    return <ZenGlobalIcon {...iconProps} />

  if (channel === 'omnichannel') return <OmnichannelIcon {...iconProps} />

  if (channel === 'b587d8ec-d105-4eb8-aef6-e6df3c37c678' || channel === 'manychat') {
    return (
      <img src={ManychatIcon} style={{ width: (size as number) / 2 }} alt="ManyChat" />
    )
  }

  if (channel === 'campaign.type.oneTime')
    return <OneTimeCampaignTypeIcon {...iconProps} />
  if (channel === 'campaign.type.automated')
    return <AutomatedCampaignTypeIcon {...iconProps} />
  if (channel === 'campaign.type.enrichment')
    return <EnrichmentCampaignTypeIcon {...iconProps} />
  if (channel === 'campaign.type.acquisition')
    return <AcquisitionCampaignTypeIcon {...iconProps} />

  if (channel === 'updateUser') return <UpdateUserIcon {...iconProps} />
  if (channel === 'wait') return <WaitIcon {...iconProps} />
  if (channel === 'conditionalSplit') return <ConditionalSplitIcon {...iconProps} />
  if (channel === 'goal') return <GoalIcon {...iconProps} />
  if (channel === 'webhook') return <WebhookIcon {...iconProps} />
  if (channel === 'stage') return <StageIcon {...iconProps} />
  if (channel === 'note') return <NoteIcon {...iconProps} />
  if (channel === 'integration') return <IntegrationIcon {...iconProps} />
  return <MessengerIcon {...iconProps} />
}

const ChannelIcon = ({
  channel,
  className,
  size,
  withAvatar = true,
  badge,
  tooltip,
  skeleton,
  loading,
  tooltipPlacement = 'right',
  iconClassName,
}: Props) => {
  const classes = useStyles(size)
  const theme = useTheme()

  const avatarClassName = classNames(
    [classes.avatar],
    {
      [classes.email]:
        channel === 'abe42bdd-a8d7-42c1-8bc3-e53d8fcfefd9' || channel === 'email',
      [classes.sms]:
        channel === 'd341a326-333d-4675-8771-6f6b69803d55' || channel === 'sms',
      [classes.digitalWallet]:
        channel === '04f1ca69-2bc3-41ff-abf5-6d5bea6d555b' || channel === 'digitalWallet',
      [classes.messenger]:
        channel === 'fc29ce88-21e5-4a47-b697-ca9b960cf3a0' || channel === 'messenger',
      [classes.manychat]:
        channel === 'b587d8ec-d105-4eb8-aef6-e6df3c37c678' || channel === 'manychat',
      [classes.zenGlobal]:
        channel === '86365d40-fb70-42ba-914a-e21a33dc402b' || channel === 'zenGlobal',
      [classes.omnichannel]: channel === 'omnichannel',
      [classes.oneTimeCampaign]: channel === 'oneTime',
      [classes.automatedCampaign]: channel === 'automatedCampaign',
      [classes.enrichmentCampaign]: channel === 'enrichmentCampaign',
      [classes.acquisitionCampaign]: channel === 'acquisitionCampaign',
      [classes.webhook]: channel === 'webhook',
      [classes.wait]: channel === 'wait',
      [classes.stage]: channel === 'stage',
      [classes.goal]: channel === 'goal',
      [classes.note]: channel === 'note',
      [classes.conditionalSplit]: channel === 'conditionalSplit',
      [classes.withTooltip]: tooltip,
    },
    className
  )

  const channelColors = { ...theme.palette.brand } as any
  const findColor =
    channel && channel in channelColors
      ? channelColors[channel]
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.text.primary,
        }

  if (withAvatar) {
    return (
      <Badge
        overlap="circular"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        invisible={!badge}
        badgeContent={badge}
      >
        <Tooltip title={tooltip || ''} placement={tooltipPlacement} arrow>
          {skeleton ? (
            <Skeleton variant="circle" height={size} width={size} />
          ) : (
            <Avatar style={{ width: size, height: size }} className={avatarClassName}>
              {loading ? (
                <CircularProgress style={{ position: 'absolute' }} />
              ) : (
                <GetChannelIcon
                  channel={channel}
                  size={size}
                  iconClassName={iconClassName}
                />
              )}
            </Avatar>
          )}
        </Tooltip>
      </Badge>
    )
  }

  return (
    <GetChannelIcon
      channel={channel}
      size={size}
      iconClassName={iconClassName}
      color={findColor?.color}
    />
  )
}

export default ChannelIcon
