import React from 'react'
import classNames from 'classnames'
import {
  makeStyles,
  Button,
  CircularProgress,
  Theme,
  ButtonProps,
} from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    minWidth: 84,
  },
  backButton: {
    marginRight: theme.spacing(),
  },
  wrapper: {
    position: 'relative',
  },
  container: {
    display: 'flex',
  },
  flexStart: {
    justifyContent: 'flex-start',
  },
  flexCenter: {
    justifyContent: 'center',
  },
  flexEnd: {
    justifyContent: 'flex-end',
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  btnContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  edgeEnd: {
    marginRight: -theme.spacing(2),
  },
  fullWidth: {
    width: '100%',
  },
}))

interface Props extends ButtonProps {
  children: string
  className?: string
  loading?: boolean
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  back?: (event: React.MouseEvent<HTMLButtonElement>) => void
  backText?: string
  nextIsHidden?: boolean
  disabled?: boolean
  justify?: 'flex-start' | 'center' | 'flex-end' | 'space-between'
  nextJustify?: 'flex-start' | 'center' | 'flex-end' | 'space-between'
  moreButtons?: JSX.Element | JSX.Element[]
  moreButtonsPlacement?: 'start' | 'middle'
  type?: 'submit' | 'reset' | 'button'
  edgeEnd?: boolean
  wrapperClassName?: string
  fullWidth?: boolean
}

const GradientButton = ({
  children,
  loading = false,
  onClick,
  disabled = false,
  backText = 'Cancel',
  nextIsHidden = false,
  back,
  className,
  justify = 'flex-end',
  moreButtons,
  wrapperClassName,
  fullWidth,
  nextJustify = 'flex-end',
  type = 'submit',
  moreButtonsPlacement = 'start',
  edgeEnd,
  ...rest
}: Props) => {
  const classes = useStyles()

  // TODO clean up
  const containerClassName = classNames(
    [classes.container],
    {
      [classes.edgeEnd]: edgeEnd,
      [classes.flexStart]: justify === 'flex-start',
      [classes.flexCenter]: justify === 'center',
      [classes.flexEnd]: justify === 'flex-end',
      [classes.spaceBetween]: justify === 'space-between',
      [classes.fullWidth]: fullWidth,
    },
    wrapperClassName
  )
  const rootClassName = classNames(
    {
      [classes.button]: true,
      [classes.fullWidth]: fullWidth,
    },
    className
  )
  const btnContainer = classNames([classes.btnContainer], {
    [classes.fullWidth]: fullWidth,
  })
  const wrapper = classNames([classes.wrapper], {
    [classes.fullWidth]: fullWidth,
    [classes.flexStart]: nextJustify === 'flex-start',
    [classes.flexCenter]: nextJustify === 'center',
    [classes.flexEnd]: nextJustify === 'flex-end',
    [classes.spaceBetween]: nextJustify === 'space-between',
  })

  return (
    <div className={containerClassName}>
      {moreButtonsPlacement === 'start' && moreButtons}
      <div className={btnContainer}>
        {back && (
          <div>
            <Button
              color="primary"
              className={classes.backButton}
              disabled={loading}
              onClick={back}
            >
              {backText}
            </Button>
          </div>
        )}
        {moreButtonsPlacement === 'middle' && moreButtons}
        {!nextIsHidden && (
          <div className={wrapper}>
            <Button
              color="primary"
              variant="contained"
              disableElevation
              onClick={onClick}
              fullWidth={fullWidth}
              {...rest}
              disabled={disabled || loading}
              className={rootClassName}
              type={type}
            >
              {children}
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
        )}
      </div>
    </div>
  )
}

export default GradientButton
