import { ChannelIcon } from 'components';
import React from 'react';
import { DragSourceMonitor, useDrag } from 'react-dnd';

import { ListItem, ListItemAvatar, ListItemText, makeStyles, Theme } from '@material-ui/core';

import { SharedStepProps, UtilityProps } from '../../helpers';

const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    border: `2px dashed ${theme.palette.border}`,
    marginBottom: theme.spacing(),
    '&:hover': {
      cursor: 'move',
    },
  },
  listItemAvatar: {
    minWidth: 42,
    marginLeft: theme.spacing(),
    display: 'flex',
  },
}))

interface Props extends UtilityProps {
  addStep: NonNullable<SharedStepProps['addStep']>
}

export interface DropInfo {
  name: string
}

const UtilityItem = ({ label, type, disabled, category, addStep }: Props) => {
  const classes = useStyles()

  const [{ isDragging }, collected, dragRef] = useDrag(() => ({
    item: { name: label } as DropInfo,
    type,
    end: (item: DropInfo | undefined, monitor: DragSourceMonitor<DropInfo>) => {
      const dropResult = monitor.getDropResult() as any
      // eslint-disable-next-line no-console
      // console.log('drop result', dropResult)

      if (item && dropResult) {
        const { insertAfterIndex, sectionKey } = dropResult.name || {}
        if (typeof insertAfterIndex === 'number' && sectionKey) {
          addStep(type, insertAfterIndex, sectionKey)
        }
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }))

  const withAvatar = category === 'Outbound content'

  return (
    <ListItem
      className={classes.listItem}
      button
      disabled={disabled || isDragging}
      ref={dragRef}
      {...collected}
    >
      <ListItemAvatar className={classes.listItemAvatar}>
        <ChannelIcon channel={type} size={withAvatar ? 30 : 48} withAvatar={withAvatar} />
      </ListItemAvatar>
      <ListItemText primary={label} />
    </ListItem>
  )
}
export default UtilityItem
