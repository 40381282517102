import React from 'react'
import { makeStyles, Theme, Typography } from '@material-ui/core'

interface MakeStylesProps {
  color?: string
  valueColor?: string
}

const useStyles = makeStyles<Theme, MakeStylesProps>((theme: Theme) => ({
  value: {
    color: ({ valueColor }) => valueColor,
    fontSize: '18px',
  },
  valueDescription: {
    color: ({ color }) => color,
    fontSize: '11px',
  },
  valueContainer: {
    textAlign: 'left',
    marginTop: 0,
    margin: theme.spacing(2),
  },
}))

interface Props {
  secondaryValue?: string
  secondaryDescription?: string
  color?: string
  valueColor?: string
  fieldActive: boolean
}

const SecondaryFields = ({
  secondaryValue,
  secondaryDescription,
  color,
  valueColor,
  fieldActive,
}: Props) => {
  const classes = useStyles({ color, valueColor })

  return fieldActive ? (
    <div className={classes.valueContainer}>
      <Typography
        variant="body2"
        className={classes.valueDescription}
        style={{ opacity: secondaryDescription ? 1 : 0.6 }}
      >
        {secondaryDescription || 'SECONDARY FIELD'}
      </Typography>
      <Typography
        variant="body1"
        className={classes.value}
        style={{ opacity: secondaryValue ? 1 : 0.6 }}
      >
        {secondaryValue || '{{value}}'}
      </Typography>
    </div>
  ) : null
}

export default SecondaryFields
