import BigcommerceIcon from 'assets/img/bigcommerce.svg'
import FacebookIcon from 'assets/img/facebook.svg'
import GoHiLevelIcon from 'assets/img/gohighlevel.png'
import GoogleAnalyticsIcon from 'assets/img/google-analytics.svg'
import GoogleAdsIcon from 'assets/img/googleads.png'
import ManyChatIcon from 'assets/img/manychat.png'
import GatsbyIcon from 'assets/img/gatsby.svg'
import MagentoIcon from 'assets/img/magento.svg'
import MetaIcon from 'assets/img/meta_logo.svg'
import OztixIcon from 'assets/img/oztix.svg'
import MegatixIcon from 'assets/img/megatix.svg'
import Pinterest from 'assets/img/pinterest.svg'
import PageViewTestIcon from 'assets/img/preview_black_24dp.svg'
import ShopifyIcon from 'assets/img/shopify.svg'
import Snapchat from 'assets/img/snapchat.png'
import TiktokLogo from 'assets/img/Tiktok-Logo.png'
import WoocommerceIcon from 'assets/img/woocommerce.svg'
import WordpressIcon from 'assets/img/wordpress.svg'
import ZapierIcon from 'assets/img/zapier.svg'
import palette from 'theme/palette'
import { eventNameMapping } from 'views/Converge/helpers'

import { useTheme } from '@material-ui/core'
import {
  AdjustRounded as ConvergeDefaultIcon,
  CreditCardRounded as AddPaymentMethodIcon,
  DeveloperBoard,
  SearchRounded as KeywordSearchIcon,
  ShoppingBasketRounded as CheckoutIcon,
  ShoppingCartRounded as AddToCartIcon,
  VisibilityRounded as ViewContentIcon,
  OpenInBrowser,
} from '@material-ui/icons'
import CodeIcon from '@material-ui/icons/Code'

interface Props {
  converge:
    | 'shopify'
    | 'woocommerce'
    | 'bigcommerce'
    | 'facebook'
    | 'meta'
    | 'googleAds'
    | 'googleAnalytics'
    | 'snapchatPixel'
    | 'pinterest'
    | 'zapier'
    | 'manychat'
    | 'gatsby'
    | 'magento'
    | 'goHiLevel'
    | 'seekaApp'
    | string
  size: number
  color?: string
  className?: string
  style?: React.CSSProperties
}

const ConvergeIcon = ({
  converge,
  size,
  className,
  color = palette.common.white,
  style,
}: Props) => {
  const sharedProps = {
    className,
    style: {
      width: size,
      height: size,
      color,
      ...style,
    },
  }

  const theme = useTheme()

  if (converge === 'snapchatPixel') {
    return <img src={Snapchat} {...sharedProps} alt={converge} />
  }
  if (converge === 'pinterest') {
    return <img src={Pinterest} {...sharedProps} alt={converge} />
  }
  if (converge === 'meta' || converge === 'facebookPixel') {
    return <img src={MetaIcon} {...sharedProps} alt={converge} />
  }

  if (converge === 'shopify') {
    return <img src={ShopifyIcon} {...sharedProps} alt={converge} />
  }

  if (converge === 'woocommerce') {
    return <img src={WoocommerceIcon} {...sharedProps} alt={converge} />
  }

  if (converge === 'bigcommerce') {
    return <img src={BigcommerceIcon} {...sharedProps} alt={converge} />
  }

  if (converge === 'facebook') {
    return <img src={FacebookIcon} {...sharedProps} alt={converge} />
  }

  if (converge === 'tikTokPixel') {
    return <img src={TiktokLogo} {...sharedProps} alt={converge} />
  }

  if (converge === 'googleAds') {
    return <img src={GoogleAdsIcon} {...sharedProps} alt={converge} />
  }

  if (converge === 'googleAnalytics') {
    return <img src={GoogleAnalyticsIcon} {...sharedProps} alt={converge} />
  }

  if (converge === 'wordpress') {
    return <img src={WordpressIcon} {...sharedProps} alt={converge} />
  }

  if (converge === 'zapier') {
    return <img src={ZapierIcon} {...sharedProps} alt={converge} />
  }

  if (converge === 'manychat') {
    return <img src={ManyChatIcon} {...sharedProps} alt={converge} />
  }

  if (converge === 'gatsby') {
    return <img src={GatsbyIcon} {...sharedProps} alt={converge} />
  }

  if (converge === 'magento') {
    return <img src={MagentoIcon} {...sharedProps} alt={converge} />
  }

  if (converge === 'oztix') {
    return <img src={OztixIcon} {...sharedProps} alt={converge} />
  }

  if (converge === 'megatix') {
    return <img src={MegatixIcon} {...sharedProps} alt={converge} />
  }

  if (converge === 'goHiLevel') {
    return <img src={GoHiLevelIcon} {...sharedProps} alt={converge} />
  }

  if (converge === 'seekaApp') {
    return (
      <DeveloperBoard
        {...sharedProps}
        style={
          {
            ...sharedProps.style,
            color: palette.converge.seekaApp.color as any,
          } as any
        }
      />
    )
  }

  if (converge === 'scriptTag') {
    return (
      <CodeIcon
        {...sharedProps}
        style={
          {
            ...sharedProps.style,
            color: palette.converge.scriptTag.color as any,
          } as any
        }
      />
    )
  }

  if (converge === 'iframe') {
    return (
      <OpenInBrowser
        {...sharedProps}
        style={
          {
            ...sharedProps.style,
            color: palette.converge.iframe.color as any,
          } as any
        }
      />
    )
  }

  if (
    (converge in eventNameMapping &&
      eventNameMapping[converge] === eventNameMapping.ViewContent) ||
    (converge in eventNameMapping &&
      eventNameMapping[converge] === eventNameMapping.ViewProduct)
  ) {
    return <ViewContentIcon {...sharedProps} />
  }

  if (
    (converge in eventNameMapping &&
      eventNameMapping[converge] === eventNameMapping.PageView) ||
    (converge in eventNameMapping &&
      eventNameMapping[converge] === eventNameMapping.ViewPage) ||
    (converge in eventNameMapping &&
      eventNameMapping[converge] === eventNameMapping.Order)
  ) {
    return <img src={PageViewTestIcon} {...sharedProps} alt={converge} />
  }

  if (
    converge in eventNameMapping &&
    eventNameMapping[converge] === eventNameMapping.AddPaymentMethod
  ) {
    return <AddPaymentMethodIcon {...sharedProps} />
  }
  if (
    converge in eventNameMapping &&
    eventNameMapping[converge] === eventNameMapping.AddToCart
  ) {
    return <AddToCartIcon {...sharedProps} />
  }
  if (
    converge in eventNameMapping &&
    eventNameMapping[converge] === eventNameMapping.InitiateCheckout
  ) {
    return <CheckoutIcon {...sharedProps} />
  }
  if (
    converge in eventNameMapping &&
    eventNameMapping[converge] === eventNameMapping.KeywordSearch
  ) {
    return <KeywordSearchIcon {...sharedProps} />
  }
  /*  AddPaymentInfo: 'Add payment info',
  AddToCart: 'Add to cart',
  AddToWishlist: 'Add to wishlist',
  CompleteRegistration: 'Complete registration',
  Contact: 'Contact',
  CustomizeProduct: 'Customize product',
  Donate: 'Donate',
  FindLocation: 'Find location',
  InitiateCheckout: 'Initiate checkout',
  Lead: 'Lead',
  PageView: 'Page view',
  Purchase: 'Purchase',
  Schedule: 'Schedule',
  Search: 'Search',
  StartTrial: 'StartTrial',
  SubmitApplication: 'Submit application',
  Subscribe: 'Subscribe',
  ViewContent: 'View content', */

  return <ConvergeDefaultIcon {...sharedProps} />
}

export default ConvergeIcon
