import { Alert, TicketRevTextField, WizardTitle } from 'components'

import { makeStyles, Theme, Typography } from '@material-ui/core'

import { SharedStepProps } from '../../helpers'

const useStyles = makeStyles((theme: Theme) => ({
  ul: {
    ...theme.typography.body1,
    color: theme.palette.text.secondary,
  },
  input: {
    maxWidth: 388,
    marginTop: theme.spacing(4),
  },
}))

interface Props extends SharedStepProps {
  orgName: string
}

const NameYourOrganisation = ({ orgName, setValue, errors }: Props) => {
  const classes = useStyles()

  return (
    <>
      <WizardTitle title="Let's create your organisation" />

      <Alert message="A new organisation will be setup with a 7 day or 30,000 session trial, whichever comes first" />

      <Typography variant="body1" color="textSecondary" style={{ marginTop: '20px' }}>
        A new organisation is completely new instance of Seeka.
        <br />
        <br />
        An organisation can be thought of as the master folder for one or more brands that
        you will connect to Seeka.
        <br />
        <br />
        Example: <br />
        <strong>Organisation:</strong> Danny's Ice-cream PTY LTD
        <br />
        <strong>Brand 1:</strong> Danny's Ice-cream New York
        <br />
        <strong>Brand 2:</strong> Danny's Ice-cream Gold Coast
        <br />
        <br />
        <br />
        An organisation name will not appear in invoicing.
        <br />
        <br />
        If you only have a single brand to connect, you can name both the organisation and
        brand the same.
        <br />
        <br />
        In the next step you will be asked to create your first brand.
      </Typography>

      <TicketRevTextField
        label="Organisation name"
        placeholder="Your new organisation name"
        onChange={setValue}
        name="orgName"
        value={orgName}
        required
        helperText={errors.orgName}
        error={Boolean(errors.orgName)}
        containerClassName={classes.input}
      />
    </>
  )
}

export default NameYourOrganisation
