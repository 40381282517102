import React from 'react'
import classNames from 'classnames'
import { Card, CardHeader, makeStyles, Theme, CardActionArea } from '@material-ui/core'
import { ArrowDropDownRounded as ArrowDownIcon } from '@material-ui/icons'
import { SharedStepProps, StageProps } from '../../../helpers'
import useSharedStyles from '../sharedStyles'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    maxWidth: 380,
    margin: 'auto',
    textAlign: 'center',
  },
  cardContainer: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '&:hover': {
      opacity: 0.8,
    },
  },
  cardHeader: {
    paddingBottom: theme.spacing(),
    paddingTop: theme.spacing(),
  },
  cardSubHeader: {
    fontSize: '26px',
    color: theme.palette.text.primary,
  },
  icon: {
    color: theme.palette.common.white,
  },
  action: {
    marginTop: 0,
    alignSelf: 'center',
  },
  arrowDownIcon: {
    width: 75,
    height: 75,
    marginTop: -20,
    marginBottom: -20,
  },
}))

const Stage = ({ isEditing, editStep, id, sectionKey }: StageProps & SharedStepProps) => {
  const sharedClasses = useSharedStyles()
  const classes = useStyles()

  const cardClassName = classNames([classes.cardContainer], {
    // [classes.menuOpen]: Boolean(anchorEl),
    [sharedClasses.isEditing]: isEditing,
  })

  const handleEditStep = () => {
    editStep(id, sectionKey)
  }

  return (
    <div className={classes.container}>
      <Card raised={false} className={cardClassName}>
        <CardActionArea onClick={handleEditStep} className={sharedClasses.cardActionArea}>
          <CardHeader
            /* avatar={
              <ChannelIcon
                size={64}
                channel={type}
                withAvatar={false}
                iconClassName={classes.icon}
              />
            } */
            subheader={<strong>Stage 1: Presale live</strong>}
            classes={{
              root: classes.cardHeader,
              subheader: classes.cardSubHeader,
              action: classes.action,
            }}
          />
          <ArrowDownIcon className={classes.arrowDownIcon} />
        </CardActionArea>
      </Card>
    </div>
  )
}
export default Stage
