import { makeStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  token: {
    cursor: 'pointer',
    paddingLeft: 1,
    paddingRight: 1,
    marginLeft: 1,
    marginRight: 1,
    borderRadius: 4,
    textDecoration: 'none',
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
  },
}))

interface Props {
  children: any
  // offsetKey: string
  // entityKey: string
  // contentState: ContentState
  /** and a lot more */
}

const TokenSpan = (props: Props) => {
  const classes = useStyles()
  const { children /* , entityKey, contentState */ } = props
  // const tokenMetadata = contentState.getEntity(entityKey).getData()
  // console.log('TOKEN METADATA', tokenMetadata)
  // use this method when updating token stuff, like fallback. Will be
  /* const setSomeData = () => {
    contentState.mergeEntityData
  } */

  // console.log(props)

  return <span className={classes.token}>{children}</span>
}

export default TokenSpan
