import { useEffect, useState } from 'react'
import { Alert, TicketRevSelect, TicketRevTextField, PaperLoader } from 'components'
import {
  IntelligentUrl,
  IntelligentUrlCreateRequest,
  IntelligentUrlHostname,
  ApiResponseDtoOfIntelligentUrl,
  IntelligentUrlGenerateCreateResponse,
  UrlFriendlyIdGenerateMode,
  ApiResponseDtoOfIntelligentUrlGenerateCreateResponse,
} from 'generated/api/app-service-proxies'
import { useQuery } from 'react-query'
import { api, queryKeys } from 'services'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import { ArrowForwardRounded as ArrowIcon } from '@material-ui/icons'
import {
  makeStyles,
  Theme,
  Popper,
  Grow,
  Paper,
  Typography,
  Grid,
  Box,
  Button,
  ClickAwayListener,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { transformApiErrors } from 'utils'

const loadingSkeleton = (
  <>
    <Skeleton />
    <Skeleton />
    <Skeleton />
  </>
)

const useStyles = makeStyles((theme: Theme) => ({
  menu: {
    overflow: 'auto',
    // maxHeight: 250,
    boxShadow:
      '0px 5px 4px -3px rgb(0 0 0 / 8%), 0px 8px 10px 1px rgb(0 0 0 / 0%), 0px 3px 14px 2px rgb(0 0 0 / 9%)',
    width: '100%',
    maxWidth: 420,
    padding: theme.spacing(2),
  },
  popper: {
    zIndex: 1,
  },
  content: {
    marginTop: theme.spacing(1.5),
  },
  link: {
    fontSize: theme.typography.caption.fontSize,
    '&:hover': {
      opacity: 0.7,
      cursor: 'pointer',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}))

interface Props {
  anchorEl: HTMLElement
  organisationBrandId: string
  decoratedText: string
  id: string
  handleClose: () => void
  updateShortlinkEntity: (originalId: string, newId: string, shortUrl: string) => void
}

const EditShortlinkPopper = ({
  anchorEl,
  decoratedText,
  id,
  organisationBrandId,
  handleClose,
  updateShortlinkEntity,
}: Props) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const [loading, setLoading] = useState(false)
  const [showPreviouslyShortenedSelect, setShowPreviouslyShortenedSelect] =
    useState(false)
  const [error, setError] = useState(false)
  const [validatedUrl, setValidatedUrl] = useState<IntelligentUrl | null>(null)
  const [urlSuggestion, setUrlSuggestion] =
    useState<IntelligentUrlGenerateCreateResponse | null>(null)

  const { data: intelligentUrlDomainsData, error: intelligentUrlDomainsError } = useQuery(
    queryKeys.intelligentUrlDomains,
    () => api.urls.getAllHostnames()
  )

  const handleValidateUrl = () => {
    setError(false)
    setLoading(true)
    api.urls
      .getSingle(id)
      .then((result: ApiResponseDtoOfIntelligentUrl) => {
        const valUrl = result.result
        setValidatedUrl(valUrl)
        setLoading(false)
      })
      .catch(() => {
        setError(true)
        setLoading(false)
        setValidatedUrl(null)
      })
  }

  const handleGetSuggestions = () => {
    api.urls
      .suggest(
        UrlFriendlyIdGenerateMode.SearchEngineFriendly,
        decoratedText,
        organisationBrandId
      )
      .then((result: ApiResponseDtoOfIntelligentUrlGenerateCreateResponse) => {
        const suggestion = result.result
        setUrlSuggestion(suggestion)
      })
      .catch(() => {
        setUrlSuggestion(null)
      })
  }

  useEffect(() => {
    handleValidateUrl()
    handleGetSuggestions()
  }, [])

  const {
    handleSubmit,
    values,
    setFieldValue,
    errors,
    setErrors,
    isSubmitting,
    setValues,
  } = useFormik({
    initialValues: {
      sourceDnsHostname: '',
      friendlyId: '',
      existingShortUrlId: '',
    },
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (payload) => {
      if (validatedUrl) {
        if (payload.existingShortUrlId && showPreviouslyShortenedSelect) {
          const findExistingShortlink = urlSuggestion?.existing.find(
            (x) => x.id === payload.existingShortUrlId
          )
          if (findExistingShortlink) {
            enqueueSnackbar('Shortlink updated!', {
              variant: 'info',
            })
            updateShortlinkEntity(
              id,
              findExistingShortlink.id,
              findExistingShortlink.shortUrl
            )
          } else {
            enqueueSnackbar("Couldn't insert shortlink. Try creating a new one", {
              variant: 'warning',
            })
            setShowPreviouslyShortenedSelect(false)
          }
        } else {
          try {
            const response = await api.urls.create({
              sourceDnsHostname: payload.sourceDnsHostname,
              directoryPath: validatedUrl.directoryPath,
              destinationUrl: validatedUrl.destinationUrl,
              linkDescription: validatedUrl.description,
              friendlyId: payload.friendlyId,
              organisationBrandId,
            } as IntelligentUrlCreateRequest)
            enqueueSnackbar('Shortlink updated!', {
              variant: 'info',
            })
            updateShortlinkEntity(id, response.result.id, response.result.shortUrl)
          } catch (response) {
            const { message, validation } = response.error
            if (message) {
              enqueueSnackbar(`${message}`, {
                variant: 'error',
              })
            }
            setErrors(transformApiErrors(validation.properties))
          }
        }
      }
    },
  })

  useEffect(() => {
    if (validatedUrl) {
      setValues({
        sourceDnsHostname: validatedUrl.sourceDnsHostname,
        friendlyId: validatedUrl.friendlyId,
        existingShortUrlId: '',
      })
    }
  }, [validatedUrl])

  const setFieldValueWrapper = (name: string, value: any) => {
    if (errors[name]) setErrors({ ...errors, [name]: undefined })
    setFieldValue(name, value)
  }

  const showLoader =
    (!intelligentUrlDomainsData && !intelligentUrlDomainsError) || loading

  const handleContainerClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation()
  }

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box onClick={handleContainerClick}>
        <Popper
          open
          anchorEl={anchorEl}
          role={undefined}
          transition
          className={classes.popper}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper className={classes.menu}>
                <Typography variant="h4" gutterBottom>
                  <strong>Edit shortlink</strong>
                </Typography>
                {validatedUrl && (
                  <Typography variant="body2" color="textSecondary">
                    Destination URL: <br />{' '}
                    <a
                      href={validatedUrl.destinationUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {validatedUrl.destinationUrl}
                    </a>
                  </Typography>
                )}
                {showLoader ? (
                  loadingSkeleton
                ) : (
                  <div className={classes.content}>
                    <form onSubmit={handleSubmit}>
                      {error && (
                        <Alert
                          severity="error"
                          message="An error occurred. Delete the link and create a new one."
                        />
                      )}
                      {validatedUrl && (
                        <>
                          {showPreviouslyShortenedSelect &&
                          urlSuggestion &&
                          urlSuggestion.existing.length > 0 ? (
                            <TicketRevSelect
                              required
                              options={urlSuggestion.existing}
                              getOptionSelected={(
                                option: IntelligentUrl,
                                val: IntelligentUrl
                              ) => option.shortUrl === val.shortUrl}
                              getOptionLabel={(x: IntelligentUrl) => `${x.shortUrl}`}
                              getOptionDisabled={(option: IntelligentUrl) =>
                                option.shortUrl === validatedUrl.shortUrl
                              }
                              label="Existing shortlinks"
                              onChange={(name: string, value: IntelligentUrl) =>
                                setFieldValueWrapper(name, value.id)
                              }
                              name="existingShortUrlId"
                              value={
                                urlSuggestion.existing.find(
                                  (x) => x.id === values.existingShortUrlId
                                ) || null
                              }
                              disabled={isSubmitting}
                              helperText={
                                errors.sourceDnsHostname ||
                                (urlSuggestion.existing.length > 0 && (
                                  <Typography
                                    variant="body2"
                                    color="primary"
                                    component="span"
                                    className={classes.link}
                                    onClick={() =>
                                      setShowPreviouslyShortenedSelect(false)
                                    }
                                  >
                                    Create a new shortlink
                                  </Typography>
                                )) ||
                                ''
                              }
                              error={Boolean(errors.sourceDnsHostname)}
                            />
                          ) : (
                            <Grid container spacing={1}>
                              <Grid item xs={6}>
                                <TicketRevSelect
                                  required
                                  options={intelligentUrlDomainsData?.result?.items}
                                  getOptionSelected={(
                                    option: IntelligentUrlHostname,
                                    val: IntelligentUrlHostname
                                  ) => option.hostname === val.hostname}
                                  getOptionLabel={(x: IntelligentUrlHostname) =>
                                    `${x.hostname}/`
                                  }
                                  label="Shortlink domain"
                                  onChange={(
                                    name: string,
                                    value: IntelligentUrlHostname
                                  ) => setFieldValueWrapper(name, value.hostname)}
                                  name="sourceDnsHostname"
                                  value={
                                    intelligentUrlDomainsData?.result?.items.find(
                                      (x) => x.hostname === values.sourceDnsHostname
                                    ) || null
                                  }
                                  disabled={isSubmitting}
                                  helperText={
                                    errors.sourceDnsHostname ||
                                    (urlSuggestion &&
                                      urlSuggestion.existing.length > 0 && (
                                        <Typography
                                          variant="body2"
                                          color="primary"
                                          component="span"
                                          className={classes.link}
                                          onClick={() =>
                                            setShowPreviouslyShortenedSelect(true)
                                          }
                                        >
                                          Select previously used shortlink
                                        </Typography>
                                      ))
                                  }
                                  error={Boolean(errors.sourceDnsHostname)}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TicketRevTextField
                                  label="Unique slash tag"
                                  value={values.friendlyId}
                                  required
                                  name="friendlyId"
                                  disabled={isSubmitting}
                                  onChange={setFieldValueWrapper}
                                  helperText={errors.friendlyId}
                                  error={Boolean(errors.friendlyId)}
                                />
                              </Grid>
                            </Grid>
                          )}
                        </>
                      )}
                      {validatedUrl && (
                        <div className={classes.buttonContainer}>
                          <Button
                            variant="text"
                            color="primary"
                            onClick={handleClose}
                            style={{ marginTop: -3, marginRight: 4, height: 36 }}
                          >
                            Cancel
                          </Button>
                          <Button
                            color="primary"
                            variant="contained"
                            disableElevation
                            endIcon={<ArrowIcon />}
                            style={{ marginTop: -4, minWidth: 95, height: 36 }}
                            type="submit"
                            disabled={
                              isSubmitting || showPreviouslyShortenedSelect
                                ? !values.existingShortUrlId
                                : Boolean(
                                    !values.friendlyId ||
                                      !values.sourceDnsHostname ||
                                      (values.sourceDnsHostname ===
                                        validatedUrl.sourceDnsHostname &&
                                        values.friendlyId === validatedUrl.friendlyId)
                                  )
                            }
                          >
                            {isSubmitting ? (
                              <PaperLoader
                                size={20}
                                color="white"
                                compact
                                style={{
                                  display: 'flex',
                                  minWidth: 37,
                                  justifyContent: 'center',
                                }}
                              />
                            ) : (
                              'Update'
                            )}
                          </Button>
                        </div>
                      )}
                    </form>
                  </div>
                )}
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>
    </ClickAwayListener>
  )
}

export default EditShortlinkPopper
