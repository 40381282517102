import React from 'react'
import { makeStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: -11,
    marginBottom: theme.spacing(2),
  },
  innerContainer: {
    display: 'flex',
    marginBottom: 4,
    marginLeft: -1,
    marginRight: -1,
  },
}))

interface Props {
  children: JSX.Element | JSX.Element[] | false
  message: JSX.Element
}

const PasswordCheckContainer = ({ children, message }: Props) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>{children}</div>
      {message}
    </div>
  )
}

export default PasswordCheckContainer
