import React from 'react'
import {
  makeStyles,
  Theme,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Box,
} from '@material-ui/core'
import { AddPhotoAlternateRounded as AddPhotoIcon } from '@material-ui/icons'

interface MakeStylesProps {
  color?: string
  valueColor?: string
}

const useStyles = makeStyles<Theme, MakeStylesProps>((theme: Theme) => ({
  logo: {
    width: 45,
    height: 45,
    marginRight: theme.spacing(1.5),
    borderRadius: theme.spacing(0.5),
  },
  primary: {
    color: ({ color }) => color,
    fontSize: '18px',
    maxWidth: 158,
    lineHeight: '26px',
  },
  primaryPlaceholder: {
    color: ({ color }) => color,
    fontSize: '18px',
    opacity: 0.6,
    lineHeight: '26px',
  },
  value: {
    color: ({ valueColor }) => valueColor,
    fontSize: '18px',
  },
  valueDescription: {
    color: ({ color }) => color,
    fontSize: '11px',
  },
  valueContainer: {
    marginTop: -theme.spacing(0.5),
    maxWidth: 105,
  },
  listItem: {
    padding: 0,
  },
  list: {
    margin: theme.spacing(2),
    minHeight: 34,
  },
  addPhotoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
    width: 45,
    height: 45,
    marginRight: theme.spacing(1.5),
    borderRadius: theme.spacing(0.5),
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.7,
    },
  },
  hover: {
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.7,
    },
  },
}))

interface Props {
  logoUrl?: string
  /** set logo text to undefined to not show placeholder */
  logoText?: string
  value?: string
  valueDescription?: string
  fieldActive: boolean
  color?: string
  valueColor?: string
  addImage?: (type: string) => void
}

const Header = ({
  logoUrl,
  logoText,
  value,
  valueDescription,
  color,
  valueColor,
  addImage,
  fieldActive,
}: Props) => {
  const classes = useStyles({ color, valueColor })

  const handleAddImage = () => {
    if (addImage) addImage(`digitalWallet.logo`)
  }

  return (
    <List dense disablePadding className={classes.list}>
      <ListItem className={classes.listItem}>
        {logoUrl ? (
          <ListItemIcon
            onClick={handleAddImage}
            className={addImage ? classes.hover : ''}
          >
            <Tooltip title={addImage ? 'Change logo' : ''} placement="left" arrow>
              <img src={logoUrl} alt={logoText} className={classes.logo} />
            </Tooltip>
          </ListItemIcon>
        ) : (
          addImage && (
            <ListItemIcon>
              <Tooltip title="Add logo" placement="left" arrow>
                <Box className={classes.addPhotoContainer} onClick={handleAddImage}>
                  <AddPhotoIcon />
                </Box>
              </Tooltip>
            </ListItemIcon>
          )
        )}
        <ListItemText
          primary={logoText === undefined ? '' : logoText || 'Logo text'}
          classes={{ primary: logoText ? classes.primary : classes.primaryPlaceholder }}
          primaryTypographyProps={{
            noWrap: true,
          }}
        />
        {fieldActive && (
          <div className={classes.valueContainer}>
            <Typography
              variant="body2"
              align="right"
              className={classes.valueDescription}
              style={{ opacity: valueDescription ? 1 : 0.6 }}
            >
              {valueDescription || 'HEADER FIELD'}
            </Typography>
            <Tooltip
              title={value && value.length > 11 ? value : ''}
              placement="left"
              arrow
            >
              <Typography
                variant="body1"
                align="right"
                className={classes.value}
                style={{ opacity: value ? 1 : 0.6 }}
                noWrap
              >
                {value || '{{value}}'}
              </Typography>
            </Tooltip>
          </div>
        )}
      </ListItem>
    </List>
  )
}

export default Header
