import { Typography, makeStyles, Theme } from '@material-ui/core'
import { WizardTitle, TicketRevTextField } from 'components'
import { SharedStepProps } from '../../helpers'

const useStyles = makeStyles((theme: Theme) => ({
  ul: {
    ...theme.typography.body1,
    color: theme.palette.text.secondary,
  },
  input: {
    maxWidth: 388,
    marginTop: theme.spacing(1),
  },
  textBlock: {
    marginTop: theme.spacing(4),
  },
}))

interface Props extends SharedStepProps {
  brandName: string
  billingAccountEmailAddress: string
  legalEntityTradingName: string
  isSubmitting: boolean
}

const NameYourBrand = ({
  brandName,
  legalEntityTradingName,
  billingAccountEmailAddress,
  setValue,
  errors,
  isSubmitting,
}: Props) => {
  const classes = useStyles()

  return (
    <>
      <WizardTitle title={`Let's create a brand`} />
      <Typography variant="body1" color="textSecondary">
        Brands allow the segmentation of event data that Seeka collects to be sent in
        isolation to one or more destinations.
        <br />
        <br />
        Use case: You would create multiple brands if you wanted to keep event data
        separate between 2 businesses.
        <br />
        <br />
        Example: <br />
        <strong>Brand 1</strong>: Danny's Ice-cream New York
        <br />
        <strong>Brand 2</strong>: Danny's Ice-cream Gold Coast
        <br />
        <br />
        You will be able to create additional brands after setting up this brand.
        <br />
        <br />A brand can have the same name as an Organisation
      </Typography>

      <Typography variant="body1" color="textSecondary" className={classes.textBlock}>
        <strong>Step 1: Provide the name of the new brand</strong>
        <br />
        <i>This is used internally within Seeka by you and your team</i>
      </Typography>
      <TicketRevTextField
        label="Brand name"
        placeholder="Danny's Ice-cream New York"
        onChange={setValue}
        name="brandName"
        value={brandName}
        required
        helperText={errors.brandName}
        error={Boolean(errors.brandName)}
        disabled={isSubmitting}
        containerClassName={classes.input}
      />

      <Typography variant="body1" color="textSecondary" className={classes.textBlock}>
        <strong>Step 2: Provide your legal entity name for the new brand</strong>
        <br />
        <i>This will appear on invoices</i>
      </Typography>
      <TicketRevTextField
        label="Legal entity name"
        placeholder="Danny's Ice-cream LLC"
        onChange={setValue}
        name="legalEntityTradingName"
        value={legalEntityTradingName}
        required
        helperText={errors.legalEntityTradingName}
        error={Boolean(errors.legalEntityTradingName)}
        disabled={isSubmitting}
        containerClassName={classes.input}
      />

      <Typography variant="body1" color="textSecondary" className={classes.textBlock}>
        <strong>Step 3: Provide your billing email for the new brand</strong>
        <br />
        <i>This is where invoices will be sent</i>
      </Typography>
      <TicketRevTextField
        label="Billing email"
        placeholder="accounts@dannysicecream.com"
        onChange={setValue}
        name="billingAccountEmailAddress"
        value={billingAccountEmailAddress}
        required
        helperText={errors.billingAccountEmailAddress}
        error={Boolean(errors.billingAccountEmailAddress)}
        disabled={isSubmitting}
        containerClassName={classes.input}
      />
    </>
  )
}

export default NameYourBrand
