import React, { useCallback } from 'react'
import classNames from 'classnames'
import { useSnackbar } from 'notistack'
import { InputAdornment, TextField, makeStyles, Button, Grid } from '@material-ui/core'
import { FilledInputProps } from '@material-ui/core/FilledInput'
import useInputStyles from 'theme/hooks/useInputStyles'
import { LockOutlined as LockIcon } from '@material-ui/icons'
import { useClipboard } from 'use-clipboard-copy'

const useStyles = makeStyles(() => ({
  input: {
    '& label': {
      paddingLeft: 41,
    },
  },
  adornment: {
    marginTop: '0px !important',
  },
  partialLeftInput: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  partialRightInput: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeft: '1px solid #fff',
  },
}))

interface Props {
  withMargin?: boolean
  // partialFill?: boolean
  loading?: boolean
  name?: string
  staticValue: string
  staticLabel: string
  withPartialFill?: boolean
  onChange?: any
  value?: string
  helperText?: JSX.Element | string
  label?: string
  className?: string
  error?: boolean
  withCopy?: boolean
  disabled?: boolean
  placeholder?: string
  inputRef?: any
}

const ReadOnlyCopyInput = ({
  withMargin = true,
  withCopy = true,
  withPartialFill = false,
  name,
  staticValue,
  onChange,
  value,
  error,
  label,
  placeholder,
  staticLabel,
  disabled,
  helperText,
  inputRef,
  loading,
  className,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar()
  const classes = useInputStyles()
  const internalClasses = useStyles()
  const clipboard = useClipboard({
    onSuccess() {
      enqueueSnackbar(`Copied to clipboard`)
    },
    onError() {
      enqueueSnackbar(`Couldn't copy to clipboard`, {
        variant: 'error',
      })
    },
  })

  const handleCopy = useCallback(() => {
    clipboard.copy(`${staticValue}${value || ''}`)
  }, [clipboard, value, staticValue])

  const root = classNames({
    [classes.root]: true,
    [classes.purple]: true,
    [internalClasses.partialLeftInput]: withPartialFill,
    className,
  })
  const partialRoot = classNames({
    [classes.root]: true,
    [internalClasses.partialRightInput]: withPartialFill,
    className,
  })

  return (
    <div className={withMargin ? classes.withMargin : ''}>
      <Grid container>
        <Grid item xs={withPartialFill ? 6 : 12}>
          <TextField
            fullWidth
            label={staticLabel}
            helperText={helperText}
            value={staticValue}
            placeholder={placeholder}
            className={internalClasses.input}
            disabled={disabled || loading}
            autoComplete="off"
            InputProps={
              {
                startAdornment: (
                  <InputAdornment position="start" className={internalClasses.adornment}>
                    <LockIcon color="secondary" />
                  </InputAdornment>
                ),
                endAdornment: withCopy && (
                  <InputAdornment position="end">
                    <Button
                      variant="text"
                      color="secondary"
                      size="small"
                      onClick={handleCopy}
                    >
                      Copy
                    </Button>
                  </InputAdornment>
                ),
                readOnly: true,
                classes: {
                  root,
                  focused: classes.focused,
                  error: classes.error,
                  disabled: classes.disabled,
                },
                disableUnderline: true,
                autoComplete: 'new-password',
              } as Partial<FilledInputProps>
            }
            variant="filled"
          />
        </Grid>
        {withPartialFill && (
          <Grid item xs={6}>
            <TextField
              fullWidth
              label={label}
              placeholder={placeholder}
              value={value}
              onChange={onChange}
              name={name}
              error={error}
              disabled={disabled || loading}
              autoComplete="off"
              InputProps={
                {
                  inputRef,
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        variant="text"
                        color="secondary"
                        size="small"
                        onClick={handleCopy}
                      >
                        Copy
                      </Button>
                    </InputAdornment>
                  ),
                  classes: {
                    root: partialRoot,
                    focused: classes.focused,
                    error: classes.error,
                    disabled: classes.disabled,
                  },
                  disableUnderline: true,
                  autoComplete: 'new-password',
                } as Partial<FilledInputProps>
              }
              variant="filled"
            />
          </Grid>
        )}
      </Grid>
    </div>
  )
}

export default ReadOnlyCopyInput
