import { useEffect, useState, useCallback } from 'react'
import { Switch, Route, RouteComponentProps } from 'react-router-dom'
import { RouteProps } from 'utils'
import { useLoginProviders } from 'hooks'
import accountRoutes from 'routes/accountRoutes'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import Login from './Login'
import CreateAccount from './CreateAccount'
import AcceptInvitation from './AcceptInvitation'
import RequestResetPassword from './RequestResetPassword'
import SetNewPassword from './SetNewPassword'
import VerifyFlow, { VerificationFormProps } from './VerifyFlow'

const ViewContainer = ({ location }: RouteProps) => {
  // If INVITE TOKEN is present in params, pass to loginProviders fetch
  const { search } = location
  const urlParams = new URLSearchParams(search)
  const inviteTokenParam = urlParams.get('it')
  const orgParam = urlParams.get('org')
  const invitedEmailParam = urlParams.get('em')

  const { executeRecaptcha } = useGoogleReCaptcha()
  const handleReCaptchaVerify = useCallback(async () => {
    if (executeRecaptcha) {
      const token = await executeRecaptcha(
        location.pathname.replace(/\//g, '').replace(/-/g, '')
      )
      return token
    }
    return undefined
  }, [executeRecaptcha])

  const [inviteToken, setInviteToken] = useState(inviteTokenParam)

  const { data: loginProvidersData, error: loginProvidersError } = useLoginProviders(
    inviteToken || undefined,
    {
      retry: 1,
    }
  )

  useEffect(() => {
    // clear invite token will trigger a refetch
    if (inviteToken && loginProvidersError) setInviteToken(null)
  }, [loginProvidersError])

  return executeRecaptcha ? (
    <Switch>
      <Route
        render={(props) => (
          <Login
            {...props}
            loginProviders={loginProvidersData?.result || []}
            hasLoginProvidersError={Boolean(loginProvidersError)}
            getRecaptchaToken={handleReCaptchaVerify}
          />
        )}
        exact
        path={accountRoutes.login}
      />
      <Route
        render={(props) => (
          <CreateAccount
            {...props}
            loginProviders={loginProvidersData?.result || []}
            hasLoginProvidersError={Boolean(loginProvidersError)}
            getRecaptchaToken={handleReCaptchaVerify}
          />
        )}
        exact
        path={accountRoutes.signUp}
      />
      <Route
        render={(props: RouteComponentProps<any, any, VerificationFormProps | any>) => (
          <VerifyFlow {...props} getRecaptchaToken={handleReCaptchaVerify} />
        )}
        exact
        path={accountRoutes.verifyEmail}
      />
      <Route
        render={(props) => (
          <RequestResetPassword {...props} getRecaptchaToken={handleReCaptchaVerify} />
        )}
        exact
        path={accountRoutes.resetPassword}
      />
      <Route
        render={(props) => (
          <SetNewPassword {...props} getRecaptchaToken={handleReCaptchaVerify} />
        )}
        exact
        path={accountRoutes.setNewPassword}
      />
      <Route
        render={(props) => (
          <AcceptInvitation
            {...props}
            getRecaptchaToken={handleReCaptchaVerify}
            inviteToken={inviteToken || undefined}
            loginProviders={loginProvidersData?.result || []}
            hasLoginProvidersError={Boolean(loginProvidersError)}
            org={orgParam || undefined}
            invitedEmail={invitedEmailParam || undefined}
          />
        )}
        exact
        path={accountRoutes.acceptInvitation}
      />
    </Switch>
  ) : null
}

export default ViewContainer
