import React from 'react'
import classNames from 'classnames'
import { ArcherElement } from 'react-archer'
import { makeStyles, Avatar, Theme, Tooltip } from '@material-ui/core'
import { DoneRounded as YesIcon, ClearRounded as NoIcon } from '@material-ui/icons'

interface Props {
  variant: 'yes' | 'no'
  className?: string
  lineConnectorId: string
}

const useStyles = makeStyles((theme: Theme) => ({
  yes: {
    width: 36,
    height: 36,
    border: 'none',
    color: theme.palette.success.main,
    backgroundColor: '#d9ebe2',
    margin: 'auto',
    marginTop: theme.spacing(4),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#e1efe8',
    },
  },
  no: {
    width: 36,
    height: 36,
    border: 'none',
    color: theme.palette.error.main,
    backgroundColor: '#f0d5d5',
    margin: 'auto',
    marginTop: theme.spacing(4),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f7dada',
    },
  },
}))

const YesNoCircle = ({ variant, className, lineConnectorId }: Props) => {
  const classes = useStyles()

  const rootClassName = classNames(
    {
      [classes.yes]: variant === 'yes',
      [classes.no]: variant === 'no',
    },
    className
  )

  return (
    <Tooltip
      title={
        variant === 'yes'
          ? 'Yes - the user matches the conditions'
          : `No - the user doesn't match the conditions`
      }
      placement="top"
      arrow
    >
      <Avatar className={rootClassName}>
        <ArcherElement id={lineConnectorId}>
          {variant === 'yes' ? <YesIcon /> : <NoIcon />}
        </ArcherElement>
      </Avatar>
    </Tooltip>
  )
}

export default YesNoCircle
