import { useContext } from 'react'
import AppContext from 'context'
import { useInfiniteQuery, useQuery, UseQueryOptions } from 'react-query'
import { api } from 'services'
import {
  AudienceMemberListRequest,
  ApiResponseError,
  ItemListResponseOfAudienceMemberSummary,
  ItemListResponseOfFilterConditionSourceGroup,
} from 'generated/api/app-service-proxies'
import { Criteria } from 'utils'
import { formatAudienceMemberListRequest } from 'views/Audience/helpers'
import queryKeys from './queryKeys'

const { audienceInfinite, segmentConditionFields } = queryKeys

export const ROWS_PER_PAGE = 20

export const defaultTableState = {
  order: 'asc' as 'asc' | 'desc',
  orderBy: 'firstName',
  filterText: '',
  comparer: 'and' as 'and' | 'or',
  criteria: [] as Criteria[],
}

export const useGetInfiniteAudience = (filters: typeof defaultTableState) => {
  const { userGrants /* , selectedBrand */ } = useContext(AppContext)
  const formatFilters = formatAudienceMemberListRequest(filters)

  return useInfiniteQuery<
    ItemListResponseOfAudienceMemberSummary,
    ApiResponseError,
    ItemListResponseOfAudienceMemberSummary,
    ReturnType<typeof audienceInfinite>
  >(
    audienceInfinite(filters),
    ({ pageParam = 0 }) =>
      api.audience.members.getAll({
        ...formatFilters,
        limit: ROWS_PER_PAGE,
        skip: pageParam * ROWS_PER_PAGE,
      } as AudienceMemberListRequest),
    {
      getNextPageParam: (lastPage, allPages) => {
        const flattenedPagesLength = allPages.map((x) => x.result).flat().length
        const currentPage = flattenedPagesLength / ROWS_PER_PAGE - 1
        const hasNextPage = currentPage < lastPage.totalCount / ROWS_PER_PAGE - 1
        return hasNextPage ? currentPage + 1 : undefined
      },
      keepPreviousData: true,
      enabled: userGrants.includes('seeka.Audience'),
    }
  )
}

export const useSegmentConditionFields = (
  options?: UseQueryOptions<
    ItemListResponseOfFilterConditionSourceGroup,
    ApiResponseError,
    ItemListResponseOfFilterConditionSourceGroup,
    typeof segmentConditionFields
  >
) => {
  return useQuery(
    segmentConditionFields,
    () => api.audience.segment.getAllConditionFields(),
    {
      ...options,
    }
  )
}
