import { Alert } from 'components';
import AppContext from 'context';
import { OrganisationBrandBillingStatusEnablement } from 'generated/api/app-service-proxies';
import { useBillingStatus, useCanExtendTrial, usePaymentMethods } from 'hooks';
import moment from 'moment';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { Link } from '@material-ui/core';

const PaymentAlert = () => {
  const { selectedBrand } = useContext(AppContext)
  const { data } = useBillingStatus(selectedBrand.id, {
    enabled: Boolean(selectedBrand),
  })
  const { data: canExtendTrial } = useCanExtendTrial(selectedBrand.id)
  const { data: paymentMethods } = usePaymentMethods(selectedBrand.id, {
    enabled: Boolean(selectedBrand),
  })
  const defaultPaymentMethod = data?.result?.billingPlatformPaymentMethodIdentifier && paymentMethods?.result?.items ?  paymentMethods.result.items.find(e => e.billingPlatformPaymentMethodIdentifier === data?.result?.billingPlatformPaymentMethodIdentifier) : null;

  const history = useHistory()

  let severity :'error' | 'warning' = 'error';
  
  const paymentLink = (
    <Link
      underline="always"
      color="inherit"
      style={{ cursor: 'pointer' }}
      onClick={() => history.push('/settings/brand/payments')}
    >
      payments
    </Link>
  )

  const planLink = (
    <Link
      underline="always"
      style={{ cursor: 'pointer' }}
      color="inherit"
      onClick={() => history.push('/plans')}
    >
      select a plan
    </Link>
  )

  const brandBillingLink = (
    <Link
      underline="always"
      style={{ cursor: 'pointer' }}
      color="inherit"
      onClick={() => history.push('/settings/brand/billing')}
    >
      settings
    </Link>
  )

  let paymentError: any = null
  switch (data?.result.featureEnablement) {
    case OrganisationBrandBillingStatusEnablement.BrowserOnlyDueToBillingFailure:
      paymentError = (
        <>
          Seeka is partially disabled. Please go to {paymentLink} and pay your overdue
          amount
        </>
      )
      break
    case OrganisationBrandBillingStatusEnablement.NoFeaturesEnabledDueToBillingFailure:
      paymentError = (
        <>Seeka is disabled. Please go to {paymentLink} and pay your overdue amount</>
      )
      break
    case OrganisationBrandBillingStatusEnablement.NoFeaturesEnabledDueToNoCurrentPlan:
      paymentError = <>Seeka is disabled. Please {planLink} to activate</>
      break
    default:
      break
  }

  if (
    !paymentError &&
    !data?.result?.activePlan &&
    data?.result?.billingGracePeriodEndsAt
  ) {
    const daysRemaining = data.result.billingGracePeriodEndsAt.diff(moment(), 'days')
    if(canExtendTrial?.result === true){
      if (daysRemaining > 1) {
        paymentError = (
          <>
            Trial period ends in {daysRemaining} days but you are eligible for a trial extension. Visit {brandBillingLink} to extend your trial
          </>
        )
      } else {
        paymentError = <>Trial period has ended but you are eligible for a trial extension. Visit {brandBillingLink} to extend your trial</>
      }
    }
    else if (daysRemaining > 1) {
      paymentError = ( 
        <>
          Trial period ends in {daysRemaining} days. {planLink} to ensure uninterrupted
          service.
        </>
      )
      severity = 'warning';
    } else {
      paymentError = <>Trial period has ended. Please {planLink} to activate</>
    }
  }
  else if(defaultPaymentMethod) {
    const expiry = moment(`${defaultPaymentMethod.expiryMonth}/${defaultPaymentMethod.expiryYear}`, 'MM/YYYY');
    const dayDiff = expiry.diff(moment(), 'days');
    if(dayDiff < 0){
      paymentError = (
        <>
          Your default payment method expired {Math.abs(Math.round(dayDiff))} days ago. Visit {paymentLink} to update your payment method
        </>
      )
    }
    else if(dayDiff < 31){
      paymentError = (
        <>
          Your default payment method expires in {Math.abs(Math.round(dayDiff))} days. Visit {paymentLink} to update your payment method
        </>
      )
    }
    else if(dayDiff < 62){
      paymentError = (
        <>
          Your default payment method expires in {Math.abs(Math.round(dayDiff))} days. Visit {paymentLink} to update your payment method
        </>
      )
      severity = 'warning';
    }
  }

  return paymentError ? <Alert severity={severity} message={paymentError} /> : null
}

export default PaymentAlert
