import React from 'react'
import { Grid, IconButton, makeStyles, Theme, Typography } from '@material-ui/core'
import {
  ZoomInRounded as ZoomInIcon,
  ZoomOutRounded as ZoomOutIcon,
} from '@material-ui/icons'

const useStyles = makeStyles((theme: Theme) => ({
  widgetContainer: {
    width: theme.spacing(6),
    position: 'fixed',
    left: 'calc(100% - 66px)',
    bottom: 24,
  },
}))

interface Props {
  zoom: number
  setZoom: (newZoom: number) => void
}

const ZoomWidget = ({ zoom, setZoom }: Props) => {
  const classes = useStyles()

  const handleIncreaseZoom = () => {
    if (zoom < 1.5) setZoom(zoom + 0.05)
  }

  const handleDecreaseZoom = () => {
    if (zoom > 0.5) setZoom(zoom - 0.05)
  }

  return (
    <Grid container direction="column" className={classes.widgetContainer}>
      <Grid item>
        <Typography variant="body2" color="error" align="center">
          <strong>{Math.round(zoom * 100)}%</strong>
        </Typography>
      </Grid>
      <Grid item>
        <IconButton onClick={handleIncreaseZoom}>
          <ZoomInIcon />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton onClick={handleDecreaseZoom}>
          <ZoomOutIcon />
        </IconButton>
      </Grid>
    </Grid>
  )
}
export default ZoomWidget
