import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import useInputStyles from 'theme/hooks/useInputStyles'
import useDebounce from 'utils/useDebounce'

import {
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from '@material-ui/core'
import { FilledInputProps } from '@material-ui/core/FilledInput'
import InfoIcon from '@material-ui/icons/InfoRounded'

interface Props {
  withMargin?: boolean
  loading?: boolean
  className?: string
  name?: string
  defaultValue?: string | number
  disabled?: boolean
  label: string
  required?: boolean
  value?: string | number
  onBlur?: any
  helperText?: string | JSX.Element
  type?: string
  onChange?: any
  placeholder?: string
  containerClassName?: string
  rows?: number
  rowsMax?: number
  outlined?: boolean
  autoFocus?: boolean
  inputRef?: any
  autocomplete?: string
  error?: boolean
  withDebounce?: boolean
  debounceInterval?: number
  invisibleInput?: boolean
  small?: boolean
  endAdornment?: JSX.Element
  startAdornment?: JSX.Element
  onClick?: () => void
  asButton?: boolean
  readOnly?: boolean
  multiline?: boolean
  id?: string
  hideAsterix?: boolean
  infoTooltipText?: string
  infoTooltipIcon?: JSX.Element
  size?: 'small' | 'medium'
  color?: 'success' | 'warning' | 'primary' | 'error' | 'neutral'
}

const TicketRevTextField = ({
  withMargin = true,
  disabled,
  loading,
  label,
  name = '',
  required,
  size,
  className,
  value,
  autocomplete,
  hideAsterix,
  id,
  containerClassName,
  autoFocus,
  outlined,
  type,
  helperText,
  rows,
  rowsMax,
  placeholder,
  onChange,
  onBlur,
  inputRef,
  error,
  defaultValue,
  withDebounce = false,
  debounceInterval = 750,
  invisibleInput,
  small,
  endAdornment,
  startAdornment,
  asButton,
  readOnly,
  multiline,
  onClick,
  infoTooltipText,
  infoTooltipIcon,
  color = 'neutral',
}: Props) => {
  const inputStyles = useInputStyles()
  const [innerValue, setInnerValue] = useState(value)
  const [debouncedUpdate] = useDebounce(innerValue, debounceInterval)

  const handleDebouncedOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInnerValue(e.target.value)
  }
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(name, e.target.value)
  }

  useEffect(() => {
    if (onChange && withDebounce && value !== innerValue) onChange(name, innerValue)
    // eslint-disable-next-line
  }, [debouncedUpdate])

  const root = classNames({
    [inputStyles.smallRoot]: small,
    [inputStyles.root]: !small && !outlined,
    [inputStyles.outlinedRoot]: outlined,
    [inputStyles.startAdornmentInput]: startAdornment,
    [inputStyles.success]: color === 'success',
    [inputStyles.warning]: color === 'warning',
    [inputStyles.primary]: color === 'primary',
    [inputStyles.error]: color === 'error',
    className,
  })

  const container = classNames(
    [inputStyles.containerRoot],
    {
      [inputStyles.withMargin]: withMargin,
    },
    containerClassName
  )

  const infoTooltipElement = infoTooltipText ? (
    <Tooltip title={infoTooltipText}>
      <IconButton aria-label={infoTooltipText}>
        {infoTooltipIcon || <InfoIcon />}
      </IconButton>
    </Tooltip>
  ) : null

  return (
    <div className={container}>
      {/* can't use startAdornment on TextField since a lot of stuff is happening with params.InputProps.startAdornment. Ugly hack for now */}
      {startAdornment && (
        <InputAdornment position="start" className={inputStyles.startAdornment}>
          {startAdornment}
        </InputAdornment>
      )}
      <TextField
        fullWidth
        disabled={disabled || loading}
        name={name}
        autoComplete={autocomplete || 'off'}
        FormHelperTextProps={{
          style: {
            whiteSpace: 'pre-wrap',
          },
        }}
        helperText={
          helperText
            ? typeof helperText === 'string'
              ? helperText.trim()
              : helperText
            : undefined
        }
        autoFocus={autoFocus}
        size={size}
        placeholder={placeholder}
        id={id}
        label={label}
        onClick={onClick}
        rows={rows}
        rowsMax={rowsMax}
        type={type}
        onChange={withDebounce ? handleDebouncedOnChange : handleOnChange}
        value={withDebounce ? innerValue || '' : value || ''}
        onBlur={onBlur}
        multiline={multiline}
        required={required}
        defaultValue={defaultValue}
        error={error}
        InputProps={
          {
            inputRef,
            readOnly: asButton || readOnly,
            endAdornment: loading ? (
              <InputAdornment position="end">
                <CircularProgress size={20} />
              </InputAdornment>
            ) : (
              endAdornment || infoTooltipElement
            ),
            classes: {
              root,
              focused: inputStyles.focused,
              error: inputStyles.error,
              disabled: inputStyles.disabled,
              input: invisibleInput
                ? inputStyles.invisibleInputElement
                : (small && inputStyles.smallInputTextFieldElement) ||
                  (startAdornment && inputStyles.startAdornmentTextFieldInput) ||
                  '',
            },
            disableUnderline: true,
            autoComplete: autocomplete || 'off',
          } as Partial<FilledInputProps>
        }
        variant="filled"
        InputLabelProps={{
          shrink: invisibleInput || small ? true : undefined,
          required: !hideAsterix && required,
          classes: {
            root: startAdornment
              ? inputStyles.startAdornmentLabel
              : (Boolean(invisibleInput || small) && inputStyles.invisibleLabel) || '',
          },
        }}
      />
    </div>
  )
}

export default TicketRevTextField
