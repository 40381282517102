import React from 'react'
import { Box, makeStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    width: 184,
    boxShadow: '-10px 0px 10px 2px #000000',
    overflow: 'hidden',
    marginTop: -2,
    height: '100vh',
    position: 'fixed',
    padding: theme.spacing(),
  },
}))

const SubSidebarMenu = ({ children }: any) => {
  const classes = useStyles()
  return <Box className={classes.root}>{children}</Box>
}

export default SubSidebarMenu
