import { api } from 'services'
import { useSnackbar } from 'notistack'
import { RouteComponentProps } from 'react-router-dom'
import {
  LoginProviderInfo,
  PropertyValidationError,
} from 'generated/api/account-service-proxies'
import { useFormik } from 'formik'
import SignupForm, { FormProps } from '../../CreateAccount/Form'

interface Props {
  loginProviders: LoginProviderInfo[]
  getRecaptchaToken: () => Promise<string | undefined>
  inviteEmail: string
  inviteToken: string
  history: RouteComponentProps['history']
}

const SignupAndAccept = ({
  inviteEmail,
  inviteToken,
  getRecaptchaToken,
  loginProviders,
  history,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar()

  const initialValues: FormProps = {
    firstName: '',
    lastName: '',
    email: inviteEmail,
    password: '',
    tosAccepted: false,
  }

  const {
    setFieldValue,
    handleSubmit,
    values,
    setSubmitting,
    isSubmitting,
    errors,
    setErrors,
  } = useFormik({
    initialValues,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (payload) => {
      try {
        const recaptchaToken = await getRecaptchaToken()
        if (!recaptchaToken) return
        const response = await api.account.triggerEmailVerification(
          payload.email,
          recaptchaToken
        )
        history.push({
          pathname: '/verify-email',
          state: {
            firstName: payload.firstName,
            lastName: payload.lastName,
            email: payload.email,
            password: payload.password,
            emailVerificationProcessId: response.result,
            inviteToken,
          },
        })
      } catch (err) {
        const { validation, message } = err.error || err || {}
        if (message) {
          enqueueSnackbar(message, {
            variant: 'error',
          })
        }
        setErrors({
          email:
            validation && validation.properties.length > 0
              ? validation.properties.map(
                  (x: PropertyValidationError, index: number) =>
                    `${x.errorMessage}${index >= 1 ? ' ' : ''}`
                )
              : message || `An error occurred. Please try again.`,
        })
        setSubmitting(false)
      }
    },
  })

  return (
    <SignupForm
      loginProviders={loginProviders}
      setFieldValue={setFieldValue}
      errors={errors}
      handleSubmit={handleSubmit}
      isSubmitting={isSubmitting}
      values={values}
      withEmail={false}
    />
  )
}

export default SignupAndAccept
