import { PageTitle } from 'components'
import { Organisation } from 'generated/api/app-service-proxies'
import { Suspense } from 'react'
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router-dom'
import { hasOneOfRequiredGrants } from 'utils'
import Layout from 'views/Layout'

interface Props extends RouteProps {
  hasLayout: boolean
  slimSideBar: boolean
  requiresOneOf: string[]
  organisation: Organisation
  roles: string[]
  roleName: string
  component: any
  containerWidth?: number
  handleReCaptchaVerify: () => Promise<string | undefined>
}

const AuthenticatedRoute = ({
  hasLayout,
  requiresOneOf,
  component: Component,
  organisation,
  roles,
  roleName,
  containerWidth,
  slimSideBar,
  handleReCaptchaVerify,
  ...rest
}: Props) => {
  return (
    <Route
      {...rest}
      render={({ match, ...props }: RouteComponentProps) => {
        if (
          !requiresOneOf ||
          requiresOneOf.length === 0 ||
          hasOneOfRequiredGrants(roles, requiresOneOf)
        ) {
          const routeComponent = (
            <Suspense fallback={<PageTitle loading />}>
              <Component
                match={match}
                {...props}
                orgName={organisation.description}
                userGrants={roles}
              />
            </Suspense>
          )
          return hasLayout ? (
            <Layout
              userGrants={roles}
              roleName={roleName}
              orgName={organisation.description}
              containerWidth={containerWidth}
              slimSideBar={slimSideBar}
              handleReCaptchaVerify={handleReCaptchaVerify}
            >
              {routeComponent}
            </Layout>
          ) : (
            routeComponent
          )
        }
        // fallback
        return <Redirect to="/dashboard/signals" />
      }}
    />
  )
}

export default AuthenticatedRoute
