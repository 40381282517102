const integrations = ['integrations'] as const
const shopifyStores = (brandId: string) => [...integrations, 'shopifyStores', brandId]
const facebookPixels = (brandId: string) => [...integrations, 'facebookPixels', brandId]
const snapchatPixels = (brandId: string) => [...integrations, 'snapchatPixels', brandId]
const pinterestPixels = (brandId: string) => [...integrations, 'pinterestPixels', brandId]
const tikTokPixels = (brandId: string) => [...integrations, 'tikTokPixels', brandId]
const connectedPipelineIntegrations = (brandId: string) => [...integrations, 'connectedPipelineIntegrations', brandId]
const googleAnalyticsPixels = (brandId: string) => [
  ...integrations,
  'googleAnalyticsPixels',
  brandId,
]

export default {
  integrations,
  shopifyStores,
  facebookPixels,
  snapchatPixels,
  pinterestPixels,
  tikTokPixels,
  googleAnalyticsPixels,
  connectedPipelineIntegrations
}
