import React from 'react'
import { IconButton, makeStyles, Tooltip, Theme } from '@material-ui/core'
import { HelpOutline as HelpIcon } from '@material-ui/icons'

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    color: theme.palette.success.main,
  },
  iconButton: {
    padding: 4,
    marginRight: 6,
    backgroundColor: theme.palette.success.light,
  },
}))

const SupportIcon = ({ tooltip = 'Help' }) => {
  const classes = useStyles()

  return (
    <Tooltip title={tooltip} arrow>
      <IconButton className={classes.iconButton}>
        <HelpIcon className={classes.icon} />
      </IconButton>
    </Tooltip>
  )
}

export default SupportIcon
