import { Alert, AppBar, PaperLoader } from 'components';
import config from 'config';
import {
    ApiResponseDtoOfOrganisation, LoginProviderInfo
} from 'generated/api/account-service-proxies';
import { useGetOrgsForEmailAddress, useMe } from 'hooks';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { RouteComponentProps } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { api } from 'services';
import loginManager from 'services/loginManager';
import { getToken, removeToken, setToken, useDocumentTitle } from 'utils';

import { Button, CircularProgress } from '@material-ui/core';
import { useSeekaConverge } from '@seeka-labs/converge-react';

import { Container, ContentCard, FooterMessageRecaptchaEtc, Title } from '../components';
import LoginAndAccept from './LoginAndAccept';
import SignupAndAccept from './SignupAndAccept';

interface Props extends RouteComponentProps {
  loginProviders: LoginProviderInfo[]
  /** used to remove invite token on route change in case of error */
  hasLoginProvidersError: boolean
  getRecaptchaToken: () => Promise<string | undefined>
  inviteToken?: string
  org?: string
  invitedEmail?: string
}

const AcceptInvitation = ({
  history,
  location,
  getRecaptchaToken,
  inviteToken,
  loginProviders,
  hasLoginProvidersError,
  org,
  invitedEmail,
}: Props) => {
  useDocumentTitle('Accept invitation')
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const token = getToken()
  const intercom = useIntercom();

  const converge = useSeekaConverge();

  const [loading, setLoading] = useState<boolean | null>(false)

  const handleLogout = (withRedirect = true) => {
    removeToken()
    queryClient.clear();

    if (config.intercomAppId) {
      try {
        intercom.update({
          email: undefined,
          name: undefined,
          userHash: undefined,
          userId: undefined,
          phone: undefined,
          company: undefined
        })
      }
      catch (err) {
        console.error(err);
      }
    }

    converge.track.custom('WebAppLogout')

    if (withRedirect) {
      history.push(`/sign-in`)
    } else {
      // trigger rerender
      history.push(`/accept-invitation${location.search}`)
    }
  }

  const {
    data: meData,
    error: meError,
    isLoading: meLoading,
  } = useMe({
    enabled: Boolean(token),
  })

  const { data: accountExistsData, isLoading: accountExistsLoading } =
    useGetOrgsForEmailAddress(invitedEmail || '', {
      enabled: Boolean(useGetOrgsForEmailAddress),
    })

  const handleLoggedInAcceptInvitation = async () => {
    setLoading(true)
    api.account
      .acceptInviteForLoggedInUser(inviteToken as string)
      .then((result: ApiResponseDtoOfOrganisation) => {
        getRecaptchaToken().then((recapchaToken) => {
          loginManager
            .getTokenForOrgOrThrow(
              getToken() as string,
              result.result.id,
              recapchaToken as string
            )
            .then((newToken) => {
              const tokenAsString = String(newToken.accessToken)
              setToken(tokenAsString)
              // queryClient.invalidateQueries(meQueryKeys.me);
              queryClient.invalidateQueries()
              history.push('/dashboard/signals')
            })
            .catch((err) => {
              setLoading(false)
              enqueueSnackbar(
                err?.error?.message || `The invitation couldn't be accepted`,
                {
                  variant: 'error',
                }
              )
            })
        })
      })
      .catch((err) => {
        setLoading(false)
        enqueueSnackbar(err?.error?.message || `The invitation couldn't be accepted`, {
          variant: 'error',
        })
      })
  }

  const isLoading = meLoading || accountExistsLoading

  const invitationEmailMatchesMeEmail = meData?.result.profile.email === invitedEmail
  const invitationEmailHasAccount =
    accountExistsData?.result && accountExistsData.result.length > 0

  const isInvalidToken = !inviteToken || !org || hasLoginProvidersError || !invitedEmail
  const validTokenWithMeError = meError && !isInvalidToken && !isLoading
  const validTokenWithLoggedInUser =
    meData && !isInvalidToken && invitationEmailMatchesMeEmail && !isLoading
  const loggedInUserNotMatchingInvitationEmail =
    meData && !isInvalidToken && !invitationEmailMatchesMeEmail && !isLoading
  const needsLogin =
    !isInvalidToken && !token && !meData && invitationEmailHasAccount && !isLoading
  const needsSignup =
    !isInvalidToken && !token && !meData && !invitationEmailHasAccount && !isLoading

  if (isInvalidToken) {
    return (
      <Container>
        <AppBar />
        <ContentCard containerHeight={500}>
          <Title title="Accept invitation" />
          <Alert
            severity="error"
            message="The invitation is not valid. Try to click the link in the invitation email again or contact us if the problem persists."
            withMargin
          />
        </ContentCard>
      </Container>
    )
  }

  if (validTokenWithMeError) {
    return (
      <Container>
        <AppBar />
        <ContentCard containerHeight={500}>
          <Title title="Accept invitation" />
          <Alert
            severity="error"
            message="An error occurred when we tried to resolve your user. Try to sign out and click the invitation link in your email inbox again."
            withMargin
          />
          <Button
            onClick={() => handleLogout()}
            variant="contained"
            color="primary"
            disableElevation
          >
            Sign out
          </Button>
        </ContentCard>
      </Container>
    )
  }

  if (loggedInUserNotMatchingInvitationEmail) {
    return (
      <Container>
        <AppBar />
        <ContentCard containerHeight={500}>
          <Title title="Accept invitation" />
          <Alert
            severity="error"
            message="The account you're logged into doesn't match the email of this invitation. Sign out and signin or create an account with the correct email."
            withMargin
          />
          <Button
            onClick={() => handleLogout(false)}
            variant="contained"
            color="primary"
            disableElevation
          >
            Sign out
          </Button>
        </ContentCard>
      </Container>
    )
  }

  if (validTokenWithLoggedInUser) {
    return (
      <Container>
        <AppBar />
        <ContentCard containerHeight={500}>
          <Title title={`You've been invited to ${org}.`} center />
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              onClick={handleLoggedInAcceptInvitation}
              variant="contained"
              color="primary"
              disableElevation
              disabled={Boolean(loading)}
            >
              {loading && (
                <CircularProgress size={18} style={{ color: '#FFF', marginRight: 8 }} />
              )}
              Accept invitation
            </Button>
          </div>
          <FooterMessageRecaptchaEtc />
        </ContentCard>
      </Container>
    )
  }

  if (needsSignup && invitedEmail) {
    return (
      <Container>
        <AppBar />
        <ContentCard containerHeight={970}>
          <Title title={`You've been invited to ${org}.`} center />
          <SignupAndAccept
            loginProviders={loginProviders}
            inviteEmail={invitedEmail}
            getRecaptchaToken={getRecaptchaToken}
            history={history}
            inviteToken={inviteToken}
          />
        </ContentCard>
      </Container>
    )
  }

  // use goBack() if for password recovery
  if (needsLogin && invitedEmail) {
    return (
      <Container>
        <AppBar />
        <ContentCard containerHeight={970}>
          <Title title={`You've been invited to ${org}.`} center />
          <LoginAndAccept
            loginProviders={loginProviders}
            inviteEmail={invitedEmail}
            getRecaptchaToken={getRecaptchaToken}
            /** ...trigger rerender so useMe() triggers with new token set inside <LoginAndAccept /> */
            onSuccess={() => {
              setLoading(null)
              handleLoggedInAcceptInvitation()
            }}
          />
        </ContentCard>
      </Container>
    )
  }

  return (
    <Container>
      <AppBar />
      <ContentCard containerHeight={500}>
        <PaperLoader />
      </ContentCard>
    </Container>
  )
}

export default AcceptInvitation
