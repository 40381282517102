import React from 'react'
import classNames from 'classnames'
import { makeStyles, Theme, useTheme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  connectingDot: {
    position: 'absolute',
    width: 10,
    height: 10,
    borderRadius: theme.shape.borderRadius,
    border: '2px solid',
    left: '50%',
    marginLeft: -5,
    zIndex: 1,
  },
}))

interface Props {
  color?: string
  placement: 'top' | 'bottom'
  className?: string
}

const ConnectingDot = ({ color, placement, className }: Props) => {
  const classes = useStyles()
  const theme = useTheme()

  const dotClassName = classNames([classes.connectingDot], className)

  return (
    <span
      className={dotClassName}
      style={{
        borderColor: '#fff',
        backgroundColor: color || theme.palette.text.disabled,
        top: placement === 'top' ? -5 : 'unset',
        bottom: placement === 'bottom' ? -6 : 'unset',
      }}
    />
  )
}
export default ConnectingDot
