import { TableCell, TableRow } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

interface Props {
  colSpan?: number
  rows?: number
  rowHeight?: number
}

const LoadingTableRow = ({ colSpan = 8, rows = 10, rowHeight = 70 }: Props) => {
  return (
    <>
      {Array.from(Array(rows).keys()).map((x) => {
        return (
          <TableRow key={x} style={{ backgroundColor: 'transparent' }}>
            <TableCell colSpan={colSpan} padding="none">
              <Skeleton variant="rect" animation="wave" width="100%" height={rowHeight} />
            </TableCell>
          </TableRow>
        )
      })}
    </>
  )
}

export default LoadingTableRow
