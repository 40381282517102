import React, { useState, useEffect, useRef, memo } from 'react'
import 'emoji-mart/css/emoji-mart.css'
import { BaseEmoji } from 'emoji-mart'
import { TokenListing } from 'generated/api/app-service-proxies'
import classNames from 'classnames'
import formatTextTokens, { TextTokensProps } from 'utils/formatTextTokens'
import { useSnackbar } from 'notistack'
import { InputLabel, Box, IconButton, Tooltip, FormHelperText } from '@material-ui/core'
import './styles.css'
import { CodeBraces as TokenIcon } from 'mdi-material-ui'
import useInputStyles from 'theme/hooks/useInputStyles'
import { groupBy, Dictionary } from 'lodash'
import {
  EditorState,
  convertFromRaw,
  CompositeDecorator,
  Editor,
  getDefaultKeyBinding,
} from 'draft-js'
import { matchSorter } from 'match-sorter'
import EmojiSelect from './components/EmojiSelect'
import pluginStyles from './pluginStyles'
import TemplateSelect from './components/TemplateSelect'
import ResolvedLinkSpan from './components/ResolvedLinkSpan'
// import CharCountChip from './components/CharCountChip'
// import SmsCountChip from './components/SmsCountChip'
import NonResolvedLinkSpan from './components/NonResolvedLinkSpan'
import SaveTemplateButton from './components/SaveTemplateButton'
import HiddenRequiredInput from './components/HiddenRequiredInput'
import TokenSpan from './components/TokenSpan'
import InlineTokenDropdown from './components/InlineTokenDropdown'
import TrackingLinkDialog from './components/TrackingLinkDialog'
import ShortlinkPopper from './components/NonResolvedLinkSpan/ShortlinkPopper'
import EditShortlinkPopper from './components/ResolvedLinkSpan/EditShortlinkPopper'
import {
  createRawContentStateFromText,
  insertCharacter,
  findTokenEntities,
  findUrlWithProtolMatchesNotTurnedToEntitiesYet,
  findUrlWithoutProtolMatchesNotTurnedToEntitiesYet,
  findUrlEntities,
  getTriggerRange,
  insertToken,
  convertToString,
  insertCharsAtCursor,
  styleMap,
  blockRenderMap,
  handleUpdateShortUrl,
  insertShortUrl,
} from './utils'

// const SMS_LENGTH = 160

export interface InlineTokenDropdownProps {
  searchText: string
  start: number
  end: number
  range: Range
  selectedIndex: number
  indexedTokens: TextTokensProps[]
  groupedSearchedTokens: Dictionary<TextTokensProps[]>
  isHovering: boolean
}

interface Props {
  name: string
  onChange?: (name: string, value: string) => void
  defaultValue?: string
  className?: string
  inputClassName?: string
  disabled?: boolean
  helperText?: string
  readOnly?: boolean
  error?: boolean
  label: string
  maxLength?: number
  tokens: TokenListing
  /** e.g. ['sms', 'digitalWallet', 'email']. Impact on shown tokens */
  scope?: string[]
  withCharCount?: boolean
  withSmsCount?: boolean
  required?: boolean
  withMargin?: boolean
  withSmsTemplates?: boolean
  withIntelligentUrls?: boolean
  intelligentUrlDefaultDirectoryPath?: string
  organisationBrandId?: string
}

const RichTextEditor = ({
  name,
  onChange,
  required,
  defaultValue = '',
  className,
  inputClassName,
  disabled,
  error,
  helperText,
  readOnly,
  withSmsTemplates = false,
  label,
  tokens,
  scope = [],
  maxLength,
  // withCharCount,
  // withSmsCount,
  withMargin = true,
  withIntelligentUrls = false,
  organisationBrandId,
}: Props) => {
  const classes = pluginStyles()
  const { enqueueSnackbar } = useSnackbar()
  const inputClasses = useInputStyles()
  const editorRef = useRef<any>(null)

  const formattedTokens = formatTextTokens(tokens, scope)

  // had trouble with editor focus when moving is inside editor container
  const [urlAnchorEl, setUrlAnchorEl] = useState<HTMLElement | null>(null)
  const [url, setUrl] = useState<{
    url: string
    start: number
    end: number
    blockKey: string
    /** if entityKey and id, it's a configured shortlink opened for editing */
    entityKey?: string
    id?: string
  } | null>(null)

  const handleSetAnchorEl = (anchorEl: HTMLElement | null) => setUrlAnchorEl(anchorEl)
  const handleSetUrl = (props: typeof url) => setUrl(props)

  const handleCloseUrlPopper = () => {
    if (urlAnchorEl) setUrlAnchorEl(null)
    if (url) setUrl(null)
  }

  const sharedUrlDecoratorProps = {
    props: {
      withIntelligentUrls,
      handleSetAnchorEl,
      handleSetUrl,
    },
  }

  const decorator = new CompositeDecorator([
    {
      strategy: findTokenEntities,
      component: TokenSpan,
      props: {
        tokens: formattedTokens,
      },
    },
    {
      strategy: findUrlEntities,
      component: ResolvedLinkSpan,
      ...sharedUrlDecoratorProps,
    },
    {
      strategy: findUrlWithProtolMatchesNotTurnedToEntitiesYet,
      component: NonResolvedLinkSpan,
      ...sharedUrlDecoratorProps,
    },
    {
      strategy: findUrlWithoutProtolMatchesNotTurnedToEntitiesYet,
      component: NonResolvedLinkSpan,
      ...sharedUrlDecoratorProps,
    },
  ])

  const [inFocus, setInFocus] = useState(false)
  const [inlineTokenDropdown, setInlineTokenDropdown] =
    useState<InlineTokenDropdownProps | null>(null)
  const [editorState, setEditorState] = useState(
    defaultValue
      ? EditorState.createWithContent(
          convertFromRaw(createRawContentStateFromText(defaultValue, formattedTokens)),
          decorator
        )
      : EditorState.createEmpty(decorator)
  )
  const [isHoveringInlineDropdown, setIsHoveringInlineDropdown] = useState(false)

  const textOnly = convertToString(editorState) // convertToString(editorState) // editorState.getCurrentContent().getPlainText('\n') // \u0001
  const charCount = textOnly.length

  const containerClassName = classNames(
    [inputClasses.root, classes.container],
    {
      [inputClasses.disabled]: disabled,
      [classes.disabled]: disabled,
      [classes.textCursor]: !disabled && !readOnly,
      [inputClasses.error]: error,
      [inputClasses.focused]: inFocus,
    },
    className
  )

  const wrapper = classNames([inputClasses.containerRoot], {
    [inputClasses.withMargin]: withMargin,
  })

  const shrinkLabel = inFocus || charCount > 0

  // Set editor in focus when container div is clicked
  const handleFocus = () => {
    if (!disabled && !readOnly && !inFocus) {
      editorRef.current.focus()
      setInFocus(true)
    }
  }

  const handleBlur = () => {
    if (inFocus) setInFocus(false)
  }

  const handleCheckMaxLength = () => {
    if (maxLength && charCount > maxLength - 1) {
      return 'handled'
    }
    return 'not-handled'
  }

  const handleCheckMaxLengthWhenPasting = (pastedText: string) => {
    if (maxLength && charCount + pastedText.length > maxLength - 1) {
      enqueueSnackbar('Inserting text exceeds max length of input', {
        variant: 'info',
      })
      return 'handled'
    }
    return 'not-handled'
  }

  const handleInsertTokenChar = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    // avoid calling editorRef.current.focus() which breaks below solution
    event.stopPropagation()
    const newEditorStateWithTokenChar = insertCharsAtCursor('{', editorState)
    setEditorState(newEditorStateWithTokenChar)
    setTimeout(() => handleFocus(), 50)
  }

  const handleSetEditorState = (newEditorState: EditorState) => {
    setEditorState(newEditorState)
  }

  const handleInsertEmoji = (emoji: BaseEmoji) => {
    setEditorState(insertCharacter(emoji.native, editorState))
  }

  // SyntheticKeyboardEvent - can't find where to import this from
  const keyBindingFn = (event: any) => {
    // hasCommandModifier(e) -> check if CTRL is activated and do epic stuff
    // const { hasCommandModifier } = KeyBindingUtil

    // if token dropdown is open and up/down is pressed, change selected index
    if (event.keyCode === 40 && inlineTokenDropdown) {
      return 'select-next-token'
    }
    if (event.keyCode === 38 && inlineTokenDropdown) {
      return 'select-previous-token'
    }
    if (
      event.keyCode === 13 &&
      inlineTokenDropdown?.indexedTokens &&
      // only hijack ENTER if token dropdown is shown
      inlineTokenDropdown.indexedTokens.length > 0
    ) {
      return 'insert-selected-token'
    }
    // support all default draft key bindings
    return getDefaultKeyBinding(event)
  }

  // intercept any key command here and do stuff
  const handleKeyCommands = (command: string) => {
    if (
      command === 'select-next-token' &&
      inlineTokenDropdown &&
      inlineTokenDropdown.selectedIndex < inlineTokenDropdown.indexedTokens.length - 1
    ) {
      setInlineTokenDropdown({
        ...inlineTokenDropdown,
        selectedIndex: inlineTokenDropdown.selectedIndex + 1,
      })
      return 'handled'
    }
    if (
      command === 'select-previous-token' &&
      inlineTokenDropdown &&
      inlineTokenDropdown.selectedIndex > 0
    ) {
      setInlineTokenDropdown({
        ...inlineTokenDropdown,
        selectedIndex: inlineTokenDropdown.selectedIndex - 1,
      })
      return 'handled'
    }
    if (command === 'insert-selected-token') {
      const tokenToInsert =
        inlineTokenDropdown?.indexedTokens[inlineTokenDropdown.selectedIndex]
      if (tokenToInsert) {
        const newEditorState = insertToken(editorState, tokenToInsert)
        setEditorState(newEditorState)
      }
      if (isHoveringInlineDropdown) {
        setInlineTokenDropdown(null)
        setIsHoveringInlineDropdown(false)
      }
      return 'handled'
    }
    return 'not-handled'
  }

  const insertTokenOnClick = (tokenValue: string) => {
    const tokenToInsert = formattedTokens.find((x) => x.value === tokenValue)
    if (tokenToInsert) {
      const newEditorState = insertToken(editorState, tokenToInsert)
      setEditorState(newEditorState)
    }
    setInlineTokenDropdown(null)
    setIsHoveringInlineDropdown(false)
  }

  useEffect(() => {
    // have to be in useEffect to ensure that editorState actually has updated
    const curlyBraceTriggered = getTriggerRange('{')
    if (curlyBraceTriggered) {
      const newSearchText = curlyBraceTriggered.text.slice(
        1,
        curlyBraceTriggered.text.length
      )
      // search the tokens with {searchString
      const newSearchedTokens = matchSorter(formattedTokens, newSearchText, {
        keys: ['description'],
      })
      // group by token group
      const groupedSearchedTokens = groupBy(newSearchedTokens, 'group')
      // flatten again... so we can keep using index to select... possibly other ways here :P
      const indexedTokens = Object.keys(groupedSearchedTokens)
        .sort()
        .map((group) => groupedSearchedTokens[group])
        .flat()

      const newSelectedIndex =
        inlineTokenDropdown && inlineTokenDropdown.selectedIndex > indexedTokens.length
          ? indexedTokens.length - 1
          : (inlineTokenDropdown && inlineTokenDropdown.selectedIndex) || 0
      setInlineTokenDropdown({
        searchText: newSearchText,
        start: curlyBraceTriggered.start,
        end: curlyBraceTriggered.end,
        range: curlyBraceTriggered.range,
        selectedIndex: newSelectedIndex,
        indexedTokens,
        groupedSearchedTokens,
        isHovering: inlineTokenDropdown?.isHovering || false,
      })
      // prevent editor from losing focus and close the dropdown on click. Close manually on token click instead
    } else if (inlineTokenDropdown && !isHoveringInlineDropdown) {
      setInlineTokenDropdown(null)
    }
  }, [editorState])

  useEffect(() => {
    if (onChange) onChange(name, convertToString(editorState))
  }, [textOnly])

  const handleSetTemplate = (body: string) => {
    setEditorState(
      EditorState.createWithContent(
        convertFromRaw(createRawContentStateFromText(body, formattedTokens)),
        decorator
      )
    )
  }

  const handleInsertUrlEntity = (
    id: string,
    destinationUrl: string,
    shortUrl: string
  ) => {
    if (url) {
      const newEditorState = insertShortUrl(
        editorState,
        destinationUrl,
        shortUrl,
        id,
        url.start,
        url.end,
        url.blockKey
      )
      setEditorState(newEditorState)
    }
    setUrlAnchorEl(null)
    setUrl(null)
  }

  const handleUpdateUrlEntity = (originalId: string, newId: string, shortUrl: string) => {
    setUrlAnchorEl(null)
    setUrl(null)
    const textWithUpdatedToken = handleUpdateShortUrl(
      editorState,
      originalId,
      newId,
      shortUrl
    )

    setEditorState(
      EditorState.createWithContent(
        convertFromRaw(
          createRawContentStateFromText(textWithUpdatedToken, formattedTokens)
        ),
        decorator
      )
    )
  }

  return (
    <>
      {withSmsTemplates && <TemplateSelect handleSetTemplate={handleSetTemplate} />}
      <div className={wrapper}>
        <Box className={containerClassName} onClick={handleFocus}>
          <InputLabel
            shrink={shrinkLabel}
            className={shrinkLabel ? classes.shrinkLabel : classes.unshrinkLabel}
            disabled={disabled}
            error={error}
          >
            {`${label}${required ? ' *' : ''}`}
          </InputLabel>
          {required && (
            <HiddenRequiredInput disabled={disabled} name={name} currentText={textOnly} />
          )}
          <div className={inputClassName}>
            <Editor
              readOnly={readOnly || disabled}
              ref={editorRef}
              editorState={editorState}
              onChange={handleSetEditorState}
              onBlur={handleBlur}
              handleBeforeInput={handleCheckMaxLength}
              keyBindingFn={keyBindingFn}
              handleKeyCommand={handleKeyCommands}
              handlePastedText={handleCheckMaxLengthWhenPasting}
              customStyleMap={styleMap}
              blockRenderMap={blockRenderMap}
            />
          </div>
          <Box className={classes.toolbarContainer}>
            <div className={classes.leftToolbar}>
              {organisationBrandId && (
                <TrackingLinkDialog
                  organisationBrandId={organisationBrandId}
                  readOnly={Boolean(readOnly)}
                  handleBlur={() => editorRef.current.blur()}
                />
              )}
              <EmojiSelect
                readOnly={readOnly}
                handleInsertEmoji={handleInsertEmoji}
                handleFocus={handleFocus}
              />

              <Tooltip title={readOnly ? '' : 'Insert token'} placement="bottom" arrow>
                <IconButton
                  size="small"
                  disabled={readOnly}
                  onClick={handleInsertTokenChar}
                >
                  <TokenIcon />
                </IconButton>
              </Tooltip>
            </div>
            <div className={classes.rightToolbar}>
              {/* withCharCount && (
                  <CharCountChip maxLength={maxLength} charCount={charCount} />
                ) */}
              {/* withSmsCount && (
                  <SmsCountChip oneSmsLength={SMS_LENGTH} charCount={charCount} />
                ) */}
            </div>
          </Box>
        </Box>
        {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
      </div>

      {withSmsTemplates && (
        <SaveTemplateButton
          currentText={textOnly}
          organisationBrandId={organisationBrandId}
        />
      )}
      {inlineTokenDropdown && (
        <InlineTokenDropdown
          {...inlineTokenDropdown}
          isHovering={isHoveringInlineDropdown}
          setIsHovering={setIsHoveringInlineDropdown}
          handleInsertToken={insertTokenOnClick}
        />
      )}
      {urlAnchorEl && url && organisationBrandId && (
        <>
          {url.entityKey && url.id ? (
            <EditShortlinkPopper
              anchorEl={urlAnchorEl}
              decoratedText={url.url}
              id={url.id}
              organisationBrandId={organisationBrandId}
              handleClose={handleCloseUrlPopper}
              updateShortlinkEntity={handleUpdateUrlEntity}
            />
          ) : (
            <ShortlinkPopper
              anchorEl={urlAnchorEl}
              decoratedText={url.url}
              organisationBrandId={organisationBrandId}
              insertShortlink={handleInsertUrlEntity}
              handleClose={handleCloseUrlPopper}
            />
          )}
        </>
      )}
    </>
  )
}

export default memo(RichTextEditor)
