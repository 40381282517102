import React from 'react'
import { makeStyles, Theme, Box } from '@material-ui/core'
import { MoreVertRounded as MoreVertIcon } from '@material-ui/icons'

const useStyles = makeStyles((theme: Theme) => ({
  roundButton: {
    flex: '0 0 auto',
    color: '#666',
    padding: 6,
    overflow: 'visible',
    fontSize: '1.5rem',
    textAlign: 'center',
    borderRadius: '50%',
    display: 'flex',
    transition: theme.transitions.create('background-color', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      opacity: 0.7,
    },
  },
}))

interface Props {
  handleClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
}

const CardHeaderActionButton = ({ handleClick }: Props) => {
  const classes = useStyles()

  return (
    <Box className={classes.roundButton} onClick={handleClick}>
      <MoreVertIcon />
    </Box>
  )
}
export default CardHeaderActionButton
