import React from 'react'
import classNames from 'classnames'
import {
  Card,
  makeStyles,
  Theme,
  CardActionArea,
  CardActions,
  Typography,
} from '@material-ui/core'
import { SharedStepProps, NoteProps } from '../../../helpers'
import useSharedStyles from '../sharedStyles'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: 280,
    margin: 'auto',
    textAlign: 'center',
    position: 'relative',
  },
  cardContainer: {
    border: 'none',
    position: 'relative',
    boxShadow: 'none',
    width: 186,
    minHeight: 100,
    margin: 'auto',
    backgroundColor: '#ffffcc',
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflow: 'hidden',
    '&::before': {
      content: '""',
      transition: theme.transitions.create('border', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      borderStyle: 'solid',
      borderColor: '#ffffdc whitesmoke',
      boxShadow: '0px 0px 12px rgb(0 0 0 / 12%)',
      borderWidth: '28px 28px 0 0',
      position: 'absolute',
      bottom: -2,
      right: -2,
      borderTopLeftRadius: theme.shape.borderRadius,
    },
    '&:hover': {
      opacity: 0.8,
      '&::before': {
        transition: theme.transitions.create(['border', 'box-shadow'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        boxShadow: 'none',
        borderWidth: '0 0 0 0',
      },
    },
  },
  cardActionArea: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    height: '100%',
    minHeight: 100,
    padding: theme.spacing(1.5),
    paddingBottom: 0,
    textAlign: 'left',
  },
  cardActions: {
    paddingLeft: 0,
    marginTop: theme.spacing(0.5),
  },
  noteFont: {
    fontSize: 11,
  },
}))

const LeaveNote = ({
  isEditing,
  editStep,
  id,
  sectionKey,
}: NoteProps & SharedStepProps) => {
  const sharedClasses = useSharedStyles()
  const classes = useStyles()

  const cardClassName = classNames([classes.cardContainer], {
    [sharedClasses.isEditing]: isEditing,
  })

  const handleEditStep = () => {
    editStep(id, sectionKey)
  }

  return (
    <div className={classes.container}>
      <Card raised={false} className={cardClassName}>
        <CardActionArea onClick={handleEditStep} className={classes.cardActionArea}>
          <Typography variant="body2" className={classes.noteFont}>
            <i>The step will split in two and if something sometihng</i>
          </Typography>
          <CardActions className={classes.cardActions}>
            <Typography variant="body2" className={classes.noteFont}>
              <strong>Feb 16, 2021</strong>
            </Typography>
          </CardActions>
        </CardActionArea>
      </Card>
    </div>
  )
}
export default LeaveNote
