import { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { PaperLoader } from 'components'
import { useMe } from 'hooks'
import { useDocumentTitle, setToken } from 'utils'
import accountRoutes from 'routes/accountRoutes'

const LoginProviderCallback = ({ location, history }: RouteComponentProps) => {
  useDocumentTitle('Signing in...')

  const [resolved, setResolved] = useState(false)

  const { data: meData } = useMe({
    enabled: resolved,
  })

  useEffect(() => {
    if (meData && resolved) history.push('/dashboard/signals')
  }, [meData])

  let { hash } = location

  if (hash.startsWith('#')) hash = hash.substring(1)

  const hashParams = hash.split('&').reduce(function (res, item) {
    const parts = item.split('=')
    // eslint-disable-next-line prefer-destructuring
    res[parts[0]] = parts[1]
    return res
  }, {}) as any

  const token = hashParams.access_token

  useEffect(() => {
    if (token) {
      setToken(token)
      setResolved(true)
    } else {
      history.push(accountRoutes.login)
    }
  }, [token])

  return <PaperLoader />
}

export default LoginProviderCallback
