import React, { Fragment } from 'react'
import { makeStyles, Theme, Typography, Divider } from '@material-ui/core'
import { Alert } from 'components'
import { FieldProps } from 'views/Channels/DigitalWallet/CreateEditDialog/helpers'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: theme.spacing(2),
  },
  fieldsContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
  },
  field: {
    padding: theme.spacing(2),
  },
  placeholder: {
    opacity: 0.6,
  },
}))

interface Props {
  fields?: FieldProps[]
}

const Back = ({ fields }: Props) => {
  const classes = useStyles()

  return (
    <>
      <div className={classes.container}>
        <Alert message="Wallet Pass user controls will appear here" withMargin />
      </div>
      <div className={classes.fieldsContainer}>
        {fields
          ?.filter((x) => x.active)
          .map((x, index) => {
            return (
              <Fragment key={x.id}>
                <div className={classes.field}>
                  <Typography
                    variant="body2"
                    gutterBottom
                    style={{ whiteSpace: 'pre-wrap' }}
                    className={x.description ? '' : classes.placeholder}
                  >
                    {x.description || 'Description'}
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ whiteSpace: 'pre-wrap' }}
                    className={x.value ? '' : classes.placeholder}
                  >
                    {x.value || 'Content'}
                  </Typography>
                </div>
                {index !== fields.length - 1 && <Divider />}
              </Fragment>
            )
          })}
      </div>
    </>
  )
}

export default Back
