import React, { useState } from 'react'
import { makeStyles, Theme, Box } from '@material-ui/core'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { v4 as uuidv4 } from 'uuid'
import SidebarMenu from '../SidebarMenu'
import SidebarUpdateStep from './components/SidebarUpdateStep'
import SidebarUtilities from './components/SidebarUtilities'
import ZoomWidget from './components/ZoomWidget'
import { StepProps, StepTypeKey, defaultStepCollection, FlowDefinition } from './helpers'
import Section from './components/Section'

const useStyles = makeStyles((theme: Theme) => ({
  container: {},
  builderContainer: {
    // background: `linear-gradient(90deg, ${theme.palette.background.default} 20px, transparent 1%) center, linear-gradient(${theme.palette.background.default} 20px, transparent 1%) center,
    // ${theme.palette.border}`,
    // backgroundSize: `22px 22px`,
    width: 'max-content',
    paddingBottom: 300,
    overflow: 'auto',
    minWidth: '100%',
    paddingTop: 71,
    transition: theme.transitions.create('padding', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    paddingRight: 100,
    position: 'relative',
  },
}))

interface Props {
  flow: FlowDefinition
  name: string
  onChange: (name: string, flow: FlowDefinition) => void
}

const FlowBuilder = ({ flow, name, onChange }: Props) => {
  const classes = useStyles()

  const [editingStep, setEditingStep] = useState<{
    sectionKey: string
    id: string
  } | null>(null)
  const [zoom, setZoom] = useState(1)

  const handleSetEditingStep = (id: string, sectionKey: string) => {
    if (id === editingStep?.id) {
      setEditingStep(null)
    } else {
      setEditingStep({ sectionKey, id })
    }
  }

  const handleAddStep = (
    type: StepTypeKey,
    insertAfterIndex: number,
    sectionKey: string
  ) => {
    const findDefaultValues = defaultStepCollection.find((x) => x.type === type)

    if (findDefaultValues) {
      if (type === StepTypeKey.CONDITIONALSPLIT) {
        const passFlowId = uuidv4()
        const failFlowId = uuidv4()

        const newStep = {
          ...findDefaultValues,
          id: uuidv4(),
          passFlowId,
          failFlowId,
        }
        const newSection =
          insertAfterIndex === -1
            ? [newStep, ...flow.sections[sectionKey]]
            : [
                ...flow.sections[sectionKey].slice(0, insertAfterIndex + 1),
                newStep,
                ...flow.sections[sectionKey].slice(insertAfterIndex + 1),
              ]
        const newFlow = {
          ...flow,
          sections: {
            ...flow.sections,
            [sectionKey]: newSection,
            [passFlowId]: [],
            [failFlowId]: [],
          },
        }
        onChange(name, newFlow)
      } else {
        const newStep = {
          ...findDefaultValues,
          id: uuidv4(),
        }
        const newSection =
          insertAfterIndex === -1
            ? [newStep, ...flow.sections[sectionKey]]
            : [
                ...flow.sections[sectionKey].slice(0, insertAfterIndex + 1),
                newStep,
                ...flow.sections[sectionKey].slice(insertAfterIndex + 1),
              ]
        const newFlow = {
          ...flow,
          sections: {
            ...flow.sections,
            [sectionKey]: newSection,
          },
        }
        onChange(name, newFlow)
      }
    }
  }

  const handleUpdateStepProperty = (
    property: string,
    value: string,
    id: string,
    sectionKey: string
  ) => {
    const newSection = [...flow.sections[sectionKey]]
    const findStepIndex = newSection.findIndex((x) => x.id === id)
    newSection[findStepIndex] = {
      ...newSection[findStepIndex],
      [property]: value,
    }
    const newFlow = {
      ...flow,
      sections: {
        ...flow.sections,
        [sectionKey]: newSection,
      },
    }
    onChange(name, newFlow)
  }

  const handleUpdateStep = (newStep: StepProps, sectionKey: string) => {
    const newSection = [...flow.sections[sectionKey]]
    const findStepIndex = newSection.findIndex((x) => x.id === newStep.id)
    newSection[findStepIndex] = { ...newStep }
    const newFlow = {
      ...flow,
      sections: {
        ...flow.sections,
        [sectionKey]: newSection,
      },
    }
    onChange(name, newFlow)
  }

  const handleRemoveStep = (id: string, sectionKey: string) => {
    const newSection = flow.sections[sectionKey].filter((x) => x.id !== id)
    if (editingStep?.id === id) setEditingStep(null)
    const newFlow = {
      ...flow,
      sections: {
        ...flow.sections,
        [sectionKey]: newSection,
      },
    }
    onChange(name, newFlow)
  }

  const handleCloseEditing = () => {
    if (editingStep) setEditingStep(null)
  }

  const findEditingStep = editingStep
    ? flow.sections[editingStep.sectionKey].find((x) => x.id === editingStep.id)
    : null

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={classes.container}>
        <SidebarMenu
          open
          paperStyle={{ marginTop: 71 }}
          width={findEditingStep ? 400 : 300}
        >
          {editingStep && findEditingStep ? (
            <SidebarUpdateStep
              step={findEditingStep}
              sectionKey={editingStep.sectionKey}
              updateStep={handleUpdateStep}
              closeEditingDrawer={() => setEditingStep(null)}
            />
          ) : (
            <SidebarUtilities addStep={handleAddStep} />
          )}
        </SidebarMenu>
        <Box
          className={classes.builderContainer}
          onClick={handleCloseEditing}
          style={{
            paddingLeft: findEditingStep ? 500 : 400,
            transform: `scale(${zoom})`,
            transformOrigin: 'top',
            marginTop: 80 * 1 + (1 - zoom),
          }}
        >
          <Section
            rootSection={flow.rootSection}
            sections={flow.sections}
            editingStepId={editingStep?.id}
            editStep={handleSetEditingStep}
            addStep={handleAddStep}
            removeStep={handleRemoveStep}
            updateStep={handleUpdateStepProperty}
          />
        </Box>
      </div>
      <ZoomWidget zoom={zoom} setZoom={(newZoom: number) => setZoom(newZoom)} />
    </DndProvider>
  )
}

export default FlowBuilder

// steps missing setup in top right corner
