import { makeStyles, Theme, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  separator: {
    marginBottom: theme.spacing(5),
    width: '100%',
    display: 'block',
    position: 'relative',
    zIndex: 1,
    overflow: 'hidden',
    '&:after': {
      position: 'absolute',
      top: '48%',
      overflow: 'hidden',
      width: '50%',
      height: 1,
      content: '"a0"',
      backgroundColor: theme.palette.divider,
      right: -22,
    },
    '&:before': {
      position: 'absolute',
      top: '51%',
      overflow: 'hidden',
      width: '50%',
      height: 1,
      content: '"a0"',
      backgroundColor: theme.palette.divider,
      textAlign: 'right',
      left: -22,
    },
  },
}))

const OrSeparator = () => {
  const classes = useStyles()

  return (
    <Typography
      align="center"
      color="textSecondary"
      variant="h5"
      className={classes.separator}
    >
      Or
    </Typography>
  )
}

export default OrSeparator
