export interface BrandsFilterProps {
  pageSize: number
  searchString?: string
}

const brands = ['brands'] as const
const singleBrands = [...brands, 'singleBrands'] as const

export default {
  brands,
  default: [...brands, 'default'] as const,
  brandsPage: (filters: BrandsFilterProps) => [...brands, filters] as const,
  singleBrands,
  singleBrand: (id: string) => [...singleBrands, id] as const,
}
