import React from 'react'
import { Link } from 'react-router-dom'
import { Tab, Tabs, makeStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(2),
    backgroundRepeat: 'repeat-x',
    backgroundSize: '100% 2px',
    backgroundPosition: '90% 90%',
    backgroundImage: 'linear-gradient(to bottom right,#0de2b8,#3e92dc)',
    paddingBottom: '5px',
  },
  tabRoot: {
    transition: theme.transitions.create(['background-color']),
    color: theme.palette.text.primary,
    minHeight: '43px',
    '&:hover': {
      color: theme.palette.primary.main,
      '&$indicator': {
        borderColor: '#fff !important',
        backgroundColor: '#fff !important',
      },
    },
  },
  tabSelected: {
    // backgroundColor: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(0.5),
    borderTopLeftRadius: theme.spacing(0.5),
    // color: `${theme.palette.text.primary} !important`,
  },
  indicator: {
    borderColor: '#fff !important',
    backgroundColor: '#fff !important',
  },
}))

const RouterLinkTab = (props: any) => {
  const classes = useStyles()
  return (
    <span>
      <Tab
        component={Link}
        {...props}
        classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
      />
    </span>
  )
}

interface TabsProps {
  label: string
  to: string
}

interface PageTabsProps {
  currentPath: string
  tabs: TabsProps[]
}

const PageTabs = ({ currentPath, tabs }: PageTabsProps) => {
  const classes = useStyles()
  return (
    <Tabs
      className={classes.root}
      variant="scrollable"
      textColor="primary"
      value={currentPath}
      scrollButtons="auto"
      classes={{ indicator: classes.indicator }}
    >
      {tabs.map((tab) => {
        return <RouterLinkTab key={tab.to} label={tab.label} to={tab.to} value={tab.to} />
      })}
    </Tabs>
  )
}

export default PageTabs
