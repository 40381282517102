import { makeStyles, Theme } from '@material-ui/core'

export default makeStyles((theme: Theme) => ({
  listItem: {
    cursor: 'pointer',
    paddingLeft: theme.spacing(1.5),
    height: 50,
    transition: theme.transitions.create('background-color', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginBottom: theme.spacing(1.5),
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      borderRadius: theme.shape.borderRadius,
      '& $icon': {
        color: theme.palette.primary.main,
      },
      '& $listItemText': {
        color: theme.palette.primary.main,
      },
      textDecoration: 'none',
    },
  },
  active: {
    backgroundColor: theme.palette.primary.light,
    borderRadius: theme.shape.borderRadius,
    '& $listItemText': {
      color: theme.palette.primary.main,
      fontWeight: 700,
    },
    '& $icon': {
      color: theme.palette.primary.main,
    },
  },
  listItemText: {
    color: theme.palette.text.secondary,
    fontSize: 16,
    lineHeight: 'initial',
    fontWeight: 500,
    transition: theme.transitions.create('color', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  icon: {
    color: theme.palette.text.secondary,
    maxWidth: 22,
    maxHeight: 32,
    height: 32,
    width: 32,
    transition: theme.transitions.create('color', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  listItemIcon: {
    minWidth: 38,
  },
}))
