import React from 'react'
import { Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import { SharedStepProps } from '../../helpers'
import UtilityItem from '../UtilityItem'
import dummyUtilities from './dummyUtilities'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    padding: theme.spacing(2),
  },
  section: {
    marginBottom: theme.spacing(3),
  },
  sectionTitle: {
    fontSize: '15px',
    marginBottom: theme.spacing(1.5),
  },
}))

interface Props {
  addStep: NonNullable<SharedStepProps['addStep']>
}

const SidebarUtilities = ({ addStep }: Props) => {
  const classes = useStyles()

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} className={classes.section}>
        <Typography className={classes.sectionTitle}>
          <strong>Outbound content</strong>
        </Typography>
        {dummyUtilities.outboundContent.map((x) => (
          <UtilityItem {...x} key={x.type} addStep={addStep} />
        ))}
      </Grid>
      <Grid item xs={12} className={classes.section}>
        <Typography className={classes.sectionTitle}>
          <strong>Actions</strong>
        </Typography>
        {dummyUtilities.actions.map((x) => (
          <UtilityItem {...x} key={x.type} addStep={addStep} />
        ))}
      </Grid>
      <Grid item xs={12} className={classes.section}>
        <Typography className={classes.sectionTitle}>
          <strong>Logic</strong>
        </Typography>
        {dummyUtilities.logic.map((x) => (
          <UtilityItem {...x} key={x.type} addStep={addStep} />
        ))}
      </Grid>
      <Grid item xs={12} className={classes.section}>
        <Typography className={classes.sectionTitle}>
          <strong>Internal helpers</strong>
        </Typography>
        {dummyUtilities.internalHelpers.map((x) => (
          <UtilityItem {...x} key={x.type} addStep={addStep} />
        ))}
      </Grid>
    </Grid>
  )
}
export default SidebarUtilities
