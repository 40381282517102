import { Box, Typography } from '@material-ui/core'
import { convertMonthlySessionsToHumanReadable } from 'utils'
import { AppBillingPlan } from 'generated/api/app-service-proxies'
import theme from 'theme'

interface Props {
  plan?: AppBillingPlan
}

const YouHaveSelected = ({ plan }: Props) => {
  return (
    <Box pt={2} pb={3}>
      <Typography
        color="textSecondary"
        variant="body2"
        style={{ marginBottom: theme.spacing(1) }}
      >
        You have selected:
      </Typography>
      <Typography
        variant="h5"
        style={{ fontSize: '20px', marginBottom: theme.spacing(1) }}
      >
        {plan ? plan.description : 'Custom'}
      </Typography>
      {plan ? (
        <>
          <Typography
            variant="h5"
            style={{ fontSize: '20px', marginBottom: theme.spacing(2) }}
          >
            {plan.pricing[0].baseCost.currencySymbol}
            {plan.pricing[0].baseCost.amount} monthly
          </Typography>
          <Typography variant="body2">
            {convertMonthlySessionsToHumanReadable(plan.entitlements.monthlySessions)}
          </Typography>

          {plan.pricing[0].costPerAdditionalSession ? (
            <Typography
              variant="body2"
              style={{ paddingTop: '20px', paddingBottom: '10px' }}
            >
              Additional sessions charged at: $
              {plan.pricing[0].costPerAdditionalSession.amount} per session
            </Typography>
          ) : (
            <> </>
          )}
        </>
      ) : undefined}
    </Box>
  )
}

export default YouHaveSelected
