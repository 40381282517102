import React from 'react'
import classNames from 'classnames'
import { makeStyles, Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    height: '54px',
    justifyContent: 'space-between',
    position: 'relative',
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
    borderRadius: theme.shape.borderRadius,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  bgColor: {
    backgroundColor: theme.palette.divider,
  },
  title: {
    fontSize: '16px',
    fontWeight: 700,
    color: theme.palette.text.primary,
  },
  toolbar: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    display: 'flex',
  },
  content: {
    paddingTop: theme.spacing(),
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(),
  },
}))

interface Props {
  title?: string
  toolbar?: JSX.Element
  skeleton?: boolean
  className?: string
  children?: JSX.Element | JSX.Element[]
}

const SectionBar = ({ title, toolbar, className, children, skeleton = false }: Props) => {
  const classes = useStyles()

  const rootClassName = classNames(
    {
      [classes.root]: true,
      [classes.bgColor]: !skeleton,
    },
    className
  )

  return skeleton ? (
    <Skeleton variant="rect" width="100%" className={rootClassName} />
  ) : (
    <>
      {title && (
        <div className={rootClassName}>
          <Typography variant="h5" className={classes.title}>
            {title}
          </Typography>
          {toolbar && <div className={classes.toolbar}>{toolbar}</div>}
        </div>
      )}
      {children && <div className={classes.content}>{children}</div>}
    </>
  )
}

export default SectionBar
