import { alpha, makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) => ({
  root: {
    border: `1px solid ${theme.palette.border}`,
    overflow: 'hidden',
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    transition: theme.transitions.create([
      'border-color',
      'box-shadow',
      'background-color',
    ]),
    '&:hover': {
      backgroundColor: '#f8f6fc',
    },
    '&$focused': {
      backgroundColor: theme.palette.common.white,
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
    '&$disabled': {
      backgroundColor: '#f8f6fc',
      boxShadow: 'none !important',
    },
  },
  outlinedRoot: {
    border: `1px solid ${theme.palette.text.primary}`,
    overflow: 'hidden',
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    transition: theme.transitions.create([
      'border-color',
      'box-shadow',
      'background-color',
    ]),
    '&:hover': {
      backgroundColor: '#f8f6fc',
    },
    '&$focused': {
      backgroundColor: theme.palette.common.white,
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
    '&$disabled': {
      backgroundColor: '#f8f6fc',
      boxShadow: 'none !important',
    },
  },
  purple: {
    backgroundColor: '#EDE9F9',
    color: theme.palette.primary.main,
  },
  focused: {
    backgroundColor: theme.palette.common.white,
    boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
    borderColor: theme.palette.primary.main,
  },
  error: {
    boxShadow: `${alpha(theme.palette.error.main, 0.25)} 0 0 0 2px !important`,
    borderColor: `${theme.palette.error.main} !important`,
  },
  warning: {
    boxShadow: `${alpha(theme.palette.warning.main, 0.25)} 0 0 0 2px !important`,
    borderColor: `${theme.palette.warning.main} !important`,
  },
  primary: {
    boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px !important`,
    borderColor: `${theme.palette.primary.main} !important`,
  },
  success: {
    boxShadow: `${alpha(theme.palette.success.main, 0.25)} 0 0 0 2px !important`,
    borderColor: `${theme.palette.success.main} !important`,
  },
  withMargin: {
    marginBottom: theme.spacing(2),
  },
  disabled: {
    backgroundColor: '#f8f6fc',
    boxShadow: 'none !important',
  },
  invisibleRoot: {
    border: 'none',
    overflow: 'hidden',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
    '&$focused': {
      backgroundColor: theme.palette.common.white,
      boxShadow: 'none',
      borderColor: 'none',
    },
    '&$disabled': {
      backgroundColor: '#f8f6fc',
      boxShadow: 'none !important',
    },
  },
  smallRoot: {
    border: `1px solid rgba(0, 0, 0, 0.23)`,
    fontSize: 14,
    overflow: 'hidden',
    borderRadius: theme.shape.borderRadius,
    paddingTop: 9,
    backgroundColor: theme.palette.common.white,
    transition: theme.transitions.create([
      'border-color',
      'box-shadow',
      'background-color',
    ]),
    '&:hover': {
      backgroundColor: '#f8f6fc',
    },
    '&$focused': {
      backgroundColor: theme.palette.common.white,
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
    '&$disabled': {
      backgroundColor: '#f8f6fc',
      boxShadow: 'none !important',
    },
  },
  invisibleLabel: {
    display: 'none',
  },
  invisibleInputElement: {
    marginTop: -10,
    marginBottom: 10,
    textAlign: 'inherit',
  },
  smallInputElement: {
    marginTop: -10,
    marginBottom: 8,
  },
  smallInputTextFieldElement: {
    padding: '15px 11px 6px',
    marginTop: -10,
    marginBottom: 7,
  },
  datePickerIconSmall: {
    marginBottom: 10,
  },
  clickHover: {
    cursor: 'pointer !important',
  },
  containerRoot: {
    position: 'relative',
  },
  startAdornmentLabel: {
    zIndex: 2,
    marginLeft: theme.spacing(4),
  },
  startAdornmentInput: {
    position: 'absolute',
    zIndex: 1,
    paddingLeft: `${theme.spacing(5)}px !important`,
  },
  startAdornment: {
    position: 'absolute',
    left: 14,
    top: 27,
    zIndex: 2,
  },
  startAdornmentTextFieldInput: {
    paddingLeft: `${theme.spacing(0.5)}px !important`,
  },
}))
