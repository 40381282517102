import React from 'react'
import {
  makeStyles,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  Button,
  Theme,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import {
  OfflineBoltOutlined as InactiveIcon,
  CheckCircleOutline as CheckIcon,
} from '@material-ui/icons'

interface Props {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  title: string
  description: string
  imgUrl?: string
  icon?: JSX.Element
  backgroundColor?: string
  active: boolean | string
  loading?: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  media: {
    height: 140,
  },
  iconContainer: {
    height: 140,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.common.white,
  },
  card: {
    boxShadow: '0 16px 64px -16px rgba(46,55,77,.1)',
  },
  cardActions: {
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(2),
  },
  status: {
    display: 'flex',
    alignItems: 'center',
  },
  active: {
    color: theme.palette.success.main,
  },
  inactive: {
    color: theme.palette.text.secondary,
  },
  icon: {
    marginRight: theme.spacing(0.6),
  },
  content: {
    minHeight: 110,
  },
}))

const IntegrationCard = ({
  title,
  description,
  active,
  backgroundColor,
  imgUrl,
  icon,
  onClick,
  loading = false,
}: Props) => {
  const classes = useStyles()

  return (
    <Card className={classes.card}>
      <CardActionArea onClick={loading ? undefined : onClick}>
        {loading ? (
          <Skeleton animation="wave" height={140} width="100%" variant="rect" />
        ) : (
          (imgUrl && (
            <CardMedia
              className={classes.media}
              image={imgUrl}
              title={`Integrate with ${title}`}
            />
          )) ||
          (icon && (
            <div className={classes.iconContainer} style={{ backgroundColor }}>
              {icon}
            </div>
          ))
        )}
        <CardContent className={classes.content}>
          {loading ? (
            <>
              <Skeleton animation="wave" height={30} width="75%" />
              <Skeleton animation="wave" height={16} width="100%" />
              <Skeleton animation="wave" height={16} width="100%" />
            </>
          ) : (
            <>
              <Typography gutterBottom variant="h5" component="h2">
                <strong>{title}</strong>
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {description}
              </Typography>
            </>
          )}
        </CardContent>
      </CardActionArea>
      <CardActions className={classes.cardActions}>
        {loading ? (
          <>
            <Skeleton animation="wave" height={30} width="25%" />
            <Skeleton animation="wave" height={30} width="25%" />
          </>
        ) : (
          <>
            <Button size="small" color="primary" onClick={onClick}>
              Learn More
            </Button>
            <Typography
              variant="body2"
              noWrap
              className={`${classes.status} ${
                active ? classes.active : classes.inactive
              }`}
            >
              {active ? (
                <>
                  <CheckIcon className={classes.icon} />
                  {typeof active === 'boolean' ? 'Active' : active}
                </>
              ) : (
                <>
                  <InactiveIcon className={classes.icon} />
                  Inactive
                </>
              )}
            </Typography>
          </>
        )}
      </CardActions>
    </Card>
  )
}

export default IntegrationCard
