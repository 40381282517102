import { useState, useEffect, useCallback } from 'react'
import {
  AddRounded as AddIcon,
  KeyboardBackspaceRounded as BackIcon,
  BusinessRounded as OrgIcon,
} from '@material-ui/icons'
import { useQueryClient } from 'react-query'
import { api } from 'services'
import { useSnackbar } from 'notistack'
import { RouteProps, getToken, setToken } from 'utils'
import { useOrganisations } from 'hooks'
import { matchSorter } from 'match-sorter'
import { DropdownDivider, TicketRevListItem, SearchBar, Alert } from 'components'
import {
  makeStyles,
  Theme,
  Typography,
  IconButton,
  Slide,
  Radio,
} from '@material-ui/core'
import loginManager from 'services/loginManager'
import CreateOrgDialog from '../../../CreateOrgDialog'
import { getBrandSelectDimensions } from '../../helpers'

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    marginLeft: theme.spacing(1.3),
  },
  backContainer: {
    display: 'flex',
    color: theme.palette.text.primary,
    marginLeft: 6,
    alignItems: 'center',
    marginBottom: theme.spacing(0.5),
  },
  searchContainer: {
    marginBottom: theme.spacing(),
  },
  brandsContainer: {
    maxHeight: 398,
    overflow: 'auto',
  },
}))

interface Props {
  toggleView: (view: 'switch-brand' | 'switch-organisation' | 'show-menu') => void
  orgId: string
  handleReCaptchaVerify: () => Promise<string | undefined>
  handleSetDimensions: (newDimensions: {
    paperWidth: number
    paperHeight: number
  }) => void
}

const ViewSwitchOrganization = ({
  toggleView,
  orgId,
  handleSetDimensions,
  handleReCaptchaVerify,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()
  const queryClient = useQueryClient()
  const [searchString, setSearchString] = useState('')
  const [createOrgDialogOpen, setCreateOrgDialogOpen] = useState(false)
  const [loading, setLoading] = useState('')

  const { data, error, isLoading } = useOrganisations()

  const orgs = data?.result || []
  const searchedOrganisations = matchSorter(orgs, searchString, {
    keys: ['organisation.description'],
  })

  useEffect(() => {
    handleSetDimensions(getBrandSelectDimensions(orgs.length > 0 ? orgs.length : 3))
  }, [])

  useEffect(() => {
    if (data) {
      handleSetDimensions(getBrandSelectDimensions(orgs.length > 8 ? 8 : orgs.length))
    }
  }, [data])

  const handleSearchChange = (newValue: string) => setSearchString(newValue)

  const handleOpenCreateDialog = () => {
    setCreateOrgDialogOpen(true)
    // handleCloseMenu()
  }

  const handleCloseCreateDialog = () => setCreateOrgDialogOpen(false)

  const changeCustomer = async (id: string) => {
    setLoading(id)
    try {
      const newToken = await loginManager.getTokenForOrgOrThrow(
        getToken() as string,
        id,
        (await handleReCaptchaVerify()) as string
      )
      setLoading('')
      setToken(newToken.accessToken)
      // TODO: test if it results in refetching everything in view with new token
      queryClient.invalidateQueries()
    } catch (err) {
      setLoading('')
      const responseError = { ...err }
      enqueueSnackbar(responseError?.error?.message || 'An error occurred', {
        variant: 'error',
      })
    }
  }

  return (
    <>
      <Slide in direction="left" timeout={{ appear: 350, enter: 200, exit: 0 }}>
        <div>
          <div className={classes.backContainer}>
            <IconButton color="inherit" onClick={() => toggleView('show-menu')}>
              <BackIcon color="inherit" />
            </IconButton>
            <Typography variant="h4" className={classes.title}>
              <strong>Select organisation</strong>
            </Typography>
          </div>
          <div className={classes.searchContainer}>
            <SearchBar
              placeholder="Search"
              handleChange={handleSearchChange}
              autoFocus
              value={searchString}
            />
          </div>
          <div className={classes.brandsContainer}>
            {isLoading && (
              <>
                <TicketRevListItem size="small" listItemSize="smallListItem" skeleton />
                <TicketRevListItem size="small" listItemSize="smallListItem" skeleton />
                <TicketRevListItem size="small" listItemSize="smallListItem" skeleton />
              </>
            )}
            {error && (
              <Alert
                severity="error"
                message={error?.error?.message || 'An error occurred'}
              />
            )}
            {searchedOrganisations.map((x) => {
              return (
                <TicketRevListItem
                  key={x.organisation.id}
                  primaryText={x.organisation.description}
                  icon={<OrgIcon />}
                  onClick={() => changeCustomer(x.organisation.id)}
                  size="small"
                  listItemSize="smallListItem"
                  noWrap
                  loading={loading === x.organisation.id}
                  disabled={
                    orgId === x.organisation.id ||
                    (loading !== x.organisation.id && Boolean(loading))
                  }
                  secondaryAction={
                    <Radio checked={orgId === x.organisation.id} color="primary" />
                  }
                />
              )
            })}
          </div>
          <DropdownDivider />
          <TicketRevListItem
            primaryText="Create organisation"
            icon={<AddIcon />}
            onClick={handleOpenCreateDialog}
            size="small"
            listItemSize="smallListItem"
          />
        </div>
      </Slide>
      {createOrgDialogOpen && (
        <CreateOrgDialog
          open={createOrgDialogOpen}
          handleClose={handleCloseCreateDialog}
          handleReCaptchaVerify={handleReCaptchaVerify}
        />
      )}
    </>
  )
}

export default ViewSwitchOrganization
