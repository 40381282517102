import React from 'react'
import { makeStyles, Theme, Tooltip } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Checkbox from '@material-ui/core/Checkbox'
import { UserRecommendation } from 'generated/api/app-service-proxies'
import {
  Link as LinkIcon,
  Create as CreateIcon,
  Info as InfoIcon,
} from '@material-ui/icons'

interface Props {
  recommendations: UserRecommendation[]
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    boxShadow: 'none',
    overflow: 'hidden',
    backgroundColor: 'transparent',
  },
  content: {
    textAlign: 'center',
  },
  selected: {
    backgroundColor: theme.palette.primary.light,
  },
  title: {
    marginTop: theme.spacing(),
  },
}))

const UserRecommendationList = ({ recommendations }: Props) => {
  const classes = useStyles()
  const [checked, setChecked] = React.useState(
    recommendations.map((_r: UserRecommendation, index: number) => index)
  )

  const getIconForRecommendation = (recommendationTypeKey: string): JSX.Element => {
    switch (recommendationTypeKey) {
      case 'text.content.url.convert':
        return <LinkIcon />
      case 'sms.sender.name.provide':
        return <CreateIcon />
      default:
        return <InfoIcon />
    }
  }

  const handleRecommendationActionToggle = (recommendationIndex: number) => () => {
    const currentIndex = checked.indexOf(recommendationIndex)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(recommendationIndex)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  return recommendations && recommendations.length > 0 ? (
    <List dense className={classes.root}>
      {recommendations.map((recommendation: UserRecommendation, index: number) => {
        const labelId = `recommendation-label-${index}`
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Tooltip title={recommendation.reasonDescriptionLong} key={index}>
            <ListItem button>
              <ListItemIcon>
                {getIconForRecommendation(recommendation.recommendationTypeKey)}
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={recommendation.actionDescription}
                secondary={recommendation.reasonDescriptionShort}
              />
              {recommendation.isActionAutomated ? (
                <ListItemSecondaryAction>
                  <Checkbox
                    edge="end"
                    onChange={handleRecommendationActionToggle(index)}
                    checked={checked.indexOf(index) !== -1}
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemSecondaryAction>
              ) : (
                <></>
              )}
            </ListItem>
          </Tooltip>
        )
      })}
    </List>
  ) : (
    <></>
  )
}

export default UserRecommendationList
