import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';

import overrides from './overrides';
import palette from './palette';
import shape from './shape';
import typography from './typography';

interface BrandObject {
  backgroundColor: string
  color: string
}
interface ConvergeObject {
  backgroundColor: string
  color: string
}
interface ConvergeDestinationObject {
  backgroundColor: string
  color: string
}
interface Gradient {
  blue: string
  red: string
  purple: string
}
export interface Brand {
  facebook: BrandObject
  google: BrandObject
  sms: BrandObject
  email: BrandObject
  omnichannel: BrandObject
  wallet: BrandObject
  messenger: BrandObject
  ticketrev: BrandObject
  manychat: BrandObject
  gatsby: BrandObject
  magento: BrandObject
  zenGlobal: BrandObject
  webhook: BrandObject
  wait: BrandObject
  goal: BrandObject
  stage: BrandObject
  note: BrandObject
  conditionalSplit: BrandObject
  oneTimeCampaign: BrandObject
  automatedCampaign: BrandObject
  enrichmentCampaign: BrandObject
  acquisitionCampaign: BrandObject
  outboundDirection: BrandObject
  inboundDirection: BrandObject
  omniDirection: BrandObject
}

export interface Converge {
  shopify: ConvergeObject
  oztix: ConvergeObject
  megatix: ConvergeObject
  woocommerce: ConvergeObject
  zapier: ConvergeObject
  manychat: ConvergeObject
  gatsby: ConvergeObject
  magento: ConvergeObject
  bigcommerce: ConvergeObject
  wordpress: ConvergeObject
  scriptTag: ConvergeObject
  iframe: ConvergeObject
  seekaApp: ConvergeObject
  goHiLevel: ConvergeObject
}

export interface ConvergeDestination {
  facebook: ConvergeDestinationObject
  googleAds: ConvergeDestinationObject
  googleAnalytics: ConvergeDestinationObject
  snapchat: ConvergeDestinationObject
  pinterest: ConvergeDestinationObject
  tiktok: ConvergeDestinationObject
}

export interface PipelineIntegration {
  klaviyo: ConvergeDestinationObject
  loke: ConvergeDestinationObject
}

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    warning: PaletteColor
    success: PaletteColor
    info: PaletteColor
    gradient: Gradient
    brand: Brand
    converge: Converge
    convergeDestination: ConvergeDestination
    pipelineIntegration: PipelineIntegration
    border: string
    divider: string
  }
  interface PaletteOptions {
    warning?: PaletteColorOptions
    success?: PaletteColorOptions
    info?: PaletteColorOptions
  }
}

/* declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    border: string
    divider: string
  }
  interface ThemeOptions {
    border?: string
    divider?: string
  }
} */

const theme = createTheme({
  palette,
  typography,
  overrides,
  shape,
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiButton: {
      disableElevation: true,
    },
  },
})

export default responsiveFontSizes(theme)
